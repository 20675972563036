<ng-container>
  <ng-template #customContent>
    <p>
      The left Menu bar has links to all <b>Zceppa features</b> and product your
      purchase.
    </p>
    <p>
      <b>Settings</b> takes you to your Zceppa configurations along with your
      business profile, account details, your Plan information and usage
      metrics.
    </p>
  </ng-template>
  <ng-template #doneButton>
    <my-button class="joyride-button cursor-pointer">Done</my-button>
  </ng-template>

  <div
    joyrideStep="firstStep"
    text="Hi, Glad to have you here. Let us show you how easy it is to use Zceppa. Click next to begin."
    stepPosition="center"
  ></div>
  <nav
    joyrideStep="secondStep"
    text="{{ productTourSteps?.stepTwo[0] }}"
    class="navbar custom-navbar bg-faded main-header px-3 top_headermenu"
  >
    <a
      href="javascript:;"
      class="nav-link pl-0 d-lg-none"
      (click)="toggleSidenav.emit()"
      [joyrideStep]="joyRideIsMobileSidebar()"
      [stepContent]="customContent"
      [doneTemplate]="doneButton"
      (next)="onDone()"
    >
      <i class="icon ion-android-menu"></i>
    </a>
    <div class="d-flex flex-row flex-wrap align-items-center">
      <p
        class="welcome-text headweltext ellipse-business-name mb-1 pr-3"
        [title]="business_name"
        style="flex: 0 1 auto"
      >
        Welcome
        <span class="font-weight-bold"> {{ business_name }}</span>
      </p>
      <div class="" *ngIf="isBusinessView">
        <div class="btn-group">
          <button
            class="btn on-hover btn-secondary"
            [routerLink]="[switchToMultilocView]"
            [class.btn-primary]="isMultiLocation"
          >
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#menu-sprite-multi-location'"
              [width]="'20px'"
              [height]="'22px'"
            >
            </svg-icon-sprite>
          </button>
          <button
            class="btn on-hover btn-secondary"
            [routerLink]="['/dashboard']"
            [class.btn-primary]="!isMultiLocation"
            [disabled]="!this.is_outlet_view"
          >
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#menu-sprite-location'"
              [width]="'13px'"
              [height]="'19px'"
            >
            </svg-icon-sprite>
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row">
      <ng-container *ngIf="!isMultiLocation">
        <ul class="navbar-nav">
          <li class="nav-item" style="display: grid">
            <div class="outlet-dropdown">
              <ng-select
                class="outlet-dropdown"
                placeholder="Select Outlet"
                [items]="outlet_list"
                class="ng-select-custom"
                (ngModelChange)="changeOutlet($event)"
                bindValue="outlet_id"
                [(ngModel)]="outlet_details"
                [clearable]="false"
                [loading]="outletLoading"
                #selectOutlet
                (close)="selectOutlet.blur()"
                [clearOnBackspace]="true"
                notFoundText="No Outlet Found"
                bindLabel="internal_name"
                autocomplete="off">
                <ng-template ng-option-tmp let-item = "item">
                  <span [title]="item.internal_name">{{ item.internal_name }}</span>
                </ng-template>
              </ng-select>
            </div>
          </li>

          <li
            class="nav-item"
            joyrideStep="thirdStep"
            [text]="
              showButton.buttonName == 'Send Review Invite'
                ? productTourSteps.stepThree[0]
                : showButton.buttonName == 'Create Order'
                ? productTourSteps.stepThree[1]
                : productTourSteps.stepThree[2]
            "
          >
            <button
              *ngIf="showButton.buttonName"
              (click)="changeRoute(showButton.route)"
              [disabled]="disableOnGatherInfo()"
              class="btn btn-secondary btn-sm px-3 feedback-btn slideleft mobhide"
            >
              <span>
                <ng-container
                  *ngIf="showButton.buttonName == 'Send Review Invite'"
                >
                  <img src="assets/images/send.svg" />
                </ng-container>
                <ng-container
                  *ngIf="showButton.buttonName !== 'Send Review Invite'"
                >
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#' + showButton.buttonIcon"
                    [width]="'22px'"
                    [height]="'22px'"
                    style="position: relative; top: -3px"
                  >
                  </svg-icon-sprite>
                </ng-container> </span
              >&nbsp;
              {{ showButton.buttonName }}
            </button>
            <button
              *ngIf="showButton.buttonName"
              (click)="changeRoute(showButton.route)"
              [disabled]="disableOnGatherInfo()"
              class="btn btn-secondary btn-sm px-3 feedback-btn mobshow"
            >
              <span>
                <ng-container
                  *ngIf="showButton.buttonName == 'Send Review Invite'"
                >
                  <img src="assets/images/send.svg" />
                </ng-container>
                <ng-container
                  *ngIf="showButton.buttonName !== 'Send Review Invite'"
                >
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#' + showButton.buttonIcon"
                    [width]="'22px'"
                    [height]="'22px'"
                    style="position: relative; top: -3px"
                  >
                  </svg-icon-sprite>
                </ng-container> </span
              >&nbsp;
            </button>
          </li>
        </ul>
        <ul
          class="navbar-nav notify_dropdown"
          style="cursor: pointer"
          *ngIf="this.main_menu.includes('Interact')"
        >
          <li
            *ngIf="!isMultiLocation"
            class="nav-item"
            (click)="goToInteract()"
          >
            <div class="new-message-notification-wrap">
              <!-- <img src="assets/icons/menu-interact.svg" alt="" /> -->
              <img src="assets/images/header-interact-notification.svg" alt="interact" />
              <div *ngIf="new_msg_count" class="new-message-notification">
                <span class="msg-count">{{ new_msg_count }}</span>
              </div>
              <audio id="notificationSound">
                <source src="assets/sound/notification-sound.mp3" type="audio/mpeg">
              </audio>
            </div>
          </li>
        </ul>
      </ng-container>

      <ul class="navbar-nav notify_dropdown" *ngIf="!isMultiLocation">
        <li
          class="nav-item"
          ngbDropdown
          placement="bottom-right"
          (click)="countDown()"
          [class.pointer-disable]="disableOnGatherInfo()"
        >
          <a class="nav-link" ngbDropdownToggle
            >
            <!-- <img src="assets/images/bell.svg" /> -->
            <img src="assets/images/header-bell.svg" />
            <span class="notifydot" *ngIf="total_review_count != 0"></span
          ></a>

          <div
            ngbDropdownMenu
            class="notification-dropdown dropdown-menu-right pb-0"
          >
            <perfect-scrollbar style="max-height: 100%">
              <a
                class="dropdown-item notif d-flex"
                *ngFor="let notif of latestnotification"
                style="
                  background: url('assets/images/notifygrey.png');
                  background-size: 50px 100% !important;
                  background-repeat: no-repeat;
                "
              >
                <div class="notification-icon">
                  <img src="../../../assets/images/db_zceppa.png" />
                  <div
                    *ngIf="notif.is_seen == false"
                    class="unseen-noti-dot"
                  ></div>
                </div>

                <div
                  class="notification-details flex-grow-1"
                  (click)="reviews(notif)"
                >
                  <div class="m-0 d-flex align-items-start">
                    <span>
                      <span *ngIf="notif.category === 'goal_status'"
                        >Review Goal &nbsp;</span
                      >
                      <span *ngIf="notif.category === 'renewal_reminder'"
                        >Renewal Reminder &nbsp;</span
                      >
                      <span *ngIf="notif.category === 'credit_reminder'"
                        > {{ notif.data.title }} </span
                      >

                      <span *ngIf="notif.category === 'campaign_reminder'"
                        >Campaign Reminder &nbsp;</span
                      >
                      <span *ngIf="notif.category === 'credits_exhausted'"
                        >Credits Exhausted &nbsp;</span
                      >
                      <span *ngIf="notif.category === 'bad_review'"
                        >Negative Review &nbsp;</span
                      >
                      <span *ngIf="notif.category === 'new_review'"
                        >Positive Review&nbsp;</span
                      >
                      <span *ngIf="notif.category === 'new_ticket'"
                        >New Ticket assigned to
                        {{ notif.data.assigned_to }}&nbsp;</span
                      >
                      <span *ngIf="notif.category === 'ticket_status_update'"
                        >Ticket {{ notif.data.ticket_id }} update&nbsp;</span
                      >
                      <span *ngIf="notif.category === 'bulk_upload_status'"
                        >Contact Uploaded
                        <ng-container *ngIf="notif.data?.filename">
                          from {{ notif.data.filename }}
                        </ng-container>
                        <ng-container *ngIf="notif.data?.customer_list_name">
                          to {{ notif.data.customer_list_name }}
                        </ng-container>
                        &nbsp;
                      </span>
                      <span *ngIf="notif.category === 'ticket_reply_update'"
                        >New Reply for Ticket
                        {{ notif.data.ticket_id }}&nbsp;</span
                      >
                      <span *ngIf="notif.category === 'ticket_note_update'"
                        >New Note Created by
                        {{ notif.data.created_by }}&nbsp;</span
                      >
                      <span *ngIf="notif.category === 'ticket_assignee_update'"
                        >Ticket Assignee Update
                        {{ notif.data.ticket_id }}&nbsp;</span
                      >

                      <!-- sd bulk campaign -->
                      <span *ngIf="notif.category === 'bulk_campaign_status'"
                        >Campaign Status</span
                      >
                      <span *ngIf="notif.category === 'bulk_message_status'"
                        >Message Status
                        <ng-container *ngIf="notif.data.filename">
                          from {{ notif.data.filename }}
                        </ng-container>
                        <ng-container *ngIf="notif.data.list_name">
                          from {{ notif.data.list_name }}
                        </ng-container>
                      </span>
                      <span *ngIf="notif.category === 'new_appointment'"
                        >New Appointment</span
                      >
                      <span *ngIf="notif.category === 'update_appointment'">
                        Appointment Update
                      </span>
                      <span *ngIf="notif.category === 'delete_appointment'"
                        >Cancel Appointment</span
                      >
                      <span *ngIf="notif.category === 'new_enquiry'">
                        New Enquiry
                      </span>
                      <span *ngIf="notif.category === 'social_template_update'"
                        >New Social Template</span
                      >

                      <span *ngIf="notif.category === 'template_update'">
                        {{ notif.data.title }}
                      </span>

                      <span *ngIf="notif.category === 'reconnect_google'"
                        >Reconnect Google</span
                      >
                      <span
                        *ngIf="
                          notif.category === 'google_messaging_launch_status'
                        "
                        >{{ notif.data.title }}</span
                      >
                      <span *ngIf="notif.category === 'new_order_status'"
                        >New Order</span
                      >
                      <span *ngIf="notif.category === 'bulk_product_status'"
                        >Product Uploaded</span
                      >
                      <span *ngIf="notif.category === 'bulk_review_status'"
                        >Send Review Status</span
                      >
                      <span *ngIf="notif.category === 'order_update_status'"
                        >Order Update Status</span
                      >
                      <span *ngIf="notif.category === 'order_delete_status'"
                        >Order Delete Status</span
                      >
                      <span *ngIf="notif.category === 'product_update_status'"
                        >Product Update Status</span
                      >
                      <span *ngIf="notif.category === 'product_delete_status'"
                        >Product Delete Status</span
                      >
                      <span *ngIf="notif.category === 'payment_request'"
                        >Payment Request</span
                      >
                      <span *ngIf="notif.category === 'app_update'"
                        >App Update</span
                      >
                      <span *ngIf="notif.category === 'contact_delete_status'"
                        >Contact Delete Status</span
                      >
                      <span *ngIf="notif.category === 'listing_updates'"
                        >New Notification from
                        {{ notif?.data?.content?.listing }}</span
                      >
                      <span *ngIf="notif.category === 'social_post'">{{
                        notif.data.title
                      }}</span>
                      <span
                        *ngIf="
                          notif.data.source != 'INTERNAL' &&
                          (notif.category === 'bad_review' ||
                            notif.category === 'new_review')
                        "
                        >on {{ notif.data.source }}</span
                      >
                      <span *ngIf="notif.data.source == 'INTERNAL'"
                        >on Zceppa</span
                      >
                    </span>

                    <!-- <span *ngIf="notif.data.source == undefined && notif.category === 'new_ticket'">{{notif.data.assigned_to}}</span>
                <span *ngIf="notif.data.source == undefined && notif.category === 'ticket_status_update'">{{notif.data.assigned_to}}</span> -->
                    <!-- <span class="badge badge-pill badge-primary ml-1 mr-1">3</span> -->
                    <span class="flex-grow-1"></span
                    ><span
                      class="text-small text-muted ml-auto"
                      style="float: right; white-space: nowrap"
                      >{{ notif.created_at | dateFormatter : "ago" }}</span
                    >
                  </div>
                  <ng-container
                    *ngIf="
                      notif.category !== 'ticket_reply_update' ||
                      notif.category !== 'ticket_assignee_update' ||
                      notif.category !== 'ticket_note_update' ||
                      notif.category !== 'order_update_status' ||
                      notif.category === 'order_delete_status' ||
                      notif.category === 'contact_delete_status'
                    "
                  >
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        (notif.category === 'bulk_upload_status' ||
                          notif.category === 'bulk_product_status') &&
                        notif.category !== 'new_ticket' &&
                        notif.category !== 'ticket_status_update'
                      "
                    >
                      <ng-container
                        *ngIf="
                          notif.category === 'bulk_upload_status';
                          else bulk_upload_status
                        "
                      >
                        <ng-container
                          *ngIf="
                            notif.data.error_message;
                            else bulk_upload_status
                          "
                        >
                          &nbsp;{{ notif.data.error_message }}
                        </ng-container>
                      </ng-container>
                      <ng-template #bulk_upload_status>
                        &nbsp;Added : {{ notif.data.added }} &nbsp;Skipped :
                        {{ notif.data.skipped }} &nbsp;Duplicate :
                        {{ notif.data.duplicate }}
                      </ng-template>
                      <!-- <p class="text-small text-muted m-0" *ngIf="notif.category !== 'bulk_upload_status' && notif.category !== 'new_ticket' && notif.category !== 'ticket_status_update'">{{ notif.data.reviewer_name}}: {{ (notif.data.comment.length>100)? (notif.data.comment | slice:0:100)+ '...':(notif.data.comment) }}</p> -->
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category !== 'bulk_upload_status' &&
                        notif.category !== 'bulk_product_status' &&
                        notif.category !== 'new_ticket' &&
                        notif.category !== 'ticket_status_update' &&
                        notif.category !== 'campaign_reminder' &&
                        notif.category !== 'ticket_reply_update' &&
                        notif.category !== 'ticket_note_update' &&
                        notif.category !== 'ticket_assignee_update' &&
                        notif.category !== 'renewal_reminder' &&
                        notif.category !== 'credit_reminder' &&
                        notif.category !== 'goal_status' &&
                        notif.category !== 'credits_exhausted' &&
                        notif.category !== 'bulk_campaign_status' &&
                        notif.category !== 'bulk_message_status' &&
                        notif.category !== 'new_order_status' &&
                        notif.category !== 'new_appointment' &&
                        notif.category !== 'update_appointment' &&
                        notif.category !== 'delete_appointment' &&
                        notif.category !== 'new_enquiry' &&
                        notif.category !== 'social_template_update' &&
                        notif.category !== 'template_update' &&
                        notif.category !== 'reconnect_google' &&
                        notif.category !== 'google_messaging_launch_status' &&
                        notif.category !== 'payment_request' &&
                        notif.category !== 'app_update' &&
                        notif.category !== 'bulk_review_status' &&
                        notif.category !== 'order_update_status' &&
                        notif.category !== 'order_delete_status' &&
                        notif.category !== 'contact_delete_status' &&
                        notif.category !== 'product_update_status' &&
                        notif.category !== 'product_delete_status' &&
                        notif.category !== 'listing_updates' &&
                        notif.category !== 'social_post'
                      "
                    >
                      {{ notif.data.comment | slice : 0 : 100 }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category == 'listing_updates'"
                    >
                      {{ notif.data.content.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'social_post'"
                    >
                      {{ notif.data.content }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        (notif.category !== 'bulk_upload_status' &&
                          notif.category !== 'bulk_product_status' &&
                          notif.category !== 'campaign_reminder' &&
                          notif.category !== 'renewal_reminder' &&
                          notif.category !== 'credit_reminder' &&
                          notif.category !== 'credits_exhausted' &&
                          notif.category !== 'goal_status' &&
                          notif.category !== 'bulk_review_status' &&
                          notif.category === 'new_ticket') ||
                        notif.category === 'ticket_status_update'
                      "
                    >
                      {{ notif.data.title }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category !== 'bulk_upload_status' &&
                        notif.category !== 'bulk_product_status' &&
                        notif.category !== 'bulk_review_status' &&
                        notif.category === 'goal_status'
                      "
                    >
                      {{ notif.data.content }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category !== 'bulk_upload_status' &&
                        notif.category !== 'bulk_product_status' &&
                        notif.category !== 'bulk_review_status' &&
                        notif.category === 'campaign_reminder'
                      "
                    >
                      {{ notif.data.title }}:
                      {{ notif.data.content | slice : 0 : 100 }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category !== 'bulk_upload_status' &&
                        notif.category !== 'bulk_product_status' &&
                        notif.category !== 'bulk_review_status' &&
                        notif.category === 'renewal_reminder'
                      "
                    >
                      {{ notif.data.title }}:
                      {{ notif.data.content | slice : 0 : 100 }}
                    </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category !== 'bulk_upload_status' &&
                        notif.category !== 'bulk_product_status' &&
                        notif.category !== 'bulk_review_status' &&
                        notif.category === 'credits_exhausted'
                      "
                    >
                      {{ notif.data.title }}: {{ notif.data.content }}
                    </p>

                    <p
                    class="text-small text-muted m-0"
                    *ngIf="
                      notif.category !== 'bulk_upload_status' &&
                      notif.category !== 'bulk_product_status' &&
                      notif.category !== 'bulk_review_status' &&
                      notif.category === 'credit_reminder'
                    "
                  >
                    {{ notif.data.content }}
                  </p>

                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'bulk_campaign_status'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'bulk_message_status'"
                    >
                      <ng-container *ngIf="notif.data.content">
                        {{ notif.data.content }}
                      </ng-container>
                      <ng-container *ngIf="!notif.data.content">
                        <ng-container
                          *ngIf="
                            notif.data.error_message;
                            else bulk_message_status
                          "
                        >
                          &nbsp;{{ notif.data.error_message }}
                        </ng-container>
                      </ng-container>
                      <ng-template #bulk_message_status>
                        &nbsp;Sent : {{ notif.data.sent }} &nbsp;Failed :
                        {{ notif.data.failed }} &nbsp;Duplicate :
                        {{ notif.data.duplicate }}
                      </ng-template>
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'bulk_review_status'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'new_appointment'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p *ngIf="notif.category === 'update_appointment'"
                      class="text-small text-muted m-0">
                      {{ notif.data.content }}
                    </p>
                    <p *ngIf="notif.category === 'new_enquiry'"
                      class="text-small text-muted m-0">
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'delete_appointment'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'social_template_update'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'template_update'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'reconnect_google'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="
                        notif.category === 'google_messaging_launch_status'
                      "
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'new_order_status'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'payment_request'"
                    >
                      {{ notif.data.content }}
                    </p>
                    <p
                      class="text-small text-muted m-0"
                      *ngIf="notif.category === 'app_update'"
                    >
                      {{ notif.data.content }}
                    </p>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      notif.category === 'ticket_reply_update' ||
                      notif.category === 'ticket_note_update' ||
                      notif.category === 'ticket_assignee_update'
                    "
                  >
                    <p class="text-small text-muted m-0">
                      {{ notif.data.title }}
                    </p>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      notif.category === 'order_update_status' ||
                      notif.category === 'order_delete_status' ||
                      notif.category === 'contact_delete_status' ||
                      notif.category === 'product_update_status' ||
                      notif.category === 'product_delete_status'
                    "
                  >
                    <p class="text-small text-muted m-0">
                      {{ notif.data.content }}
                    </p>
                  </ng-container>
                </div>
              </a>
              <div
                class="d-flex dropdown-item"
                *ngIf="latestnotification.length != 0"
              >
                <a
                  href="javascript:;"
                  [routerLink]="['/notifications']"
                  class="notify_viewmore"
                  >View More</a
                >
              </div>
              <div class="d-flex" *ngIf="latestnotification.length == 0">
                <p style="text-align: center; width: 100%; padding: 80px 0px">
                  No Notification Found!
                </p>
              </div>
            </perfect-scrollbar>
          </div>
        </li>
      </ul>

      <ng-container *ngIf="isMultiLocation && businessList?.length>1">
        <ul class="navbar-nav mr-3">
          <li class="nav-item" style="display: grid">
            <div class="outlet-dropdown">
              <ng-select
                class="outlet-dropdown"
                placeholder="Select Business"
                [items]="businessList"
                class="ng-select-custom"
                (ngModelChange)="changeBusiness($event)"
                bindValue="business_id"
                [(ngModel)]="businessDetails"
                [clearable]="false"
                [loading]="businessLoading"
                #selectBusiness
                (close)="selectBusiness.blur()"
                [clearOnBackspace]="true"
                notFoundText="No Business Found"
                bindLabel="business_name"
                autocomplete="off">
                <ng-template ng-option-tmp let-item = "item">
                  <span [title]="item.business_name">{{ item.business_name }}</span>
                </ng-template>
              </ng-select>
            </div>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="isMultiLocation">
        <app-interact-notification
          class="d-flex"
          [notificationType]="'popup'"
        ></app-interact-notification>
        <app-business-notification-popup
          class="d-flex"
          [notificationType]="'popup'"
        >
        </app-business-notification-popup>
      </ng-container>

      <ul class="navbar-nav">
        <li
          class="nav-item product-tour-menu"
          ngbDropdown
          placement="bottom-right"
        >
          <a href="javascript:;" class="nav-link" ngbDropdownToggle>
            <ngx-avatar
              *ngIf="profile_image_url == null"
              [name]="user_name"
              textSizeRatio="2"
              size="32"
              [round]="true"
              bgColor="#868e96"
            ></ngx-avatar>
            <img
              *ngIf="profile_image_url != null"
              [src]="profile_image_url"
              imgPreview
              style="width: 30px; border-radius: 50px; height: 30px"
            />
          </a>

          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <div
              joyrideStep="fifthStep"
              text="The account page also has your current plan information and links for
        product you have purchased."
              (next)="redirectToNextTour()"
            >
              <a
                *ngIf="is_outlet_view"
                class="dropdown-item"
                href="javascript:;"
                [routerLink]="['/profile']"
                (click)="supportSelection()"
                [class.pointer-disable]="disableOnGatherInfo()"
              >
                <span
                  ><img src="assets/icons/profile.svg" width="25" class="mr-3"
                /></span>
                Profile
              </a>
              <a
                *ngIf="!isMultiLocation"
                class="dropdown-item"
                href="javascript:;"
                [routerLink]="['/accounts']"
                (click)="accountSelection()"
                [class.pointer-disable]="disableOnGatherInfo()"
              >
                <span
                  ><img src="assets/images/account.svg" width="25" class="mr-3"
                /></span>
                Account
              </a>
              <a
                *ngIf="!isMultiLocation && isDashboardMenu()"
                class="dropdown-item"
                href="javascript:;"
                (click)="onClick()"
                [class.pointer-disable]="disableOnGatherInfo()"
              >
                <span
                  ><img
                    src="assets/images/guide.svg"
                    width="25"
                    class="mr-3" /></span
                >Product Tour
              </a>
              <a
                *ngIf="!isMultiLocation"
                class="dropdown-item"
                href="javascript:;"
                [routerLink]="['/api-docs']"
                (click)="supportSelection()"
                [class.pointer-disable]="disableOnGatherInfo()"
              >
                <span
                  ><img
                    src="assets/icons/api-key.svg"
                    width="25"
                    class="mr-3" /></span
                >API Key
              </a>
              <a
                *ngIf="is_outlet_view"
                class="dropdown-item"
                href="javascript:;"
                [routerLink]="['/support']"
                (click)="supportSelection()"
                [class.pointer-disable]="disableOnGatherInfo()"
              >
                <span
                  ><img
                    src="assets/icons/menu-help.svg"
                    width="25"
                    class="mr-3" /></span
                >Support
              </a>
              <a class="dropdown-item" href="javascript:;" (click)="signout()">
                <span
                  ><img
                    src="assets/icons/logout.svg"
                    width="25"
                    class="mr-3" /></span
                >Logout
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>

  <ng-template #sendReviewModal>
    <div class="row review-invite w-100">
      <div class="col-lg-12 col-md-12 col-sm-12 left-container popsendview">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="sendReviewModalClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div
          class="col-lg-4 col-md-4 col-sm-4 popsendviewleft"
          *ngIf="sendreview == true"
        >
          <div class="share-options-container">
            <p class="heading">Send Review Invite</p>
            <h6>Type</h6>
            <div class="form-check">
              <label for="sendsingle" class="radio-container">
                <span class="share-option-title">Single</span>
                <input
                  id="sendsingle"
                  type="radio"
                  name="sendbulksingle"
                  value="0"
                  (change)="choosetype($event)"
                  checked
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="form-check">
              <label for="sendbulk" class="radio-container">
                <span class="share-option-title">Bulk</span>
                <input
                  id="sendbulk"
                  type="radio"
                  name="sendbulksingle"
                  value="1"
                  (change)="choosetype($event)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="share-options-container">
            <h6>Medium</h6>
            <div
              class="form-check"
              *ngFor="let option of shareOptions; let i = index"
            >
              <label for="radio{{ i }}" class="radio-container">
                <span class="share-option-title">{{ option.title }}</span>
                <input
                  id="radio{{ i }}"
                  type="radio"
                  [value]="option.title"
                  [checked]="option.selected"
                  name="share-option-radio"
                  (change)="onShareOptionChange(option, i)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 popsendviewright">
          <form
            class="modalreviewadd"
            [formGroup]="reviewSendForm"
            (ngSubmit)="getReviewTemplate(reviewSendForm)"
          >
            <div class="col-lg-12 col-md-12 col-sm-12 right-container">
              <div
                *ngIf="bulkActive == false && nxtmove == false"
                class="sinvite"
              >
                <p class="heading">Single Invite</p>
                <div class="form-group">
                  <label class="busprotit_label">Name</label>
                  <input
                    type="text"
                    [ngClass]="{
                      'red-error-class':
                        isValidFormSubmitted === false &&
                        reviewFormData.reviewerName.errors
                    }"
                    class="form-control name-text"
                    formControlName="reviewerName"
                    id="brand"
                    pattern="[a-zA-Z0-9 ]*"
                    required
                  />
                </div>
                <div
                  *ngIf="reviewFormData.reviewerName.errors?.pattern"
                  class="invalid-feedback"
                >
                  <p>Name should be Alphabets.</p>
                </div>
                <div class="form-group">
                  <label class="busprotit_label">Email</label>
                  <input
                    type="email"
                    [ngClass]="{
                      'red-error-class':
                        isValidFormSubmitted === false && emailnotvalid == false
                    }"
                    class="form-control name-text"
                    formControlName="reviewerEmail"
                    id="brand"
                    (keyup)="checkmail()"
                    required
                  />
                </div>
                <div
                  *ngIf="
                    reviewFormData.reviewerEmail.value != '' &&
                    emailnotvalid != true
                  "
                  class="invalid-feedback"
                >
                  <p>Email is Not valid</p>
                </div>
                <div class="form-group">
                  <label class="busprotit_label">Mobile</label>
                  <input
                    type="text"
                    [ngClass]="{
                      'red-error-class':
                        isValidFormSubmitted === false &&
                        reviewFormData.phoneNumber.errors
                    }"
                    class="form-control mobile-text"
                    formControlName="phoneNumber"
                    pattern="[0-9]*"
                    minlength="10"
                    maxlength="10"
                    required
                  />
                  <span class="sendpopph">
                    <select
                      class="form-control busprotit_text"
                      formControlName="country_code"
                      id="phone-number-prefix"
                      required
                    >
                      <option
                        *ngFor="let prefix of phoneNumberPrefixes"
                        [value]="prefix"
                      >
                        {{ prefix }}
                      </option>
                    </select>
                  </span>
                </div>
                <div
                  *ngIf="reviewFormData.phoneNumber.errors?.pattern"
                  class="invalid-feedback"
                >
                  <p>Mobile number should be number .</p>
                </div>
                <div class="form-group">
                  <label class="busprotit_label">Template</label>
                  <select
                    [ngClass]="{
                      'red-error-class': isValidFormSubmitted === false
                    }"
                    formControlName="review_template_id"
                    class="form-control busprotit_text"
                    id="phone-number-prefix"
                    (change)="validationCheck()"
                    required
                  >
                    <option
                      *ngFor="let template of review_template"
                      [value]="template.id"
                    >
                      {{ template.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div *ngIf="bulkActive && nxtmove == false" class="binvite">
                <p class="heading">Bulk Invite</p>
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <p>Please check the format and upload Excel File (.XLSX)</p>
                    <p>(OR)</p>
                    <button class="upload-excel-btn invite-type-btn">
                      Click ghere to download the sample Excel file
                    </button>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div
                      class="d-flex flex-column w-100 h-100 align-items-center justify-content-center upload-excel-container"
                      ng2FileDrop
                      [ngClass]="{ 'file-over': hasBaseDropZoneOver }"
                      (fileOver)="fileOverBase($event)"
                      (onFileDrop)="onAfterAddingFile($event)"
                      [uploader]="excelUploader"
                    >
                      <label class="upload-excel-text" for="upload-excel"
                        >Upload Excel</label
                      >
                      <input
                        type="file"
                        ng2FileSelect
                        [uploader]="excelUploader"
                        id="upload-excel"
                        (onFileSelected)="fileUploaded($event)"
                      />
                      <p>(or)</p>
                      <p>you can also drop your files here</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="nxtmove == false">
              <ul class="list-inline pull-right reviwinvitbtns">
                <li>
                  <button type="submit" class="btn btn-default feedback-btn">
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="text-center" *ngIf="submitReview == true">
            <div class="row w-100 m-0 footer-container">
              <div class="col-lg-12 col-md-12">
                <p class="text-center">
                  <strong>{{ review_render }}!</strong>&nbsp; Thank you for
                  choosing us
                </p>
                <p class="link">
                  <a>https://www.zceppa.com/review/invitation</a>
                </p>
              </div>
              <div
                class="col-lg-12 col-md-12 footer-btns-container text-center"
              >
                <div
                  class="d-flex justify-content-between justify-content-xs-between"
                >
                  <button class="reviwback_btn" (click)="nexttab('send_Type')">
                    <span><img src="assets/images/back.svg" width="17" /></span>
                  </button>
                  <ul
                    class="list-inline reviwinvitbtns text-center"
                    style="width: 100%"
                  >
                    <li>
                      <button class="send-now-btn" (click)="sendSms()">
                        <span><img src="assets/images/send.svg" /></span
                        ><span class="srbtns">&nbsp;Send</span>
                      </button>
                    </li>
                    <li class="sendlaterntn">
                      <input
                        type="hidden"
                        placeholder="Date Time:"
                        [(ngModel)]="dateTime"
                        [owlDateTime]="dt2"
                      /><button class="send-now-btn">
                        <span class="example-trigger" [owlDateTimeTrigger]="dt2"
                          ><img
                            src="assets/images/drop-down-arrow.svg"
                            width="17"
                        /></span>
                      </button>
                      <owl-date-time #dt2></owl-date-time>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #ErrorModal401 let-modal>
    <div class="permission-denied-modal">
      <img
        src="assets/images/permission-denied.svg"
        width="150"
        height="75"
        alt="Permission Denied"
      />
      <div class="">
        <p class="heading">Permission Denied</p>
        <p class="message">
          {{ errorMessage401 }}
        </p>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-sm reviwadd"
        (click)="onOkError401()"
        [style.width.px]="100"
      >
        Ok
      </button>
    </div>
  </ng-template>
  <ng-template #ErrorModal403 let-modal>
    <div class="permission-denied-modal">
      <img
        src="assets/images/permission-denied.svg"
        width="150"
        height="75"
        alt="Permission Denied"
      />
      <div class="">
        <p class="heading">Permission Denied</p>
        <p class="message">
          {{ errorMessage403 }}
        </p>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-sm reviwadd"
        (click)="onOkError403()"
        [style.width.px]="100"
      >
        Ok
      </button>
    </div>
  </ng-template>

  <ng-template #headerTemp let-column="column">
    <div>{{ column.name }}</div>
  </ng-template>
  <ng-template #cellTemp let-row="row" let-value="value">
    <div>{{ value }}</div>
  </ng-template>

  <ng-template #doneButton>
    <my-button class="joyride-button cursor-pointer">Done</my-button>
  </ng-template>
  <div
    joyrideStep="lastStep"
    text="You have any questions please write to support@zceppa.com"
    stepPosition="center"
    (prev)="goBackPrevTour()"
    (done)="stopProductTour()"
    [doneTemplate]="doneButton"
  ></div>
</ng-container>
