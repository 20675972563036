import { Inject, Injectable } from "@angular/core";
import { fromEvent, of, pipe, throwError } from "rxjs";
import {
  delay,
  filter,
  map,
  mergeMap,
  retry,
  retryWhen,
  scan,
  take,
  tap,
} from "rxjs/operators";
import { DataService, Response } from "src/app/dataService/data.service";
import {
  FeatureKey,
  FeatureParams,
  SubFeatures,
  MultipleCondition,
} from "./interface/common";
import { GlobalVariable } from "../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Injectable({
  providedIn: "root",
})
export class FeaturesByPlanService {
  private BASE_URL = GlobalVariable;
  public featureKeysStorage: Array<FeatureKey> = [
    "feature_reviews",
    "feature_appointments",
    "feature_mystore",
    "feature_teams",
    "feature_integration",
    "feature_mylistings",
    "feature_social",
    "feature_customers",
    "feature_interact",
    "feature_notifications",
    "feature_messaging",
  ];

  constructor(
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  public async getFeature(
    features: FeatureParams,
    subFeatures: SubFeatures = null
  ) {
    let [feature, subFeatureKey, subFeatureCondition, values] = features;
    if (subFeatures == null) {
      subFeatures = (await this.getFeatures(feature)) || null;
    }
    const getSubFeatureData =
      subFeatures !== null &&
      subFeatures?.plan_subfeatures.hasOwnProperty(subFeatureKey) &&
      subFeatures?.plan_subfeatures[subFeatureKey];

    return {
      isApplicable: () => {
        try {
          let subFeatureData: any = getSubFeatureData;
          let isApplicable: boolean = subFeatureData[subFeatureCondition];
          return isApplicable || false;
        } catch (error) {
          return false;
        }
      },
      checkValues: () => {
        try {
          let subFeatureData = getSubFeatureData;
          let data = subFeatureData[subFeatureCondition];
          let filteredValues =
            (data !== null &&
              Array.isArray(values) &&
              values.filter((value) => data.hasOwnProperty(value))) ||
            [];
          return filteredValues;
        } catch (error) {
          return [];
        }
      },
      // this function will give you full object of the value in the sub-feature
      getDataValue: () => {
        try {
          let subFeatureData = getSubFeatureData;
          let data = subFeatureData[subFeatureCondition];
          return data;
        } catch (error) {
          return [];
        }
      },
      checkCount: (multipleCondition: MultipleCondition = null) => {
        try {
          let subFeatureData: any = getSubFeatureData;
          let countValue = subFeatureData[subFeatureCondition];
          let allowedCount = countValue;
          let [customLength] = values;
          let isMoreThanLimit =
            (allowedCount !== null &&
              Number(allowedCount) <= Number(customLength)) ||
            false;
          if (multipleCondition !== null) {
            let [mulCond, cond] = multipleCondition;
            if (cond == "OR") {
              return isMoreThanLimit || mulCond;
            } else if (cond == "AND") {
              return isMoreThanLimit && mulCond;
            } else {
              return isMoreThanLimit;
            }
          }
          return isMoreThanLimit;
        } catch (error) {
          return false;
        }
      },
      getCount: () => {
        try {
          let subFeatureData: any = getSubFeatureData;
          let countValue = subFeatureData[subFeatureCondition];

          return countValue;
        } catch (error) {
          return 0;
        }
      },
    };
  }
  async getFeatures(feature: FeatureKey) {
    return (await this._getItem(feature)) || null;
  }
  public async getFeaturesForMultiloc(feature: FeatureKey) {
    return (await this._handleAPI(feature)) || null;
  }

  get outlet_id() {
    return this.storage.get("outlet_id");
  }

  private async _getItem(key: FeatureKey): Promise<SubFeatures> {
    let has_data = this.storage.has(key);
    if (has_data) {
      let featureData: SubFeatures = this.storage.get(key);
      return featureData;
    }
    try {
      let apiResponse = await this._handleAPI(key);
      apiResponse && this._setItem(key, apiResponse);
      return apiResponse;
    } catch (error) {
      return null;
    }
  }

  private _setItem(key: string, value: any) {
    this.storage.set(key, value);
    return;
  }

  private _handleAPI(feature: FeatureKey): Promise<SubFeatures> {
    switch (feature) {
      case "feature_reviews":
        return this._features.reviews();
      case "feature_mylistings":
        return this._features.myListings();
      case "feature_social":
        return this._features.social();
      case "feature_integration":
        return this._features.integration();
      case "feature_appointments":
        return this._features.appointments();
      case "feature_teams":
        return this._features.teams();
      case "feature_customers":
        return this._features.customers();
      case "feature_interact":
        return this._features.interact();
      case "feature_notifications":
        return this._features.notification();
      case "feature_messaging":
        return this._features.messaging();
      case "feature_mystore":
        return this._features.mystore();
      default:
        break;
    }
  }

  private _features = {
    mapResponse: async (response: Promise<Response>) => {
      let res = await response;
      if (res.code == 200) {
        return res.data;
      }
    },
    reviews: () => {
      return this._features.mapResponse(this._reviewsAPI());
    },
    myListings: () => {
      return this._features.mapResponse(this._myListingsAPI());
    },
    social: () => {
      return this._features.mapResponse(this._socialAPI());
    },
    integration: () => {
      return this._features.mapResponse(this._integrationAPI());
    },
    appointments: () => {
      return this._features.mapResponse(this._appointmentsAPI());
    },
    teams: () => {
      return this._features.mapResponse(this._teamsAPI());
    },
    customers: () => {
      return this._features.mapResponse(this._customersAPI());
    },
    interact: () => {
      return this._features.mapResponse(this._interactAPI());
    },
    notification: () => {
      return this._features.mapResponse(this._notificationAPI());
    },
    messaging: () => {
      return this._features.mapResponse(this._messagingAPI());
    },
    mystore: () => {
      return this._features.mapResponse(this._mystoreAPI());
    },
  };

  private _reviewsAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURES_REVIEWS(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          }
        );
    });
  }

  private _socialAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_SOCIAL(this.outlet_id),
        })
        // .pipe(retryWhen((errors) => errors.pipe(retry(3), delay(1000), tap())))
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _appointmentsAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_APPOINTMENTS(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _mystoreAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_MYSTORE(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _myListingsAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_MYLISTINGS(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _integrationAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_INTEGRATION(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _teamsAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_TEAMS(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _customersAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_CUSTOMERS(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _interactAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_INTERACT(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _notificationAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_NOTIFICATION(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  private _messagingAPI(): Promise<Response> {
    return new Promise((resolve, reject) => {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.FEATURE_MESSAGING(this.outlet_id),
        })
        .subscribe(
          (response: Response) => {
            return resolve(response);
          },
          (error) => {
            return reject(error);
          },
          () => {}
        );
    });
  }

  public clearFeaturesByPlan(): void {
    this.featureKeysStorage.forEach((key) => this.storage.remove(key));
    return;
  }
}
