import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "src/app/app.component";
import { DataService, Response } from "src/app/dataService/data.service";
import { BehaviorSubject, forkJoin, Observable, Subject } from "rxjs";
import { MultiLocationService } from "src/app/multi-location/multi-location.service";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  private BASE_URL = GlobalVariable;
  constructor(
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private multiLocationService: MultiLocationService
  ) {}

  get business_id() {
    return this.storage.get("business_id");
  }
  get business_country_id() {
    return this.storage.get("business_country_id");
  }
  /**
   * getOutletListAPI - get Outlet
   */
  public getOutletListAPI(filters: {
    page?: string;
    size?: string;
    keyword?: string;
    sort?: string;
    listView?: string;
    account_classification?: string | null;
    location_groups?: string;
    cities?: string;
    gbp_status?: string;
    download?: string;
  }): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_OUTLET_LIST(this.business_id),
      page: filters?.page,
      size: filters?.size,
      keyword: filters?.keyword,
      sort_order: filters?.sort,
      list_format: filters?.listView,
      account_classification: filters?.account_classification,
      location_groups: filters?.location_groups,
      cities: filters?.cities,
      gbp_status: filters?.gbp_status,
      download: filters?.download,
    });
  }
  /**
   * getOutletDetailsAPI - get getOutletDetailsAPI
   */
  public getOutletDetailsAPI(outlet_id: string): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_BUSINESS_OUTLET_DETAILS(
        this.business_id,
        outlet_id
      ),
    });
  }

  /**
   * getOutletDetailsAPI - get getOutletDetailsAPI
   */
  public getOutletListDropdownAPI(): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_BUSINESS_OUTLET_LIST(this.business_id),
    });
  }

    /**
   * excelSampleFile - Download sample file excel
   */
    public downloadSampleFile(): string {
      return this.BASE_URL.DOWNLOAD_LOCATION_BULK_EXCEL_FILE_URL;
    }

bulkUploadOutletAPI(payload): Observable<Response> {
  return this.httpService.postRequest({
    customApiUrl: this.BASE_URL.OUTLET_BULK_UPLOAD(this.business_id),
    data: payload,
  });
}

  getStateList(): Observable<Response> {
    return this.httpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.VIEW_ONBOARDING_STATES,
    });
  }

  getCountryList(): Observable<Response> {
    return this.httpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.COUNTRY_API,
    });
  }

  changeState(state_id: string): Observable<Response> {
    return this.httpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.VIEW_ONBOARDING_CITIES(state_id),
    });
  }
  changeCountry(country_id: string): Observable<Response> {
    return this.httpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.STATES_API(country_id),
    });
  }
  createOutletAPI(payload: any): Observable<Response> {
    return this.httpService.postRequest({
      customApiUrl: this.BASE_URL.CREATE_NEW_OUTLET(this.business_id),
      data: payload,
    });
  }
  createOutletProduct(outlet_id: string, payload: any): Observable<Response> {
    return this.httpService.postRequestAdmin({
      customApiUrl: this.BASE_URL.CREATE_ZCEPPA_PRODUCT(outlet_id),
      data: payload,
    });
  }
  updateOutletAPI(outlet_id: string, payload: any): Observable<Response> {
    return this.httpService.putRequest({
      customApiUrl: this.BASE_URL.UPDATE_OUTLET(this.business_id, outlet_id),
      data: payload,
    });
  }
  updateCopySettingsAPI(payload: any): Observable<Response> {
    return this.httpService.postRequest({
      customApiUrl: this.BASE_URL.UPDATE_COPY_SETTINGS(this.business_id),
      data: payload,
    });
  }

  getProductListAPI(): Observable<Response> {
    return this.httpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.VIEW_ZCEPPA_PRODUCTS,
    });
  }

  updateCopyTemplatesAPI(payload: any): Observable<Response> {
    return this.httpService.postRequest({
      customApiUrl: this.BASE_URL.UPDATE_COPY_TEMPLATES(this.business_id),
      data: payload,
    });
  }

  getCityListForFilter(): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_CITY_DATA_FILTER(this.business_id),
    });
  }

  getAllGbpErrors(isDownload?: boolean): Observable<Response> {
    let requestUrl = this.BASE_URL.GET_ALL_GBP_ERRORS(this.business_id);
    if (isDownload) {
      requestUrl = requestUrl + "?download=excel";
    }
    return this.httpService.getRequest({
      customApiUrl: requestUrl,
    });
  }

  socialConnectQueryBuilder(
    baseApi: string,
    socialAuthApi: string,
    redirectUrl: string,
    outlet_id: string,
    isSyncAll: boolean
  ) {
    let accessToken = this.storage.get("access_token");
    const data = `${baseApi}${socialAuthApi}?jwt=${accessToken}&redirect_url=${redirectUrl}&outlet_id=${outlet_id}${
      isSyncAll && "&sync_all=true"
    }`;
    return data;
  }
  reconnectGoogle(outlet_id: string, isSyncAll: boolean) {
    let redirectUrl = window.location.href;
    const val = this.socialConnectQueryBuilder(
      this.BASE_URL.BASE_API_URL,
      this.BASE_URL.GOOGLE_URL(outlet_id),
      redirectUrl,
      outlet_id,
      isSyncAll
    );
    window.location.replace(val);
  }
  toastr(message: string) {
    return this.multiLocationService.toastr(message);
  }

  templatesPreview(
    templatesFeature: TemplatesFeature,
    outlet_id: string
  ): Promise<any[]> {
    return this._handleTemplatesAPI(templatesFeature, outlet_id);
  }

  private _handleTemplatesAPI(
    templateFeature: TemplatesFeature,
    outlet_id: string
  ): Promise<any[]> {
    switch (templateFeature) {
      case "transactional":
        return this._templateFeature.transactional(outlet_id);
      case "promotional":
        return this._templateFeature.promotional(outlet_id);
      case "social":
        return this._templateFeature.social(outlet_id);
      case "reviewInvite":
        return this._templateFeature.reviewInvite(outlet_id);
      case "reviewReply":
        return this._templateFeature.reviewReply(outlet_id);
      case "appointments":
        return this._templateFeature.appointments(outlet_id);
      case "mystore":
        return this._templateFeature.mystore(outlet_id);

      default:
        break;
    }
  }

  private _templateFeature = {
    mapResponse: (
      endPoint: string,
      isReviewReply?: boolean
    ): Promise<any[]> => {
      let api = this.httpService.getRequest({ customApiUrl: endPoint });
      return new Promise<any[]>((resolve): void => {
        api.subscribe((response) => {
          if (response.code == 200) {
            let templates = [];
            if (isReviewReply) {
              templates = response.data.response.templates;
            } else {
              templates = response.data.templates;
            }
            console.log(templates);

            templates = templates.filter((template) => !template?.default);
            resolve(templates);
          }
        });
      });
    },
    transactional: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.MESSAGING_SETTINGS_GET_TRANSACTIONAL_TEMPLATE(outlet_id)
      );
    },
    promotional: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.MESSAGING_SETTINGS_GET_PROMOTIONAL_TEMPLATE(outlet_id)
      );
    },
    social: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.SOCIAL_ZCEPPA_TEMPLATE_SETTING(outlet_id)
      );
    },
    reviewInvite: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.SMS_REVIEW_GET_WP_V2(outlet_id)
      );
    },
    reviewReply: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.SMS_REPLY_URL_GET_WP_V2(outlet_id),
        true
      );
    },
    appointments: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.APPOINTMENT_TEMPLATE_LIST(outlet_id)
      );
    },
    mystore: (outlet_id: string) => {
      return this._templateFeature.mapResponse(
        this.BASE_URL.VIEW_MYSTORE_TEMPLATE(outlet_id)
      );
    },
  };

  settingsPreview(
    settingsFeature: SettingsFeature,
    outlet_id: string
  ): Promise<any[]> {
    return this._handleSettingsAPI(settingsFeature, outlet_id);
  }

  private _handleSettingsAPI(
    settingsFeature: SettingsFeature,
    outlet_id: string
  ): Promise<any[]> {
    switch (settingsFeature) {
      case "messaging":
        return this._settingsFeature.messaging(outlet_id);
      case "social":
        return this._settingsFeature.social(outlet_id);
      case "reviews":
        return this._settingsFeature.review(outlet_id);
      case "reviewConsumerLink":
        return this._settingsFeature.reviewConsumerLink(outlet_id);
      case "appointments":
        return this._settingsFeature.appointments(outlet_id);
      case "appointmentHours":
        return this._settingsFeature.appointmentHours(outlet_id);
      case "mystore":
        return this._settingsFeature.mystore(outlet_id);
      case "customers":
        return this._settingsFeature.customers(outlet_id);
      case "listing management":
        return this._settingsFeature.myListings(outlet_id);
      case "interact":
        return this._settingsFeature.interact(outlet_id);
      default:
        break;
    }
  }

  private _settingsFeature = {
    mapResponse: (endPoint: string): Promise<any[]> => {
      let api = this.httpService.getRequest({ customApiUrl: endPoint });
      return new Promise<any[]>((resolve): void => {
        api.subscribe((response) => {
          if (response.code == 200) {
            let settings = [];
            settings = response.data;
            resolve(settings);
          }
        });
      });
    },
    messaging: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.CAMPAIGN_SETTINGS_V2(outlet_id)
      );
    },

    social: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.SOCIAL_SETUP_SETTING(outlet_id)
      );
    },
    review: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.REVIEW_SETUP_VALUE_URL_V2(outlet_id)
      );
    },
    reviewConsumerLink: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.CONSUMER_LINK_AVAIL_V2(outlet_id)
      );
    },
    appointments: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.APPOINTMENT_VIEW(outlet_id)
      );
    },
    appointmentHours: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.APPOINTMENT_HOURS(outlet_id)
      );
    },
    mystore: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.VIEW_MYSTORE_SETTINGS(outlet_id)
      );
    },
    interact: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.INTERACT_SETTINGS(outlet_id)
      );
    },
    customers: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.GET_CONTACT_SETTING(outlet_id)
      );
    },
    myListings: (outlet_id: string) => {
      return this._settingsFeature.mapResponse(
        this.BASE_URL.MYLISTINGS_SETTINGS(outlet_id)
      );
    },
  };
}

export type TemplatesFeature =
  | "transactional"
  | "promotional"
  | "social"
  | "reviewInvite"
  | "reviewReply"
  | "appointments"
  | "mystore";

export type SettingsFeature =
  | "messaging"
  | "social"
  | "reviews"
  | "reviewConsumerLink"
  | "appointments"
  | "interact"
  | "listing management"
  | "appointmentHours"
  | "customers"
  | "mystore";
