<ngx-loading [show]="accessApiLoading"></ngx-loading>
<div class="row">
  <div class="col-sm-6 col-md-6 col-lg-6 p-0 mob-hide">
    <app-onboard-random-ui></app-onboard-random-ui>
  </div>

  <div class="col-sm-6 col-md-6 col-lg-6 min-height-100 h-100 mob-h-auto">
    <div class="col-xs-12 login_page">
      <img src="assets/images/zceppa.png" class="signpcontimg" />

      <div class="col-lg-12 col-md-12 col-xs-12 login_sec">
        <label *ngIf="onLoad" class="welcome_text">Welcome to Zceppa</label>
        <div class="form-group mt-3 mb-0">
          <!-- <input
            *ngIf="onLoad"
            #phone_numbers
            id="phoneNumber"
            (keyup)="phoneNumberChange($event)"
            type="tel"
            placeholder="Email ID or Phone Number"
            [(ngModel)]="phoneNumber"
            class="form-control"
            pattern="/^-?\d+\.?\d*$/"
            (paste)="onPaste($event)"
            (keypress)="numberOnly($event)"
            maxlength="10"
            autofocus
            [required]="true"
          /> -->
          <input
            *ngIf="onLoad"
            #phone_numbers
            id="phoneNumber"
            (keyup)="phoneNumberChange($event)"
            placeholder="Email ID or Phone Number"
            [(ngModel)]="phoneNumber"
            class="form-control"
            autofocus
            [required]="true"
          />
          <div *ngIf="show_password" class="input-wrapper">
            <input
              *ngIf="show_password"
              #passwords
              id="password"
              [type]="type"
              placeholder="Password"
              (keyup)="submitPassword($event)"
              [(ngModel)]="password"
              class="form-control"
              autofocus
            />
            <div *ngIf="!showPass" (click)="showPassword('pass')">
              <img class="hide-image" src="../../../assets/images/onboarding/eye-close.svg"
                alt="password visibility"/>
            </div>
            <div *ngIf="showPass" (click)="showPassword('pass')">
              <img class="hide-image" src="../../../assets/images/onboarding/eye.svg"
                alt="password visibility"/>
            </div>
          </div>
        </div>
        <div *ngIf="show_password" class="row m-0">
          <input id="rememberMe" type="checkbox" name="rememberMe"
            class="styled-checkbox"
            value="remember-me"
            [disabled]="phoneNumber == undefined || phoneNumber == '' || password == undefined || phoneNumber == ''"
            (click)="rememeberMe($event)"
            [checked]="user_name == '' ? false : true"/>
          <label for="rememberMe" class="remember-me-text mb-0">Remember me</label>
        </div>
        <div *ngIf="errorUiVisible">
          <app-error-toast-ui [imageUrl]="'assets/images/onboarding/error-toastr.png'"
            [headerText]="errorUiMsg" (outputDataClose)="errorBannerClose($event)">
          </app-error-toast-ui>
        </div>
        <div class="form-group pos-r">
          <button
            *ngIf="show_password"
            class="submit-btn w-100"
            type="button"
            [class.spinner]="showLoginBtnLoader" [disabled]="showLoginBtnLoader"
            (click)="onPasswordSubmit()"
          >
            Login
            <!-- <img *ngIf="showLoginBtnLoader" src="assets/images/25.gif" width="20" height="20" alt=""/> -->
          </button>
          <!-- display this loader while login api is not got repsonse -->
          <!-- <img src="assets/images/25.gif"
              width="20" height="20" alt=""/> -->
        </div>

        <div *ngIf="show_password" class="row m-0">
          <p class="forget-pwd m-0 w-100 text-center" (click)="goToEmailOtpScreen(null,null,true)">
            Forgot Password?
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

