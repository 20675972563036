import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import { BusinessNotificationService } from "../business-notification.service";

@Component({
  selector: "app-business-notification-page",
  templateUrl: "./business-notification-page.component.html",
  styleUrls: ["./business-notification-page.component.scss"],
})
export class BusinessNotificationPageComponent implements OnInit, OnDestroy {
  notificationOnScrollSubscription: Subscription;
  loading: boolean;
  pageNo: number = 1;
  size: number = 25;
  notifications: any[] = [];
  constructor(
    private commonService: CommonService,
    private businessNotificationService: BusinessNotificationService
  ) {}

  ngOnInit(): void {
    this.notificationOnScrollSubscription =
      this.commonService.businessNotificationOnScroll$.subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
    this.getNotifications();
  }

  ngOnDestroy(): void {
    this.notificationOnScrollSubscription.unsubscribe();
  }

  getNotifications() {
    this.loading = true;
    this.businessNotificationService
      .getBusinessNotifications({
        page: this.pageNo.toString(),
        size: this.size.toString(),
      })
      .subscribe((response) => {
        console.log(response);
        if (response.code == 200) {
          const notifications = response.data.notifications;
          notifications.forEach((val: any, key: any) => {
            notifications[key].data = JSON.parse(val.data);
          });
          notifications.length && this.notifications.push(...notifications);
          if (notifications.length && this.notifications.length) {
            this.pageNo += 1;
          }
        }
        this.loading = false;
      });
  }
}
