import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalVariable } from "../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Response } from "../shared/models";

@Injectable({
  providedIn: "root",
})
export class OtpService {
  private BASE_URL = GlobalVariable;

  constructor(
    private http: HttpClient,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  get outlet_id() {
    return this.storage.get("outlet_id");
  }

  getOTP(mobile_number: number): Observable<Response> {
    const body = { mobile_number: mobile_number.toString() };
    return this.http
      .put(`${this.BASE_URL.BASE_API_URL}${this.BASE_URL.OTP_URL}`, body, {
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }
  authLock(mobile_number: number): Observable<Response> {
    const body = { mobile_number: mobile_number.toString() };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.AUTH_LOCK_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
  verifyOTP(mobile_number: number, otp: number): Observable<Response> {
    const body = {
      mobile_number: mobile_number.toString(),
      otp: otp.toString(),
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.LOGIN_VERIFY_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
  verifyMobileOTP(
    mobile_number: string,
    otp: string,
    isForgetPassword: boolean = false
  ): Observable<Response> {
    const body = {
      mobile_number: mobile_number,
      otp: otp,
      is_forget_password: isForgetPassword,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.LOGIN_VERIFY_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
  resendOTP(mobile_number: number): Observable<Response> {
    const body = { mobile_number: mobile_number.toString() };
    return this.http
      .put(`${this.BASE_URL.BASE_API_URL}${this.BASE_URL.RESEND_URL}`, body, {
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  resendMobileOTP(mobile_number: string): Observable<Response> {
    const body = { mobile_number: mobile_number };
    return this.http
      .put(`${this.BASE_URL.BASE_API_URL}${this.BASE_URL.RESEND_URL}`, body, {
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  hasPasswordSetCheck(username: number): Observable<Response> {
    return this.http
      .get(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.HAS_PASSWORD_SET_URL}?username=` +
          username.toString(),
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  createPassword(
    mobile_number: number,
    password: String
  ): Observable<Response> {
    const body = {
      mobile_number: mobile_number.toString(),
      password: password,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.CREATE_PASSWORD_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
  generatePassword(
    mobile_number: String,
    password: String
  ): Observable<Response> {
    const body = {
      mobile_number: mobile_number,
      password: password,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.CREATE_PASSWORD_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  generatePasswordForWebsite(
    email: String,
    password: String
  ): Observable<Response> {
    const body = {
      username: email,
      login_type: "EMAIL",
      password: password,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.CREATE_PASSWORD_WEBSITE}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  passwordSubmit(
    mobile_number: number,
    password: String
  ): Observable<Response> {
    const body = {
      mobile_number: mobile_number.toString(),
      password: password,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.PASSWORD_VERIFY_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  loginUser(mobile_number: String, password: String): Observable<Response> {
    const body = {
      username: mobile_number,
      password: password,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.PASSWORD_VERIFY_URL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  returnValue(res: any): Response {
    let response = <Response>{};
    let token = "";
    const val = res.body;
    if (res.body.tokens) {
      token = res.body;
    }
    response = {
      code: res.status,
      message: res.body.message,
      token: token,
      val: val,
    };
    return response;
  }

  resendEmailOTP(userId: string): Observable<Response> {
    const body = {
      user_id: userId,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.ONBOARD_RESEND_EMAIL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
  verifyEmailOTP(userId: string): Observable<Response> {
    const body = {
      user_id: userId,
    };
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.ONBOARD_VERIFY_EMAIL}`,
        body,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  getOnboardingPlatforms(): Observable<Response> {
    return this.http
      .get(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.GET_ONBOARDING_PLATFORM(
          this.outlet_id
        )}`,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  websiteEmailOtp(payload: any): Observable<Response> {
    return this.http
      .put(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.WEBSITE_EMAIL_OTP}`,
        payload,
        {
          observe: "response",
        }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  verifyWebsiteEmailOTP(payload: any): Observable<Response> {
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.VERIFY_URL_V3}`,
        payload,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  forgetPassword(payload: any): Observable<Response> {
    return this.http
      .post(
        `${this.BASE_URL.BASE_API_URL}${this.BASE_URL.FORGET_PASSWORD}`,
        payload,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }
}
