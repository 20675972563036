<div *ngIf="isVisible" class="resend-success">
  <div class="success-container">
    <div *ngIf="imageUrl">
      <img [src]="imageUrl">
    </div>
    <div class="sent-text-container">
      <p *ngIf="headerText" class="sent-header mb-0">{{headerText}}</p>
      <p *ngIf="descriptionText" class="sent-description mb-0 mt-1">{{descriptionText}}</p>
    </div>
  </div>
  <div class="close-banner" (click)="closeToastMessage()">
    <img src="assets/images/onboarding/close.svg"/>
  </div>
</div>
