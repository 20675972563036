import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private AlertTitle = {
    Success: "Success",
    Information: "Information",
    Error: "Error",
  };

  private buttonColors = {
    cancel: "#9b9b9b",
    confirmThemeColor: "#03c0c2",
    confirmDeleteColor: "#e55528",
  };

  private confirmButtonText = {
    Ok: "Ok",
  };

  constructor() {}

  basicMessage(msg: string) {
    Swal.fire({
      text: msg,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
      },
    });
  }

  successmsg(msg: string) {
    Swal.fire({
      title: this.AlertTitle.Success,
      text: msg,
      icon: "success",
      reverseButtons: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: this.confirmButtonText.Ok,
    });
  }

  successmsgWithCallback(msg: string) {
    return Swal.fire({
      title: this.AlertTitle.Success,
      icon: "success",
      text: msg,
      reverseButtons: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: this.confirmButtonText.Ok,
    });
  }

  errormsg(msg: string) {
    Swal.fire({
      title: this.AlertTitle.Error,
      text: msg,
      icon: "error",
      reverseButtons: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: this.confirmButtonText.Ok,
    });
  }

  comfirmationAlert(
    title: string,
    msg: string,
    confirmBtnText: string,
    cancelBtnText?: string
  ) {
    return Swal.fire({
      title: title,
      text: msg,
      // icon: "warning",
      // icon: "info",
      icon: "question",
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        icon: "swal-icon",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      reverseButtons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: confirmBtnText,
      cancelButtonText: cancelBtnText ? cancelBtnText : "Cancel",
    });
  }

  comfirmationAlertWithCustomImage(
    title: string,
    msg: string,
    imgUrl: string,
    confirmBtnText: string,
    cancelBtnText?: string
  ) {
    return Swal.fire({
      title: title,
      text: msg,
      imageUrl: imgUrl,
      imageHeight: 50,
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        icon: "swal-icon",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      reverseButtons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: confirmBtnText,
      cancelButtonText: cancelBtnText ? cancelBtnText : "Cancel",
    });
  }

  comfirmationAlertWithCallback(
    title: string,
    msg: string,
    confirmBtnText: string,
    preConfirmCallback
  ) {
    return Swal.fire({
      title: title,
      text: msg,
      // icon: "warning",
      // icon: "info",
      icon: "question",
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        icon: "swal-icon",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      reverseButtons: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: confirmBtnText,
      preConfirm: (isConfirmed) => {
        return new Promise<void>(async (resolve, reject) => {
          if (isConfirmed) {
            try {
              let response = await preConfirmCallback();
              if (response) resolve(response);
            } catch (error) {
              Promise.reject(new Error(`Confirmation Modal Error: ${error}`));
              if (Swal.isVisible()) Swal.close();
            }
          } else {
            reject();
          }
        });
      },
    });
  }

  infoMsgWithCallback(msg: string) {
    return Swal.fire({
      title: this.AlertTitle.Information,
      text: msg,
      icon: "info",
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        icon: "swal-icon",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      reverseButtons: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      cancelButtonColor: this.buttonColors.cancel,
      confirmButtonText: this.confirmButtonText.Ok,
    });
  }

  alertWithCustomImage(
    title: string,
    msg: string,
    imgUrl: string,
    confirmBtnText?: string
  ) {
    Swal.fire({
      title: title,
      text: msg,
      imageUrl: imgUrl,
      imageHeight: 50,
      reverseButtons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmThemeColor,
      confirmButtonText: confirmBtnText,
      cancelButtonColor: this.buttonColors.cancel,
    });
  }

  alertWithDeleteImage(title: string, msg: string, confirmBtnText?: string) {
    return Swal.fire({
      title: title,
      text: msg,
      imageUrl: "assets/images/alerts/alert_delete.svg",
      customClass: {
        title: "swal-title",
        htmlContainer: "swal-message",
        icon: "swal-icon",
        actions: "swal-actions",
        confirmButton: "swal-confirm-button",
        cancelButton: "swal-cancel-button",
      },
      reverseButtons: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmDeleteColor,
      confirmButtonText: confirmBtnText,
      cancelButtonColor: this.buttonColors.cancel,
    });
  }

  /**
   *
   * @param title Modal header
   * @param msg Modal Content
   * @param confirmBtnText Modal upgrade button
   * @param target HtmlElement of the parent
   * @returns Triggers Alert Modal
   */
  alertOnExpiry(title: string, msg: string, confirmBtnText: string, target: HTMLElement | null, upgradePlanCallback: Function) {
    return Swal.fire({
      title: title,
      text: msg,
      customClass: {
        title: "swal-title-expiry",
        htmlContainer: "swal-content-expiry",
        actions: "swal-actions-expiry",
        confirmButton: "swal-expiry-button",
        container: 'position-absolute'
      },
      target: target,
      width: "349px",
      reverseButtons: true,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: this.buttonColors.confirmDeleteColor,
      confirmButtonText: confirmBtnText,
      cancelButtonColor: this.buttonColors.cancel,
      toast: true,
      preConfirm: () => {
        console.log("resfsdfds");

        // this.expiryWarningService.requestUpgradePlan();
          upgradePlanCallback();
          return false; // Prevent confirmed
      },
    });
  }


  /**
   *
   * @param htmlContent HtmlContent
   * @param target HtmlElement of the parent
   * @returns Triggers Alert Modal
   */
  alertOnFirstTimeDashboard(htmlContent: string, target: HTMLElement | null) {
    return Swal.fire({
      customClass: {
        container: 'position-absolute margin-0 padding-0',
        htmlContainer: "margin-0",
        popup: "padding-0",
      },
      html: htmlContent,
      width: "auto",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      toast: true,
      target: target,
      didOpen: () => {
        // Prevent any default actions on the content
        const contentElement = document.querySelector('#overlayPage');
        contentElement?.addEventListener('click', (event) => {
            const target = event.target as HTMLElement;
            if (target && target.classList.contains('skip-step')) {
                Swal.close();
            } else {
                event.preventDefault();
                event.stopPropagation();
            }
        });
    }
    });
  }
}
