import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CommonService } from "../../services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService, Response } from "../../dataService/data.service";
import { GlobalVariable } from "../../app.component";
import { NgbRatingConfig } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-review-improvement",
  templateUrl: "./review-improvement.component.html",
  styleUrls: ["./review-improvement.component.scss"],
})
export class ReviewImprovementComponent implements OnInit {
  @ViewChild("needimproveModal", { static: true }) needimproveModal: ElementRef;
  @ViewChild("thankyoumodal", { static: true }) thankyoumodal: ElementRef;

  name: String;
  feedback: String;
  slug_value: String;
  selected_rating: number = 0;
  ratings: number = 0;
  readonly: boolean = false;
  private BASE_URL = GlobalVariable;
  logo_url: String;
  business_name: String;
  customer_name: String;
  reviewp: String;
  reviewp1: String;
  reviewlike: any;
  reviewdislike: any;
  poweredby: String;
  poweredby1: any;
  public reviewlikeStyle: any;
  public reviewdislikeStyle: any;
  public loading = false;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private modalService: NgbModal,
    private commonservice: CommonService,
    private route: ActivatedRoute,
    private HttpService: DataService,
    private toastrService: ToastrService,
    config: NgbRatingConfig,
    private spinner: NgxSpinnerService
  ) {
    config.max = 5;
    config.readonly = true;
  }

  ngOnInit() {
    this.loading = true;
    //this.spinnerService.show();
    //this.spinner.show();
    //console.log(this.logo_url);
    let res: Response;
    this.name = this.storage.get("user_name");
    this.route.params.forEach((val) => {
      this.slug_value = val["id"];
    });
    const json_data = {
      slug: this.slug_value,
    };

    const userInfo = this.HttpService.get(
      this.BASE_URL.FEEDBACK_SLUG_DATA,
      null,
      this.slug_value
    );
    userInfo.subscribe((data: Response) => {
      res = data;
      // console.log(res.data);
      this.reviewp = "Hi ";
      this.reviewp1 =
        "Your experience matters to us. Please tell us how we did!";
      this.reviewlike = "assets/images/thumbs-up-hand-symbol.svg";
      this.reviewdislike = "assets/images/thumbs-down-silhouette.svg";
      this.poweredby = "Powered by";
      this.poweredby1 = "assets/images/zceppadark.svg";
      this.reviewlikeStyle = {
        "background-color": "#03c0c2",
        width: "auto",
        padding: "0.6rem 2rem",
        "font-size": "18px",
        background: "#03c0c2",
        color: "#fff",
        margin: "auto",
        "margin-bottom": "20px",
        "margin-top": "20px",
      };
      this.reviewdislikeStyle = {
        padding: "0.6rem 2rem",
        "font-size": "14px",
        background: "#f1f1f1",
        color: "#4a4a4a",
        border: "0px solid #333",
        "margin-top": "10px",
      };
      this.business_name = res.data["business_name"];
      this.logo_url = res.data["business_logo"];
      this.customer_name = res.data["contact_name"];
    });

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.loading = false;
    }, 2000);
  }

  /**
   * open social review page
   *
   * @memberof ReviewImprovementComponent
   */
  openSocialReview() {
    this.commonservice.openCustomerReviewPage("YES");
  }
  needImproveModal() {
    this.modalService.open(this.needimproveModal, {
      size: "sm",
      windowClass: "modalfeedback",
      centered: true,
    });
  }
  thankyouModal() {
    let res: Response;
    this.route.params.forEach((val) => {
      this.slug_value = val["id"];
    });
    if (this.feedback === undefined || this.feedback === "") {
      this.toastrService.error("Please Enter Feedback");
      return;
    }
    if (this.selected_rating === 0) {
      this.toastrService.error("Please Choose Ratings");
      return;
    }
    const json_data = {
      slug: this.slug_value,
      feedback_text: this.feedback,
      feedback_star: this.selected_rating,
    };
    const data_service = this.HttpService.postjson(
      this.BASE_URL.SMS_SLUG_REQUEST_URL,
      json_data
    );
    data_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {},
      () => {
        if (res.code === 200 || res.code === 201) {
          this.modalService.dismissAll(this.needimproveModal);
          this.modalService.open(this.thankyoumodal, {
            size: "sm",
            windowClass: "modalfeedback",
            centered: true,
          });
          this.feedback = "";
        } else {
          this.toastrService.error(res.data["message"]);
        }
      }
    );
  }
  closeModal() {
    this.modalService.dismissAll(this.thankyoumodal);
  }
}
