<div class="card card-border-radius box_shadow_color mt-1 mx-2 px-sm-2 py-3">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="row first-child">
    <div
      class="col-lg-12 col-md-12 col-sm-12 emptydashbrd text-center"
      *ngIf="noDataFound"
    >
      <img src="assets/images/team.svg" width="100" />
      <h5>No Team Members Found</h5>
      <!-- <button class='btn btn-secondary btn-sm reviwadd text-center' (click)='addNewMember()'>
          <span>+</span> Click here to Add New Member
        </button> -->
    </div>
    <app-banner *ngIf="team_warning_text?.length>0" [bannerType]="'warning'" [bannerIconRequired]="true"
      [bannerText]="team_warning_text">
    </app-banner>

    <div
      class="row w-100 d-flex align-items-sm-center flex-column flex-sm-row align-items-baseline justify-content-sm-between pl-2"
    >
      <span class="" style="font-size: 16px; color: #4a4a4a"
        >{{ userName | titlecase }}
        <span *ngIf="isOwnerPreference" style="font-size: 13px"
          >(Owner access
          <img
            src="assets/images/del.svg"
            alt="remove"
            class="remove-icon"
            title="Remove Owner Access"
            (click)="alertForRemoveOwnerPermission()"
          />)
        </span>
      </span>
      <div
        class="d-flex align-items-center"
        *ngIf="assign_permission?.is_update"
      >
        <span class="pr-1" style="font-size: 16px; color: #4a4a4a"
          >Copy Permissions From</span
        >
        <div class="input-group-btn bs-dropdown-to-select-group">
          <select
            class="form-control"
            [(ngModel)]="teamMemberSelected"
            (ngModelChange)="onUserChange($event)"
          >
            <option value="" disabled selected>Select</option>
            <ng-container *ngFor="let val of managerList | sortBy: 'asc':'manager_first_name';
            let i = index">
              <option [value]="val.manager_id">
                {{ val.manager_first_name }}
              </option>
            </ng-container>
            <ng-container *hasFeature="teamsFeature?.owner_preference">
              <ng-container *ngIf="ownerDetail.length !== 0">
                <option [value]="ownerDetail[0].owner_id">
                  {{ ownerDetail[0].owner_first_name }} (Owner)
                </option>
              </ng-container>
            </ng-container>
          </select>
        </div>
      </div>
    </div>

    <div class="row w-100 no-padding no-margin pt-3">
      <div class="table-responsive">
        <table class="display dataTable-border" style="width: 100%">
          <thead>
            <tr>
              <th style="border: 0">Features</th>
              <th style="border: 0">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="
                let v of permissions
                  | paginate: { itemsPerPage: items_per_page, currentPage: p };
                let i = index
              "
            >
              <tr id="table-example">
                <td class="first-td">
                  {{ v.name }}
                </td>
                <td class="tag_listsbtn">
                  <button
                    type="button"
                    class="btn btn-outline-primary downchevron"
                    (click)="clickToShow(i)"
                    aria-controls="collapseExample"
                  >
                    <img
                      width="23px"
                      class="down-chevron"
                      style="top: 0px"
                      src="assets/images/down-chevron.svg"
                      *ngIf="show === i"
                    />
                    <img
                      width="23px"
                      class="down-chevron"
                      style="top: 0px"
                      src="assets/images/right-arrow-circular-button.svg"
                      *ngIf="show !== i"
                    />
                  </button>
                </td>
              </tr>
              <ng-container>
                <tr id="collapseExample" *ngIf="show === i">
                  <td colspan="2" style="padding: 0px">
                    <div
                      class="table-responsive collapse-table col-lg-6 no-padding col-12"
                    >
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th>Permissions</th>
                            <th>Read</th>
                            <th>Create</th>
                            <th>Update</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let val of v.values">
                            <tr>
                              <td class="first-child">
                                <p class="pt-1">{{ val.permission }}</p>
                              </td>
                              <td>
                                <ui-switch
                                  class="p-0"
                                  [disabled]="!teamPermissions.is_update"
                                  switchColor="#fcfcfc"
                                  color="#03c0c2"
                                  style="position: relative; top: 5px"
                                  [(ngModel)]="val.is_read"
                                  [checked]="val.is_read"
                                  (ngModelChange)="onRead(val)"
                                >
                                </ui-switch>
                              </td>
                              <td>
                                <ui-switch
                                  class="p-0"
                                  [disabled]="!teamPermissions.is_update"
                                  switchColor="#fcfcfc"
                                  color="#03c0c2"
                                  style="position: relative; top: 5px"
                                  [checked]="val.is_create"
                                  [(ngModel)]="val.is_create"
                                  (ngModelChange)="onCreate(val)"
                                >
                                </ui-switch>
                              </td>
                              <td>
                                <ui-switch
                                  class="p-0"
                                  [disabled]="!teamPermissions.is_update"
                                  switchColor="#fcfcfc"
                                  color="#03c0c2"
                                  style="position: relative; top: 5px"
                                  [checked]="val.is_update"
                                  [(ngModel)]="val.is_update"
                                  (ngModelChange)="onUpdate(val)"
                                >
                                </ui-switch>
                              </td>
                              <td>
                                <ui-switch
                                  class="p-0"
                                  [disabled]="!teamPermissions.is_update"
                                  switchColor="#fcfcfc"
                                  color="#03c0c2"
                                  style="position: relative; top: 5px"
                                  [(ngModel)]="val.is_delete"
                                  [checked]="val.is_delete"
                                  (ngModelChange)="onDelete(val)"
                                >
                                </ui-switch>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>

        <!-- <table class="display dataTable no-footer" style="width:100%;">
          <thead>
            <tr>
              <th style="border:0;width: 400px;">Permissions</th>
              <th style="border:0;">Read</th>
              <th style="border:0;">Create</th>
              <th style="border:0;">Update</th>
              <th style="border:0;">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of permissions | paginate: { itemsPerPage: items_per_page, currentPage: p }">
              <td class="first-td">
                {{v.permission}}
              </td>
              <td>
                <ui-switch [disabled]="!teamPermissions.is_update" switchColor="#fcfcfc" color="#03c0c2"
                  style="position: relative;top: 5px;" [(ngModel)]="v.is_read" [checked]="v.is_read"
                  (ngModelChange)="onRead(v)">
                </ui-switch>
              </td>
              <td>
                <ui-switch [disabled]="!teamPermissions.is_update" switchColor="#fcfcfc" color="#03c0c2"
                  style="position: relative;top: 5px;" [checked]="v.is_create" [(ngModel)]="v.is_create"
                  (ngModelChange)="onCreate(v)">
                </ui-switch>
              </td>
              <td>
                <ui-switch [disabled]="!teamPermissions.is_update" switchColor="#fcfcfc" color="#03c0c2"
                  style="position: relative;top: 5px;" [checked]="v.is_update" [(ngModel)]="v.is_update"
                  (ngModelChange)="onUpdate(v)">
                </ui-switch>
              </td>
              <td>
                <ui-switch [disabled]="!teamPermissions.is_update" switchColor="#fcfcfc" color="#03c0c2"
                  style="position: relative;top: 5px;" [(ngModel)]="v.is_delete" [checked]="v.is_delete"
                  (ngModelChange)="onDelete(v)">
                </ui-switch>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>

      <div class="showentries">
        <label>Show Entries</label>
        <div class="select-style" style="width: 60px">
          <select
            class="ng-tns-c5-1"
            id="phone-number-prefix"
            [(ngModel)]="items_per_page"
          >
            <option
              class="ng-tns-c5-1"
              *ngFor="let page of page_per_item_list"
              [value]="page.value"
            >
              {{ page.value }}
            </option>
          </select>
        </div>
      </div>
      <pagination-controls
        (pageChange)="p = $event"
        class="revis_pagination"
      ></pagination-controls>
    </div>
    <div class="mt-4" *ngIf="assign_permission?.is_update && !is_team_blocked">
      <button
        (click)="onSave()"
        class="edit-button mr-2"
        type="button"
        [disabled]="save_btn_submit == true"
      >
        <img src="assets/images/save-file-option.svg" />&nbsp; Save
      </button>
      <a [routerLink]="['/team']">
        <button class="cancel-button" type="button">
          &nbsp;<span class="srbtns">&nbsp;Cancel</span>
        </button>
      </a>
    </div>
  </div>
</div>
