<!-- <ngx-ui-loader></ngx-ui-loader> -->
<ngx-loading [show]="loading"></ngx-loading>
<div id="expiry-modal"></div>

<div [appExpiryOverlay]="is_product_blocked" class="row first-child">
  <app-banner
  *ngIf="!is_product_blocked && product_warning_text?.length > 0"
  [bannerType]="'warning'"
  [bannerIconRequired]="true"
  [bannerText]="product_warning_text"
  [bannerCloseIconRequired]="true"
  (isRemoveBanner)="deleteBanner()"
>
</app-banner>
  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
    <ngx-tabset class="card card-border-radius reviewtabs box_shadow_color">
      <ngx-tab tabTitle="Profile">
        <form
          class="sec_margin_space profile_form"
          [formGroup]="user_profile"
          (ngSubmit)="updateUserProfile(user_profile)"
        >
          <!-- <div class='row d-flex align-items-baseline w-100' >
                <div class="col-md-6 col-12 profile_image">
                  <img src="assets/images/user.svg" width="70px">
                </div>
                <div class="col-md-6 col-12">
                  <button class='edit-button' type="submit">
                    Edit
                  </button>
                </div>
            </div> -->
          <div class="row w-100">
            <div class="p-4 w-100">
              <!-- <div class='row'>
                        <div class="col-md-6 col-12 profile_image">
                          <img src="assets/images/user.svg" width="70px">
                        </div>
                        <div class="col-md-12 col-12">
                            <button class='edit-button' type="submit" [disabled]="!user_profile.valid">
                                <img src="assets/images/save-file-option.svg">&nbsp;
                                Save
                            </button>
                        </div>
                    </div> -->
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label
                      for="first_name"
                      class="busprotit_label col-md-3 col-12"
                      >First Name</label
                    >
                    <input
                      type="text"
                      class="form-control busprotit_text col-md-8 col-12"
                      placeholder="First Name"
                      id="first_name"
                      name="first_name"
                      formControlName="first_name"
                      pattern="[a-zA-Z ]*"
                      required
                    />
                  </div>
                  <div
                    *ngIf="userFormData.first_name.errors?.pattern"
                    class="invalid-feedback"
                  >
                    <p>Name should be Alphabets.</p>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label
                      for="last_name"
                      class="busprotit_label col-md-3 col-12"
                      >Last Name</label
                    >
                    <input
                      type="text"
                      class="form-control busprotit_text col-md-8 col-12"
                      placeholder="Last Name"
                      id="last_name"
                      formControlName="last_name"
                      pattern="[a-zA-Z ]*"
                      required
                    />
                    <div
                      *ngIf="userFormData.last_name.errors?.pattern"
                      class="invalid-feedback"
                    >
                      <p>Last Name should be Alphabets.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label for="role" class="busprotit_label col-md-3 col-12"
                      >Role</label
                    >
                    <input
                      type="text"
                      class="form-control busprotit_text col-md-8 col-12"
                      placeholder="Role"
                      id="role"
                      name="role"
                      formControlName="role"
                      pattern="[a-zA-Z0-9 ]*"
                      disabled
                      required
                    />
                    <!-- <select class="form-control busprotit_text" id="role" formControlName="role">
                                    <option [value]="rol.id" *ngFor="let rol of rolelist">{{rol.name}}</option>
                                </select> -->
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label for="branch" class="busprotit_label col-md-3 col-12"
                      >Branch</label
                    >
                    <input
                      type="text"
                      class="form-control busprotit_text col-md-8 col-12"
                      placeholder="Branch"
                      id="branch"
                      name="branch"
                      formControlName="branch"
                      disabled
                      required
                    />
                    <!-- <select class="form-control busprotit_text" id="branch" formControlName="branch">
                                    <option [value]="bra.id" *ngFor="let bra of branchlist">{{bra.name}}</option>
                                </select> -->
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label for="brand" class="busprotit_label col-md-3 col-12"
                      >Mobile</label
                    >
                    <div class="input-group col-md-8 col-12" style="padding: 0">
                      <div class="input-group-btn bs-dropdown-to-select-group">
                        <select
                          class="form-control phnosendreview"
                          [disabled]="true"
                          id="phone-number-prefix"
                          required
                        >
                          <option
                            *ngFor="let prefix of phoneNumberPrefixes"
                            [value]="prefix"
                          >
                            {{ prefix }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        [readonly]="read_only"
                        class="form-control busprotit_text"
                        formControlName="phone_number"
                        pattern="[0-9]*"
                      />
                      <div
                        *ngIf="userFormData.phone_number.errors?.pattern"
                        class="invalid-feedback"
                      >
                        <p>Mobile number should be number .</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="row form-group col-12">
                    <label
                      for="Leave_Detail"
                      class="busprotit_label col-md-3 col-12"
                      >Email</label
                    >
                    <input
                      type="email"
                      class="form-control busprotit_text col-md-8 col-12"
                      id="email_id"
                      name="email_id"
                      formControlName="email_id"
                      placeholder="Email"
                      id="email"
                      (keyup)="checkmail()"
                      required
                    />
                    <div
                      *ngIf="
                        userFormData.email_id.value != '' &&
                        emailnotvalid != true
                      "
                      class="invalid-feedback"
                    >
                      <p>Email is Not valid</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-12">
                  <div class="row form-group upload-container">
                    <label class="heading col-md-3 col-12">Profile image</label>
                    <div class="col-md-8 col-12 card w-98 card-border-radius">
                      <div class="row w-100">
                        <div class="col-md-8 col-lg-8 upload-area">
                          <div
                            class="d-flex flex-column w-100 h-100 align-items-center justify-content-center upload-photo-container"
                          >
                            <label for="upload-photo"
                              ><img
                                src="assets/images/cloud-upload-arrow.svg"
                                width="60px"
                            /></label>
                            <input
                              id="upload-photo"
                              type="file"
                              ng2FileSelect
                              [uploader]="excelUploader"
                              (change)="fileUploaded($event)"
                            />
                            <p id="upload-photo">
                              Upload or Drop your files here
                            </p>
                          </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                          <div class="pl-2 up_img_view">
                            <div
                              class="pl-2 up_img_view"
                              *ngIf="profile_image_url == undefined"
                            >
                              <img
                                src
                                imgPreview
                                [image]="url"
                                style="width: 55px; height: auto"
                              />
                            </div>
                            <div
                              class="pl-2 up_img_view"
                              *ngIf="profile_image_url != undefined"
                            >
                              <img
                                [src]="profile_image_url"
                                imgPreview
                                style="width: 55px; height: auto"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class='row w-100'>
                                        <div class='col-md-8 col-lg-8 upload-area' ng2FileDrop
                                            [ngClass]="{'file-over': type.hasBaseDropZoneOver}"
                                            (fileOver)="fileOverBase($event,i)"
                                            (onFileDrop)="onAfterAddingFile($event,i)" [uploader]="type.uploader">
                                            <div
                                                class='d-flex flex-column w-100 h-100 align-items-center justify-content-center upload-photo-container'>
                                                <label for="upload-photo{{i}}"><img src="assets/images/cloud-upload-arrow.svg" width="60px"></label>

                                                <input id='upload-photo' type="file" ng2FileSelect [uploader]="excelUploader" (change)="fileUploaded($event)" >
                                                <p id='upload-photo'>Upload or Drop your files here</p>
                                            </div>
                                        </div>
                                        <div class='col-md-4 col-lg-4'>
                                            <div class='pl-2 up_img_view' *ngIf='logo_url == undefined'>
                                                <img src imgPreview [image]="type.uploader?.queue[0]?._file" style='width:100px;height:auto;'>
                                                <p class='image-name'>{{type.src}}</p>
                                            </div>
                                            <div class='pl-2 up_img_view' *ngIf='logo_url != undefined'>
                                                <img [src]='logo_url' imgPreview style='width:100px;height:auto;'>
                                                <p class='image-name'>{{type.src}}</p>
                                            </div>
                                        </div>
                                    </div> -->
                    </div>
                  </div>
                </div>
                <div
                  class="row form-group col-md-6 col-12"
                  *ngIf="userFormData.role.value != 'Owner'"
                >
                  <label class="col-md-3 col-12 busprotit_label"
                    >Weekly Off</label
                  >
                  <div class="input-group col-md-8 col-12">
                    <select
                      class="form-control col-md-12 col-12"
                      name="weekly_off"
                      placeholder="Select Role"
                      formControlName="weekly_off"
                      disabled
                    >
                      <option value="" selected>NA</option>
                      <ng-container *ngFor="let day of weekdays">
                        <option *ngIf="day.SUNDAY" value="SUNDAY">
                          {{ day.SUNDAY }}
                        </option>
                        <option *ngIf="day.MONDAY" value="MONDAY">
                          {{ day.MONDAY }}
                        </option>
                        <option *ngIf="day.TUESDAY" value="TUESDAY">
                          {{ day.TUESDAY }}
                        </option>
                        <option *ngIf="day.WEDNESDAY" value="WEDNESDAY">
                          {{ day.WEDNESDAY }}
                        </option>
                        <option *ngIf="day.THURSDAY" value="THURSDAY">
                          {{ day.THURSDAY }}
                        </option>
                        <option *ngIf="day.FRIDAY" value="FRIDAY">
                          {{ day.FRIDAY }}
                        </option>
                        <option *ngIf="day.SATURDAY" value="SATURDAY">
                          {{ day.SATURDAY }}
                        </option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <label
                  class="col-12 busprotit_label"
                  style="text-align: left"
                  *ngIf="userFormData.role.value != 'Owner'"
                  >Leave Information</label
                >
                <div
                  class="row p-2 table-responsive notifitable"
                  *ngIf="userFormData.role.value != 'Owner'"
                >
                  <table
                    id="example"
                    class="display dataTable no-footer"
                    style="width: 100%"
                  >
                    <thead>
                      <tr>
                        <th style="width: 25%">Start Date</th>
                        <th style="width: 25%">End Date</th>
                        <th style="width: 25%">Leave Days</th>
                        <th style="width: 25%">Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!Total_leave?.length">
                        <td colspan="8">
                          <div
                            class="col-12 emptydashbrd reviews text-center my-5"
                          >
                            <img src="assets/icons/3.svg" width="60" />
                            <h5 class="mt-2">No Data Found</h5>
                          </div>
                        </td>
                      </tr>
                      <tr *ngFor="let item of Total_leave">
                        <td>
                          <input
                            class="form-control w-100"
                            disabled
                            placeholder="Start Date"
                            [(ngModel)]="item.start_date"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </td>
                        <td>
                          <input
                            class="form-control w-100"
                            disabled
                            placeholder="End Date"
                            [(ngModel)]="item.end_date"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </td>
                        <td>
                          {{ item.total_leave_day }}
                        </td>
                        <td>
                          {{ item.reason }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <!-- <div class="col-md-6 col-12 profile_image">
                              <img src="assets/images/user.svg" width="70px">
                            </div> -->
                <div class="col-md-12 col-12">
                  <button
                    class="edit-button"
                    type="submit"
                    [disabled]="!user_profile.valid"
                  >
                    <img src="assets/images/save-file-option.svg" />&nbsp; Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ngx-tab>
    </ngx-tabset>
  </div>
</div>
