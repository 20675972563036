import { Inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "src/app/app.component";
import { DataService, Response } from "src/app/dataService/data.service";
import { AlertService } from "../alert.service";
import {
  ProductFeature,
  ExpiryProductDetails,
  ExpiryPlanDetails,
  PlanNames,
  PRODUCT_NAMES,
} from "./interface";

@Injectable({
  providedIn: "root",
})
export class ExpiryWarningService {
  private BASE_URL = GlobalVariable;
  /**
   * Check outlet type whether Trial or Paid
   */
  private accountType: PlanNames;

  /**
   * Get list of product names to show modal title
   */
  private productNames: ProductFeature = PRODUCT_NAMES;

  /**
   * Product expiry detail based on product in following structure
   *
   * ```
   * 'PRODUCT': {
   * is_expired: boolean,
   * product_warning_text: string
   * }
   * ```
   *
   */
  public expiryProductDetails: ExpiryProductDetails;

  /**
   * expiry detail based on plan in following structure
   *
   * ```
   * {
   * is_expired: boolean,
   * product_warning_text: string
   * }
   * ```
   *
   */
  public expiryPlanDetails: ExpiryPlanDetails;

  /**
   * Product expiry detail based on product in following structure
   *
   * ```
   * 'PRODUCT': {
   * is_expired: boolean,
   * product_warning_text: string
   * }
   * ```
   *
   */
  public multiLocExpiryProductDetails: ExpiryProductDetails;

  /**
   * expiry detail based on plan in following structure
   *
   * ```
   * {
   * is_expired: boolean,
   * product_warning_text: string
   * }
   * ```
   *
   */
  public multiLocExpiryPlanDetails: ExpiryPlanDetails;

  /**
   * Title and content for expiry modal based on outlet type
   * Consider other than Trial as paid.
   */
  private expiryDetailByAccountType = {
    TITLE: `Unlock `, // Append feature name
  };

  private expiryModalConfig = {
    TRIAL: {
      button: `Upgrade Account`,
    },
    PAID: {
      button: `Upgrade Plan`,
    },
  };

  constructor(
    private alertService: AlertService,
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private toastrService: ToastrService
  ) {
    this.expiryProductDetails = this.productsSubscription;
    this.expiryPlanDetails = this.planSubscription;
    this.multiLocExpiryProductDetails = this.multiLocProductsSubscription;
    this.multiLocExpiryPlanDetails = this.multiLoclPlanSubscription;
  }

  /**
   * Get expiry details based on each product
   */
  get productsSubscription() {
    let expiryProductDetails =
      this.storage.get("access")?.products_subscription;
    return expiryProductDetails;
  }

  /**
   * Get expiry details based on plan
   */
  get planSubscription() {
    let expiryPlanDetails: ExpiryPlanDetails =
      this.storage.get("access")?.plan_subscription;
    this.accountType = expiryPlanDetails?.plan_name;
    return expiryPlanDetails;
  }

  /**
   * Get multiloc expiry details based on each product
   */
  get multiLocProductsSubscription() {
    let expiryProductDetails =
      this.storage.get("business_menu")?.products_subscription;
    return expiryProductDetails;
  }

  /**
   * Get multiloc expiry details based on plan
   */
  get multiLoclPlanSubscription() {
    let expiryPlanDetails: ExpiryPlanDetails =
      this.storage.get("business_menu")?.plan_subscription;
    this.accountType = expiryPlanDetails?.plan_name;
    return expiryPlanDetails;
  }

  /**
   *
   * @param message Toaster message
   * @returns triggers toaster method
   */
  toaster(message: string) {
    return {
      success: this.toastrService.success(message),
      error: this.toastrService.error(message),
      warning: this.toastrService.warning(message),
    };
  }

  /**
   * To get outlet id from storage
   */
  get outlet_id() {
    return this.storage.get("outlet_id");
  }

  /**
   * Function to show expiry modal when product is expired
   * @param feature @interface ProductFeature
   * @param divElement @type HTMLElement
   * @returns isExpired, expiryWarningText
   */
  checkExpiryBasedOnProduct(
    feature: keyof ProductFeature,
    divElement: HTMLElement | null,
    isMultiLoc?: boolean
  ): { isExpired: boolean; expiryWarningText: string } {
    let expiryTitle: string = "";
    let expiryContent: string = "";
    let isExpired: boolean;
    let expiryWarningText = "";
    if (isMultiLoc) {
      isExpired =
        this.multiLocExpiryProductDetails[feature]?.is_expired || false;
      expiryWarningText =
        this.multiLocExpiryProductDetails[feature]?.product_warning_text || "";
    } else {
      isExpired = this.expiryProductDetails[feature]?.is_expired || false;
      expiryWarningText =
        this.expiryProductDetails[feature]?.product_warning_text || "";
    }

    if (isExpired) {
      expiryTitle =
        this.expiryDetailByAccountType.TITLE + this.productNames[feature];
      expiryContent = expiryWarningText;
      this.openExpiryModal({ feature, expiryTitle, expiryContent, divElement });
    }
    return { isExpired, expiryWarningText };
  }

  checkExpiryBasedOnProductWithoutDialog(
    feature: keyof ProductFeature,
    isMultiLoc?: boolean
  ): { isExpired: boolean; expiryWarningText: string } {
    let expiryTitle: string = "";
    let expiryContent: string = "";
    let isExpired: boolean;
    let expiryWarningText = "";
    if (isMultiLoc) {
      isExpired =
        this.multiLocExpiryProductDetails[feature]?.is_expired || false;
      expiryWarningText =
        this.multiLocExpiryProductDetails[feature]?.product_warning_text || "";
    } else {
      isExpired = this.expiryProductDetails[feature]?.is_expired || false;
      expiryWarningText =
        this.expiryProductDetails[feature]?.product_warning_text || "";
    }

    if (isExpired) {
      expiryTitle =
        this.expiryDetailByAccountType.TITLE + this.productNames[feature];
      expiryContent = expiryWarningText;
    }
    return { isExpired, expiryWarningText };
  }

  /**
   * Function to show expiry modal when plan is expired
   * @param feature @interface ProductFeature
   * @param divElement @type HTMLElement
   * @returns isExpired, expiryWarningText
   */
  checkExpiryBasedOnPlan(
    feature: keyof ProductFeature,
    divElement: HTMLElement | null,
    isMultiLoc?: boolean
  ): { isExpired: boolean; expiryWarningText: string } {
    let expiryTitle: string = "";
    let expiryContent: string = "";
    let isExpired: boolean;
    let expiryWarningText: string;
    if (isMultiLoc) {
      isExpired = this.multiLocExpiryPlanDetails?.is_expired || false;
      expiryWarningText =
        this.multiLocExpiryPlanDetails?.product_warning_text || "";
    } else {
      isExpired = this.expiryPlanDetails?.is_expired || false;
      expiryWarningText = this.expiryPlanDetails?.product_warning_text || "";
    }
    if (isExpired) {
      expiryTitle =
        this.expiryDetailByAccountType.TITLE + this.productNames[feature];
      expiryContent = expiryWarningText;
      this.openExpiryModal({ feature, expiryTitle, expiryContent, divElement });
    }
    return { isExpired, expiryWarningText };
  }

  /**
   * To open expiry modal
   * @param expiry @type Object
   */
  private openExpiryModal(expiry: {
    feature: keyof ProductFeature;
    expiryTitle: string;
    expiryContent: string;
    divElement: HTMLElement | null;
  }) {
    // Consider other than TRIAL account as PAID
    let accountType = this.accountType != "TRIAL" ? "PAID" : this.accountType;
    this.alertService
      .alertOnExpiry(
        expiry.expiryTitle,
        expiry.expiryContent,
        this.expiryModalConfig[accountType].button,
        expiry.divElement,
        this.requestUpgradePlan.bind(this)
      )
      .then((data) => {
        console.log(data, "data");
      });
  }

  /**
   * To request for account renewal after expiry
   */
  requestUpgradePlan() {
    const dataService = this.httpService.postRequest({
      customApiUrl: this.BASE_URL.RENEW_PRODUCT_API(this.outlet_id),
    });
    dataService.subscribe(
      (data: Response) => {
        this.toaster(data.data["message"]).success;
      },
      (error) => {},
      () => {}
    );
  }
}
