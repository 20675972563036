<a
  class="dropdown-item notif d-flex"
  [class.notif-hover]="notificationType == 'popup'"
>
  <div class="notification-icon">
    <img src="assets/images/db_zceppa.png" width="24" height="24" />
    <div *ngIf="!notification?.is_read" class="unseen-noti-dot"></div>
  </div>
  <div class="notification-details flex-grow-1">
    <ng-container *ngIf="notification?.category == 'mylisting_bulk_update'">
      <div (click)="getFailedUpdateDetails()">
        <!-- Title -->
        <p class="mb-1 d-flex justify-content-between">
          <span class="new-title"> GBP Listings Update </span>
          <span
            class="text-small text-muted ml-auto"
            style="float: right; white-space: nowrap"
          >
            {{ notification.created_at | dateFormatter: "ago" }}
          </span>
        </p>
        <!-- Content -->
        <p class="text-small text-muted m-0 d-flex justify-content-between">
          <span>
            completed with the following details. <br />
            <ng-container
              *ngIf="
                notification.data.error_message;
                else mylisting_bulk_update
              "
            >
              {{ notification.data.error_message }}
            </ng-container>
            <ng-template #mylisting_bulk_update>
              Completed : {{ notification.data.completed }} &nbsp;Partial :
              {{ notification.data.partial }} &nbsp;Failed :
              {{ notification.data.failed }}
            </ng-template>
            <br />
            <span class="hyperlink cursor-pointer"> click here </span>
            to view details
          </span>
          <span class="text-small text-muted ml-auto notifytime">
            <ng-container *ngIf="notificationType == 'page'">
              {{ notification.created_at | dateFormatter: "D MMM YYYY h:mm A" }}
            </ng-container>
          </span>
        </p>
      </div>
    </ng-container>
    <!-- location_bulk_upload -->
    <ng-container *ngIf="notification?.category == 'location_bulk_upload'">
      <div (click)="clickToRedirect(notification)">
        <!-- Title -->
        <p class="mb-1 d-flex justify-content-between">
          <span class="new-title"> {{notification?.data?.title}} </span>
          <span
            class="text-small text-muted ml-auto"
            style="float: right; white-space: nowrap"
          >
            {{ notification?.created_at | dateFormatter: "ago" }}
          </span>
        </p>
        <!-- Content -->
        <p class="text-small text-muted m-0 d-flex justify-content-between">
          <span>
            {{notification?.data?.content}}
          </span>
          <span class="text-small text-muted ml-auto notifytime">
            <ng-container *ngIf="notificationType == 'page'">
              {{ notification?.created_at | dateFormatter: "D MMM YYYY h:mm A" }}
            </ng-container>
          </span>
        </p>
      </div>
    </ng-container>
  </div>
</a>

<ng-template #failedUpdateModal let-modal>
  <div class="modal-container w-100">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close('Close click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="mod-content-area">
      <img
        class="mb-12px"
        src="assets/images/multi-location/update-listing/tick-success.svg"
        alt=""
      />
      <p class="mod-heading mb-12px">
        Listing was updated successfully for
        {{
          failedDetails?.total_bulk_update_outlets - failedDetails?.failed_count
        }}
        location(s)
      </p>
      <ng-container *ngIf="failedDetails?.failed_count">
        <p class="failed-indication mb-12px">
          <img
            class="mb-12px"
            src="assets/images/multi-location/update-listing/red-danger.svg"
            alt=""
          />
          Updation of following {{ failedDetails?.failed_count }} location(s)
          failed
        </p>

        <div class="mod-content mb-12px w-100">
          <perfect-scrollbar
            [ngStyle]="{
              height: '30vh'
            }"
          >
            <div class="failed-details">
              <ng-container *ngFor="let error of errors; let i = index">
                <div class="row">
                  <div class="d-flex">
                    <div class="error-outlet-name">
                      {{ i + 1 }}. {{ error?.outlet_name }} :&nbsp;
                    </div>
                    <div *ngFor="let err of error.errors" class="errors">
                      {{ err?.failed_section }} - {{ err?.message }}
                      <br />
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </perfect-scrollbar>
        </div>
      </ng-container>

      <button
        class="btn btn-primary feedback-btn px-3"
        (click)="modal.close('Close Click')"
      >
        Ok
      </button>
    </div>
  </div>
</ng-template>
