import { transition, trigger, useAnimation } from "@angular/animations";
import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as introJs from "intro.js/intro.js";
import { slideInUp } from "ng-animate";
import { FileUploader } from "ng2-file-upload";
import { JoyrideService } from "ngx-joyride";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subject } from "rxjs";
import { GlobalVariable } from "../../app.component";
import { CustomersService } from "../../clients/customers.service";
import { DataService, Response } from "../../dataService/data.service";
import { MultiLocationService } from "../../multi-location/multi-location.service";
import { MyStoreService } from "../../my-store/my-store.service";
import { CommonService } from "../../services/common.service";
import { FeaturesByPlanService } from "../../services/features-by-plan/features-by-plan.service";
import { PusherService } from "../../services/pusher/pusher.service";
import { AlertService } from "../../services/alert.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: [
    "../../appointments/appointments.component.scss",
    "./header.component.scss",
  ],
  animations: [
    trigger("slideInUp", [transition("* => *", useAnimation(slideInUp))]),
  ],
})
export class HeaderComponent implements OnInit {
  slideInUp: any;
  @ViewChild("sendReviewModal", { static: true }) sendReviewModal: ElementRef;
  @ViewChild("changePassModal") changePassModal: ElementRef;
  @ViewChild("headerTemp", { static: true }) headerTemp: TemplateRef<any>;
  @ViewChild("cellTemp", { static: true }) cellTemp: TemplateRef<any>;
  @ViewChild("ErrorModal401", { static: true }) ErrorModal401: any;
  @ViewChild("ErrorModal403", { static: true }) ErrorModal403: TemplateRef<any>;
  // @ViewChild("onboardCompletionModal", { static: true })
  // onboardCompletionModal: TemplateRef<any>;

  introJS = introJs();

  private BASE_URL = GlobalVariable;
  reviewSendForm: FormGroup;
  outlet_id: string;
  businessId: string;
  review_template: any;
  review_template_id: String;
  review_render: String;
  sendreview: boolean = true;
  selecttype: boolean = false;
  submitReview: boolean = false;
  bulkActive: boolean = false;
  hasBaseDropZoneOver = false;
  nxtmove: boolean = false;
  locations = ["Velachery"];
  business_name: String;
  dateTime: String;
  prefix: any;
  user_name: String = "User";
  reviewerEmail: String;
  reviewerName: String;
  phoneNumber: number;
  country_code: number;
  outlet_name: String;
  emailnotvalid: boolean = true;
  isValidFormSubmitted: boolean = true;
  logo_url: String;
  total_review_count: number = 0;
  profile_image_url: String = null;
  latestnotification: any = [];
  topActionButton: any;
  @Output()
  toggleSidenav = new EventEmitter<void>();
  showSendReviewInviteButton: boolean = false;
  business_city: any = null;
  outlet_city: string = "";
  shareOptions = [
    { title: "SMS", selected: true },
    { title: "Email", selected: false },
    // { title:  'Whatsapp',  selected:  false },
  ];
  phoneNumberPrefixes = ["91"];
  inviteTypes = [
    { title: "Review Invitation", selected: true },
    { title: "Invitation Follow Up", selected: false },
    { title: "Negative Review Follow Up", selected: false },
    { title: "Say Thanks", selected: false },
  ];
  rows = [
    { name: "Austin", mobile: 111111 },
    { name: "Dany", mobile: 123456 },
    { name: "Austin", mobile: 123456 },
    { name: "Dany", mobile: 123456 },
    { name: "Molly", mobile: 123456 },
  ];
  columns = [];
  excelUploader: FileUploader = new FileUploader({
    url: "URL",
    disableMultipart: true,
  });
  outlet_list: any;
  outlet_details: string;
  businessList = [];
  businessDetails: string;
  errorMessage401: string;
  errorMessage403: string;
  showButton: any;
  productTourSteps: any;
  new_msg_count: number = 0;
  new_msg: string;
  mobile_active_chat_box: any;
  main_menu: any;
  product_warning_text: any = "";
  isDashboard: boolean = false;
  step1Process: boolean = true;
  businessDomain: any[] = [];
  cities: any[] = [];
  formSubmitted_1: boolean = true;
  step2Process: boolean = false;
  has_manager: boolean = false;
  same_as_business: boolean = true;
  formSubmitted_2: boolean = true;
  outlet_phone: any;
  business_phone: any;
  showMainContent: boolean = false;
  onboardNotification: any[] = [];
  selectedCityId: any;
  outlet_city_id: string;
  business_city_id: any;
  states: any = [];
  onCitySearch$: Subject<string> = new Subject();
  country_id: string;
  outletCities: any;
  businessCities: any;
  cityLoading: boolean;
  outletLoading: boolean;
  businessLoading: boolean;
  checkInternationalCountry: boolean;
  is_google_reconnect: any;
  is_terms_conditions: boolean;
  is_user_consent: boolean;
  isTermsConditionsSelected: boolean;
  isUserConsentSelected: boolean;
  dashboardTab: any;
  isMultiLocation: boolean;
  isBusinessView: boolean = false;
  is_outlet_view: boolean;
  switchToMultilocView: string = "";
  constructor(
    private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router,
    private HttpService: DataService,
    private toastrService: ToastrService,
    public formBuilder: FormBuilder,
    private commonservice: CommonService,
    private mystoreService: MyStoreService,
    private pusherService: PusherService,
    private readonly joyrideService: JoyrideService,
    private location: Location,
    private featuresByPlan: FeaturesByPlanService,
    private multiLocationService: MultiLocationService,
    private alertService: AlertService,
    private customerService: CustomersService
  ) {
    router.events.subscribe(() => {
      //found current active url Using Angular Location Service
      this.isDashboard =
        this.location.path() == "/dashboard/rating-reviews" ||
        this.location.path() == "/dashboard/mystore" ||
        this.location.path() == "/dashboard/social" ||
        this.location.path() == "/dashboard/appointments";
    });
  }
  isGoogleReconnect() {
    this.HttpService.getRequest({
      customApiUrl: this.BASE_URL.GOOGLE_RECONNECT_API(this.outlet_id),
    }).subscribe((res: any) => {
      this.is_google_reconnect = res.data["is_google_reconnect"];
    });
  }
  socialConnectQueryBuilder(baseApi, socialAuthApi, redirectUrl) {
    let accessToken = this.storage.get("access_token");
    this.outlet_id = this.storage.get("outlet_id");
    const data =
      baseApi +
      socialAuthApi +
      "?jwt=" +
      accessToken +
      "&outlet_id=" +
      this.outlet_id +
      "&redirect_url=" +
      redirectUrl;
    return data;
  }
  reconnectGoogle() {
    let redirectUrl = window.location.href;
    const val = this.socialConnectQueryBuilder(
      this.BASE_URL.BASE_API_URL,
      this.BASE_URL.GOOGLE_URL(this.outlet_id),
      redirectUrl
    );
    window.location.replace(val);
  }

  /**
   * On init function
   *
   * @memberof HeaderComponent
   */

  init() {
    this.main_menu = this.storage.get("access").main_menu;
    this.outlet_id = this.storage.get("outlet_id");
    this.businessId = this.storage.get("business_id");
    this.topActionButton = this.storage.get("access").top_action_button;
    this.dashboardTab = this.storage.get("access").dashboard_tab;
    this.showBut(this.storage.get("access").top_action_button);

    let res: Response;
    const offset = new Date().toString();
    const ss = new Date()
      .toString()
      .match(/\((.*)\)/)
      .pop();
    this.HttpService.get(
      this.BASE_URL.USER_PROFILE_IMAGE_URL(this.outlet_id)
    ).subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {},
      () => {
        this.profile_image_url = null;
        this.profile_image_url = res.data["profile_picture"];
      }
    );
    this.reviewSendForm = this.formBuilder.group({
      reviewerName: ["", Validators.compose([Validators.required])],
      reviewerEmail: ["", Validators.compose([Validators.required])],
      phoneNumber: ["", Validators.compose([Validators.required])],
      country_code: ["", Validators.compose([Validators.required])],
      review_template_id: ["", Validators.compose([Validators.required])],
    });
    this.getBusinessDetails();
    this.getOutletList();
    this.columns = [
      {
        name: "Name",
        prop: "name",
        headerTemplate: this.headerTemp,
        cellTemplate: this.cellTemp,
      },
      {
        name: "Mobile",
        prop: "mobile",
        headerTemplate: this.headerTemp,
        cellTemplate: this.cellTemp,
      },
    ];
    this.isGoogleReconnect();
    this.getlatestnotify();
    this.getUnreadCountInteract();
    this.productTourDetails();
    this.setMultilocRedirection();
    this.getBusinessList();
  }
  ngOnInit(): void {
    this.business_name = this.storage.get("business_name");
    this.is_outlet_view = this.storage.get("is_outlet_view"); // to show business option alone
    if (this.is_outlet_view) {
      this.init();
    }
    this.isBusinessView = this.storage.get("is_business_view");

    // this.showSendReviewInviteButton = this.storage.get('access').other_menu.some(_ => _ === 'Send Review Invite');
    this.multiLocationService.isMultiLocationView$.subscribe((response) => {
      this.isMultiLocation = response;
    });
    if (this.is_outlet_view) {
      this.commonservice.error401.subscribe((msg: string) => {
        this.openError401Modal(msg);
      });

      this.commonservice.error403.subscribe((msg: string) => {
        this.openError403Modal(msg);
      });

      this.commonservice.profile_image_url.subscribe((result) => {
        if (result !== null) {
          this.profile_image_url = result;
        }
      });

      this.commonservice.switch_outlet.subscribe((result) => {
        if (result === true) {
          this.getOutletList();
        }
      });

      this.commonservice.change_outlet.subscribe((res) => {
        if (res !== null) {
          this.changeOutlet(res.outlet_id, res.redirectTo);
        }
      });

      this.commonservice.interact_unread_count.subscribe((response) => {
        if (response !== null) {
          this.getUnreadCountInteract(true);
        }
        // console.log('this.new_msg_count',this.new_msg_count);
      });

      this.commonservice.check_onboard_notification.subscribe((res: any) => {
        if (res && this.onboardNotification.length) {
          this.getBusinessDetails(true);
        }
      });
      this.commonservice.is_google_reconnect.subscribe((res: boolean) => {
        if (res) {
          this.isGoogleReconnect();
        }
      });
    }

    //  const cout_timer = Math.round(30 * 60 * 1000);
    //  setInterval(() => {
    //   //  this.timeOutFunction();
    //  }, cout_timer);

    this.pusherService.channel.bind("new_review", (data) => {
      // console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("new_ticket", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("ticket_status_update", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("ticket_reply_update", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("ticket_note_update", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("ticket_assignee_update", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("bulk_upload_status", (data) => {
      console.log(data);
      console.log(this.pusherService.channel, "this.pusherService.channel");
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      if (data.error_message) {
        this.toastrService.error(data.error_message);
      } else {
        this.customerService.fetchBulkUploadContacts(true);
        this.toastrService.success("Contacts uploaded to Zceppa successfully");
      }
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    //latestnotification

    this.pusherService.channel.bind("bulk_campaign_status", (data) => {
      console.log(data);
      const data_json = {
        data: data,
      };
      this.commonservice.sentdraftCampaignTabSelection("SENTDRAFT");
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("campaign_reminder", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("renewal_reminder", (data) => {
      //  console.log(data);
      const data_json = {
        data: data,
      };
      // this.latestnotification.push(data_json);
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("credit_reminder", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("bulk_review_status", (data) => {
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("new_appointment", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("update_appointment", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("delete_appointment", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("new_enquiry", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("new_order_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("product_update_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("product_delete_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("order_update_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("order_delete_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind("bulk_product_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("bulk_message_status", (data) => {
      this.total_review_count = 1;
      this.commonservice.sentdraftCampaignTabSelection("SENTDRAFT");
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("payment_request", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("app_update", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("contact_delete_status", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("listing_updates", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });
    this.pusherService.channel.bind("social_post", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
      this.commonservice.sentdraftSocialTabSelection("SENTDRAFT");
    });

    this.pusherService.channel.bind("interact", (data) => {
      this.getUnreadCountInteract(true);
    });

    this.pusherService.channel.bind("template_update", (data) => {
      this.total_review_count = 1;
      this.getlatestnotify();
    });

    this.pusherService.channel.bind(
      "google_messaging_launch_status",
      (data) => {
        this.total_review_count = 1;
        this.getlatestnotify();
      }
    );

    // this.onCitySearch$
    //   .pipe(distinctUntilChanged(), debounceTime(500))
    //   .subscribe((city: string) => {
    //     this.getCities(city);
    //   });
    // this.commonservice.interaction_count.subscribe((new_msg) => {
    //   console.log('new_msg',new_msg);
    //   this.new_msg = new_msg
    //   this.new_msg_count = new_msg.length;
    // })
  }

  setMultilocRedirection() {
    let businessLandingMenu = this.multiLocationService.getLandingMenu;
    if (businessLandingMenu == "Unified Reviews") {
      this.switchToMultilocView = `/business/reviews`;
    } else {
      this.switchToMultilocView = `/business/dashboard`;
    }
  }

  checkAgreeTo(event: boolean) {
    this.isTermsConditionsSelected = event;
  }

  agreeToConsent(event: boolean) {
    this.isUserConsentSelected = event;
  }


  getBusinessDetails(triggeredOnSettings: boolean = false) {
    let resp: Response;
    /** Getting user information */
    const userInfo = this.HttpService.get(
      this.BASE_URL.DASHBOARD_PROFILE_URL(this.outlet_id)
    );
    userInfo.subscribe((data: Response) => {
      resp = data;
      this.storage.set("business_id", resp.data["business_id"]);
      this.storage.set("business_name", resp.data["business_name"]);
      this.storage.set("outlet_name", resp.data["outlet_name"]);
      this.storage.set("user_name", resp.data["user_name"]);
      this.storage.set("offset", resp.data["offset"]);
      this.storage.set("time_zone", resp.data["time_zone"]);
      this.storage.set(
        "is_customer_consent_allowed",
        resp.data["is_customer_consent_allowed"]
      );
      this.storage.set(
        "business_country_name",
        resp.data["business_country_name"]
      );
      this.storage.set("business_country_id", resp.data["business_country_id"]);

      this.storage.set("is_business_view", resp.data.is_business_view);
      this.isBusinessView = this.storage.get("is_business_view");
      if (this.isBusinessView) {
        this.multiLocationService.getBusinessMenuAPI();
      }
      this.checkInternationalCountry =
        resp.data["business_country_name"] == "India";
      this.business_name = resp.data["business_name"];
      this.is_terms_conditions = resp.data["is_terms_conditions"];
      this.is_user_consent = resp.data["is_user_consent"];
      this.business_phone = resp.data["business_phone"];
      this.outlet_name = resp.data["outlet_name"];
      this.outlet_phone = resp.data["outlet_phone"];
      this.user_name = resp.data["user_name"];
      this.product_warning_text = resp.data["product_warning_text"];
      this.country_id = resp.data["country_id"];
      this.commonservice.getBusinessProfile(resp.data["business_name"]);
      if (resp.data["is_incomplete"]) {
        localStorage.setItem("hasIncompleteOnboard", "1");
        this.router.navigate(["/onboarding/step-1"]);
        return;
      }
      this.onboardNotification = [];
      if (
        !resp.data["is_incomplete"] &&
        resp.data["notifications"].length &&
        resp.data["is_service_opted"]
      ) {
        this.onboardNotification = resp.data["notifications"];
      }
    });
  }

  disableOnGatherInfo() {
    return this.router.url.split("/").includes("gather-info");
  }
  redirectToSection(notif: any) {
    switch (notif.key) {
      case "integration":
        this.router.navigate(["/settings/integrations"]);
        return;
      case "is_team":
        this.router.navigate(["/team"]);
        return;
      case "is_mystore":
        this.router.navigate(["/settings/my-store"]);
        return;
      case "is_review":
        this.router.navigate(["/settings/review"]);
        return;
      case "is_interact":
        this.router.navigate(["/settings/interact"]);
        return;
      case "is_appointment":
        this.router.navigate(["/settings/appointments-settings"]);
        return;
      case "is_social":
        this.router.navigate(["/settings/social"]);
        return;

      default:
        return "";
    }
  }
  setRedirectName(notif: any) {
    switch (notif.key) {
      case "integration":
        return "integrate";
      case "is_team":
        return "add team member";
      case "is_mystore":
      case "is_review":
      case "is_interact":
      case "is_appointment":
      case "is_social":
        return "update settings";

      default:
        return "";
    }
  }
  deleteBanner(notif: any, i: number) {
    this.onboardNotification.length = 0;
  }
  fileUploaded(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (e) => {
        // called once readAsDataURL is completed
        let url = reader.result; // add source to image
      };
      let fileToUpload = event.target.files[0];
      const mimeType = fileToUpload.type;
      if (mimeType.match(/image\/*/) == null) {
        this.toastrService.error("Selected file is not image."); // = ;
        return;
      }
      let formdata = new FormData();
      formdata.append("brand_logo", fileToUpload, fileToUpload.name);
      const data_service = this.HttpService.postFormData(
        this.BASE_URL.BRAND_UPLOAD_URL(this.outlet_id),
        formdata
      );
      data_service.subscribe(
        (res: any) => {
          let resp: Response;
          console.log(res);
          // this.showSuccess(res.data['message']);
          if (res.code == 200 || res.code == 201) {
            // console.log(JSON.parse(res["_body"]));
            this.toastrService.success(res.data.message); // = ;
          } else {
            this.toastrService.error(res.data.message); // = ;
          }
          this.HttpService.get(
            this.BASE_URL.GET_LOGO_URL(this.outlet_id)
          ).subscribe(
            (data: Response) => {
              resp = data;
            },
            (error) => {},
            () => {
              this.logo_url = null;
              this.logo_url = resp.data["url"];
              this.storage.set("logo", this.logo_url);
              this.commonservice.logoImageUrl(resp.data["url"]);
            }
          );
        },
        (error) => {
          // this.showError(error);
        }
      );
    }
  }
  getUnreadCountInteract(isCalledFromObservable: boolean = false) {
    if (this.main_menu.includes("Interact")) {
      this.HttpService.getRequest({
        customApiUrl: this.BASE_URL.INTERACT_UNREADCOUNT(this.outlet_id),
      }).subscribe((res: any) => {
        let prevValue = this.new_msg_count;
        this.new_msg_count = res.data.message;
        this.commonservice.interact_unread_unique_conversation_count.next(
          this.new_msg_count.toString()
        );
        if (isCalledFromObservable && this.new_msg_count > prevValue) {
          setTimeout(() => {
            this.playNotificationSound();
          }, 100);
          this.commonservice.isEmptyConversation.next(false);
        }
      });
    }
  }

  playNotificationSound() {
    const audioElement: HTMLAudioElement = document.getElementById(
      "notificationSound"
    ) as HTMLAudioElement;
    audioElement?.play();
  }

  openError401Modal(msg) {
    this.errorMessage401 = msg;
    this.modalService.open(this.ErrorModal401, {
      backdropClass: "light-blue-backdrop",
      keyboard: false,
      backdrop: "static",
    });
  }
  openError403Modal(msg) {
    this.errorMessage403 = msg;
    this.modalService.open(this.ErrorModal403, {
      backdropClass: "light-blue-backdrop",
      keyboard: false,
      backdrop: "static",
    });
  }
  onOkError401() {
    this.signout();
    this.modalService.dismissAll();
  }
  onOkError403() {
    let landingMenu = this.storage.get("access").landing_menu;
    this.router.navigate([`/${this.commonservice.setRoutingURL(landingMenu)}`]);
    this.modalService.dismissAll();
  }
  initialFiveTour() {
    var tourArray = [
      "firstStep",
      "secondStep",
      "thirdStep",
      "fourthStep",
      "fifthStep",
      "lastStep",
    ];
    console.log("tourArray", tourArray);

    this.joyrideService.startTour({ steps: tourArray });
    return;
  }
  onClick() {
    var tourArray = [
      "firstStep",
      "secondStep",
      "thirdStep",
      "fourthStep",
      "fifthStep",
      "sixthStep",
      "seventhStep",
      "eightStep",
      "nineStep",
    ];
    var currentRouterUrl = this.location.path();
    console.log("ll", !this.isDashboard);

    if (!this.isDashboard) {
      if (currentRouterUrl == "listing-management/gmb/overview") {
        if (localStorage.getItem("showListingComponent") == "true") {
          tourArray.push("tenStep"); // Listing dashboard have 10 card tour
          this.joyrideService.startTour({ steps: tourArray });
          return;
        } else {
          this.initialFiveTour(); // no listing data found
        }
      } else {
        if (
          localStorage.getItem("showReviewDashboardComponent") == "true" &&
          (this.topActionButton == "Send Review Invite" ||
            this.dashboardTab == "Send Review Invite")
        ) {
          tourArray.push("tenStep"); // review dashboard have 10 card tour
          this.joyrideService.startTour({ steps: tourArray });
          return;
        }
        let toReview =
          localStorage.getItem("showReviewDashboardComponent") == "false" &&
          (this.topActionButton == "Send Review Invite" ||
            this.dashboardTab == "Send Review Invite");
        let toMystore =
          localStorage.getItem("showMyStoreDashboardComponent") == "false" &&
          (this.topActionButton == "Create Order" ||
            this.dashboardTab == "Create Order");
        let toAppointment =
          localStorage.getItem("showAppointmentDashboardComponent") ==
            "false" &&
          (this.topActionButton == "Add Appointment" ||
            this.dashboardTab == "Add Appointment");
        if (toReview || toMystore || toAppointment) {
          this.initialFiveTour();
        }
      }
    } else {
      if (
        localStorage.getItem("showReviewDashboardComponent") == "true" &&
        currentRouterUrl == "/dashboard/rating-reviews"
      ) {
        // when current page is not dashboard
        tourArray.push("tenStep"); // review dashboard have 10 card tour
        this.joyrideService.startTour({ steps: tourArray });
        return;
      }
      if (
        localStorage.getItem("showReviewDashboardComponent") == "false" &&
        currentRouterUrl == "/dashboard/rating-reviews"
      ) {
        tourArray = [
          "firstStep",
          "secondStep",
          "thirdStep",
          "fourthStep",
          "fifthStep",
          "lastStep",
        ];
        this.joyrideService.startTour({ steps: tourArray });
        return;
      }
      if (
        localStorage.getItem("showMyStoreDashboardComponent") == "false" &&
        currentRouterUrl == "/dashboard/mystore"
      ) {
        tourArray = [
          "firstStep",
          "secondStep",
          "thirdStep",
          "fourthStep",
          "fifthStep",
          "lastStep",
        ];
        this.joyrideService.startTour({ steps: tourArray });
        return;
      }
      if (
        localStorage.getItem("showAppointmentDashboardComponent") == "false" &&
        currentRouterUrl == "/dashboard/appointments"
      ) {
        tourArray = [
          "firstStep",
          "secondStep",
          "thirdStep",
          "fourthStep",
          "fifthStep",
          "lastStep",
        ];
        this.joyrideService.startTour({ steps: tourArray });
        return;
      }
      if (
        localStorage.getItem("showSocialComponent") == "false" &&
        currentRouterUrl == "/dashboard/social"
      ) {
        this.initialFiveTour();
      }
    }
    this.joyrideService.startTour({ steps: tourArray });
  }

  showBut(button) {
    console.log(button);
    this.showButton = {
      buttonName: button,
      buttonIcon:
        button == "Send Review Invite"
          ? "menu-sprite-reviews"
          : button == "Create Order"
          ? "menu-sprite-mystore"
          : button == "Add Appointment"
          ? "menu-sprite-appointment"
          : "menu-sprite-social",
      route:
        button == "Send Review Invite"
          ? "/send-review-invite"
          : button == "Create Order"
          ? "/mystore/order/create"
          : button == "Add Appointment"
          ? "/appointments"
          : "/social",
    };
  }

  productTourDetails() {
    this.productTourSteps = {
      stepTwo: [
        `This section shows the name of your location, links to view and update your profile,
        notifications you will receive from Zceppa from time to time & support information.`,
      ],

      stepThree: [
        `Click this button to send out review invites to your customers by filling their name and phone number. It only takes 20 seconds!`,
        `Click this button to create a new customer order on your MyStore product. You can add products, customer and delivery details to the order.
        alternately, customers can place an order directly via your ShopNow link. To know your ShopNow link, please navigate to Settings → MyStore → Order Settings → Consumer Store
        `,
        `Click this button to navigate to your Appointment page and book a new appointment. You can select the day, time-slot, add customer details, notes during creation. Customers can also directly book appointments via the consumer link, when you simply share it with them. To locate your consumer link go to
        Settings → Appointments -->Consumer Booking
        `,
      ],
    };
  }

  goToInteract() {
    // console.log(this.new_msg);
    // console.log(this.new_msg[this.new_msg.length - 1]);
    // this.interactService.closeChatModal(this.new_msg[this.new_msg.length - 1])
    this.router.navigate(["/interact"]);

    // this.new_msg_count = 0;
  }
  changeRoute(route) {
    console.log(route);
    if (route == "/mystore/order/create") {
      this.commonservice.myStoreMenuSelectionTopActionButton(true);
      // this.commonservice.myStoreMenuSelection(true);
      this.mystoreService.createOrderOnBoard.next(true);
    } else if (route == "/appointments") {
      this.commonservice.showAddAppointmentModal(true);
    } else if (route == "/social") {
    }
    this.router.navigate([route]);
  }

  getOutletList() {
    let resp: Response;
    this.outletLoading = true;
    const outlet_list = this.HttpService.get(
      this.BASE_URL.OUTLET_LIST_URL(this.outlet_id)
    );
    outlet_list.subscribe(
      (data: Response) => {
        resp = data;
      },
      (error) => {},
      () => {
        this.outlet_list = resp.data;
        this.outlet_list.forEach((val: any, key: any) => {
          if (val.current === true) {
            this.outlet_details = val.outlet_id;
          }
          if (val.base_outlet) {
            val.internal_name += " (Base) ";
          }
        });
        this.outletLoading = false;
      }
    );
  }

  getBusinessList() {
    let resp: Response;
    this.businessLoading = true;
    this.HttpService.get(
      this.BASE_URL.BUSINESS_LIST_URL(this.businessId)
    ).subscribe(
      (data: Response) => {
        resp = data;
      },
      (error) => {},
      () => {
        this.businessList = resp.data;
        this.businessList.forEach((val: any, key: any) => {
          if (val.current === true) {
            this.businessDetails = val.business_id;
          }
        });
        this.businessLoading = false;
      }
    );
  }

  changeBusiness(businessId: string, redirectTo: string | null = null) {
    this.businessDetails = businessId;
    const existingBusinessId = this.storage.get("business_id");
    let res: Response;
    const payload = {
      business_id: this.businessDetails,
    };
    const data_service = this.HttpService.postjson(
      this.BASE_URL.SWITCH_BUSINESS_URL(existingBusinessId),
      payload
    );
    data_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        if (error.status == 401) {
          this.toastrService.error(error.error.msg);
        } else {
          this.toastrService.error(error.error.message);
        }
      },
      () => {
        console.log("1454 ---->>", res);
        if (res.code === 200 || res.code === 201) {
          this.storage.set("outlet_id", res.data["default_outlet_id"]);
          this.storage.set("business_id", res.data["business_id"]);
          this.businessId = res.data["business_id"];

          if (this.isBusinessView) {
            this.multiLocationService
              .getBusinessMenuAPIFromBusinessId()
              .subscribe((response) => {
                if (response.code == 200) {
                  this.storage.set("business_menu", response.data);
                  this.storage.set(
                    "business_name",
                    response.data.business_name
                  );

                  let url = this.getRouterPathFromLandingMenu(
                    response.data.landing_menu
                  );

                  this.router.navigate([url], { replaceUrl: true });

                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }
              });
          }
        } else {
          this.toastrService.error(res.data["message"]);
        }
      }
    );
  }

  getlatestnotify() {
    this.outlet_id = this.storage.get("outlet_id");
    let response: Response;
    const notifinfo = this.HttpService.get(
      this.BASE_URL.NOTIFICATION_LATEST_URL(this.outlet_id)
    );
    notifinfo.subscribe((data: Response) => {
      response = data;
      this.latestnotification = response.data["notifications"];
      this.latestnotification.forEach((val: any, key: any) => {
        this.latestnotification[key].data = JSON.parse(val.data);

        //  console.log('this.latestnotification');
        //  console.log(this.latestnotification.length);
        //  console.log(this.latestnotification, "this.latestnotification");
      });
    });
  }

  /**
   * Set timeout function in notification
   *
   * @memberof HeaderComponent
   */

  /**
   * Open send review model popup
   *
   * @memberof HeaderComponent
   */
  openSendReviewInviteModal() {
    this.commonDataService();
    this.reviewSendForm.setValue({
      reviewerName: "",
      reviewerEmail: "",
      phoneNumber: "",
      country_code: "91",
      review_template_id: "",
    });
    this.selecttype = true;
    this.sendreview = true;
    this.submitReview = false;
    this.nxtmove = false;
    this.bulkActive = false;
    this.modalService.open(this.sendReviewModal, {
      size: "lg",
      windowClass: "modal-xl",
      centered: true,
    });
  }

  /**
   * Change Password modal popup
   */
  openchangePasswordModal() {
    // this.modalService.open(this.changePassModal,  { size:  'lg',
    //  windowClass:  'modal-xl',  centered:  true
    //  });
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  onAfterAddingFile(event) {}
  onShareOptionChange(option, index: number) {
    this.shareOptions = this.shareOptions.map((elem, i) => {
      elem.selected = i === index;
      return elem;
    });
  }

  /**
   * Get all data from review details send form and validating the form data
   *
   * @readonly
   * @memberof HeaderComponent
   */
  get reviewFormData() {
    return this.reviewSendForm.controls;
  }

  /**
   * Email validation
   */
  checkmail() {
    this.emailnotvalid = true;
    if (
      this.reviewFormData.reviewerEmail.value.match(
        /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,}$/
      )
    ) {
      this.emailnotvalid = true;
    } else {
      this.emailnotvalid = false;
    }
  }

  /**
   * Get review template
   *
   * @param {FormGroup} Form
   * @memberof HeaderComponent
   */
  getReviewTemplate(Form: FormGroup) {
    this.isValidFormSubmitted = false;
    this.emailnotvalid = false;
    if (this.reviewSendForm.invalid) {
      return;
    }
    this.isValidFormSubmitted = true;
    this.emailnotvalid = true;
    this.selecttype = false;
    this.sendreview = false;
    this.submitReview = true;
    this.nxtmove = true;
    let resp: Response;
    const data = {
      args: {
        name: Form.value.reviewerName,
        email: Form.value.reviewerEmail,
        phonenumber: Form.value.phoneNumber,
      },
      template_id: Form.value.review_template_id,
    };
    const sendReview = this.HttpService.postjson(
      this.BASE_URL.SMS_REVIEW_RENDER_URL(this.outlet_id),
      data
    );
    sendReview.subscribe(
      (val: Response) => {
        resp = val;
      },
      (error) => {},
      () => {
        this.review_render = resp.data["template"].rendered;
      }
    );
  }

  /**
   * Select Box validation check
   */
  validationCheck() {
    this.isValidFormSubmitted = true;
  }

  /**
   * Open send review popup contact popup
   *
   * @param {*} from
   * @memberof HeaderComponent
   */
  nexttab(from) {
    if (from === "send_Type") {
      this.selecttype = true;
      this.sendreview = true;
      this.submitReview = false;
      this.nxtmove = false;
    } else if (from === "submit_Review") {
      this.selecttype = false;
      this.sendreview = false;
      this.submitReview = true;
      this.nxtmove = true;
    } else {
      this.selecttype = false;
      this.sendreview = false;
      this.submitReview = false;
    }
  }

  choosetype(e) {
    if (e.target.checked) {
      if (e.target.value === "1") {
        // console.log('mariwww');
        this.bulkActive = true;
      } else {
        // console.log('maw');
        this.bulkActive = false;
      }
    }
  }

  /**
   * Send review invite function
   *
   * @memberof HeaderComponent
   */
  sendSms() {
    let res: Response;
    const sms_data = {
      aggregator: {
        has_facebook: true,
        has_google: true,
      },
      name: this.reviewFormData.reviewerName.value,
      email_id: this.reviewFormData.reviewerEmail.value,
      phone_number: this.reviewFormData.phoneNumber.value,
      country_code: this.reviewFormData.country_code.value,
      sms_text: this.review_render,
      template_id: this.reviewFormData.review_template_id.value,
    };
    const dataService = this.HttpService.postjson(
      this.BASE_URL.SMS_REQUEST_URL_V2(this.outlet_id),
      sms_data
    );
    dataService.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        this.sendReviewModalClose();
        this.showError(error.error.message);
      },
      () => {
        this.sendReviewModalClose();
        this.showSuccess(res.data["message"]);
      }
    );
  }

  /**
   * Signout and redirect to sign in page
   * @memberof ReviewTemplatesComponent
   */
  signout() {
    this.storage.remove("access_token");
    this.storage.remove("refresh_token");
    this.storage.remove("outlet_id");
    this.storage.remove("business_id");
    this.storage.remove("user_id");
    this.storage.remove("business_name");
    this.storage.remove("outlet_name");
    this.storage.remove("user_name");
    this.storage.remove("logo");
    this.storage.remove("review_count");
    this.storage.remove("default_template_id");
    this.storage.remove("role");
    this.storage.remove("access");
    this.storage.remove("has_password");
    this.storage.remove("is_business_view");
    this.storage.remove("business_menu");
    this.featuresByPlan.clearFeaturesByPlan();
    // this.router.navigate(['../signin']);
    window.location.assign("../signin");
    this.commonservice.setFirstView(false);
  }

  /**
   * Review model popup close
   *
   * @memberof HeaderComponent
   */
  sendReviewModalClose() {
    this.modalService.dismissAll("sendReviewModal");
  }

  /**
   * Change Password popup close
   */
  changePassModalClose() {
    this.modalService.dismissAll("changePassModal");
  }

  /**
   *common data service function for listing template
   *
   * @memberof ReviewTemplatesComponent
   */
  commonDataService() {
    let res: Response;
    this.outlet_id = this.storage.get("outlet_id");
    const dataService = this.HttpService.get(
      this.BASE_URL.SMS_REVIEW_GET_WOP_V2(this.outlet_id)
    );
    dataService.subscribe((data: Response) => {
      res = data;
      this.review_template = res.data["templates"];
    });
  }

  /**
   *Common toastr for success function
   *
   * @param {string} msg
   * @memberof ReviewTemplatesComponent
   */
  showSuccess(msg: string) {
    this.toastrService.success(msg);
  }

  /**
   * Common toastr for error function
   *
   * @param {string} msg
   * @memberof ReviewTemplatesComponent
   */
  showError(msg: string) {
    this.toastrService.error(msg);
  }

  config = {
    displayKey: "description",
    search: true,
    height: "auto",
    placeholder: "Select",
    // customComparator: ()=>{}
    // limitTo: options.length
    // moreText: 'more'
    // noResultsFound: 'No results found!'
    searchPlaceholder: "Search",
    // searchOnKey: 'name'
  };

  supportSelection() {}

  accountSelection() {}
  /**
   * Reduce notification count while button clickd
   *   *
   * @param
   * @memberof ReviewTemplatesComponent
   */
  countDown() {
    this.total_review_count = 0;
  }

  /**
   * Change outlet details
   */
  changeOutlet(outlet_id: string, redirectTo: string | null = null) {
    this.outlet_details = outlet_id;
    console.log(this.outlet_details);
    const existingOutlet_id = this.storage.get("outlet_id");
    let res: Response;
    const data_json = {
      outlet_id: this.outlet_details,
    };
    const data_service = this.HttpService.postjson(
      this.BASE_URL.SWITCH_OUTLET_URL(existingOutlet_id),
      data_json
    );
    data_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        if (error.status == 401) {
          this.toastrService.error(error.error.msg);
        } else {
          this.toastrService.error(error.error.message);
        }
      },
      () => {
        if (res.code === 200 || res.code === 201) {
          // this.storage.set('access_token', res.data['tokens'].access_token);
          // this.storage.set('refresh_token', res.data['tokens'].refresh_token);
          this.storage.set("outlet_id", res.data["outlet_id"]);
          this.storage.set("business_id", res.data["business_id"]);
          const accessInfo = this.HttpService.get(
            this.BASE_URL.ACCESS_INFO(res.data["outlet_id"])
          );
          const roleInfo = this.HttpService.get(
            this.BASE_URL.USER_PROFILE(res.data["outlet_id"])
          );
          accessInfo.subscribe(
            (data: any) => {
              this.storage.set("access", data.data);
              let is_business_view = this.storage.get("is_business_view");
              if (is_business_view) {
                this.multiLocationService.getBusinessMenuAPI();
              }
            },
            (err) => {},
            () => {
              // window.location.reload();
              let landingMenu = this.storage.get("access").landing_menu;
              this.featuresByPlan.clearFeaturesByPlan();
              roleInfo.subscribe((data) => {
                // resp = data;
                this.storage.set("role", data.data["role"]);
                if (redirectTo) {
                  window.location.assign(
                    `../${this.commonservice.setRoutingURL(redirectTo)}`
                  );
                } else {
                  window.location.assign(
                    `../${this.commonservice.setRoutingURL(landingMenu)}`
                  );
                }
              });
              // this.topActionButton = data.data['top_action_button'];
            }
          );
        } else {
          this.toastrService.error(res.data["message"]);
        }
      }
    );
  }

  isDashboardMenu() {
    return (
      (this.storage.get("access") !== undefined &&
        this.storage.get("access").landing_menu == "Dashboard") ||
      false
    );
  }

  socialRedirectionBasedOnDesc(notif: {
    data: { social_id: string; is_review_share?: boolean };
  }) {
    let socialPostId: string = notif.data?.social_id;
    if (socialPostId) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([`/social/view-analytics/${socialPostId}`]);
      });
    } else if (notif.data?.is_review_share) {
      this.router.navigate(["/reviews"]);
    } else {
      this.router.navigate(["/social"]);
    }
  }

  reviews(notif) {
    if (
      notif.category === "new_ticket" ||
      notif.category === "ticket_status_update" ||
      notif.category === "ticket_reply_update" ||
      notif.category === "ticket_note_update" ||
      notif.category === "ticket_assignee_update"
    ) {
      this.router.navigate(["/tickets"]);
    } else if (
      notif.category === "bulk_upload_status" &&
      notif.data.customer_list_id
    ) {
      this.commonservice.customersMenuSelection(true);
      this.router.navigate(["/customers/lists/", notif.data.customer_list_id]);
    } else if (
      (notif.category === "bulk_upload_status" &&
        !notif.data.customer_list_id) ||
      notif.category === "contact_delete_status"
    ) {
      this.commonservice.customersMenuSelection(true);
      this.router.navigate(["/customers/list"]);
    } else if (
      notif.category === "bad_review" ||
      notif.category === "new_review"
    ) {
      this.router.navigate(["/reviews"]);
    } else if (notif.category === "bulk_message_status") {
      if (notif?.data?.message_type) {
        this.router.navigate([`/messaging/sent/${notif?.data?.message_type}`]);
      } else {
        this.router.navigate(["/messaging/sent"]);
      }
    } else if (
      notif.category === "order_update_status" ||
      notif.category === "order_delete_status" ||
      notif.category === "new_order_status"
    ) {
      this.router.navigate(["/mystore/orders"]);
    } else if (
      notif.category === "bulk_product_status" ||
      notif.category === "product_update_status" ||
      notif.category === "product_delete_status"
    ) {
      this.router.navigate(["/mystore/products"]);
    } else if (
      notif.category === "new_appointment" ||
      notif.category === "update_appointment" ||
      notif.category === "delete_appointment" ||
      notif.category === "new_enquiry"
    ) {
      this.router.navigate(["/appointments"]);
      if (notif.category === "new_enquiry") {
        this.commonservice.appointmentTab.next(2);
      } else {
        this.commonservice.appointmentTab.next(1);
      }
    } else if (notif.category === "payment_request") {
      this.router.navigate(["/accounts"]);
    } else if (notif.category === "social_template_update") {
      this.socialRedirectionBasedOnDesc(notif);
    } else if (notif.category == "reconnect_google") {
      this.router.navigate(["/settings/integrations"]);
    } else if (notif.category == "google_messaging_launch_status") {
      this.router.navigate(["/settings/interact"]);
    } else if (notif.category == "listing_updates") {
      this.router.navigate(["/listing-management"]);
    } else if (notif.category == "social_post") {
      this.socialRedirectionBasedOnDesc(notif);
    } else if (notif.category == "template_update") {
      // this.socialRedirectionBasedOnDesc(notif);
      this.router.navigate(["/settings/messaging-template"]);
      this.commonservice.settingsMeesagingTab.next(3);
    } else if (notif.category == "credit_reminder") {
      this.router.navigate(["/accounts"]);
    }

    //  else if (notif.category === 'app_update') {
    //   this.router.navigate(['../dashboard']);
    // }
  }

  redirectToNextProductTourBasedOnDashboard(tour) {
    if (tour == "appointments") {
      this.router.navigate(["/dashboard/appointments"]);
      this.commonservice.firstViewCheck("appointments");
    } else if (tour == "mystore") {
      this.router.navigate(["/dashboard/mystore"]);
      this.commonservice.firstViewCheck("mystore");
    } else if (tour == "social") {
      this.router.navigate(["/dashboard/social"]);
      this.commonservice.firstViewCheck("social");
    } else {
      this.router.navigate(["/dashboard/rating-reviews"]);
      this.commonservice.firstViewCheck("reviews");
    }
  }
  redirectToNextTour() {
    var currentRouterUrl = this.location.path();
    if (!this.isDashboard) {
      // when current page is not dashboard
      if (currentRouterUrl != "listing-management/gmb/overview") {
        if (
          this.topActionButton == "Add Appointment" ||
          this.dashboardTab == "Add Appointment"
        ) {
          this.redirectToNextProductTourBasedOnDashboard("appointments");
        } else if (
          this.topActionButton == "Create Order" ||
          this.dashboardTab == "Create Order"
        ) {
          this.redirectToNextProductTourBasedOnDashboard("mystore");
        } else if (
          this.topActionButton == "Create Post" ||
          this.dashboardTab == "Create Post"
        ) {
          this.redirectToNextProductTourBasedOnDashboard("social");
        } else {
          this.redirectToNextProductTourBasedOnDashboard("reviews");
        }
      } else {
        this.router.navigate(["listing-management/gmb/overview"]);
        this.commonservice.firstViewCheck("listing");
      }
    } else {
      // Is dashboard page
      if (currentRouterUrl == "/dashboard/appointments") {
        this.redirectToNextProductTourBasedOnDashboard("appointments");
      } else if (currentRouterUrl == "/dashboard/mystore") {
        this.redirectToNextProductTourBasedOnDashboard("mystore");
      } else if (currentRouterUrl == "/dashboard/social") {
        this.redirectToNextProductTourBasedOnDashboard("social");
      } else {
        this.redirectToNextProductTourBasedOnDashboard("reviews");
      }
    }
  }
  stopProductTour() {
    this.commonservice.firstViewCheck(false);
  }
  goBackPrevTour() {
    var ele = document.getElementsByClassName("product-tour-menu");
    let navbarToggler: HTMLElement = ele[0].children[0] as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); //hide navigation toggle when clicked on pricing option
    }
  }
  joyRideIsMobileSidebar() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      return "fourthStep";
    }
    return;
  }
  onDone() {
    var ele = document.getElementsByClassName("product-tour-menu");
    let navbarToggler: HTMLElement = ele[0].children[0] as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); //hide navigation toggle when clicked on pricing option
    }
  }

  getRouterPathFromLandingMenu(landingMenu) {
    if (landingMenu == "Central Dashboard") {
      return "/business/dashboard";
    } else if (landingMenu == "Unified Reviews") {
      return "/business/reviews";
    } else if (landingMenu == "Social Feed") {
      return "/business/reviews";
    } else if (landingMenu == "Team") {
      return "/business/teams";
    } else if (landingMenu == "Location Groups") {
      return "/business/outlet-groups";
    }
  }
}
