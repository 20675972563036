import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Observable } from "rxjs";
import { GlobalVariable } from "src/app/app.component";
import { DataService, Response } from "src/app/dataService/data.service";

@Injectable({
  providedIn: "root",
})
export class BusinessNotificationService {
  private BASE_URL = GlobalVariable;

  constructor(
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  get business_id() {
    return this.storage.get("business_id");
  }
  getBusinessNotifications(filters: {
    page: string;
    size: string;
  }): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_BUSINESS_NOTIFICATION(this.business_id),
      page: filters.page,
      size: filters.size,
    });
  }
  getFailedUpdateDetails(ref_id: string): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_FAILED_UPDATE(this.business_id, ref_id),
    });
  }
}
