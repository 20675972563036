export class ChatUserModel {
  public interactId: string;
  public contactId: string;
  public name: string;
  public contactNumber: string;
  public lastConversationId: string;
  public source: string;
  public lastMessage: string;
  public lastMessageTime: string;
  public totalMessages: number;
  public unreadMessages: number;
  public onlineStatus: boolean;
  public iconPath: string;
  public activeChat?: boolean = false;
  public userChat?: MessageModel[] = [];
  public media: string;
  public mediaType: string;
  public canSendMessage: boolean = false;
  public isBlocked: boolean = false;
  public isFocused?: boolean;
  public is_story_mention?: boolean;
  public is_story_reply?: boolean;
  public is_reply_to?: boolean;
  constructor(
    interact_id: string,
    contact_id: string,
    name: string,
    contact_number: string,
    last_conversation_id: string,
    source: string,
    last_message: string,
    last_message_time: string,
    total_messages: number,
    unread_messages: number,
    media: string,
    media_type: string,
    online_status?: boolean,
    icon_path?: string,
    is_blocked?: boolean,
    is_story_mention?: boolean,
    is_story_reply?: boolean,
    is_reply_to?: boolean
  ) {
    this.interactId = interact_id;
    this.contactId = contact_id;
    this.name = name;
    this.contactNumber = contact_number;
    this.lastConversationId = last_conversation_id;
    this.source = source;
    this.lastMessage = last_message;
    this.lastMessageTime = last_message_time;
    this.totalMessages = total_messages;
    this.unreadMessages = unread_messages;
    this.onlineStatus = online_status;
    this.iconPath = icon_path;
    this.media = media;
    (this.mediaType = media_type), (this.isBlocked = is_blocked);
    this.is_story_mention = is_story_mention;
    this.is_story_reply = is_story_reply;
    this.is_reply_to = is_reply_to;
  }
}
// interact_id: "5f66c70f-4968-472c-a0d2-ba08b17771d5"
// contact_id: "1a7779cb-8d22-45f9-84f5-9c454fe85d23"
// name: "Anu"
// contact_number: "918840243661"
// last_conversation_id: "d8a05879-44c7-464b-a53a-bba300341709"
// last_message: null
// last_message_time: "2020-01-27T05:04:09"
// source: "WHATSAPP"
// total_messages: 2
// unread_messages

export class MessageModel {
  public conversationId: string;
  public message: string;
  public status: string;
  public senderType: string;
  public media: string;
  public mediaType: string;
  public createdAt: string;
  public isRead: boolean;
  public mediaId: string;
  public ticketId?: string;
  public ticketNumber?: string;
  public quickReplyButtons?: string;
  public is_story_mention?: boolean;
  public is_story_reply?: boolean;
  public is_reply_to?: boolean;
  public tag_team_member?: string;

  constructor(
    conversation_id: string,
    message: string,
    status: string,
    sender_type: string,
    media: string,
    media_type: string,
    created_at: string,
    is_read: boolean,
    media_id: string,
    ticket_id?: string,
    ticket_number?: string,
    quick_reply_buttons?: string,
    is_story_mention?: boolean,
    is_story_reply?: boolean,
    is_reply_to?: boolean,
    tag_team_member?: string
  ) {
    this.conversationId = conversation_id;
    this.message = message;
    this.status = status;
    this.senderType = sender_type;
    this.media = media;
    this.mediaType = media_type;
    this.createdAt = created_at;
    this.isRead = is_read;
    this.mediaId = media_id;
    this.ticketId = ticket_id;
    this.ticketNumber = ticket_number;
    this.quickReplyButtons = quick_reply_buttons;
    // console.log('mediaId',this.mediaId);
    this.is_story_mention = is_story_mention;
    this.is_story_reply = is_story_reply;
    this.is_reply_to = is_reply_to;
    this.tag_team_member = tag_team_member;
  }
}
// conversation_id: "92d6f2f6-b973-4e9d-bd19-d34db73b83be"
// created_at: "2020-09-09T11:19:27"
// is_read: true
// media: null
// media_type: null
// message: "Hello, your appointment with Royal Food Lucknow is confirmed for Sep 14 2020 at 20:00 PM. Please call 8840243661 for any inquiries."
// sender_type: "Business"
// status: "Sent"
// ticket_created: "2020-09-14T05:56:20"
// ticket_id: "e0dd628c-a88a-4077-979e-bda5acf8f69e"
// ticket_number: "ZCP0025"
// ticket_status: "New"
// conversation_id: "19322c90-b547-45c8-ab79-beb9243482be"
// message: "Hey  Divagar! It was great to see you today. Take a moment and tell us what you thought https://staging.zceppa.com/r/#/jJ4BVP"
// status: "Read"
// sender_type: "Business"
// media: null
// media_type: null
// created_at: "2020-01-22T13:20:24"
// is_read: true
