<div class="error-toast">
  <div class="error-container">
    <div *ngIf="imageUrl" class="auto-margin">
      <img [src]="imageUrl">
    </div>
    <div class="sent-text-container">
      <p *ngIf="headerText" class="sent-header mb-0">{{headerText}}</p>
      <p *ngIf="descriptionText" class="sent-description mb-0 mt-1">{{descriptionText}}</p>
    </div>
  </div>
  <div class="close-banner" (click)="closeToastMessage()">
    <img src="assets/images/onboarding/close.svg"/>
  </div>
</div>
