import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxLoadingModule } from "ngx-loading";
import { SharedModule } from "src/app/common-container/shared.module";

import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { BusinessNotificationPageComponent } from "./business-notification-page/business-notification-page.component";
import { BusinessNotificationPopupComponent } from "./business-notification-popup/business-notification-popup.component";
import { BusinessNotificationRoutingModule } from "./business-notification-routing.module";
import { BusinessNotificationComponent } from "./business-notification/business-notification.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    BusinessNotificationComponent,
    BusinessNotificationPopupComponent,
    BusinessNotificationPageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BusinessNotificationRoutingModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    NgxLoadingModule,
  ],
  exports: [BusinessNotificationPopupComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class BusinessNotificationModule {}
