import { Location, PlatformLocation } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Spinkit } from "ng-http-loader";
import { JoyrideService } from "ngx-joyride";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { AuthService } from "../app/auth/auth.service";
import { CommonService } from "../app/services/common.service";
import { PusherService } from "../app/services/pusher/pusher.service";
import { environment } from "../environments/environment";
import { DataService } from "./dataService/data.service";

@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  // introJS = introJs();
  public spinkit = Spinkit;
  // API_VERSION_V1 = 'v1';
  // API_VERSION_V2 = 'v2';
  outlet_id: String =
    this.storage.get("outlet_id") === "undefined"
      ? this.storage.get("outlet_id")
      : "events_channel";
  get_outlet_id: any;
  constructor(
    private location: Location,
    private locationredirect: PlatformLocation,
    private commonservice: CommonService,
    translate: TranslateService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private pusherService: PusherService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private httpService: DataService,
    private readonly joyrideService: JoyrideService
  ) {
    // const _navigate = this.router.navigate;
    // this.router.navigate = function(commands: any[], extras: NavigationExtras = {}) {
    //   extras = {...extras, queryParamsHandling: extras.queryParamsHandling !== undefined ? extras.queryParamsHandling : true};
    //   return _navigate.call(this, commands, extras);
    // };
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
    locationredirect.onPopState(() => {
      const route_titles = this.location.path().split("/");
      const route_params =
        route_titles[1].charAt(0).toLocaleUpperCase() +
        route_titles[1].slice(1);
      if (this.auth.isAuthenticated() && this.location.path() === "/signin") {
        history.go(1);
      } else if (
        this.auth.isAuthenticated() &&
        this.location.path() === "/dashboard"
      ) {
        this.router.navigate(["../dashboard"]);
      } else {
        this.commonservice.menuDisplaySelection(route_params);
      }
    });

    // this.introJS.setOptions({
    //   steps: [
    //     {
    //       intro: "Hello world!"
    //     },
    //     {
    //       element: document.querySelector('#step1'),
    //       intro: "This is a tooltip."
    //     },
    //     {
    //       element: document.querySelectorAll('#step2')[0],
    //       intro: "Ok, wasn't that fun?",
    //       position: 'right'
    //     },
    //     {
    //       element: '#step3',
    //       intro: 'More features, more fun.',
    //       position: 'left'
    //     },
    //     {
    //       element: '#step4',
    //       intro: "Another step.",
    //       position: 'bottom'
    //     },
    //     {
    //       element: '#step5',
    //       intro: 'Get it, use it.'
    //     }
    //   ]
    // });
  }
  ngOnInit() {
    // this.introJS.start();

    // call onboard json for json data
    this.httpService
      .getRequest({ customApiUrl: GlobalVariable.GET_ONBOARDING_JSON })
      .subscribe(
        (res: any) => {
          // this.storage.set("onboard_json", res);
          if (res.code == 200) {
            if (this.storage.has("onboard_json")) {
              this.storage.remove("onboard_json");
            }
            this.storage.set("onboard_json", res.data);
          }
        },
        (error: any) => {
          console.log("this is on error", error);
        }
      );

    this.loadScript();

    if (
      this.auth.isAuthenticated() &&
      (this.location.path() === "/signin" || this.location.path() === "")
    ) {
      this.router.navigate(["../dashboard"]);
      return true;
    }

    if (this.location.path() !== "/analytics") {
      this.storage.remove("start_date");
      this.storage.remove("to_date");
    }
    const ur = window.location.href.split("/");
    if (ur.length === 5 && ur[3] === "r") {
      const url = location.origin + "/" + ur[3] + "/#/" + ur[4];
      window.location.replace(url);
    }
  }

  public loadScript() {
    /* Create your script */
    const head = <HTMLDivElement>document.head;
    const script = document.createElement("script");
    if (environment.env == "prod") {
      script.src = "./assets/scripts/newrelic/prod.js";
    } else if (environment.env == "staging") {
      script.src = "./assets/scripts/newrelic/staging.js";
    } else if (environment.env == "qa") {
      script.src = "./assets/scripts/newrelic/qa.js";
    } else {
      script.src = "./assets/scripts/newrelic/local.js";
    }

    /* add your script to DOM */
    head.appendChild(script);
  }
}

const API_VERSION_V1 = "v1";
const API_VERSION_V2 = "v2";
const API_VERSION_V3 = "v3";

/**
 * Global variable declaration for api route
 */
export const GlobalVariable = Object.freeze({
  BASE_API_URL: environment.apiHost,
  BASE_API_URL_ADMIN: environment.adminApiHost,
  GBP_STAUTS: {
    OPEN: "Open",
    CLOSED_PERMANENTLY: "Closed Permanently",
    CLOSED_TEMPORARILY: "Closed Temporarily",
    DUPLICATE: "Duplicate",
    PENDING_EDITS: "Pending Edits",
    SUSPENDED: "Suspended",
    DISABLED: "Disabled",
    DISCONNECTED: "Disconnected",
    VERIFIED: "Verified",
    NOT_VERIFIED: "Not Verified",
    NEEDS_REVERIFICATION: "Needs Reverification",
  },
  ITEMS_PER_PAGE_LIST: [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100 },
  ],
  ITEMS_PER_PAGE: 25,
  GENDER_LIST: [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
    { id: "transgender", name: "Transgender" },
  ],
  onboardingStatus: {
    type: {
      website: 1001,
      admin: 1011,
    },
  },
  loginType: {
    email: "email",
    mobile: "mobile",
  },
  dataNotFoundText: {
    social: {
      withIntegration: "You have not shared any social content yet",
      withoutIntegration:
        "Profiles not integrated. Integrate atleast one social profile to start sharing content",
      draft: {
        withIntegration: "You have not drafted any posts yet",
        withoutIntegration:
          "Profiles not integrated. Integrate atleast one social profile to start sharing content",
      },
    },
    appointments: {
      dashboard:
        "You have not received any appointment/enquiry yet. Make sure you have shared appointment/enquiry links your customers",
      appointment:
        "You have not received any appointment yet. Make sure you have shared appointment links your customers",
      enquiry:
        "You have not received any enquiry yet. Make sure you have shared enquiry links your customers",
    },
    interact: {
      dashboard: {
        withIntegration: "You have not sent/received chat messages",
        withoutIntegration: "External Platforms not Integrated",
      },
      mainChat: {
        withIntegration:
          "You have not received any inbound messages yet! Make sure your customers/profile visitors know how to reach you via chat channels.",
        withoutIntegration: "External Platforms not Integrated",
      },
      insight: {
        withIntegration: "No interact insights available to share",
        withoutIntegration: "External Platforms not Integrated",
      },
    },
    reviews: {
      dashboard: {
        withIntegration:
          "No review data available to be displayed in the dashboard",
        withoutIntegration: "Profiles/Locations not integrated",
      },
      feed: {
        withIntegration: "No review data available to be displayed.",
        withoutIntegration: "Profiles/Locations not integrated",
      },
      insight: {
        withIntegration:
          "No insights available at this time. Keep receiving reviews to gain insights",
        withoutIntegration: "Profiles/Locations not integrated",
      },
      unified: {
        withIntegration: "No review data available to be displayed.",
        withoutIntegration: "Profiles/Locations not integrated",
      },
    },
    listings: {
      withIntegration: "",
      withoutIntegration:
        "Profiles not integrated. Please integrate your Google profile to view/update data",
    },
  },
  WEBSITE_REG: "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?",

  /** Is Active Stats */
  // IS_ACTIVE_STATUS: API_VERSION_V1 + '/admin/business/isactive',

  /** Download sample file url */
  DOWNLOAD_EXCEL_FILE_URL:
    "https://s3.ap-south-1.amazonaws.com/zceppa-production/sample.xlsx",
  DOWNLOAD_MESSAGING_EXCEL_FILE_URL: `https://zceppa-production.s3.ap-south-1.amazonaws.com/sample_files/bulk_excel_sample_file.xlsx`,
  DOWNLOAD_LOCATION_BULK_EXCEL_FILE_URL: `https://zceppa-production.s3.ap-south-1.amazonaws.com/sample_files/bulk_location_upload_sample_v1.xlsx`,

  /** Public logo url */
  PUBLIC_LOGO_URL: API_VERSION_V1 + "/business/public-logo/",
  QR_PUBLIC_LOGO: API_VERSION_V1 + "/business/qr-public-details",
  FEEDBACK_SLUG_DATA: API_VERSION_V1 + "/contact/slug/contact",

  /* verify all integrations  */
  OUTLET_ALL_INTEGRATIONS: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/verify-integration`,

  /** On Boarding and outlet create urls */

  GET_ONBOARDING_JSON: API_VERSION_V2 + "/onboarding/business/product-details ",
  OUTLET_CREATE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/create`,
  OUTLET_LIST_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/list`,
  BUSINESS_LIST_URL: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/list/all`,
  SWITCH_BUSINESS_URL: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/switch`,
  SWITCH_OUTLET_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/switch`,
  ONBOARDING_CREATE_URL: API_VERSION_V2 + "/onboarding/business/create",
  OUTLET_CREATE_MAIN_URL: API_VERSION_V2 + "/onboarding/outlet/create",
  BUSINESS_DOMAIN: API_VERSION_V1 + "/onboarding/business/domain",
  // UPDATE_ONBOARD_MODAL: (outlet_id: string) =>
  //   API_VERSION_V2 + `/business/${outlet_id}/business-outlet-edit`,
  UPDATE_ONBOARD_MODAL: (outlet_id: string) =>
    API_VERSION_V3 + `/onboarding/business/${outlet_id}/update-detail`,
  ONBOARD_WEBSITE_SIGNUP: API_VERSION_V3 + "/onboarding/business/lead",
  // ONBOARD_SIGNUP: API_VERSION_V2 + "/onboarding/business/lead/create",
  ONBOARD_SIGNUP: API_VERSION_V3 + "/onboarding/business/admin/signup",
  ONBOARD_RESEND_EMAIL: API_VERSION_V2 + "/onboarding/business/resend/email",
  ONBOARD_VERIFY_EMAIL: API_VERSION_V2 + "/onboarding/business/verify/email",
  GET_ONBOARDING_PLATFORM: (outlet_id: string) =>
    `${API_VERSION_V3}/onboarding/business/${outlet_id}/integration-platforms`,
  TOKEN_VALIDATE: API_VERSION_V1 + "/admin/users/authenticate",
  /** ONBOARDING ADMINAPITESTING */

  VIEW_ONBOARDING_CITIES: (state_id: string) =>
    `${API_VERSION_V1}/location/states/${state_id}/cities`,
  ONBOARDING_CITIES_MODAL: (outlet_id: string) =>
    `${API_VERSION_V2}/outlet/${outlet_id}/cities`,
  CREATE_ZCEPPA_PRODUCT: (outlet_id: string) =>
    `${API_VERSION_V1}/subscription/trial/${outlet_id}`,
  STATES_API: (country_id: string) =>
    `${API_VERSION_V1}/location/countries/${country_id}/states`,
  VIEW_ONBOARDING_STATES: API_VERSION_V1 + "/location/states",
  COUNTRY_API: API_VERSION_V1 + "/location/countries",
  VIEW_ZCEPPA_PRODUCTS: API_VERSION_V1 + "/pricing/plan/trial/products",
  ONBOARDING_CITIES: API_VERSION_V2 + `/location/cities`,

  /** DASHBOARD BASED ON PRODUCT */

  DASHBOARD_SETUP: (outlet_id: string) =>
    API_VERSION_V3 + `/outlet/${outlet_id}/dashboard/setup`,
  VIEW_APPOINTMENT_TOP_TILE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/dashboard/top-panel`,
  VIEW_APPOINTMENT_BOTTOM_TILE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/dashboard/bottom-panel`,
  VIEW_MYSTORE_TOP_TILE: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/dashboard/top-panel`,
  VIEW_MYSTORE_BOTTOM_TILE: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/dashboard/bottom-panel`,
  VIEW_SOCIAL_TOP_PANEL: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/dashboard/top-panel`,
  VIEW_SOCIAL_BOTTOM_PANEL: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/dashboard/bottom-panel`,
  VIEW_SOCIAL_RECENT_POST: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/recent-posts`,

  /** DASHBOARD | LEADERBOARD */
  GET_LEADERBOARD_DATA: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/review/${outlet_id}/leaderboard`,
  DOWNLOAD_LEADERBOARD: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/review/${outlet_id}/leaderboard/download`,

  /** Auth url and otp */

  OTP_URL: API_VERSION_V2 + "/otp/create",
  WEBSITE_EMAIL_OTP: API_VERSION_V3 + "/otp/create",
  AUTH_LOCK_URL: API_VERSION_V3 + "/auth/lock-account ",
  VERIFY_URL: API_VERSION_V2 + "/otp/verify",
  VERIFY_URL_V3: API_VERSION_V3 + "/otp/verify",
  RESEND_URL: API_VERSION_V2 + "/otp/create",
  FORGET_PASSWORD: API_VERSION_V3 + "/otp/forgot-password",
  LOGIN_VERIFY_URL: API_VERSION_V2 + "/otp/verify/login",
  HAS_PASSWORD_SET_URL: API_VERSION_V3 + "/auth/has-password-set",
  CREATE_PASSWORD_URL: API_VERSION_V2 + "/user/password/create",
  CREATE_PASSWORD_WEBSITE: API_VERSION_V3 + "/auth/password/create",
  // PASSWORD_VERIFY_URL: API_VERSION_V2 + "/auth/login",
  PASSWORD_VERIFY_URL: API_VERSION_V3 + "/auth/login",

  /** Internal replay urls */

  INTERNAL_REPLY_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/feedback/reply`,
  INTERNAL_REPLAY_SLUG_URL: API_VERSION_V1 + "/review/slug/feedback-reply/load", // not using

  /** Social media auth urls */

  GET_SOCIAL_ANALYTICS: (outlet_id: string, post_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/post/${post_id}`,

  SOCIAL_AUTH_VERIFY_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/verify`,
  CONNECTED_GOOGLE_ACCOUNT: (outlet_id: string, token_id: string) =>
    API_VERSION_V2 +
    `/integration/google/${outlet_id}/token/${token_id}/listings`,
  SOCIAL_INTEGRATION_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/verify/all`,

  GOOGLE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/google/${outlet_id}/login`,

  GOOGLE_LISTING_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/google/${outlet_id}/listings`,
  REVIEW_FEED_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/feed`,
  REVIEW_FEED_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/feed/report/download`,
  GOOGLE_DISCONNECT_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/google/${outlet_id}/disconnect`,
  GOOGLE_LISTING_UPDATE_URL: (outlet_id: string) =>
    API_VERSION_V2 +
    `/integration/google/${outlet_id}/listings/update
  `,
  GOOGLE_REPLY_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/google/${outlet_id}/reply`,
  GOOGLE_PLACE_ID: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/update-place-id`,

  FACEBOOK_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/login`,
  FACEBOOK_LISTING_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/pages`, // post
  FACEBOOK_PAGES_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/pages`,
  FACEBOOK_DISCONNECT_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/disconnect`,
  FB_REPLY_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/reply`,
  FB_TOKEN_PERMISSION: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/token/permission`,

  INSTAGRAM_DISCONNECT_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/facebook/${outlet_id}/instagram/disconnect`,

  EXTERNAL_INTEGRATION_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/external/integration/${outlet_id}/list`,
  UPDATE_SHOPIFY_DATA: (outlet_id: string) =>
    API_VERSION_V3 + `/external/integration/${outlet_id}/shopify`,
  DISCONNECT_SHOPIFY_DATA: (outlet_id: string) =>
    API_VERSION_V3 + `/external/integration/${outlet_id}/shopify`,

  /** Business Brand profile urls */

  BUSINESS_PROFILE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/business/${outlet_id}/detail`,
  // DASHBOARD_PROFILE_URL: (outlet_id: string) =>
  //   API_VERSION_V2 + `/outlet/${outlet_id}/dashboard/detail`,
  DASHBOARD_PROFILE_URL: (outlet_id: string) =>
    API_VERSION_V3 + `/outlet/${outlet_id}/dashboard/detail`,
  USER_PROFILE: (outlet_id: string) =>
    API_VERSION_V2 + `/user/${outlet_id}/profile`,
  USER_PROFILE_IMAGE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/user/${outlet_id}/profile-picture`,
  USER_PROFILE_UPDATE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/user/${outlet_id}/profile/edit`,
  USER_PROFILE_IMAGE_UPDATE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/upload/${outlet_id}/user/profile-picture`,
  BUSINESS_PROFILE_UPDATE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/business/${outlet_id}/edit`,

  refreshTokenUrl: API_VERSION_V3 + `/auth/refresh`,
  // refreshTokenUrl = () => this.http.post<any>(this.api_version_1 + 'auth/refresh', 'body');
  /** Review urls */

  GOOGLE_RECONNECT_API: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/verify`,
  REVIEW_INVITE_PERMISSIONS_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/invite/${outlet_id}/permissions`,

  REVIEW_MANAGEMENT: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/validate`,
  OVERALL_REVIEW_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/overall`,

  SMS_REVIEW_RENDER_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/${outlet_id}/render`,
  SMS_REVIEW_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/create`,
  SMS_REVIEW_DELETE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/`,
  SMS_REVIEW_GET_WP_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/list`,
  SMS_REVIEW_GET_WOP_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/list/all`,
  UPDATE_WHATSAPP_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/whatsapp/${outlet_id}/update/default/`, // template id
  SMS_REVIEW_UPDATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/`,
  SMS_REPLY_URL_DELETE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/`,
  SMS_REPLY_URL_UPDATE_V2: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/${template_id}`,
  SMS_REPLY_URL_GET_WP_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/list`,
  SMS_REPLY_URL_GET_WOP_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/list/all`,
  SMS_REPLY_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/create`,
  SMS_CAMPAIGN_INVITE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/sms`,
  CUSTOMER_LIST_CAMPAIGN_SMS_API_V2: (outlet_id) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/bulk/sms`,
  MONTHLY_REVIEW_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/month`,
  BEFORE_JOIN_ZCEPPA: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/before_integration`,
  OVERALL_MONTHLY_REVIEW_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/monthly`,
  REVIEW_BY_JOINING_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/since`,
  REVIEW_COUNT_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/feed/count`,
  RATING_BY_SITE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/ratings/site`,
  REVIEW_SETUP_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/config`,
  REVIEW_SETUP_VALUE_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/value`,
  REVIEW_SETUP_UPDATE_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/update`,
  REVIEW_REQ_UPD_SMS_TEMP_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/invite/update/default/`,
  REVIEW_REQ_UPD_REPLY_TEMP_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/update/default/`,

  GET_REVIEW_WHATSAPP_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/review/templates/${outlet_id}/whatsapp`,
  GET_REVIEW_WHATSAPP_TEMPLATE_SEND_REVIEW_INVITE: (outlet_id: string) =>
    API_VERSION_V3 + `/review/templates/${outlet_id}/whatsapp/list`,
  SET_DEFAULT_REVIEW_WHATSAPP_TEMPLATE: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/review/templates/${outlet_id}/whatsapp/update/default/${template_id}`,
  SEND_REVIEW_INVITE_VIA_WHATSAPP: (outlet_id: string) =>
    API_VERSION_V3 + `/review/request/${outlet_id}/whatsapp`,

  UNSELECT_DEFAULT_REPLY: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 +
    `/template/review/${outlet_id}/reply/unselect-default/${template_id}`,
  EMAIL_REVIEW_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/template/email/${outlet_id}/review`,
  REVIEW_MAPS_PLACE_DISCONNECT_URL: (outlet_id: string) =>
    API_VERSION_V2 +
    `/review/request/${outlet_id}/settings/disconnect-place-id`,
  SMS_ALERT: (outlet_id: string) =>
    API_VERSION_V2 + `/tracking/${outlet_id}/sms_sent_count/alert`,
  PERFORMANCE_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/performance`, // filter_by
  GET_UPDATED_REVIEW: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/review-detail`,
  UPDATE_CAMPAIGN_TEMPLATE:
    API_VERSION_V1 + "/review/request/settings/update-campaign-template", // not using
  COMPETITOR_SETUP_VALUE_URL: API_VERSION_V1 + "/competitor/google", // not using
  COMPETITOR_DELETE_URL: API_VERSION_V1 + "/competitor/google/delete", //not using
  COMPETITOR_LIST_URL: API_VERSION_V1 + "/competitor/google/list", // not using
  COMPETITOR_INSIGHTS_URL: API_VERSION_V1 + "/review/analytics/insight", // not using
  SMS_CAMPAIGN_URL: API_VERSION_V1 + "/template/sms/campaign", // not using

  /** Clients urls */

  FILTER_CUSTOMER_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/common-list`,
  CLIENTS_LIST_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/list`,
  CLIENTS_CREATE_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/create`,
  CLIENTS_DELETE_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/`,
  CLIENTS_MODIFY_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/`,
  CLIENTS_BLUK_UPLOAD_ASYNC_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/upload/async`,
  CLIENTS_BLUK_UPLOAD_SYNC_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/upload/sync`,
  DOWNLOAD_CUSTOMER: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/report`,
  CLIENTS_DELETE_BULK_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/bulk-delete`,

  VIEW_CONTACT_DETAIL: (outlet_id: string, contact_id) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/${contact_id}`,
  /** Invites click rate urls */

  INVITE_SEND_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/analytics/sent`,
  GET_CONTACT_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/settings`,
  SAVE_CONTACT_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/settings`,
  CONTACT_DELETE_CATEGORY: (outlet_id: string, category_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/contact-category/${category_id}`,
  UPDATE_CONTACT_DETAILS: (outlet_id: string, contact_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/edit/${contact_id}`,
  GET_CONTACT_COMMON_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/common-settings`,
  INVITE_CLICKED_URL: API_VERSION_V1 + "/review/request/analytics/clicked", // not using

  /** Customer sent reviews urls */

  SMS_REQUEST_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/sms`,
  EMAIL_REQUEST_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/email`,
  SMS_SLUG_REQUEST_URL: API_VERSION_V1 + "/review/slug/feedback", // not using
  SMS_SLUG_URL: API_VERSION_V1 + "/review/slug/load", // not using

  /** Review request feed */

  REQUEST_FEED_URL: API_VERSION_V1 + "/review/request/feed", // not using

  /** Brand uploads urls */

  BRAND_UPLOAD_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/upload/${outlet_id}/business/logo`,
  GET_LOGO_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/business/${outlet_id}/logo/`,

  /** Goal urls */

  GOAL_READ_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/goal`,

  /** Notification settings api urls */

  NOTIFICATION_SETTING_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/notification/settings`,
  OUTLET_NOTIFICATION_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/notification/${outlet_id}/outlet-settings`,
  UPDATE_OUTLET_NOTIFICATION_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/notification/${outlet_id}/outlet-settings`, //put
  PREFER_NOTIFY: (outlet_id: string) =>
    API_VERSION_V2 + `/tracking/${outlet_id}/team-member/notifications/list`,
  PREFER_NOTIFY_UPDATE: (outlet_id: string) =>
    API_VERSION_V2 +
    `/tracking/${outlet_id}/update/team-member/notification/settings`,
  BEFORE_PREFERENCES: (outlet_id: string) =>
    API_VERSION_V2 + `/tracking/${outlet_id}/manager/validation`,
  MANAGER_NOTIFICATION_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/notification/${outlet_id}/manager/settings`,
  UPDATE_MANAGER_NOTIFICATION_SETTINGS: (outlet_id: string, user_id: string) =>
    API_VERSION_V3 + `/notification/${outlet_id}/manager/${user_id}/settings`,
  NOTIFICATION_SETTING_LIST_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/notification/settings/list`,
  NOTIFICATION_ALL_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/notification`,
  NOTIFICATION_LATEST_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/notification/latest`,

  /** QR code api urls */
  QRCODE_GENERATE_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/qr-code/generate`,
  QRCODE_DISPLAY_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/qr-code/read`,
  QRCODE_TEMP_UPDATE_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/qr-code/settings`,
  QRCODE_SETTINGS_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/qr-code/settings`,
  QRCODE_SETTINGS_RESET_URL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/qr-code/settings/reset`,
  QRCODE_DETAIL_URL: API_VERSION_V1 + "/review/qr-code/details", // not using

  /** Custom integration api urls */

  // CUSTOM_INTEG_ALL: (outlet_id: string) => API_VERSION_V2 + `/integration/custom/${outlet_id}/invite/list`,
  CUSTOM_INTEG_ALL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/list`,
  CUSTOM_INTEG_DELETE: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/delete`,
  CUSTOM_AMAZON_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/amazon`,
  CUSTOM_ZOMATO_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/zomato`,
  CUSTOM_TRIPADVISOR_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/trip_advisor`,
  CUSTOM_PRACTO_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/practo`,
  CUSTOM_JUSTDIAL_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/custom/${outlet_id}/just_dial`,
  INTEGRATION_VERIFY_PERMISSION: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/verify-permission`,

  /** Manager manipulation api urls */

  MANAGER_APPOINTMENT_HOURS: (outlet_id: string, manager_id) =>
    API_VERSION_V3 +
    `/appointment/${outlet_id}/${manager_id}/user-appointment-hours`,

  EMPLOYEE_APPOINTMENT_HOURS_DELETE: (
    outlet_id: string,
    manager_id,
    appointment_hour_id
  ) =>
    API_VERSION_V3 +
    `/appointment/${outlet_id}/${manager_id}/user-appointment-hours/${appointment_hour_id}`,
  EMPLOYEE_APPOINTMENT_HOURS_UPDATE: (outlet_id: string, manager_id) =>
    API_VERSION_V3 +
    `/appointment/${outlet_id}/${manager_id}/user-appointment-hours`,

  SINGLE_APPOINTMENT_VIEW: (appointment_id) =>
    API_VERSION_V2 + `/appointment/${appointment_id}`,

  GET_APPOINTMENT_DETAIL: (outlet_id: string, appointment_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/appointment/${appointment_id}`,

  MANAGER_APPOINTMENT: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/managers/list`,
  TAG_TEAM_MEMBER: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/managers/list`,

  TOTAL_MANAGER_API: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/manager/list`,

  MANAGER_VIEW_API: (outlet_id: string, manager_id) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/detail/${manager_id}`,

  GET_PRODUCT_LIST: (outlet_id: string, manager_id) =>
    API_VERSION_V2 +
    `/people/managers/${outlet_id}/${manager_id}/tag-product-list`,
  POST_PRODUCT_LIST: (outlet_id: string, manager_id) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/${manager_id}/tag-product`,

  MANAGER_LEAVE_DELETE_API: (outlet_id: string, manager_id, leave_id) =>
    API_VERSION_V2 +
    `/people/managers/${outlet_id}/${manager_id}/${leave_id}/leave`,
  MANAGER_LEAVE_EDIT_API: (outlet_id: string, manager_id, leave_id) =>
    API_VERSION_V2 +
    `/people/managers/${outlet_id}/${manager_id}/${leave_id}/leave`,

  MANAGER_LEAVE_API: (outlet_id: string, manager_id) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/${manager_id}/leave`,
  GET_MANAGER_LEAVE_API: (outlet_id: string, manager_id) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/${manager_id}/leave`,
  GET_MANAGER_LEAVE_PROFILE_API: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/leave`,

  // MANAGER_CREATE_API_V2: (outlet_id: string) =>
  //   API_VERSION_V2 + `/people/managers/${outlet_id}/create`,
  MANAGER_CREATE_API_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/create`,
  MANAGER_API_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/list`,
  MANAGER_LIST_API: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/tag/list`,
  MANAGER_WEEKDAYS: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/weekdays`,

  TAG_TEAM_SETTING: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/tag-team-setting`,

  MANAGER_DELETE_API_V3: (outlet_id: string, manager_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/${manager_id}`,
  MANAGER_ENABLE_API_V3: (outlet_id: string, manager_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/enable/${manager_id}`,
  MANAGER_DISABLE_API_V3: (outlet_id: string, manager_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/disable/${manager_id}`,
  MANAGER_EDIT_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/`,
  MANAGER_EDIT_API_V3: (outlet_id: string, manager_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/${manager_id}`,

  /** Ticket module api urls */

  TICKET_CREATE_API: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/create`,
  // RAISE_TICKET: (outlet_id: string) => API_VERSION_V2 + `/${outlet_id}/tickets/zceppa-ticket`,
  TICKET_FEED_API: API_VERSION_V1 + "/tickets/feed/filter",
  TICKET_NOTE_UPDATE_API: API_VERSION_V1 + "/tickets/update/note",
  TICKET_STATUS_UPDATE_API: API_VERSION_V1 + "/tickets/update/status",
  TICKET_FEED_DELETE_API: API_VERSION_V1 + "/tickets/delete",
  // RAISE_TICKET: API_VERSION_V1 + '/tickets/zceppa-ticket',
  RAISE_TICKET: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/zceppa-ticket`,
  RAISE_SUPPORT_TICKET: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/support-ticket`,
  TICKET_LIST_API: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/list`,
  TICKET_VIEW_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/detail/${ticket_id}`,
  TICKET_META_DATA_API: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/meta-data`,
  TICKET_REPLY_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/reply/${ticket_id}`, //put
  TICKET_NOTES_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/notes/${ticket_id}`, //put
  TICKET_UPDATE_STATUS_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/update-status/${ticket_id}`, //pu
  TICKET_UPDATE_PRIORITY_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/update-priority/${ticket_id}`, //put
  TICKET_UPDATE_ASSIGNEE_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/update-assignee/${ticket_id}`, //put
  TICKET_DELETE_API: (outlet_id: string, ticket_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/delete/${ticket_id}`,
  GET_TICKET_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/settings`,
  UPDATE_TICKET_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/settings`, //put

  /** Customer list api urls */

  CUSTOMER_LIST_CREATE_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/create`,
  CUSTOMER_LIST_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/list/all`,
  CUSTOMER_LIST_API_WITH_PERMISSIONS_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/list`,
  CUSTOMER_CONTACT_LIST_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/contacts/`,
  CUSTOMER_LIST_EDIT_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/`,
  CUSTOMER_LIST_DELETE_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/`,
  CONTACT_LIST_ADD_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/add-contacts/`,
  CONTACT_LIST_DELETE_API_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/customer-lists/${outlet_id}/delete-contacts/`,

  /** Analytics timeline filters api urls */

  // ANALYTICS_FILTERS_API: API_VERSION_V1 + '/review/analytics/weighted_overall_range',
  // ANALYTICS_FILTERS_RESET_API: API_VERSION_V1 + '/review/analytics/weighted_overall_feed',
  ANALYTICS_OVERALL_RESET: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/review-stats`,
  ANALYTICS_OVERALL_FILTER: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/review-stats`,

  /** SMS Tracking */

  SMS_BULK_SEND: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/sms/bulk`,
  LIST_BULK_SEND: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/sms/list`,
  LIST_BULK_SEND_WHATSAPP: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/whatsapp/bulk`,
  UPDATE_ENABLE_INTERACT_WHATSAPP_TEMPLATE: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/${template_id}/whatsapp`,
  SMS_CREDITS: API_VERSION_V1 + "/tracking/sms/credits",

  /** Consumer Link */
  CONSUMER_LINK_AVAIL_UPDATE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/consumer-link`,
  CONSUMER_LINK_AVAIL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/settings/consumer-link`,

  /** Campaign  */

  CAMPAIGN_CHANNELS: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/channels`,
  UPDATE_DEFAULT_CAMPAIGN_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/update/default/`,
  UPDATE_CAMPAIGN_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/`,
  CREATE_CAMPAIGN_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/create`,
  DELETE_CAMPAIGN_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/`,
  LIST_CAMPAIGN_V2_WP: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/list`,
  LIST_CAMPAIGN_V2_WOP: (outlet_id: string) =>
    API_VERSION_V2 + `/template/sms/campaign/${outlet_id}/list/all`,
  CAMPAIGN_EMAIL_CREATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/email/${outlet_id}/campaign`,
  CAMPAIGN_EMAIL_UPDATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/email/${outlet_id}/${template_id}/email`, //put , delete
  CAMPAIGN_EMAIL_SUBMIT: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/email`,
  CAMPAIGN_EMAIL_BULK: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/email/bulk`,
  CAMPAIGN_EMAIL_TEMPLATE_UPDATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 +
    `/template/email/${outlet_id}/update/default/${template_id}`,
  // SHARE_CAMPAIGN: API_VERSION_V2 + '/campaign/social',
  // SOCIAL_UPLOAD: (outlet_id: string) => API_VERSION_V2 + `/upload/${outlet_id}/media`,
  // SOCIAL_VIDEO_UPLOAD: API_VERSION_V1 + '/upload/media/video',
  // SOCIAL_VIDEO_UPLOAD: (outlet_id: string) => API_VERSION_V2 + `/upload/${outlet_id}/media/video`,
  // SOCIAL_MEDIA_DELETE: (outlet_id: string) => API_VERSION_V2 + `/upload/${outlet_id}/media`,
  SENT_CAMPAIGN: API_VERSION_V1 + "/campaign/sent",

  SHARE_CAMPAIGN: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/social`,
  SOCIAL_UPLOAD: (outlet_id: string) =>
    API_VERSION_V2 + `/upload/${outlet_id}/media`,
  SOCIAL_VIDEO_UPLOAD: (outlet_id: string) =>
    API_VERSION_V2 + `/upload/${outlet_id}/media/video`,
  SOCIAL_MEDIA_DELETE: (outlet_id: string, media_id: string) =>
    API_VERSION_V2 + `/upload/${outlet_id}/media/${media_id}`,
  DRAFT_CAMPAIGN: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/draft`,
  CAMPAIGN_SETTINGS_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/settings`,
  SHARED_POST_FILTER_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/sent`,
  SHARED_POST_FILTER_SOCIAL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/sent`,
  DRAFT_POST_FILTER_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/draft`,
  DRAFT_POST_FILTER_SOCIAL_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/draft`,
  SOCIAL_DOWNOLOAD_REPORT: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/report/download`,

  // SENT_CAMPAIGN: API_VERSION_V1 + '/campaign/sent', // not using
  CAMPAIGN_TRACKING: API_VERSION_V1 + "/campaign/tracking", // not using
  SOCIAL_ZCEPPA_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/list`,
  SOCIAL_CREATE_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/create`,
  SOCIAL_UPDATE_TEMPLATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/${template_id}`, //put
  SOCIAL_DELETE_TEMPLATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/${template_id}`, //delete
  SOCIAL_SHARE_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/post`, // post, put
  DELETE_SOCIAL_POST: (outlet_id: string, social_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/delete/${social_id}`,

  SOCIAL_ZCEPPA_TEMPLATE_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/list/all`,
  SOCIAL_CREATE_TEMPLATE_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/create`,
  SOCIAL_UPDATE_TEMPLATE_SETTING: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/${template_id}`, //put
  SOCIAL_DELETE_TEMPLATE_SETTING: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/social/${outlet_id}/${template_id}`, //delete
  SOCIAL_SETUP_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/settings`, // get, put
  SOCIAL_COMMON_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/common-settings`,
  SOCIAL_UPDATE_DEFAULT_TEMPLATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 +
    `/template/social/${outlet_id}/update/default/${template_id}`, //put
  SOCIAL_CALENDAR_LIST: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/schedule`,
  REVIEW_SHARE_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/review-auto-share-settings`,
  REVIEW_SHARE_META: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/review-share-meta-data`,
  SOCIAL_SHARE_REVIEW: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/social-share-reviews`,
  ADD_REVIEW_SOCIAL_SHARE: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/add-review-to-social-share`,

  /** Accounts */

  REVIEW_VIEW_TICKET: (outlet_id: string) =>
    API_VERSION_V2 + `/tickets/${outlet_id}/ticket/details`,
  ACCOUNT_API: (outlet_id: string) =>
    API_VERSION_V2 + `/subscriptions/${outlet_id}/outlet/details`,
  VIEW_PAYMENT_DETAIL_API: (outlet_id: string) =>
    API_VERSION_V2 + `/subscriptions/${outlet_id}/details`,
  RENEW_PRODUCT_API: (outlet_id: string) =>
    API_VERSION_V2 + `/subscriptions/${outlet_id}/renewal-request`,
  POST_ACCOUNT_DELETION: (outlet_id: string) =>
    API_VERSION_V3 + `/business/${outlet_id}/account-deletion-request`,
  CUSTOMER_VIEW: API_VERSION_V1 + "/contact/customer/details", // not using

  /** Settings Notifications */

  REPLY_DEFAULT_VALUE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/review/${outlet_id}/reply/default`,
  DEL_CAMPAIGN: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/delete`,
  VIEW_CAMPAIGN_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/`,

  // CONNECTED_PLATFORMS: API_VERSION_V1 + '/integration/verify/all/integrations',
  // AUTO_REDIRECT_AFTER_DISCONNECT: API_VERSION_V1 + '/integration/update/integration/auto-redirect',

  DOWNLOAD_STATS_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/analytics/report/generation`,
  DOWNLOAD_INTERACT_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/report`,

  //  Settings Business Profile

  VIEW_BUSINESS_HOURS: (outlet_id: string) =>
    API_VERSION_V2 + `/business/${outlet_id}/working-hours`,
  CREATE_BUSINESS_HOURS: (outlet_id: string) =>
    API_VERSION_V2 + `/business/${outlet_id}/working-hours`,
  GET_PUBLIC_HOLIDAYS: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/public-holiday/list`,
  CREATE_PUBLIC_HOLIDAYS: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/public-holiday`,
  UPDATE_PUBLIC_HOLIDAYS: (outlet_id: string, holiday_id) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/public-holiday/${holiday_id}`,
  DELETE_PUBLIC_HOLIDAYS: (outlet_id: string, holiday_id) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/public-holiday/${holiday_id}`,

  /** Messaging */
  MESSAGING_SMS_SENT_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/sms`,
  MESSAGING_SMS_BULK_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/bulk/sms`,
  MESSAGING_SMS_SENT_FILTER_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/sent`,
  MESSAGING_SMS_DRAFT_FILTER_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/draft`,
  MESSAGING_LIST_V2_WP: (outlet_id: string) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/list`,
  MESSAGING_LIST_V2_WOP: (outlet_id: string) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/list/all`,
  CREATE_MESSAGE_TEMPLATE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/create`,
  UPDATE_MESSAGE_TEMPLATE_V2: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/${template_id}`,
  SEND_WHATSAPP_MESSAGE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/whatsapp`,
  SEND_BULK_WHATSAPP_MESSAGE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/whatsapp/bulk`,
  GET_WHATSAPP_METADATA: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/whatsapp/metadata`,
  VIEW_MESSAGE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/`,
  DELETE_MESSAGE_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/`,
  MESSAGING_SMS_DELETE: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/delete`,
  TRANSACTIONAL_DOWNLOAD_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/transactional/report`,
  PROMOTIONAL_DOWNLOAD_REPORT: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/promotional/report`,
  GET_PREDEFINED_VARIABLES: (outlet_id: string) =>
    API_VERSION_V2 + `/template/message/${outlet_id}/predefined-variables`,
  UPDATE_DYNAMIC_VARIABLES: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 +
    `/template/message/${outlet_id}/${template_id}/dynamic-var`,
  MESSAGING_COMMON_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/campaign/${outlet_id}/common-settings`,
  MESSAGING_COMMON_SETTINGS_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/settings`,

  MESSAGING_SENT_FILTER: (outlet_id: string, messaging_type: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/sent/${messaging_type}`,

  MESSAGING_DRAFT_FILTER: (outlet_id: string, messaging_type: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/draft/${messaging_type}`,

  MESSAGING_DELETE_DRAFT: (outlet_id: string, message_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/${message_id}`,

  MESSAGING_GET_TRANSACTIONAL_TEMPLATES: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/transactional/list`,

  MESSAGING_GET_PROMOTIONAL_TEMPLATES: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/promotional/list`,

  MESSAGING_DETAILS_FROM_MESSAGEID: (outlet_id: string, message_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/detail/${message_id}`,

  MESSAGING_TEMPLATE_DETAILS_FROM_ID: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/${template_id}/detail`,

  MESSAGING_POST_MESSAGE_BY_CONTACT: (
    outlet_id: string,
    messaging_type: string,
    channel: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/contact/${channel}`,

  MESSAGING_POST_MESSAGE_BY_CUSTOMERLIST: (
    outlet_id: string,
    messaging_type: string,
    channel: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/customer-list/${channel}`,

  MESSAGING_POST_MESSAGE_BY_EXCEL: (
    outlet_id: string,
    messaging_type: string,
    channel: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/excel/${channel}`,

  MESSAGING_UPDATE_MESSAGE_BY_CONTACT: (
    outlet_id: string,
    messaging_type: string,
    channel: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/contact/${channel}/${template_id}`,

  MESSAGING_UPDATE_MESSAGE_BY_CUSTOMERLIST: (
    outlet_id: string,
    messaging_type: string,
    channel: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/customer-list/${channel}/${template_id}`,

  MESSAGING_UPDATE_MESSAGE_BY_EXCEL: (
    outlet_id: string,
    messaging_type: string,
    channel: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/send/${messaging_type}/excel/${channel}/${template_id}`,

  MESSAGING_SETTINGS_GET_TRANSACTIONAL_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/transactional`,
  MESSAGING_SETTINGS_CREATE_TRANSACTIONAL_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/transactional/create`,
  MESSAGING_UPDATE_DELETE_TRANSACTIONAL_TEMPLATE: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/templates/transactional/${template_id}`,
  MESSAGING_DELETE_BULK_TRANSACTIONAL_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/templates/transactional/bulk-delete`,
  GET_MESSAGING_METADATA: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/metadata`,
  MESSAGING_SETTINGS_GET_PROMOTIONAL_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/promotional`,
  MESSAGING_SETTINGS_CREATE_PROMOTIONAL_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/promotional/create`,
  MESSAGING_UPDATE_DELETE_PROMOTIONAL_TEMPLATE: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/messages/${outlet_id}/templates/promotional/${template_id}`,

  GET_SCHEDULED_MESSAGES: (outlet_id: string, message_type: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/schedule/${message_type}`,

  MESSAGING_BULK_EXCEL: (
    outlet_id: string,
    messagingType: "transactional" | "promotional",
    MessagingChannel: "sms" | "whatsapp"
  ) =>
    API_VERSION_V2 +
    `/bulk-message/${outlet_id}/${messagingType}/${MessagingChannel}/send`,
  MESSAGING_LIST_API: (
    outlet_id: string,
    messagingType: "transactional" | "promotional",
    messagingChannel: "sms" | "whatsapp" | "email"
  ) =>
    API_VERSION_V2 +
    `/bulk-message/${outlet_id}/${messagingType}/${messagingChannel}/customer-list/send`, // POST, PUT
  DOWNLOAD_CAMPAIGN_DATA: (outlet_id: string, message_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/download/${message_id}`,
  // MESSAGING_SINGLE_API: (
  //   outlet_id: string,
  //   messagingType: "transactional" | "promotional",
  //   messagingChannel: "sms" | "whatsapp"
  // ) =>
  //   API_VERSION_V2 + `/${outlet_id}/${messagingType}/${messagingChannel}/send`, // POST, PUT

  /** Google Insights */

  INSIGHTS: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/mylisting/${outlet_id}/google/metrics`,

  OVERVIEW_DETAILS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/location-detail`,
  EDIT_LOCATION_DETAIL: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/location-detail`, // put
  /** Interact */

  // /interaction-management/01a5f6d9-4900-4424-b024-51f038268e08/d7823f96-0f72-40ea-aa62-20399e6bdb71
  INTERACT_INSIGHTS: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/insights`,
  INTERACT_INSIGHTS_CONVERSATIONS_LIST: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/conversations/list`,
  GET_LAST_CONVERSATION: (outlet_id: string, interact_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/${interact_id}`,
  ACCOUNTS_LIST: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/accounts`,
  ACCOUNTS_LIST_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/accounts-details`,
  GET_DIALOG360_CHANNELS: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/dialog360/${outlet_id}/channels`, // qp -> ?id={{channel_1}},{{channel_2}}
  CONNECT_DIALOG360_CHANNEL: (outlet_id: string, channel_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/dialog360/${outlet_id}/channels/${channel_id}`,
  WHATSAPP_CONNECT: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/whatsapp/connect`,
  WHATSAPP_DISCONNECT: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/whatsapp/disconnect`,
  WHATSAPP_VERIFY: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/verify-whatsapp`,
  WHATSAPP_VERIFY_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/interact/${outlet_id}/verify-whatsapp`,
  WHATSAPP_TEMP: (outlet_id: string) =>
    API_VERSION_V2 + `/template/whatsapp/${outlet_id}/review`,
  WHATSAPP_SEND_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/review/request/${outlet_id}/whatsapp`,
  CONVERSATIONS_LIST: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/conversations`,
  CONVERSATION_DETAIL: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/conversation-detail`,
  CONVERSATION_MARK_AS_READ: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/conversation/read`,
  SEND_MESSAGE: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/conversation/send-message`,
  VERIFY_OPTIN: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/verify-optin`,
  INTERACT_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/interact/settings/${outlet_id}/general`,
  INTERACT_DELETE_CATEGORY: (outlet_id: string, category_id: string) =>
    API_VERSION_V3 +
    `/interact/settings/${outlet_id}/conversation-category/${category_id}`,
  INTERACT_DELETE_CHAT_TEMPLATE: (outlet_id: string, category_id: string) =>
    API_VERSION_V3 +
    `/interact/settings/${outlet_id}/conversation-chat-template/${category_id}`,
  INTERACT_DASHBOARD_METRIC: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/interact/${outlet_id}/dashboard/metrics`,
  INTERACT_MOM_CHART_DATA: (outlet_id: string) =>
    API_VERSION_V1 +
    `/analytics/interact/${outlet_id}/dashboard/monthly_interactions`,
  INTERACT_DASHBOARD_UNREAD_MESSAGE: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/unread_conversations`,
  INTERACT_INSIGHT: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/interact/${outlet_id}/insights/metrics`,
  INTERACT_SETTINGS_WHATSAPP: (outlet_id: string) =>
    // API_VERSION_V2 + `/interaction-management/${outlet_id}/settings/whatsapp`, //put
    API_VERSION_V3 + `/interact/settings/${outlet_id}/whatsapp`, //put
  GET_INTERACT_WHATSAPP_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/status`,
  UPDATE_INTERACT_WHATSAPP_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/status`,
  BLOCKUSER_INTERACT: (outlet_id: string, contact_id: string, source: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/block/${contact_id}/${source}`,
  INTERACT_UNREADCOUNT: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/unread_convo`,
  INTERACT_GET_WHATSAPP_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/whatsapp/interact/list`,
  INTERACT_GET_DEFAULT_QR_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/interact/settings/${outlet_id}/whatsapp`,
  GET_WHATSAPP_TEMPLATE_LIST: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/whatsapp`,
  SYNC_WHATSAPP_TEMPLATES: (outlet_id: string) =>
    API_VERSION_V3 + `/messages/${outlet_id}/templates/whatsapp/sync`,

  GOOGLE_MESSAGING_SETTING: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting`,
  MAP_GOOGLE_MESSAGING: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/map-google-messaging`,
  GOOGLE_MESSAGING_DISCONNECT: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting/disconnect`,
  GOOGLE_MESSAGING_SETTING_GOOGLE_TAB: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting/update`, //put
  GOOGLE_VERIFICATION_SUBMIT: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting/request-verification`,
  GOOGLE_LAUNCH_SUBMIT: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting/request-launch`,
  GOOGLE_DISCONNECT: (outlet_id: string) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/google-messaging-setting/request-unlaunch`,
  GET_INTERACT_CONTACT_TAGS: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/category/list`,
  UPDATE_INTERACT_CONTACT_TAGS: (
    outlet_id: string,
    interact_id: string,
    source: string
  ) =>
    API_VERSION_V2 +
    `/interaction-management/${outlet_id}/category/update/${interact_id}/${source}`,
  /** Zceppa Version */

  ZCEPPA_VERSION: (outlet_id: string) =>
    API_VERSION_V2 + `/version/${outlet_id}/number/Web_App`,

  // Appointments Apis

  APPOINTMENT_METADATA: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/meta-data`,
  APPOINTMENT_CREATE_UPDATE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment-settings/${outlet_id}/settings`,
  APPOINTMENT_UPDATE_CONSUMER_LINK: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment-settings/${outlet_id}/consumer-settings`,
  APPOINTMENT_VIEW: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment-settings/${outlet_id}/settings`,
  APPOINTMENT_LIST_CALENDAR: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/calendar`,
  APPOINTMENT_LIST: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/list`,
  ENQUIRY_LIST: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment-enquiry/${outlet_id}/enquiry/list`,
  DELETE_ENQUIRY: (outlet_id: string, enquiry_id: string) =>
    API_VERSION_V3 +
    `/appointment-enquiry/${outlet_id}/enquiry/delete/${enquiry_id}`,
  GET_ENQUIRY_BY_ID: (outlet_id: string, enquiry_id: string) =>
    API_VERSION_V3 + `/appointment-enquiry/${outlet_id}/enquiry/${enquiry_id}`,
  UPDATE_ENQUIRY_BY_ID: (outlet_id: string, enquiry_id: string) =>
    API_VERSION_V3 +
    `/appointment-enquiry/${outlet_id}/enquiry/update/${enquiry_id}`,
  AVAILABLE_SLOTS_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/booking-slots`,
  CREATE_APPOINTMENT: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/create`,
  DELETE_APPOINTMENT: (outlet_id: string, appointment_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/delete/${appointment_id}`,
  EDIT_APPOINTMENT: (outlet_id: string, appointment_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/update/${appointment_id}`,
  DOWNLOAD_APPOINTMENT_REPORT: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/report`,
  APPOINTMENT_TEMPLATE_LIST: (outlet_id: string) =>
    API_VERSION_V2 + `/template/appointment/${outlet_id}/list`,
  GET_WHATSAPP_APPOINTMENT_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/templates/${outlet_id}/whatsapp`,
  UPDATE_WHATSAPP_APPOINTMENT_TEMPLATE: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V3 +
    `/appointment/templates/${outlet_id}/whatsapp/update/${template_id}`,
  GET_APPOINTMENT_TEMPLATE_LIST: (outlet_id: string, channel: string) =>
    API_VERSION_V2 + `/template/appointment/${outlet_id}/${channel}/list`,
  CREATE_CUSTOM_APPOINTMENT_TEMPLATE: (outlet_id: string, channel: string) =>
    API_VERSION_V2 + `/template/appointment/${outlet_id}/${channel}/create`,
  DELETE_CUSTOM_APPOINTMENT_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/appointment/${outlet_id}`,
  SET_AS_DEFAULT_APPOINTMENT_TEMPLATE: (
    outlet_id: string,
    template_id: string,
    channel: string
  ) =>
    API_VERSION_V2 +
    `/template/appointment/${outlet_id}/update/default/${template_id}/${channel}`,
  SET_AS_DEFAULT_APPOINTMENT_EMAIL_THEME: (
    outlet_id: string,
    template_id: string
  ) =>
    API_VERSION_V2 +
    `/template/appointment/${outlet_id}/update/email/default/${template_id}`,
  APPOINTMENT_UPDATE_API: (
    outlet_id: string,
    template_id: string,
    channel: string
  ) =>
    API_VERSION_V2 +
    `/template/appointment/${outlet_id}/${template_id}/${channel}`,
  APPOINTMENT_HOURS: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/appointment-hours`, // GET
  APPOINTMENT_HOURS_UPDATE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/appointment-hours`, // POST
  APPOINTMENT_HOURS_DELETE: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/appointment-hour/`, // id
  APPOINTMENT_TEAM_MEMBER: (outlet_id: string, app_id: string) =>
    API_VERSION_V2 +
    `/people/managers/${outlet_id}/team-members/${app_id}/list`,
  APPOINTMENT_TEAM_MEMBER_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/tag-team-member/list`,
  // Permissions Apis

  // USER_PERMISSIONS: API_VERSION_V1 + "/people/manager/permissions",
  USER_PERMISSIONS_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/permissions`,
  COPY_OWNERS_PERMISSION_V3: (outlet_id: string) =>
    API_VERSION_V3 + `/team/${outlet_id}/permissions`,
  REMOVE_USER_PERMISSIONS_V2: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/permissions/remove`,
  ACCESS_INFO: (outlet_id: string) =>
    API_VERSION_V2 + `/user/${outlet_id}/permissions`,

  /** MyStore Products API */

  CREATE_MYSTORE_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product`,
  VIEW_MYSTORE_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/feed`,
  UPDATE_MYSTORE_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/`,
  DELETE_MYSTORE_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/`,
  VIEW_MYSTORE_PRODUCT_EDIT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/`,
  UPLOAD_MYSTORE_PRODUCTS_ASYNC: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/upload/async`,
  UPLOAD_MYSTORE_PRODUCTS_SYNC: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/upload/sync`,
  DOWNLOAD_MYSTORE_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/report`,
  DELETE_PRODUCTS_IMAGE: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/image/`, // + /{{MEDIA_ID}},
  SHARE_MYSTORE_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/share-link/`, // + {product_id}

  /** MyStore Orders API */
  VIEW_MYSTORE_ORDERS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/list`,
  VIEW_MYSTORE_ORDERED_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + "/mystore/ordered-products/",
  VIEW_MYSTORE_ORDERED_DETAILS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/`,
  UPDATE_MYSTORE_ORDER_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/status-update/`,
  DELETE_MYSTORE_ORDER: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/`,

  /** MYSTORE DOWNLOAD ORDERS */

  DOWNLOAD_MYSTORE_ORDERS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/report`,

  /** MyStore settings API */

  VIEW_MYSTORE_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/settings`,
  UPDATE_MYSTORE_ORDER_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order-settings`,
  UPDATE_MYSTORE_SHOPNOW_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/shopnow-settings`,
  UPDATE_MYSTORE_PRODUCT_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-settings`,
  UPDATE_MYSTORE_PAYMENT_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/payment-settings`,
  CHECK_CONSUMERLINK_AVAILABLE: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/validate-consumer-link/`,

  /** MyStore product-category API */

  CREATE_MYSTORE_PRODUCT_CATEGORY: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-category`,
  VIEW_MYSTORE_PRODUCT_CATEGORY: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-category`,
  UPDATE_MYSTORE_PRODUCT_CATEGORY: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-category/`,
  DELETE_MYSTORE_PRODUCT_CATEGORY: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-category/`,
  VIEW_MYSTORE_PRODUCT_MY_CATEGORY: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/my-category`,
  VIEW_MYSTORE_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-category/list`,

  /** MyStore Create / edit Order */
  CREATE_MYSTORE_ORDER: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/create`,
  UPDATE_MYSTORE_ORDER: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/`,
  VIEW_DELIVERY_SLOTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/delivery-slots/`,
  DELETE_ORDERED_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/ordered-product/`,

  /** MyStore Common settings */
  MYSTORE_COMMON_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/common-settings`,
  MYSTORE_PRODUCTS_COMMON_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/common-feed`,

  /** BULK DELETE AND STATUS UPDATE ORDERS PAGE */
  DELETE_BULK_ORDERS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/bulk-delete`,
  UPDATE_BULK_ORDER_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/order/bulk-update`,
  DELETE_BULK_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/bulk-delete`,
  UPDATE_BULK_PRODUCT_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/bulk-status-update`,
  UPDATE_BULK_PRODUCT_TAGS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product/bulk-tag-update`,

  VIEW_MYSTORE_TEMPLATE: (outlet_id: string) =>
    API_VERSION_V2 + `/template/mystore/${outlet_id}/list`,
  UPDATE_MYSTORE_TEMPLATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 + `/template/mystore/${outlet_id}/${template_id}`,
  DEFAULT_MYSTORE_TEMPLATE: (outlet_id: string, template_id: string) =>
    API_VERSION_V2 +
    `/template/mystore/${outlet_id}/update/default/${template_id}`,

  /** PRODUC TAGS */
  CREATE_PRODUCT_TAG: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag`,
  CREATE_PRODUCT_TAG_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/add-product/`,
  VIEW_PRODUCT_TAG: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/list`,
  DELETE_PRODUCT_TAG_PRODUCTS: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/remove/`,
  DELETE_PRODUCT_TAG: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/`,
  VIEW_PRODUCT_TAG_PRODUCT: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/`,
  UPDATE_PRODUCT_TAG: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/product-tag/`,

  // Payments API //

  CREATE_PAYMENT: (outlet_id: string) =>
    API_VERSION_V2 + `/payments/${outlet_id}/create`,
  GET_ISSUED_PAYMENT: (outlet_id: string) =>
    API_VERSION_V2 + `/payments/${outlet_id}/sent`,
  GET_PAYMENT_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/payments/${outlet_id}/settings`,
  SAVE_PAYMENT_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/payments/${outlet_id}/settings`,
  COMMON_PAYMENT_SETTING: (outlet_id: string) =>
    API_VERSION_V2 + `/payments/${outlet_id}/common-settings`,
  // CREATE_PAYMENT: API_VERSION_V2 + '/payments/create',
  // GET_ISSUED_PAYMENT: API_VERSION_V2 + '/payments/sent',

  // GMB Listing //

  // GMB_LISTING_INSIGHTS: (outlet_id: string) =>
  //   API_VERSION_V2 + `/listing_management/${outlet_id}/graph`,
  GMB_LISTING_INSIGHTS: (outlet_id: string) =>
    API_VERSION_V1 + `/analytics/mylisting/${outlet_id}/google/metrics/mom`,
  GMB_CATEGORY_LIST: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/categories`,
  UPDATE_ADDRESS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/address`,
  DELETE_SERVICE_AREA: (outlet_id: string, area_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${area_id}/service-area`,
  DELETE_ADDITIONAL_CATEGORY: (outlet_id: string, cat_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${cat_id}/category`,
  DELETE_REGULAR_HOUR: (outlet_id: string, hour_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${hour_id}/hour`,
  UPDATE_REGULAR_HOUR: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/working-hours`,
  UPDATE_SPECIAL_HOUR: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/special-hours`,
  // GMB_ATTRIBUTES: (outlet_id: string , category_id) => API_VERSION_V2 + `/listing_management/${outlet_id}/gcid:computer_security_service/attributes`,
  GMB_ATTRIBUTES: (outlet_id: string, category_id) =>
    API_VERSION_V2 +
    `/listing_management/${outlet_id}/${category_id}/attributes`,

  UPDATE_URL: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/url`,
  DELETE_URL: (outlet_id: string, url_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${url_id}/attribute-url`,
  SAVE_LISTING_IMAGE: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/upload`,
  GET_ALL_IMAGES: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/media-detail`,
  GET_ALL_VIDEOS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/videos`,
  DELETE_IMAGES: (outlet_id: string, media_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${media_id}/media`,
  UPDATE_MORE_HOURS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/more-hours`,
  DELETE_SPECIAL_HOUR_DATE: (outlet_id: string, date) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${date}/date`,
  GET_QUESTIONS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/questions`,
  GET_SINGLE_QUESTION: (outlet_id: string, question_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${question_id}/question`,
  GET_ATTRIBUTES_VALUES: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/attributes`,
  ADD_QUESTION_ANSWER: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/question-answer`,
  DELETE_ANSWER: (outlet_id: string, ans_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${ans_id}/answer`,
  ADD_ANSWER: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/answer`,
  GET_CATEGORY_MORE_HOURS: (outlet_id: string, category_id) =>
    API_VERSION_V2 +
    `/listing_management/${outlet_id}/${category_id}/more-hours-types`,

  GET_SERVICES: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/services`,
  UPDATE_SERVICES: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/service`,
  DELETE_SERVICES: (outlet_id: string, service_id) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/${service_id}/service`,
  // /listing_management/01a5f6d9-4900-4424-b024-51f038268e08/categories?category_id=corporate_office
  GET_SERVICES_PER_CATEGORY: (outlet_id: string, category_id) =>
    API_VERSION_V2 +
    `/listing_management/${outlet_id}/categories?category_id=${category_id}`,
  DELETE_BANNER: (outlet_id: string, banner_id) =>
    API_VERSION_V2 +
    `/listing_management/${outlet_id}/${banner_id}/notification`,
  UPDATE_MISMATCH: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/update-gmb-info`,
  VERIFICATION_METHODS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/verification-options`,
  VERIFICATION_PIN: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/verification-pin`,
  VERIFICATION_REQUEST: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/verification-request`,
  MYLISTINGS_SETTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/settings`, // GET, PUT
  UPDATE_GBP_STATUS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/status`, // PUT

  // Facebook Listing //

  FACEBOOK_OVERVIEW_DETAILS: (outlet_id: string) =>
    API_VERSION_V2 +
    `/listing_management/facebook/${outlet_id}/location-detail`,
  GET_FACEBOOK_CATEGORIES: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/facebook/${outlet_id}/categories`,
  GET_FACEBOOK_METADATA: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/facebook/${outlet_id}/metadata`,
  SUBMIT_FACEBOOK_PRIMARY_INFO: (outlet_id: string) =>
    API_VERSION_V2 +
    `/listing_management/facebook/${outlet_id}/primary-details`,
  SUBMIT_FACEBOOK_ADDRESS_INFO: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/facebook/${outlet_id}/address`,
  SUBMIT_FACEBOOK_HOURS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/facebook/${outlet_id}/hours`,
  DELETE_FACEBOOK_BANNER: (outlet_id: string, banner_id: string) =>
    API_VERSION_V2 +
    `/listing_management/facebook/${outlet_id}/${banner_id}/notification`,
  UPDATE_FACEBOOK_MISMATCH: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/update-fb-info`,
  DELETE_FACEBOOK_HOURS: (outlet_id: string, hour_id: string) =>
    API_VERSION_V2 +
    `/listing_management/facebook/${outlet_id}/${hour_id}/hours`,

  // INSTAGRAM Listing //
  INSTAGRAM_OVERVIEW_DETAILS: (outlet_id: string) =>
    API_VERSION_V2 +
    `/listing_management/instagram/${outlet_id}/location-detail`,
  GET_INSTAGRAM_METADATA: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/instagram/${outlet_id}/metadata`,

  // Gather Info //

  SUBMIT_GATHER_INFO: (outlet_id: string) =>
    API_VERSION_V2 + `/outlet/${outlet_id}/opted-services`,

  // API Docs

  VIEW_API_DOCS: (outlet_id: string) =>
    API_VERSION_V2 + `/subscriptions/${outlet_id}/api-key`,

  // Features - Reviews

  FEATURES_REVIEWS: (outlet_id: string) =>
    API_VERSION_V2 + `/review/${outlet_id}/sub-features`,
  FEATURE_MYLISTINGS: (outlet_id: string) =>
    API_VERSION_V2 + `/listing_management/${outlet_id}/sub-features`,
  FEATURE_SOCIAL: (outlet_id: string) =>
    API_VERSION_V2 + `/social/${outlet_id}/sub-features`,
  FEATURE_INTEGRATION: (outlet_id: string) =>
    API_VERSION_V2 + `/integration/${outlet_id}/sub-features`,
  FEATURE_APPOINTMENTS: (outlet_id: string) =>
    API_VERSION_V3 + `/appointment/${outlet_id}/sub-features`,
  FEATURE_CUSTOMERS: (outlet_id: string) =>
    API_VERSION_V2 + `/contacts/${outlet_id}/sub-features`,
  // FEATURE_TEAMS: (outlet_id: string) =>
  //   API_VERSION_V2 +
  //   `/Copy Permissions From
  //   /${outlet_id}/sub-features`,
  FEATURE_TEAMS: (outlet_id: string) =>
    API_VERSION_V2 + `/people/managers/${outlet_id}/sub-features`,
  FEATURE_INTERACT: (outlet_id: string) =>
    API_VERSION_V2 + `/interaction-management/${outlet_id}/sub-features`,
  FEATURE_NOTIFICATION: (outlet_id: string) =>
    API_VERSION_V3 + `/notification/${outlet_id}/sub-features`,
  FEATURE_MESSAGING: (outlet_id: string) =>
    API_VERSION_V2 + `/messages/${outlet_id}/sub-features`,
  FEATURE_MYSTORE: (outlet_id: string) =>
    API_VERSION_V2 + `/mystore/${outlet_id}/sub-features`,

  // MultiLocation API
  GET_OUTLET_LIST: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/outlets`,
  GET_REVIEW_LIST: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/review-feed`,
  REVIEW_REPORT_DOWNLOAD: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/review-feed/report/download`,
  GET_OUTLET_LIST_DROPDOWN: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/list`,
  GET_BUSINESS_MENU: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/permissions`,
  GET_BUSINESS_OUTLET_DETAILS: (business_id: string, outlet_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/detail/${outlet_id}`,
  GET_BUSINESS_OUTLET_LIST: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/list`,
  UPDATE_OUTLET: (business_id: string, outlet_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/edit/${outlet_id}`,
  UPDATE_COPY_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/copy-settings`,
  UPDATE_COPY_TEMPLATES: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/copy-templates`,
  CREATE_NEW_OUTLET: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/outlet/create`,
  GET_MULTILOC_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/settings`,
  GET_COMMON_MULTILOC_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/common-settings`,
  MULTILOC_REPORTS_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/report-configuration`,
  UPDATE_MULTILOC_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/settings`,
  GET_ALL_GBP_ERRORS: (business_id: string) =>
    API_VERSION_V1 + `/business/mylisting/${business_id}/outlets/gbp-errors`,
  GET_WHITE_LABEL_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/white-label-configuration`,
  // MultiLocation Dashboard filter API
  GET_CITY_DATA_FILTER: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/cities`,
  OUTLET_BULK_UPLOAD: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/bulk-outlet/create`,
  GET_CENTRAL_DASHBOARD_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/central-dashboard-settings`, // GET, PUT
  GET_UNIFIED_REVIEWS_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/unified-reviews-settings`, // GET, PUT
  GET_INTERACT_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/interact-settings`, // GET, PUT
  GET_NOTIFICATION_SETTINGS: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/notification-settings`, // GET, PUT
  DELETE_ADDITIONAL_EMAIL: (business_id: string, additional_email_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/report-additional-email/${additional_email_id}`,
  UPDATE_ADDITIONAL_EMAIL: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/report-additional-email`,

  // Multiloc Teams
  GET_TEAM_LIST: (business_id: string) =>
    API_VERSION_V1 + `/business/team/${business_id}/list`,
  CREATE_TEAM_MEMBER: (business_id: string) =>
    API_VERSION_V1 + `/business/team/${business_id}/create`,
  EDIT_TEAM_MEMBER: (business_id: string, user_id: string) =>
    API_VERSION_V1 + `/business/team/${business_id}/${user_id}`,
  VIEW_TEAM_MEMBER: (business_id: string, user_id: string) =>
    API_VERSION_V1 + `/business/team/${business_id}/detail/${user_id}`,
  DELETE_TEAM_MEMBER: (business_id: string, user_id: string) =>
    API_VERSION_V1 + `/business/team/${business_id}/${user_id}`,
  GET_FILTER_METADATA_FOR_OUTLET: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/filter-meta-data`,
  GET_LOCATION_GROUP_LIST: (business_id: string) =>
    API_VERSION_V1 + `/business/location-group/${business_id}/list`,
  CREATE_LOCATION_GROUP: (business_id: string) =>
    API_VERSION_V1 + `/business/location-group/${business_id}/create`,
  ADD_OUTLET_TO_GROUP: (business_id: string, location_group_id: string) =>
    API_VERSION_V1 +
    `/business/location-group/${business_id}/${location_group_id}/add-outlets`,
  REMOVE_OUTLET_TO_GROUP: (business_id: string, location_group_id: string) =>
    API_VERSION_V1 +
    `/business/location-group/${business_id}/${location_group_id}/remove-outlets`,
  EDIT_LOCATION_GROUP: (business_id: string, location_group_id: string) =>
    API_VERSION_V1 +
    `/business/location-group/${business_id}/${location_group_id}`,
  VIEW_LOCATION_GROUP: (business_id: string, location_group_id: string) =>
    API_VERSION_V1 +
    `/business/location-group/${business_id}/detail/${location_group_id}`,
  DELETE_LOCATION_GROUP: (business_id: string, location_group_id: string) =>
    API_VERSION_V1 +
    `/business/location-group/${business_id}/${location_group_id}`,
  GET_OUTLET_LIST_DETAILED: (business_id: string) =>
    API_VERSION_V3 + `/business/outlet/${business_id}/pagination/list`,
  UPDATE_MYLISTINGS_COPY_FIELDS: (business_id: string, platform: string) =>
    API_VERSION_V1 +
    `/business/mylisting/${business_id}/${platform}/bulk-update`,
  // Business Profile
  UPDATE_BUSINESS_PROFILE: (business_id: string) =>
    API_VERSION_V3 + `/business/edit/${business_id}`,
  UPDATE_BUSINESS_LOGO: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/logo`,

  // Multiloc Interact
  GET_INTERACT_NOTIFICATION: (business_id: string) =>
    API_VERSION_V1 + `/business/interact/${business_id}/notifications`,

  //  Multiloc Expiry
  GET_SUBSCRIPTION_DETAIL: (business_id: string) =>
    API_VERSION_V3 + `/business/${business_id}/detail`,

  // Business Notification
  GET_BUSINESS_NOTIFICATION: (business_id: string) =>
    API_VERSION_V1 + `/business/notification/${business_id}/list`,
  GET_FAILED_UPDATE: (business_id: string, bulk_update_id) =>
    API_VERSION_V1 +
    `/business/mylisting/${business_id}/${bulk_update_id}/failures`,

  // Social Feed
  GET_SOCIAL_META: (business_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/meta-data`,
  GET_SOCIAL_FEED: (business_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/feed`,
  GET_OUTLET_LIST_FOR_SOCIAL_FEED: (business_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/outlet/list`,
  GET_LIKES_DATA_OF_FEED: (business_id: string, feed_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/likes/list/${feed_id}`,
  BUSINESS_LIKE_TO_FEED: (business_id: string, post_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/like/${post_id}/submit`,
  DELETE_LIKE_TO_FEED: (business_id: string, post_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/like/${post_id}/delete`,
  COMMENT_TO_FEED: (business_id: string, post_id: string) =>
    API_VERSION_V1 +
    `/business/social/${business_id}/comment/${post_id}/submit`,
  GET_FEED_COMMENTS: (business_id: string, feed_id: string) =>
    API_VERSION_V1 + `/business/social/${business_id}/comments/list/${feed_id}`,

  // REVIEW WIDGET V3
  GET_REVIEW_WIDGET_SETTINGS: (outlet_id: string, widget_type: string) =>
    API_VERSION_V3 +
    `/review/${outlet_id}/review-widget-settings/${widget_type}`,
  UPDATE_REVIEW_WIDGET_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/review/${outlet_id}/review-feed-widget-settings`,
  UPDATE_REVIEW_BADGE_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/review/${outlet_id}/review-badge-settings`,
  UPDATE_REVIEW_WIDGET: (outlet_id: string) =>
    API_VERSION_V3 + `/review/${outlet_id}/update-review-widget`,
  GET_REVIEW_WIDGET: (outlet_id: string) =>
    API_VERSION_V3 + `/review/${outlet_id}/review-feed-widget`,

  // SOCIAL WIDGET V3
  GET_SOCIAL_WIDGET_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/social-widget-settings`,
  UPDATE_SOCIAL_WIDGET_SETTINGS: (outlet_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/social-widget-settings`,

  ADD_POST_TO_WIDGET: (outlet_id: string, post_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/update-post-widget/${post_id}`,

  REMOVE_POST_FROM_WIDGET: (outlet_id: string, post_id: string) =>
    API_VERSION_V3 + `/social/${outlet_id}/delete-post-widget/${post_id}`,
});
