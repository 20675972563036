import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { OtpService } from "../services/otp.service";
import { Response } from "../shared/models";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "src/app/app.component";
import { DataService } from "src/app/dataService/data.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { MultiLocationService } from "src/app/multi-location/multi-location.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-create-password",
  templateUrl: "./create-password.component.html",
  styleUrls: ["./create-password.component.scss"],
})
export class CreatePasswordComponent implements OnInit {
  loading: boolean = false;
  newPassword: string = "";
  confirmPassword: string = "";
  userId = "";
  phoneNumber = "";
  userEmailId = null;

  isMinLength: boolean = false;
  isSpecialContain: boolean = false;
  isNumberContain: boolean = false;
  isSubmitted: boolean = false;

  specialCharFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  numberFormat = /\d/;

  public showPass = false;
  public showPassForConfirm = false;
  public passwordType = "password";
  public passwordTypeForConfirm = "password";
  show_error_message = "";
  private BASE_URL = GlobalVariable;
  errorUiVisible: boolean = false;
  errorUiMsg: string = "";

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private httpService: DataService,
    private otpService: OtpService,
    private modalService: NgbModal,
    private multiLocationService: MultiLocationService,
    private pusherService: PusherService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    let qParams;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      qParams = { ...params.keys, ...params };
    });
    if (qParams?.user_id) {
      this.userId = qParams.user_id;
      this.verifyEmail();
    }
    if (qParams?.mobile) {
      this.phoneNumber = qParams.mobile;
    }

    if (qParams?.user_email) {
      this.userEmailId = qParams?.user_email;
    }
  }

  onPasswordChange() {
    this.isSubmitted = false;
    this.isSpecialContain = this.specialCharFormat.test(this.newPassword);
    this.isNumberContain = this.numberFormat.test(this.newPassword);
    if (this.newPassword.length >= 8) {
      this.isMinLength = true;
    } else {
      this.isMinLength = false;
    }
  }

  onConfirmPasswordChange() {
    this.isSubmitted = false;
  }

  verifyEmail() {
    this.loading = true;
    this.otpService.verifyEmailOTP(this.userId).subscribe(
      (data: Response) => {
        this.loading = false;
        this.phoneNumber = data.val.phone_number;
        //existing user
        if (data.val.has_password) {
          // redirect to login
          this.router.navigate(["/signin"]);
        }
      },
      (error) => {
        //what to do with the error message
        this.loading = false;
      }
    );
  }

  showPassword(type: number) {
    if (type == 1) {
      this.showPass = !this.showPass;
      if (this.showPass) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    } else {
      this.showPassForConfirm = !this.showPassForConfirm;
      if (this.showPassForConfirm) {
        this.passwordTypeForConfirm = "text";
      } else {
        this.passwordTypeForConfirm = "password";
      }
    }
  }

  createPassword() {
    this.isSubmitted = true;
    if (
      this.newPassword !== this.confirmPassword ||
      !this.isMinLength ||
      !this.isSpecialContain ||
      !this.isNumberContain
    ) {
      return;
    }

    this.loading = true;
    let res: Response;

    let generatePassword;

    if (this.userEmailId) {
      generatePassword = this.otpService.generatePasswordForWebsite(
        this.userEmailId,
        this.newPassword
      );
    } else {
      generatePassword = this.otpService.generatePassword(
        this.phoneNumber,
        this.newPassword
      );
    }

    generatePassword.subscribe(
      (data: Response) => {
        res = data;
        this.loading = false;
        if (res && (res.code === 201 || res.code === 200)) {
          this.storage.set("access_token", res.token.tokens.access_token);
          this.storage.set("refresh_token", res.token.tokens.refresh_token);
          this.storage.set("is_business_view", res.token.is_business_view);
          this.storage.set("business_id", res.token.business_id);
          this.storage.set("is_outlet_view", res.token.is_outlet_view);
          let outlet_id = res.token.outlet_id;
          this.storage.set("outlet_id", outlet_id);
          if (outlet_id) {
            if (res.token.is_outlet_view) {
              this.httpService
                .get(this.BASE_URL.ACCESS_INFO(res.token.outlet_id))
                .subscribe((data: any) => {
                  this.storage.set("access", data.data);
                });

              this.httpService
                .get(this.BASE_URL.USER_PROFILE(outlet_id))
                .subscribe((data) => {
                  this.storage.set("role", data.data["role"]);
                });
            }
          }

          if (res.token.is_incomplete) {
            // move to onboard screen
            this.router.navigate(["/onboarding/step-1"]);
          } else {
            if (res.token.is_business_view) {
              this.multiLocationService.getBusinessMenuAPI();
            }
            // setTimeout(() => {
            //   if (res.token.is_business_view && !res.token.is_outlet_view) {
            //     let businessRedirectTo = this.setMultilocRedirection();
            //     this.router.navigate([`${businessRedirectTo}`]);
            //   } else {
            //     this.onPageLoadRouting();
            //   }
            // }, 1000);
            if (res.token.is_business_view && !res.token.is_outlet_view) {
              let businessRedirectTo = this.setMultilocRedirection();
              this.router.navigate([`${businessRedirectTo}`]);
            } else {
              this.onPageLoadRouting();
            }
          }
          this.errorUiVisible = false;
          this.errorUiMsg = "";
        } else {
          if (res) {
            // this.toastrService.error(res.message);
            this.errorUiVisible = true;
            this.errorUiMsg = res.message;
          }
        }
      },
      (error) => {
        // this.show_error_message = error.error.message;
        this.loading = false;
        console.log("error in create pass", error);
        this.errorUiVisible = true;
        this.errorUiMsg = error.error.message;
      }
    );
  }

  onPageLoadRouting() {
    this.loading = true;
    let res: Response;
    let outlet_id = this.storage.get("outlet_id");
    const accessInfo = this.httpService.get(
      this.BASE_URL.ACCESS_INFO(outlet_id)
    );
    accessInfo.subscribe(
      (data: any) => {
        res = data;
        if (res.code === 200) {
          this.storage.set("access", data.data);
          let is_business_view = this.storage.get("is_business_view");
          if (is_business_view) {
            this.multiLocationService.getBusinessMenuAPI();
          }
          this.loading = false;
        }
      },
      (error) => {
        console.log(error);
        this.loading = false;
      },
      () => {
        this.loading = false;
        this.commonService.setDashboardRedirect();
        this.pusherService.setPusherChannel();
      }
    );
  }

  setMultilocRedirection() {
    let businessLandingMenu = this.multiLocationService.getLandingMenu;
    if (businessLandingMenu == "Unified Reviews") {
      let switchToMultilocView = `/business/reviews`;
      return switchToMultilocView;
    }
    let switchToMultilocView = `/business/dashboard`;
    return switchToMultilocView;
  }

  errorBannerClose(data) {
    this.errorUiVisible = false;
    this.errorUiMsg = "";
  }
}
