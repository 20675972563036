import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-error-toast-ui",
  templateUrl: "./error-toast-ui.component.html",
  styleUrls: ["./error-toast-ui.component.scss"],
})
export class ErrorToastUiComponent implements OnInit {
  @Input() imageUrl = "";
  @Input() headerText = "";
  @Input() descriptionText = "";
  @Output() outputDataClose = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  closeToastMessage() {
    this.outputDataClose.emit(false);
  }
}
