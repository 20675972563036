import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BusinessNotificationPageComponent } from "./business-notification-page/business-notification-page.component";
const routes: Routes = [
  {
    path: "business-notifications",
    component: BusinessNotificationPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessNotificationRoutingModule {}
