import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class OnBoardGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(): boolean {
    const value = localStorage.getItem("hasIncompleteOnboard");

    if (value && value === "1") {
      this.router.navigate(["/onboarding/step-1"]);
      return false;
    }
    return true;
  }
}
