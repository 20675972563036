import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { JoyrideService } from "ngx-joyride";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { GlobalVariable } from "../../app.component";
import { DataService } from "../../dataService/data.service";
import { CommonService } from "../../services/common.service";

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: "app-admin",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  [x: string]: any;
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  current_url: String;
  new_password: String;
  show_haspass: boolean;
  routeOptions: Options;

  page_no: number;
  private BASE_URL = GlobalVariable;
  outlet_id: string;
  options = {
    lang: "en",
    theme: "winter",
    settings: false,
    docked: false,
    boxed: false,
    opened: false,
  };
  screenWidth: number;
  screenHeight: number;
  mobile_chat_display = true;

  currentLang = "en";

  @ViewChild("sidebar")
  sidebar;
  @ViewChild("sidemenu")
  sidemenu: MatSidenav;

  @ViewChild("accessDeniedpopModal") accessDeniedpopModal: ElementRef;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private _element: ElementRef,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private titleService: Title,
    zone: NgZone,
    private commonService: CommonService,
    private modalService: NgbModal,
    private HttpService: DataService,
    private location: Location,
    private readonly joyrideService: JoyrideService
  ) {
    this.mediaMatcher.addListener((mql: any) =>
      zone.run(() => {
        this.mediaMatcher = mql;
      })
    );
    router.events.subscribe(() => {
      //found current active url Using Angular Location Service
      this.isInteractChatWindow = this.location.path() == "/interact";
    });
  }

  ngOnInit(): void {
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
        if (document?.querySelector(".main-content")) {
          document.querySelector(".main-content").scrollTop = 0;
        }

        if (this.isOver()) {
          this.sidemenu.close();
        }
        this.runOnRouteChange();
      });

    this.runOnRouteChange();
    this.current_url = this.router.url;
    if (this.current_url !== "/reviews") {
      // this.commonService.resetPageNo(1);
      // this.commonService.getSocialReview("NO_REVIEW");
    }

    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth < 750) {
      this.mobile_chat_display = false;
    }
  }

  ngAfterViewInit(): void {
    // this.joyrideService.startTour(
    //   { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep']}
    // );
    this.commonService.firstView.subscribe((res) => {
      if (res === true) {
        // console.log(res);
        // let showps: String;
        // showps = this.storage.get('has_password');
        // if(this.showps !== undefined){
        this.joyrideService.startTour({
          steps: ["firstStep", "secondStep", "thirdStep", "fourthStep"],
        });
        // }
        // this.hasPassShow();
      } else if (res === false) {
        this.joyrideService.closeTour();
      }
    });
  }

  // hasPassShow(){
  //   let showps: String;
  //   showps = this.storage.get('has_password');
  //   if(this.showps === '0'){
  //     this.joyrideService.startTour(
  //       { steps: ['firstStep', 'secondStep', 'thirdStep', 'fourthStep']}
  //     );
  //   }
  // }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  runOnRouteChange(): void {
    window.scrollTo(0, 0);
    if (this.location.path() !== "/analytics") {
      this.storage.remove("start_date");
      this.storage.remove("to_date");
    }
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
        this.routeOptions = activeRoute?.snapshot?.data;
      }
    });

    if (this.routeOptions?.heading) {
      if (this.routeOptions.heading === "Clients") {
        this.setTitle("Customers");
      } else {
        this.setTitle(this.routeOptions.heading);
      }
    } else {
      const tit = this.location.path().split("/");
      this.setTitle(tit[1].charAt(0).toUpperCase() + tit[1].slice(1));
    }
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle("Zceppa | " + newTitle);
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  receiveMessage($event) {
    this.options = $event;
  }

  toggleFullscreen(): void {
    const elem = this._element.nativeElement.querySelector(".main-content");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullScreen) {
      elem.msRequestFullScreen();
    }
  }

  onReviewScroll() {
    let url: any = this.router.url;
    console.log(url);
    if (url == "/reviews") {
      this.commonService.reviewsOnScroll.next(true);
      return;
    }
    if (url == "/business/reviews") {
      this.commonService.businessReviewsOnScroll.next(true);
      return;
    }
    if (url == "/business/social-feed") {
      this.commonService.businessSocialFeedOnScroll.next(true);
      return;
    }
    if (url == "/business/interact/notifications") {
      this.commonService.businessInteractNotificationOnScroll.next(true);
      return;
    }
    if (url == "/business/notifications/business-notifications") {
      this.commonService.businessNotificationOnScroll.next(true);
      return;
    }
  }

  // onScroll() {
  //   this.current_url = this.router.url;
  //   let flag_value: string;
  //   let filters;
  //   let getReviewData;
  //   let filterPage;
  //   console.log("is this calld");

  //   this.commonService.page_no.subscribe((pageNO) => {
  //     this.page_no = pageNO;
  //   });
  //   this.commonService.infinte_scroll_flag.subscribe((flag) => {
  //     flag_value = flag;
  //   });
  //   this.commonService.scroll_filter_reviews.subscribe((response) => {
  //     filters = response;
  //   });
  //   if (
  //     this.current_url === "/reviews" &&
  //     (flag_value === null || Array.isArray(filters))
  //   ) {
  //     let res: Response;
  //     this.outlet_id = this.storage.get("outlet_id");
  //     // this.page_no = this.page_no;
  //     const size = 20;
  //     const order = "DESC";
  //     if (Array.isArray(filters)) {
  //       this.commonService.filter_scroll_page.subscribe((page) => {
  //         if (page !== null) {
  //           filterPage = page;
  //         }
  //       });
  //       getReviewData = this.HttpService.commonServiceFunction(
  //         this.BASE_URL.GOOGLE_REVIEW_URL_V2(this.outlet_id),
  //         filterPage,
  //         size,
  //         order,
  //         ...filters
  //       );
  //     } else {
  //       getReviewData = this.HttpService.commonServiceFunction(
  //         this.BASE_URL.GOOGLE_REVIEW_URL_V2(this.outlet_id),
  //         this.page_no,
  //         size,
  //         order
  //       );
  //     }
  //     // this.HttpService.commonServiceFunction(this.BASE_URL.GOOGLE_REVIEW_URL_V2(this.outlet_id), this.page_no, size, order).
  //     getReviewData.subscribe(
  //       (data: Response) => {
  //         res = data;
  //       },
  //       (error) => {},
  //       () => {
  //         if (res.data["reviews"].length !== 0) {
  //           const val = res.data["reviews"];
  //           this.commonService.getSocialReview(val);
  //         }
  //       }
  //     );
  //   }
  // }

  onUp() {
    // console.log('hello');
  }
}
