import { FeatureParams, SubFeatures, GetValues, FeatureKey } from "./common";

export type CustomersSubFeatures = {
  no_of_customers: "no_of_customers";
  customer_list: "customer_list";
  flex_fields: "flex_fields";
  whatsapp_optin: "whatsapp_optin";
  activity_log: "activity_log";
  contact_category: "contact_category";
};

export const CUSTOMERS_SUBFEATURES: CustomersSubFeatures = {
  no_of_customers: "no_of_customers",
  customer_list: "customer_list",
  flex_fields: "flex_fields",
  whatsapp_optin: "whatsapp_optin",
  activity_log: "activity_log",
  contact_category: "contact_category",
};
interface CustomersParams
  extends FeatureParams<"feature_customers", GetValues<CustomersSubFeatures>> {}
export interface CustomersFeatures
  extends SubFeatures<GetValues<CustomersSubFeatures>> {}

export interface CustomersStructure {
  [key: string]: CustomersParams;
}
