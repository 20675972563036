<ul
  *ngIf="isNotificationAvailable"
  class="navbar-nav notify_dropdown"
  style="cursor: pointer"
>
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <div class="new-message-notification-wrap">
      <!-- <img src="assets/images/interact-notif.svg" alt="" ngbDropdownToggle /> -->
      <img src="assets/images/header-interact-notification.svg" alt="interact" ngbDropdownToggle/>
      <div
        class="new-message-notification"
        *ngIf="
          unreadCount !== 0 && manageNotificationVia == 'central_dashboard'
        "
      >
        <span class="msg-count">{{ unreadCount }}</span>
        <audio id="notificationSound">
          <source src="assets/sound/notification-sound.mp3" type="audio/mpeg">
        </audio>
      </div>
    </div>
    <div
      ngbDropdownMenu
      class="notification-dropdown dropdown-menu-right p-0 m-0"
      style="min-width: 350px"
    >
      <perfect-scrollbar style="max-height: 100%">
        <ng-container *ngFor="let notification of notifications">
          <app-notifications
            [notificationType]="'popup'"
            [notification]="notification"
          >
          </app-notifications>
        </ng-container>
        <div *ngIf="notifications?.length" class="d-flex dropdown-item">
          <a
            href="javascript:;"
            [routerLink]="['/business/interact/interact-notifications']"
            class="notify_viewmore"
            >View More
          </a>
        </div>
        <div class="d-flex" *ngIf="!notifications?.length">
          <p style="text-align: center; width: 100%; padding: 80px 0px">
            No Messages Found!
          </p>
        </div>
      </perfect-scrollbar>
    </div>
  </li>
</ul>
