import { Component, OnInit } from "@angular/core";
import { MultiLocationService } from "src/app/multi-location/multi-location.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { BusinessNotificationService } from "../business-notification.service";
@Component({
  selector: "app-business-notification-popup",
  templateUrl: "./business-notification-popup.component.html",
  styleUrls: ["./business-notification-popup.component.scss"],
})
export class BusinessNotificationPopupComponent implements OnInit {
  isNotificationAvailable: boolean;
  notifications: any[] = [];
  isNewNotification: boolean = false;
  constructor(
    private pusherService: PusherService,
    private multiLocationService: MultiLocationService,
    private businessNotificationsService: BusinessNotificationService
  ) {}

  ngOnInit(): void {
    this.pusherService.businessUserChannel.bind(
      "mylisting_bulk_update",
      (data) => {
        this.processNewNotification(data);
      }
    );
    this.pusherService.businessUserChannel.bind(
      "location_bulk_upload",
      (data) => {
        this.processNewNotification(data);
      }
    );
    this.onInit();
  }

  processNewNotification(data) {
    if (this.multiLocationService.getIsMultiLocationView()) {
      this.onInit(data);
      this.isNewNotification = true;
    }
  }
  onInit(data: any = null) {

    this.getNotifications();
  }

  getNotifications() {
    const pageNo = 1;
    const size = 5;
    this.businessNotificationsService
      .getBusinessNotifications({
        page: pageNo.toString(),
        size: size.toString(),
      })
      .subscribe((response) => {
        console.log(response);
        if (response.code == 200) {
          this.isNotificationAvailable = true;
          let notifications = response.data.notifications;
          notifications.forEach((val: any, key: any) => {
            notifications[key].data = JSON.parse(val.data);
          });
          this.notifications = notifications;
        } else {
          this.isNotificationAvailable = false;
        }
      });
  }
}
