import { Component, OnInit } from "@angular/core";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { InteractService } from "../../interact.service";

@Component({
  selector: "app-interact-notification",
  templateUrl: "./interact-notification.component.html",
  styleUrls: ["./interact-notification.component.scss"],
})
export class InteractNotificationComponent implements OnInit {
  pageNo: number = 1;
  size: number = 20;
  notifications: any[] = [];
  isNotificationAvailable: boolean;
  unreadCount: number = 0;
  outletList: any[] = [];
  manageNotificationVia: "central_dashboard" | "location" = null;
  constructor(
    private interactService: InteractService,
    private pusherService: PusherService
  ) {}

  ngOnInit(): void {
    console.log("test");
    this.pusherService.businessChannel.bind("interact", (data) => {
      if (this.interactService.isMultiLocationView()) {
        this.onInit(data);
      }
    });
    this.onInit();
  }

  async onInit(data: any = null) {
    if (!this.outletList.length) {
      this.outletList = await this.getHasOutletAccess();
    }
    if (data) {
      const dataJson = JSON.parse(JSON.stringify(data));
      let hasOutlet = this.outletList.find(
        (outlet) => outlet.outlet_id == dataJson.outlet_id
      );
      if (hasOutlet) {
        this.getNotifications();
      }
      return;
    }
    this.getNotifications();
  }

  getHasOutletAccess(): Promise<any[]> {
    return this.interactService.getHasOutletAccess();
  }

  mapHasOutletAccess() {
    this.notifications = this.notifications.map((notif) => {
      let outlet = this.outletList.find(
        (outlet) => outlet.outlet_id == notif.outlet_id
      );
      if (outlet) {
        notif.has_outlet_access = outlet.has_outlet_access;
      }
      return notif;
    });
    console.log(this.notifications);
  }

  getNotifications() {
    this.pageNo = 1;
    this.size = 5;
    this.interactService
      .getInteractNotifications({
        page: this.pageNo.toString(),
        size: this.size.toString(),
      })
      .subscribe((response) => {
        console.log(response);
        if (response.code == 200) {
          this.isNotificationAvailable = true;
          this.notifications = response.data.conversations;
          if (response.data?.unread_conversations > 0) {
            if (response.data.unread_conversations > this.unreadCount) {
              this.unreadCount = response.data.unread_conversations;
              //ring bell notification
              setTimeout(() => {
                // added timeout to avoid the null/undefined in document.getElementById
                this.playNotificationSound();
              }, 100);
            }
          } else {
            this.unreadCount = response.data.unread_conversations || 0;
          }
          // this.unreadCount = response.data.unread_conversations || 0;
          this.manageNotificationVia = response.data.manage_interact_messages;
          this.mapHasOutletAccess();
        } else {
          this.isNotificationAvailable = false;
        }
      });
  }

  playNotificationSound() {
    const audioElement: HTMLAudioElement = document.getElementById(
      "notificationSound"
    ) as HTMLAudioElement;
    audioElement?.play();
  }
}
