import { Inject, Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public jwtHelper: JwtHelperService
  ) {}

  public isAuthenticated(): boolean {
    const token = this.storage.get("access_token");
    console.log(token);
    // if(token != ''){
    //   return true;
    // }
    // return false;
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  getAccessToken() {
    return this.storage.get("access_token");
  }

  // logout() {
  //   var credentials, remember_me;
  //   if(localStorage.getItem('remember_me')){
  //     credentials = localStorage.getItem('credentials');  //re-store credentials in local storage
  //     remember_me = localStorage.getItem('remember_me');
  //   }
  //   localStorage.clear();
  //   if(remember_me){
  //     console.log('credentials', credentials)
  //     localStorage.setItem('credentials',credentials);
  //     localStorage.setItem('remember_me', remember_me);
  //   }
  //   return !this.jwtHelper.isTokenExpired(token);
  // }
}
