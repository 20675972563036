import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxLoadingModule } from "ngx-loading";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { SharedModule } from "src/app/common-container/shared.module";
import { InteractNotificationMainComponent } from "./interact-notification-main/interact-notification-main.component";
import { InteractNotificationComponent } from "./interact-notification-main/interact-notification/interact-notification.component";
import { NotificationsComponent } from "./interact-notification-main/notifications/notifications.component";
import { InteractRoutingModule } from "./interact-routing.module";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    InteractNotificationComponent,
    NotificationsComponent,
    InteractNotificationMainComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    InteractRoutingModule,
    NgxLoadingModule,
  ],
  exports: [InteractNotificationComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class InteractModule {}
