import { Component, Inject, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { DataService, Response } from "../../dataService/data.service";
import { GlobalVariable } from "../../app.component";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CommonService } from "src/app/services/common.service";

interface ServiceList {
  key: string;
  value: string;
  checked?: boolean;
}
@Component({
  selector: "app-gather-info",
  templateUrl: "./gather-info.component.html",
  styleUrls: ["./gather-info.component.scss"],
})
export class GatherInfoComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  outlet_id: string = this.storage.get("outlet_id");
  serviceList: any[] = [];
  selectedService: string[] = [];
  serviceSumbitted: boolean;
  textToShow: string;
  buttonToShow: string;
  loading: boolean;

  constructor(
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private commonservice: CommonService,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.commonservice.gather_info_data.subscribe((data: ServiceList[]) => {
      console.log(data);
      if (data !== null) {
        this.setServiceList(data);
        this.loading = false;
      }
    });
    this.commonservice.gather_info_service.subscribe((data: boolean) => {
      console.log(data);
      if (data !== null) {
        this.loading = false;
        this.setServiceCondition(data);
      }
    });
  }

  setServiceCondition(isService: boolean) {
    if (isService) {
      this.onAction("redirect");
    }
  }

  setServiceList(data: ServiceList[]) {
    let serviceList = data;
    this.serviceList = [...serviceList];
    this.serviceList.map((e) => {
      e.checked = false;
      return e;
    });
    this.serviceList.push({
      key: "none",
      value: "None of the above",
      checked: false,
    });
  }
  uncheckAll() {
    this.serviceList.map((e) => {
      e.checked = false;
    });
  }
  disable() {
    return this.serviceList.some((_) => _.key == "none" && _.checked);
  }
  disableButton() {
    return this.serviceList.every((_) => !_.checked);
  }
  setService(event, service: ServiceList, index: number = null) {
    let key = service.key;
    if (event.target.checked) {
      if (key == "none") {
        this.uncheckAll();
      }
      this.serviceList[index].checked = true;
    } else {
      this.serviceList[index].checked = false;
    }
    console.log(this.serviceList);
  }
  showThankyouScreen(keyArr: Array<string>) {
    if (
      (keyArr.includes("GMB_OPTIMIZATION") ||
        keyArr.includes("GMB_PHONE_NUMBER")) &&
      keyArr.includes("GMB_VERIFICATION")
    ) {
      this.textTransform(
        "BOTH",
        this.selectedService.filter(
          (text) => text !== "GBP Address Verification"
        )
      );
      return;
    } else if (
      keyArr.includes("GMB_OPTIMIZATION") ||
      keyArr.includes("GMB_PHONE_NUMBER")
    ) {
      this.textTransform("EITHER");
      return;
    } else {
      this.textTransform("GMB");
      return;
    }
  }
  textTransform(condition: string, text: Array<string> = []) {
    switch (condition) {
      case "GMB":
        this.textToShow = `Thank you. Please go ahead and connect google account.`;
        this.buttonToShow = `Connect to Google Now`;
        break;

      case "BOTH":
        this.textToShow = `Thank you. Please go ahead and connect google account.<br/><br/>
        For ${text.join(
          ","
        )}, Zceppa representative will reach out to you for further assistance.`;
        this.buttonToShow = `Connect to Google Now`;

        break;

      case "EITHER":
        this.textToShow = `Thank you. Zceppa representative will reach out to you for further assistance.`;
        this.buttonToShow = `Got it`;

        break;
      default:
        break;
    }
  }
  onAction(action: string) {
    if (action == "redirect") {
      let landingMenu = this.storage.get("access").landing_menu;
      window.location.assign(
        `../${this.commonservice.setRoutingURL(landingMenu)}`
      );
    } else {
      window.location.assign(`/settings/integrations`);
      // this.router.navigate(["/settings/integrations"]);
    }
  }
  selectedServiceList(type: string) {
    return this.serviceList.some((_) => _.key == "none" && _.checked)
      ? []
      : this.serviceList
          .filter((_) => _.checked)
          .map((service) => service[type]);
  }
  submitGatherInfo() {
    this.selectedService = this.selectedServiceList("value");

    this.httpService
      .postRequest({
        customApiUrl: this.BASE_URL.SUBMIT_GATHER_INFO(this.outlet_id),
        data: {
          opted_services: this.selectedService,
        },
      })
      .subscribe(
        (res: Response) => {
          if (res.code == 200) {
            if (this.selectedService.length) {
              this.serviceSumbitted = true;
              let selectedServiceKey = this.selectedServiceList("key");
              this.showThankyouScreen(selectedServiceKey);
              console.log(this.selectedService);
            } else {
              this.onAction("redirect");
            }
          }
        },
        (err) => {
          this.toastrService.error(err.error.message);
        }
      ),
      () => {};
  }
}
