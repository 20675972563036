export type Formats =
  | "DD-MM-YYYY"
  | "YYYY-MM-DD"
  | "DD-MM-YYYY h:mm A"
  | "MMM D, YYYY h:mm A"
  | "YYYY-MM-DD HH:mm"
  | "ago"
  | "MMMM D"
  | "MMM D"
  | "D MMM YYYY h:mm A"
  | "DD/MM/YYYY h:mm A"
  | "MMM D, YYYY"
  | "MMM d, YYYY"
  | "MMM DD, YYYY"
  | "MMMM"
  | "hh:mm"
  | "h:mm a";
type DATE_FORMATS = {
  dateFirst: "DD-MM-YYYY";
  yearFirst: "YYYY-MM-DD";
  monthFirst: "MMM d, YYYY";
  monthDateYear: "MMM D, YYYY";
  dateFirstHour: "DD-MM-YYYY h:mm A";
  monthFirstHour: "MMM D, YYYY h:mm A";
  yearFirstHour: "YYYY-MM-DDTHH:mm:ss";
  timeAgo: "ago";
  monthAndDay: "MMMM d";
  month: "MMMM";
  dateFirstHourGmb: "DD/MM/YYYY h:mm A";
  shortMonthAndDay: "MMM D";
  hourMinute: "h:mm a";
  dateMonthYearHour: "DD MMM YYYY, h:mm A";
  dateMonthYear: "DD MMM YYYY";
  timeOnly: "hh:mm";
};
export const DATE_FORMATS: DATE_FORMATS = {
  dateFirst: "DD-MM-YYYY",
  yearFirst: "YYYY-MM-DD",
  monthFirst: "MMM d, YYYY",
  monthDateYear: "MMM D, YYYY",
  dateFirstHour: "DD-MM-YYYY h:mm A",
  monthFirstHour: "MMM D, YYYY h:mm A",
  yearFirstHour: "YYYY-MM-DDTHH:mm:ss",
  timeAgo: "ago",
  monthAndDay: "MMMM d",
  month: "MMMM",
  dateFirstHourGmb: "DD/MM/YYYY h:mm A",
  shortMonthAndDay: "MMM D",
  hourMinute: "h:mm a",
  dateMonthYearHour: "DD MMM YYYY, h:mm A",
  timeOnly: "hh:mm",
  dateMonthYear: "DD MMM YYYY",
};

export type DateType = Date | string | moment.Moment;
