import { DatePipe } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { JwtModule } from "@auth0/angular-jwt";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { SelectDropDownModule } from 'ngx-select-dropdown';
// import { NgSelectModule } from 'ng-custom-select';
import { NgSelectModule } from "@ng-select/ng-select";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { DataTablesModule } from "angular-datatables";
import { StarRatingModule } from "angular-star-rating";
import { NgHttpLoaderModule } from "ng-http-loader";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { FileUploadModule } from "ng2-file-upload";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { AccordionModule } from "ngx-accordion";
import { AutofocusFixModule } from "ngx-autofocus-fix";
import { AutosizeModule } from "ngx-autosize";
import { AvatarModule } from "ngx-avatar";
import { NgxFontAwesomeModule } from "ngx-font-awesome";
import { NgxGaugeModule } from "ngx-gauge";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { JoyrideModule } from "ngx-joyride";
import { NgxLinkifyjsModule } from "ngx-linkifyjs";
import { NgxLoadingModule } from "ngx-loading";
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { TimeagoModule } from 'ngx-timeago';
// import { SharedModule } from '../common-container/shared.module';
import { MomentModule } from "ngx-moment";
import { NgxPaginationModule } from "ngx-pagination";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { TabsModule } from "ngx-tabset";
import { TimeagoModule } from "ngx-timeago";
import { ToastrModule } from "ngx-toastr";
import { UiSwitchModule } from "ngx-toggle-switch";
import { StorageServiceModule } from "ngx-webstorage-service";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { BusinessOnboardingTweakedComponent } from "./business-onboarding-tweaked/business-onboarding-tweaked.component";
import { BusinessOnboardingComponent } from "./business-onboarding/business-onboarding.component";
import { SharedModule } from "./common-container/shared.module";
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective,
  AdminLayoutComponent,
  AuthLayoutComponent,
  CampaignSmsTrackingComponent,
  FooterComponent,
  HeaderComponent,
  MenuComponent,
  ReviewExperienceComponent,
  ReviewImprovementComponent,
  ReviewLayoutComponent,
  ReviewReplyComponent,
  SidebarComponent,
} from "./core";
import { ChatComponent } from "./core/chat/chat.component";
import { GatherInfoComponent } from "./core/gather-info/gather-info.component";
import { ReviewExperienceLayoutComponent } from "./core/review-experience-layout/review-experience-layout.component";
import { DataService } from "./dataService/data.service";
import { UppercaseDirective } from "./directives/uppercase.directive";
import { MyHttpInterceptor } from "./interceptor/my-http-interceptor";
import { BusinessNotificationModule } from "./multi-location/multi-location-main/components/business-notification/business-notification.module";
import { InteractModule } from "./multi-location/multi-location-main/components/interact/interact.module";
import { PermissionsComponent } from "./permissions/permissions.component";
import { FeaturesByPlanResolver } from "./services/features-by-plan/FBP-resolver/features-by-plan.resolver";
// import { PaymentComponent } from './payment/payment.component';
import { ProfileComponent } from "./settings/profile/profile.component";
import {
  SafePipe,
  UserAPIPageComponent,
} from "./user-api-page/user-api-page.component";
import { AccountModule } from "./account/account.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ReviewLayoutComponent,
    ReviewReplyComponent,
    ReviewExperienceComponent,
    ReviewImprovementComponent,
    ReviewExperienceLayoutComponent,
    BusinessOnboardingComponent,
    UppercaseDirective,
    CampaignSmsTrackingComponent,
    ChatComponent,
    PermissionsComponent,
    // PaymentComponent,
    ProfileComponent,
    BusinessOnboardingTweakedComponent,
    GatherInfoComponent,
    UserAPIPageComponent,
    SafePipe,
  ],
  imports: [
    NgxIntlTelInputModule,
    BrowserModule,
    NgxSpinnerModule,
    AccordionModule,
    NgxFontAwesomeModule,
    BrowserAnimationsModule,
    AutofocusFixModule.forRoot(),

    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    // SelectDropDownModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    FileUploadModule,
    NgxDatatableModule,
    MomentModule,
    DataTablesModule,
    AvatarModule,
    // AngularFontAwesomeModule,
    PerfectScrollbarModule,
    Ng4LoadingSpinnerModule.forRoot(),
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#03c0c2",
      secondaryColour: "#03c0c2",
      tertiaryColour: "#03c0c2",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LoadingBarRouterModule,
    NgbModule,
    MatSidenavModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      //timeOut: 1000,
      closeButton: true,
      maxOpened: 1,
    }),
    StorageServiceModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["http://localhost:4200"],
        blacklistedRoutes: ["http://localhost:4200/#/signin"],
      },
    }),
    NgxPaginationModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    IconSpriteModule,
    UiSwitchModule,
    InfiniteScrollModule,
    AvatarModule,
    TimeagoModule.forRoot(),
    NgxGaugeModule,
    StarRatingModule,
    JoyrideModule.forRoot(),
    NgxLinkifyjsModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AutosizeModule,
    PickerModule,
    TabsModule,
    FontAwesomeModule,
    InteractModule,
    BusinessNotificationModule,
    AccountModule,
  ],
  exports: [NgxSpinnerModule, NgbModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    DataService,
    FeaturesByPlanResolver,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {}
