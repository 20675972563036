import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { FeaturesByPlanService } from "../features-by-plan.service";

@Injectable()
export class FeaturesByPlanResolver implements Resolve<Promise<any>> {
  constructor(private featuresByPlan: FeaturesByPlanService) {}

  resolve(route: ActivatedRouteSnapshot) {
    const featureKey = route.data.featureKey;
    return new Promise((resolve, reject) => {
      resolve(this.featuresByPlan.getFeatures(featureKey));
    });
  }
}
