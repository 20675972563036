import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { FeaturesByPlanService } from "../services/features-by-plan/features-by-plan.service";
import {
  CustomersStructure,
  CUSTOMERS_SUBFEATURES,
} from "./../services/features-by-plan/interface/customers";
import { DataService, Response } from "../dataService/data.service";
import { GlobalVariable } from "../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
@Injectable({
  providedIn: "root",
})
export class CustomersService {
  customersFeature: CustomersStructure = {
    activity_log: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.activity_log,
      "is_applicable",
    ],
    customer_list: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.customer_list,
      "is_applicable",
    ],
    flex_fields: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.flex_fields,
      "is_applicable",
    ],
    no_of_customers: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.no_of_customers,
      "count",
    ],
    whatsapp_optin: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.whatsapp_optin,
      "is_applicable",
    ],
    contact_category: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.contact_category,
      "is_applicable",
    ],
    contact_category_value: [
      "feature_customers",
      CUSTOMERS_SUBFEATURES.contact_category,
      "values",
      ["Setting,Tag"],
    ],
  };

  outlet_id = this.storage.get("outlet_id");
  private BASE_URL = GlobalVariable;

  private _customersCount = new BehaviorSubject<number>(null);
  public customersCount$ = this._customersCount.asObservable();
  private _fetchBulkUploadContacts = new BehaviorSubject<boolean>(null);
  public fetchBulkUploadContacts$ =
    this._fetchBulkUploadContacts.asObservable();

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private featuresByPlan: FeaturesByPlanService,
    private httpService: DataService
  ) {}
  setTotalCount(count: number) {
    this._customersCount.next(count);
  }
  fetchBulkUploadContacts(fetchData: boolean) {
    this._fetchBulkUploadContacts.next(fetchData);
  }

  async isFeatureApplicable(subFeature: keyof CustomersStructure) {
    let subFeatureDetails: any = [...this.customersFeature[subFeature]];
    let response = (
      await this.featuresByPlan.getFeature(subFeatureDetails)
    ).isApplicable();
    return response;
  }

  async isFlexFieldAllowed() {
    return (
      await this.featuresByPlan.getFeature(this.customersFeature.flex_fields)
    ).isApplicable();
  }

  async isActivityLogAllowed() {
    return (
      await this.featuresByPlan.getFeature(this.customersFeature.activity_log)
    ).isApplicable();
  }

  async isCustomerListAllowed() {
    return (
      await this.featuresByPlan.getFeature(this.customersFeature.customer_list)
    ).isApplicable();
  }

  async isWhatsappOptinAllowed() {
    return (
      await this.featuresByPlan.getFeature(this.customersFeature.whatsapp_optin)
    ).isApplicable();
  }

  async noOfCustomers(customersCount: number) {
    let custArr: any = [].concat(this.customersFeature.no_of_customers);
    custArr.push([customersCount]);
    console.log(custArr);

    return (await this.featuresByPlan.getFeature(custArr)).checkCount();
  }

  async checkFeatureValue(subFeature: keyof CustomersStructure) {
    let subFeatureDetails: any = [...this.customersFeature[subFeature]];
    let response = (
      await this.featuresByPlan.getFeature(subFeatureDetails)
    ).getDataValue();
    return response;
  }

  public getCommonSettings(): Observable<Response> {
    return this.httpService.get(
      this.BASE_URL.GET_CONTACT_COMMON_SETTING(this.outlet_id)
    );
  }

  public deleteCustomerContactType(categoryId: string): Observable<Response> {
    return this.httpService.deleteRequest({
      customApiUrl: this.BASE_URL.CONTACT_DELETE_CATEGORY(
        this.outlet_id,
        categoryId
      ),
    });
  }

  public updateCustomerDetails(
    payload: any,
    contact_id: string
  ): Observable<Response> {
    return this.httpService.putRequest({
      customApiUrl: this.BASE_URL.UPDATE_CONTACT_DETAILS(
        this.outlet_id,
        contact_id
      ),
      data: payload,
    });
  }
}
