import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "src/app/services/common.service";
import { icons } from "../../../../../../interact/interact.service";
import { AlertService } from "src/app/services/alert.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  @Input("notificationType") notificationType: "popup" | "page" = "page";
  @Input("notification") notification;
  // @ViewChild("switchOutletModal", { static: true })
  // switchOutletModal: ElementRef;
  @ViewChild("switchOutleNoAccesstModal", { static: true })
  switchOutleNoAccesstModal: ElementRef;

  platformIcons = icons;

  constructor(
    private alertService: AlertService,
    private commonService: CommonService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.notification["platformIcon"] =
      this.platformIcons[this.notification.source];
  }

  onSwitchOutlet() {
    if (this.notification.has_outlet_access) {
      // this.openModal(this.switchOutletModal);

      //confirmation
      // You will be redirected to {{ notification?.outlet_name }}. Click continue
      // to proceed

      this.alertService
        .comfirmationAlert(
          "Switch to outlet",
          "Do you wish to switch to " + this.notification?.outlet_name + "?",
          "Switch",
          "Cancel"
        )
        .then((data) => {
          if (data.isConfirmed) {
            this.switchOutlet();
          }
        });
    } else {
      this.openModal(this.switchOutleNoAccesstModal);
    }
  }

  switchOutlet() {
    this.commonService.changeOutlet(this.notification.outlet_id, "Interact");
  }

  openModal(modal) {
    this.modalService.open(modal, {
      size: "md",
    });
  }
}
