import { EventEmitter, Inject, Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { GlobalVariable } from "../app.component";
import { DataService, Response } from "../dataService/data.service";
import { PusherService } from "../services/pusher/pusher.service";
import { ChatUserModel, MessageModel } from "./interact.model";
// import { last } from '@angular/router/src/utils/collection';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CommonService } from "../services/common.service";
import { FeaturesByPlanService } from "../services/features-by-plan/features-by-plan.service";
import {
  InteractStructure,
  INTERACT_SUBFEATURES,
} from "../services/features-by-plan/interface/interact";

@Injectable({
  providedIn: "root",
})
export class InteractService {
  outlet_id = this.storage.get("outlet_id");
  private BASE_URL = GlobalVariable;
  activeChat = new EventEmitter<{ chatUser: ChatUserModel; event: string }>();
  scrollChat = new EventEmitter<void>();
  chatUsersUpdated = new EventEmitter<ChatUserModel[]>();
  permissions = new Subject<any>();
  ticketPermissions = new Subject<any>();
  details = new Subject<any>();
  conversationEmpty = new Subject<any>();
  interactAccounts: BehaviorSubject<{ data: any; code: number }> =
    new BehaviorSubject({ data: undefined, code: 300 });
  chatUsers: ChatUserModel[] = [];
  totalPendingMessages: number = 20;
  // messageStatus: Subject<any> = new ReplaySubject<any>();
  // newMessage: Subject<any> = new ReplaySubject<any>();
  messageStatus: EventEmitter<any> = new EventEmitter<any>();
  newMessage: EventEmitter<any> = new EventEmitter<any>();
  interaction_count = new Subject<any>();
  updateLastMessage: Subject<{ message: MessageModel; interactId: string }> =
    new Subject<{ message: MessageModel; interactId: string }>();
  updateWhatsappMessage: Subject<ChatUserModel[]> = new Subject<
    ChatUserModel[]
  >();
  setIsBlockedCondition: Subject<any> = new Subject<any>();
  updateUserName: Subject<any> = new Subject<any>();
  isFacebookAndWhatsappNotConnect = new Subject<any>();
  chatLoading$ = new Subject<any>();

  closeModal = new EventEmitter<void>();
  interactFeature: InteractStructure = {
    gmb_website_widget: [
      "feature_interact",
      INTERACT_SUBFEATURES.gmb_website_widget,
      "is_applicable",
    ],
    insights: [
      "feature_interact",
      INTERACT_SUBFEATURES.insights,
      "is_applicable",
    ],
    interact_report: [
      "feature_interact",
      INTERACT_SUBFEATURES.interact_report,
      "is_applicable",
    ],
    quick_reply: [
      "feature_interact",
      INTERACT_SUBFEATURES.quick_reply,
      "is_applicable",
    ],
    status: ["feature_interact", INTERACT_SUBFEATURES.status, "is_applicable"],
    conversation_category: [
      "feature_interact",
      INTERACT_SUBFEATURES.conversation_category,
      "is_applicable",
    ],
    conversation_category_value: [
      "feature_interact",
      INTERACT_SUBFEATURES.conversation_category,
      "values",
      ["setting", "tag"],
    ],
    central_waba: [
      "feature_interact",
      INTERACT_SUBFEATURES.central_waba,
      "is_applicable",
    ],
    central_waba_value: [
      "feature_interact",
      INTERACT_SUBFEATURES.central_waba,
      "values",
      ["central", "location"],
    ],
    conversation_chat_template: [
      "feature_interact",
      INTERACT_SUBFEATURES.conversation_chat_template,
      "is_applicable",
    ],
    conversation_chat_template_count: [
      "feature_interact",
      INTERACT_SUBFEATURES.conversation_chat_template,
      "count",
    ],
  };
  interact_details: any;
  showAlert: boolean;
  warningText: string;
  creditsExhausted: boolean;
  loadingInteractDetail$ = new Subject<any>();
  appointment: any;
  review: any;
  shopnow: any;
  isGif: any;
  settingLoaded: boolean;
  // closeModal = new EventEmitter()
  // varSub : Subscription;
  closeInteractModal = new EventEmitter<any>();
  closeInteractOpenModal = new EventEmitter<any>();
  updateChildToParentInteract = new EventEmitter<any>();
  conversationEmptyWithFilter = new Subject<any>();
  public conversationDetailPageNumber = "1";
  public conversationDetailSearchText = "";
  closeSearchModal = new Subject<any>();

  constructor(
    private modalService: NgbModal,
    private httpService: DataService,
    private pusherService: PusherService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private commonservice: CommonService,
    private featuresByPlan: FeaturesByPlanService
  ) {
    this.pusherService.channel.bind("interact", this.handleWsEvent.bind(this));
    this.getInteractAccounts();
  }

  async isFeatureApplicable(subFeature: keyof InteractStructure) {
    let subFeatureDetails: any = [...this.interactFeature[subFeature]];
    let response = (
      await this.featuresByPlan.getFeature(subFeatureDetails)
    ).isApplicable();
    return response;
  }

  async checkFeatureValue(subFeature: keyof InteractStructure) {
    let subFeatureDetails: any = [...this.interactFeature[subFeature]];
    let response = (
      await this.featuresByPlan.getFeature(subFeatureDetails)
    ).getDataValue();
    return response;
  }

  isInsightsAllowed() {
    return this.featuresByPlan.getFeature(this.interactFeature.insights);
  }

  getWhatsappStatus() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_INTERACT_WHATSAPP_STATUS(this.outlet_id),
    });
  }

  updateWhatsappStatus(status) {
    return this.httpService.putRequest({
      customApiUrl: this.BASE_URL.UPDATE_INTERACT_WHATSAPP_STATUS(
        this.outlet_id
      ),
      data: status,
    });
  }

  getInteractAccounts() {
    return this.httpService
      .getRequest({
        customApiUrl: this.BASE_URL.ACCOUNTS_LIST_V2(this.outlet_id),
      })
      .subscribe((data: any) => this.interactAccounts.next(data));
  }

  handleWsEvent(data) {
    // console.log("data in websocket", data);
    // this.notification_count.next(data)
    if (data.notification_type === "MESSAGE_STATUS") {
      this.messageStatus.next(data);
    }

    // this.interaction_count.next(data);

    if (data.notification_type === "NEW_MESSAGE") {
      console.log(data);
      this.newMessage.next(data);
    }
  }

  getChattersApiCall(
    page: string,
    size: string,
    order: string,
    source: string[] = [],
    isUnreadOnly: boolean = false,
    conversationTags: string[] = [],
    contactTags: string[] = []
  ) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.CONVERSATIONS_LIST(this.outlet_id),
      page: page,
      size: size,
      order: order,
      source: source.map((_) => _.toLowerCase()).join(",") || null,
      is_unread: isUnreadOnly,
      conversation_tag: conversationTags.join(",") || null,
      contact_tag: contactTags.join(",") || null,
    });
  }
  createChatUser(el) {
    return new ChatUserModel(
      el.interact_id,
      el.contact_id,
      el.name,
      el.contact_number,
      el.last_conversation_id,
      el.source,
      el.last_message,
      el.last_message_time,
      el.total_messages,
      el.unread_messages,
      el.media,
      el.media_type,
      false,
      icons[el.source],
      el.is_blocked
    );
  }
  createMessage(el) {
    return new MessageModel(
      el.conversation_id,
      el.message,
      el.status,
      el.sender_type,
      el.media,
      el.media_type,
      el.created_at,
      el.is_read,
      el.media_id,
      el.ticket_id,
      el.ticket_number,
      el.quick_reply_buttons,
      el.is_story_mention,
      el.is_story_reply,
      el.is_reply_to,
      el.tag_team_member
    );
  }

  getChatters(
    page: string,
    size: string,
    order: string,
    source: string[] = [],
    isUnreadOnly: boolean = false,
    conversationTags: string[] = [],
    contactTags: string[] = []
  ) {
    let response = this.getChattersApiCall(
      page,
      size,
      order,
      source,
      isUnreadOnly,
      conversationTags,
      contactTags
    );
    response.subscribe((data: any) => {
      this.chatUsers = [];
      this.permissions.next(data.data.permissions);
      this.ticketPermissions.next(data.data.ticket_permissions);
      this.details.next(data.data.details);
      if (page === "1") {
        if (!(isUnreadOnly || conversationTags.length || contactTags.length)) {
          // basically do not send this event while filters is active
          this.conversationEmpty.next(data.data.conversations.length == 0);
        }
        this.conversationEmptyWithFilter.next(
          data.data.conversations.length == 0
        );
        // this.conversationEmpty.next(data.data.conversations.length == 0);
      }
      this.chatUsers.push(...data.data.conversations.map(this.createChatUser));
      this.chatUsersUpdated.emit(this.chatUsers);

      // if (data.data.last_conversations.length) {
      //   this.chatUsers[0].userChat.unshift(...data.data.last_conversations.map(this.createMessage));
      // }
      // this.activeChat.emit({chatUser: this.chatUsers[0], event: 'firstLoad'});
    });
  }

  conversationDetailApiCall(
    page: string,
    size: string,
    order: string,
    source: string,
    interactId: string,
    keyword?: string
  ) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.CONVERSATION_DETAIL(this.outlet_id),
      page,
      size,
      order,
      source,
      interact_id: interactId,
      keyword,
    });
  }

  getConversationDetail(
    page: string,
    size: string,
    order: string,
    source: string,
    interactId: string,
    event?: string,
    keyword?: string
  ) {
    return new Promise((resolve, reject) => {
      if (this.totalPendingMessages > 0) {
        this.conversationDetailPageNumber = page;
        if (keyword?.length) {
          this.conversationDetailSearchText = keyword;
        } else {
          // this.conversationDetailSearchText = "";
          this.conversationDetailSearchText = null;
        }
        let response = this.conversationDetailApiCall(
          page,
          size,
          order,
          source,
          interactId,
          keyword
        );
        let chatUser: any;
        response.subscribe(
          (data: any) => {
            let conversations = data.data.conversations;
            // console.log('conversations',conversations);
            console.log("338 --->>>>", conversations);
            chatUser = this.getUser(interactId);
            // chatUser.unreadMessages = data.data.contact_detail?.unread_messages;
            if (chatUser) {
              chatUser.unreadMessages =
                data.data.contact_detail?.unread_messages;
            }

            if (!chatUser) {
              chatUser = this.createChatUser(data.data.contact_detail);
            }
            chatUser.canSendMessage = Boolean(data.data.is_allow_message);
            this.totalPendingMessages = conversations.length;

            if (conversations.length) {
              if (keyword?.length || page === "1") {
                chatUser.userChat = [];
              }
              chatUser.userChat.unshift(
                ...conversations.map(this.createMessage)
              );
              resolve(true);
            }
            //  else {
            //   if (keyword?.length) {
            //     chatUser.userChat = [];
            //   }
            //   chatUser.userChat.unshift(
            //     ...conversations.map(this.createMessage)
            //   );
            //   resolve(true);
            // }
          },
          (err) => {
            reject(true);
          },
          () => {
            console.log("374 --->>>>", chatUser);
            this.activeChat.emit({ chatUser: chatUser, event: event });
            this.commonservice.interactUnreadCount(true);
            this.chatLoading(false);
          }
        );
      } else {
        resolve(false);
      }
    });
  }

  getLastConversation(interact_id: string) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_LAST_CONVERSATION(
        this.outlet_id,
        interact_id
      ),
    });
  }

  chatLoading(status: boolean) {
    this.chatLoading$.next(status);
  }
  loadingInteractDetail = (status: boolean) =>
    this.loadingInteractDetail$.next(status);

  getChatOnClick(source: string, interactId: string) {
    this.totalPendingMessages = 20;
    // let chatUser = this.getUser(interactId);
    // if (chatUser.userChat.length) {
    //   this.activeChat.emit({chatUser: chatUser, event: "click"});
    // } else {
    this.getConversationDetail("1", "20", "DESC", source, interactId, "click");
    // }
  }
  // emitChatUsers() {
  //   this.chatUsersUpdated.emit(this.chatUsers);
  // }
  getUser(interactId: string) {
    return this.chatUsers.find(
      (chatUser) => chatUser.interactId === interactId
    );
  }

  searchChatUsers(search: string) {
    if (search !== "") {
      search = search.toLowerCase();
      let chatUsers = this.chatUsers.filter((chatUser) => {
        if (chatUser.name === null) {
          return chatUser.contactNumber.indexOf(search) > -1;
        } else {
          return (
            chatUser.name.toLowerCase().indexOf(search) > -1 ||
            (chatUser.contactNumber &&
              chatUser.contactNumber.indexOf(search) > -1)
          );
        }
      });
      this.chatUsersUpdated.emit(chatUsers);
      return;
    }
    console.log("is called");

    this.chatUsersUpdated.emit(this.chatUsers);
  }

  sendMessageApiCall(
    source: string,
    interact_id: string,
    message: string,
    media: string,
    media_id: string,
    is_appointment: boolean = false,
    is_shopnow: boolean = false
  ) {
    return this.httpService.postjson(
      this.BASE_URL.SEND_MESSAGE(this.outlet_id),
      {
        source,
        interact_id,
        message,
        media,
        media_id,
        ...(is_appointment && { is_appointment }),
        ...(is_shopnow && { is_shopnow }),
      }
    );
  }

  sendMessage(activeChat: ChatUserModel, message: MessageModel) {
    // console.log(message);

    // if (activeChat.source === 'WHATSAPP') {
    return this.sendMessageApiCall(
      activeChat.source,
      activeChat.interactId,
      message.message,
      message.media,
      message.mediaId
    );
    // let response = this.httpService.postjson(this.BASE_URL.SEND_MESSAGE, {
    //   source: activeChat.source,
    //   interact_id: activeChat.interactId,
    //   message: message.message,
    //   media: message.media
    // });
    // return response;

    // response.subscribe((data: any) => {
    //   console.log("data", data);
    // },
    // error => {
    //   console.log("error", error);
    //   this.toastrService.error(error.error.message);
    // })
    // }
  }

  getFileUrl(fileToUpload: File) {
    let fileUrl;
    //  let is_media = false;
    let formData = new FormData();
    formData.append("upload_location", "WHATSAPP");

    if (fileToUpload.type.match(/audio\/*/)) {
      formData.append("is_media", "True");
      formData.append("media", fileToUpload, fileToUpload.name);
    } else {
      formData.append("social_image", fileToUpload, fileToUpload.name);
      formData.append("is_media", "False");
    }

    console.log(fileToUpload);
    //  var obj = {
    //     'social_image': fileToUpload, fileToUpload.name,
    //     'upload_location' :'WHATSAPP' ,
    //     'is_media' : false
    //   }

    const data_service = this.httpService
      .postRequest({
        customApiUrl: this.BASE_URL.SOCIAL_UPLOAD(this.outlet_id),
        data: formData,
      })
      .toPromise();
    console.log("data_service", data_service);
    return data_service.then((response: any) => {
      // console.log("response", response);
      if (response.code === 201 || response.code === 200) {
        fileUrl = {
          url: response.data.url,
          media_id: response.data.media_id,
          media_type: response.data.kind_of_file,
        };
        return fileUrl;
        console.log("fileUrl", fileUrl);
      }
    });
  }

  getVideoFileUrl(fileToUpload: File) {
    let fileUrl;
    let fileId;
    let formData = new FormData();
    console.log(fileToUpload.type);

    formData.append("social_video", fileToUpload, fileToUpload.name);
    formData.append("upload_location", "WHATSAPP");
    // formData.append('is_media','False');

    const data_service = this.httpService
      .postFormData(this.BASE_URL.SOCIAL_VIDEO_UPLOAD(this.outlet_id), formData)
      .toPromise();
    console.log("data_service", data_service);
    return data_service.then((response: any) => {
      console.log("response", response);
      if (response.code === 201 || response.code === 200) {
        fileUrl = {
          url: response.data.url,
          media_id: response.data.media_id,
        };
        return fileUrl;
        console.log("fileUrl", fileUrl);
      }
    });
  }

  closeChatModal(chat) {
    this.closeModal.emit(chat);
  }

  scrollChatContainter() {
    // console.log(this.scrollChat , "scrollchat" , this.chatUsers);
    this.scrollChat.emit();

    // this.chatUsersUpdated.emit(this.chatUsers);
  }
  isMobile() {
    return window.innerWidth < 750;
  }
  getInteractDetails() {
    this.details.subscribe((data: any) => {
      this.interact_details = data;
      let reviewLimit = this.interact_details["review_limit"];
      let reviewUsage = this.interact_details["review_usage"];
      let outletStatus = this.interact_details["outlet_status"];
      // let outletStatus = 'Active';
      let subscriptionEndDate = this.interact_details["subscription_end_date"];
      let support_phone = this.interact_details["support_phone"];

      if (outletStatus == "Active") {
        if (reviewLimit) {
          let creditsUsed = (Number(reviewUsage) / Number(reviewLimit)) * 100;
          // let creditsUsed = 101;
          console.log(creditsUsed);
          if (creditsUsed >= 90 && creditsUsed < 100) {
            let creditsLeft = reviewLimit - reviewUsage;
            this.showAlert = true;
            this.warningText = `You have only ${creditsLeft} reviews available till you exhaust your account. Please upgrade to enjoy uninterrupted services - Call ${support_phone} or email support@zceppa.com to upgrade`;
            // console.log(this.warningText);
          }
          if (creditsUsed >= 100) {
            this.creditsExhausted = true;
            this.showAlert = true;
            this.warningText = `Ooops it looks like your account needs an upgrade to continue using Zceppa. Call ${support_phone} or email support@zceppa.com to upgrade`;
          }
          if (
            moment(new Date()).isAfter(
              moment(subscriptionEndDate, "YYYY-MM-DD"),
              "date"
            )
          ) {
            this.showAlert = true;
            this.warningText = `Ooops it looks like your account needs an upgrade to continue using Zceppa. Call ${support_phone} or email support@zceppa.com to upgrade`;
          }
        }
      } else if (outletStatus == "Trial") {
        // console.log('zf',moment(new Date()).isAfter(moment(subscriptionEndDate, 'YYYY-MM-DD'), 'date'));

        if (
          moment(new Date()).isAfter(
            moment(subscriptionEndDate, "YYYY-MM-DD"),
            "date"
          )
        ) {
          this.creditsExhausted = true; // disabled create oredrs button
          this.showAlert = true;
          this.warningText = `Hi, looks like your trial has already expired. Please reach out to us to activate your Zceppa account at support@zceppa.com or call ${support_phone}`;
        }
      }
      this.loadingInteractDetail(false);
    });
  }
  getSettings() {
    let res: any = this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_SETTINGS(this.outlet_id),
    });
    res.subscribe((data: any) => {
      // console.log("data", data);
      if (data.code === 200) {
        this.settingLoaded = true;
        this.appointment = data.data.appointment;
        this.review = data.data.review;
        this.shopnow = data.data.mystore;
        this.isGif = data.data.is_gif;
      }
    });
    return res;
  }
  onCloseChatWindow() {
    this.closeInteractModal.emit();
    this.commonservice.hideShowByMobile(false);
  }

  getInteractDashboardMatric() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_DASHBOARD_METRIC(this.outlet_id),
    });
  }

  getInteractMomChartData(source: string = null) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_MOM_CHART_DATA(this.outlet_id),
      source: source,
    });
  }

  getInteractDashboardUnreadConversation() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_DASHBOARD_UNREAD_MESSAGE(
        this.outlet_id
      ),
    });
  }

  getInteractInsights(filters: { start?: string; end?: string }) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_INSIGHT(this.outlet_id),
      start: filters?.start,
      end: filters?.end,
    });
  }

  public getAllWappTemplates(filters: {
    download?: string;
    page: string;
    size: string;
    keyword?: string | null;
    sort_order?: string;
    sort_by?: string;
    status?: string | null;
    template_category?: string | null;
    template_type?: string | null;
    start?: string | null;
    end?: string | null;
  }): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_WHATSAPP_TEMPLATE_LIST(this.outlet_id),
      download: filters.download,
      page: filters.page,
      size: filters.size,
      keyword: filters.keyword,
      sort_order: filters.sort_order,
      sort_by: filters.sort_by,
      status: filters?.status,
      template_category: filters?.template_category,
      template_type: filters?.template_type,
      start: filters?.start,
      end: filters?.end,
    });
  }

  public getDefaultInteractQRTemplate() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.INTERACT_GET_DEFAULT_QR_TEMPLATE(
        this.outlet_id
      ),
    });
  }

  public syncWappTemplates() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.SYNC_WHATSAPP_TEMPLATES(this.outlet_id),
    });
  }

  public markAsUnreadConversation(sourcePlatform, conversationID) {
    return this.httpService.putRequest({
      customApiUrl: this.BASE_URL.CONVERSATION_MARK_AS_READ(this.outlet_id),
      source: sourcePlatform,
      interact_id: conversationID,
    });
  }

  public deleteInteractCategory(categoryId: string): Observable<Response> {
    return this.httpService.deleteRequest({
      customApiUrl: this.BASE_URL.INTERACT_DELETE_CATEGORY(
        this.outlet_id,
        categoryId
      ),
    });
  }

  public deleteInteractChatTemplate(categoryId: string): Observable<Response> {
    return this.httpService.deleteRequest({
      customApiUrl: this.BASE_URL.INTERACT_DELETE_CHAT_TEMPLATE(
        this.outlet_id,
        categoryId
      ),
    });
  }

  public getAllTags(): Observable<Response> {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_INTERACT_CONTACT_TAGS(this.outlet_id),
    });
  }

  public updateInteractTags(
    payload: any,
    interactId: string,
    source: string
  ): Observable<Response> {
    return this.httpService.postRequest({
      customApiUrl: this.BASE_URL.UPDATE_INTERACT_CONTACT_TAGS(
        this.outlet_id,
        interactId,
        source
      ),
      data: payload,
    });
  }
}

export const icons = {
  WHATSAPP: "assets/images/social/whatsapp.png",
  FACEBOOK: "assets/images/fb-new.svg",
  ZOMATO: "assets/images/social/zomato.png",
  ZCEPPA: "assets/images/social/zceppa1.png",
  GOOGLE: "assets/images/Google_Messages_logo.svg",
  INSTAGRAM: "assets/images/inst.svg",
};
