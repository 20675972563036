<div class="ui-container h-100">
  <div class="white-bg h-100">
    <div class="d-flex m-0 row-container"
      [ngClass]="{'pdt-150':isMargin, 'pdt-200':isBigMargin}">
      <div class="image-bg-container">
        <img src="assets/images/onboarding/left-circle.svg" class="left-circle" alt="pattern">
        <div class="image-container">
          <img class="product-image" src="assets/images/onboarding/curve-image.png" alt="custom-image"/>
          <div class="bottom-circle-container">
            <img src="assets/images/onboarding/bottom-circle.svg" class="bottom-circle" alt="pattern">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom-circle-container">
      <img src="assets/images/onboarding/bottom-circle.svg" class="bottom-circle" alt="pattern">
    </div> -->
  </div>
</div>

