import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-success-toast-ui",
  templateUrl: "./success-toast-ui.component.html",
  styleUrls: ["./success-toast-ui.component.scss"],
})
export class SuccessToastUiComponent implements OnInit {
  @Input() isVisible: boolean = true;
  @Input() imageUrl = "";
  @Input() headerText = "";
  @Input() descriptionText = "";
  @Output() outputData = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  closeToastMessage() {
    // this.isVisible = false;
    this.outputData.emit(false);
  }
}
