import { Component, Inject, Input, OnInit } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-onboard-random-ui",
  templateUrl: "./onboard-random-ui.component.html",
  styleUrls: ["./onboard-random-ui.component.scss"],
})
export class OnboardRandomUiComponent implements OnInit {
  @Input() isMargin: boolean = false;
  @Input() isBigMargin: boolean = false;
  @Input() isBottomCircleReq: boolean = true;
  onboardArray = [];
  selectedData = null;
  attempt: number = 0;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {}

  ngOnInit(): void {
    this.getData();
  }

  public async getData() {
    let jsonData = await this.storage.get("onboard_json");
    if (jsonData) {
      this.onboardArray = jsonData.onboarding_product_details;
      if (this.onboardArray.length) {
        this.selectedData =
          this.onboardArray[
            Math.floor(Math.random() * this.onboardArray.length)
          ];
      }
    } else {
      if (this.attempt < 5) {
        setTimeout(() => {
          this.attempt++;
          this.getData();
        }, 1000);
      }
    }
  }
}
