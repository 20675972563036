import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbCarouselConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as introJs from "intro.js/intro.js";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { GlobalVariable } from "../../app.component";
import { DataService } from "../../dataService/data.service";
import { MultiLocationService } from "../../multi-location/multi-location.service";
import { CommonService } from "../../services/common.service";
import { OtpService } from "../services/otp.service";
import { Response } from "../shared/models";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  @ViewChild("phone_numbers") mob_numbers;
  @ViewChild("passwords") log_pwd: ElementRef;
  introJS = introJs();
  private BASE_URL = GlobalVariable;
  public form: FormGroup;
  onLoad = true;
  phoneNumber;
  otp: number;
  disableLogin = true;
  disableSubmit = true;
  data_array: any[] = [];
  password: string;
  show_password: boolean = true;
  forgotPassicon: boolean = false;
  accessApiLoading: boolean = false;
  show_error_message: String;
  user_name: string = this.cookieService.get("user_name");
  show_message: boolean = false;
  outlet_name: string;
  wrongPasswordAttempt: number = 0;
  public showPass = false;
  public type = "password";

  landingMenu: any;
  attemptsLeft: number;
  previousPhoneNumber: number = null;
  isPasswordAttempt: boolean;
  showLoginBtnLoader = false;

  errorUiVisible: boolean = false;
  errorUiMsg: string = "";

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private otpService: OtpService,
    private spinner: NgxUiLoaderService,
    private toastrService: ToastrService,
    private HttpService: DataService,
    private commonService: CommonService,
    private cookieService: CookieService,
    private pusherService: PusherService,
    private config: NgbCarouselConfig,
    private route: ActivatedRoute,
    private multiLocationService: MultiLocationService
  ) {
    this.config.interval = 3000;
  }

  ngOnInit() {
    if (this.user_name !== "") {
      const num = this.cookieService.get("user_name");
      // this.phoneNumber = +num;
      this.phoneNumber = num;
      this.password = this.cookieService.get("password");
    }
    this.form = this.fb.group({
      uname: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])],
    });

    this.commonService.otp_mobile_number.subscribe((res) => {
      if (res !== null) {
        this.phoneNumber = res;
        this.forgotPassicon = false;
        this.onLoad = false;
        this.show_password = false;
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      if (params.access) {
        this.accessApiLoading = true;
        let authDetails = JSON.parse(params.access);
        this.storage.set("access_token", authDetails.accessToken);
        this.storage.set("outlet_id", authDetails.outletId);
        this.hybridAppRedirectToWebPages(authDetails);
        return;
      }
      let qParams = { ...params.keys, ...params };
      let phoneNumberParam = Object.keys(qParams)
        .filter((key) => qParams[key] !== "")
        .reduce((obj: any, key: string) => {
          obj[key] = qParams[key];
          return obj;
        }, {});
      if (Object.keys(phoneNumberParam).length) {
        let phoneNumber = phoneNumberParam?.phone_number;
        let emailId = phoneNumberParam?.email_id;
        if (emailId) {
          this.phoneNumber = emailId;
        } else {
          this.phoneNumber = phoneNumber;
        }
        this.onOtpSent();
      }
    });
  }
  showPassword(param) {
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }
  rememeberMe(e) {
    if (e.target.checked === true) {
      if (this.isMobileNumber(this.phoneNumber)) {
        this.cookieService.set("user_name", this.phoneNumber.toString());
      } else {
        this.cookieService.set("user_name", this.phoneNumber);
      }
      this.cookieService.set("password", this.password);
      this.cookieService.set("remember", e.target.checked);
    } else {
      this.cookieService.deleteAll();
    }
  }

  onOtpSent() {
    if (this.phoneNumber === null || this.phoneNumber === undefined) {
      this.showError("Please enter phone number");
      return;
    }

    // basically allow email and phone login
    if (this.isEmail(this.phoneNumber)) {
      if (
        this.phoneNumber.includes("@") &&
        /\..{2,}$/.test(this.phoneNumber.split("@")[1])
      ) {
        console.log("183 eif called");
      } else {
        return;
      }

      // Call your authentication service with email
    } else if (this.isMobileNumber(this.phoneNumber)) {
      console.log("Login with mobile number:", this.phoneNumber);
      // Call your authentication service with mobile number
    } else {
      // this.showError("Invalid email or mobile number");
      return;
    }

    this.otp = null;
    let res: Response;
    this.forgotPassicon = true;
    // this.phoneNumber = Number(this.phoneNumber.toString().trim());
    this.phoneNumber = this.phoneNumber.toString().trim();
    this.otpService.hasPasswordSetCheck(this.phoneNumber).subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        this.spinner.stop();
      },
      () => {
        if (res.code === 200 || res.code === 201) {
          if (res.val["has_password"] === false) {
            if (!res.val.is_otp_verified) {
              this.goToEmailOtpScreen(
                res.val.email_id,
                res.val.phone_number,
                false
              );
            } else {
              //go to create password
              this.router.navigate(["/signin/create-password"], {
                queryParams: {
                  type: this.BASE_URL.onboardingStatus.type.admin,
                  user_email: res.val.email_id,
                  mobile: this.phoneNumber + "",
                },
              });
            }
          } else {
            this.onLoad = true;
            this.show_password = true;
          }
        }
        // else {
        //   this.showError(res.message);
        // }
        this.spinner.stop();
      }
    );
  }

  goToMobileOtpScreen(isForgetPassword = false) {
    if (this.phoneNumber) {
      if (isForgetPassword) {
        this.router.navigate(["/signin/mobile-otp"], {
          queryParams: { phone: this.phoneNumber, action: "forget-password" },
        });
      } else {
        this.router.navigate(["/signin/mobile-otp"], {
          queryParams: { phone: this.phoneNumber },
        });
      }
    }
  }

  goToEmailOtpScreen(emailId, phone, isForgetPassword = false) {
    if (isForgetPassword) {
      if (this.phoneNumber) {
        // here we do not know that user entered mobile or email
        let loginType = null;
        if (this.isEmail(this.phoneNumber)) {
          if (
            this.phoneNumber.includes("@") &&
            /\..{2,}$/.test(this.phoneNumber.split("@")[1])
          ) {
            loginType = this.BASE_URL.loginType.email;
          } else {
            return;
          }
        } else if (this.isMobileNumber(this.phoneNumber)) {
          console.log("Login with mobile number:", this.phoneNumber);
          loginType = this.BASE_URL.loginType.mobile;
        } else {
          // this.showError("Invalid email or mobile number");
          return;
        }

        this.router.navigate(["/signin/verify-email"], {
          queryParams: {
            type: this.BASE_URL.onboardingStatus.type.admin,
            user_email: this.phoneNumber + "",
            phone: phone,
            action: "forget-password",
            loginType: loginType,
          },
        });
      }
    } else {
      this.router.navigate(["/signin/verify-email"], {
        queryParams: {
          type: this.BASE_URL.onboardingStatus.type.admin,
          user_email: emailId,
          phone: phone,
        },
      });
    }
  }

  onChangeNumber() {
    this.onLoad = true;
    this.phoneNumber = null;
    this.data_array = [];
    this.show_password = false;
  }
  showError(msg: string) {
    this.toastrService.error(msg, "", {
      timeOut: 1000,
    });
  }
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text/plain");
    let value = pastedText.replace(/\D+/g, "");
    this.phoneNumber = Number(value);
    event.preventDefault();
  }

  phoneNumberChange(e) {
    if (e.which !== 17) {
      this.disableLogin =
        this.phoneNumber && this.phoneNumber.toString().length ? false : true;
      if (this.phoneNumber !== undefined) {
        if (this.phoneNumber.toString().length >= 8 || e.keyCode === 13) {
          this.onOtpSent();
        }
      }
    }
  }

  setMultilocRedirection() {
    let businessLandingMenu = this.multiLocationService.getLandingMenu;
    if (businessLandingMenu == "Unified Reviews") {
      let switchToMultilocView = `/business/reviews`;
      return switchToMultilocView;
    }
    let switchToMultilocView = `/business/dashboard`;
    return switchToMultilocView;
  }

  /**
   *  Password submit
   */
  submitPassword(e) {
    if (e.keyCode === 13) {
      this.onPasswordSubmit();
    }
  }

  /**
   * On password submit
   *
   * @memberof SigninComponent
   */
  onPasswordSubmit() {
    //this.spinner.start();
    let res: Response;
    const remember: string = this.cookieService.get("remember");
    if (remember === "true") {
      this.cookieService.set("user_name", this.phoneNumber.toString());
      this.cookieService.set("password", this.password);
    }
    this.showLoginBtnLoader = true;
    // now we submitted/ resubmitted so let api decide response so meanwhile clear error
    this.errorUiVisible = false;
    this.errorUiMsg = "";

    // show error message for 400, 404 and 206.
    // use toastr error layout for error and success toastr layout for success

    this.otpService
      .loginUser(this.phoneNumber.toString(), this.password)
      .subscribe(
        (data: Response) => {
          res = data;
        },
        (error) => {
          this.spinner.stop();
          if (this.previousPhoneNumber == null) {
            this.previousPhoneNumber = this.phoneNumber;
          }
          if (
            this.previousPhoneNumber !== null &&
            this.previousPhoneNumber !== this.phoneNumber
          ) {
            this.wrongPasswordAttempt = 0;
            this.previousPhoneNumber = null;
          }
          this.wrongPasswordAttempt += 1;
          if (this.wrongPasswordAttempt == 8) {
            this.show_error_message =
              "Incorrect password might lock your account try Forgot Password";
            this.errorUiVisible = true;
            this.errorUiMsg =
              "Incorrect password might lock your account try Forgot Password";
            return;
          }

          if (this.wrongPasswordAttempt == 10) {
            this.lockAccount();
            return;
          }
          this.show_error_message = error.error.message;
          // this.showError(error.error.message);

          this.errorUiVisible = true;
          this.errorUiMsg = error.error.message;
          this.showLoginBtnLoader = false;
        },
        () => {
          // this.spinner.stop();
          if (res && res.code > 400) {
            this.show_error_message = res.message;
            this.errorUiVisible = true;
            this.errorUiMsg = res.message;
          } else if ((res && res.code === 201) || res.code === 200) {
            this.errorUiVisible = false;
            this.errorUiMsg = "";
            this.storage.set("access_token", res.token.tokens.access_token);
            this.storage.set("refresh_token", res.token.tokens.refresh_token);
            this.storage.set("is_business_view", res.token.is_business_view);
            this.storage.set("business_id", res.token.business_id);
            this.storage.set("business_name", res.token.business_name);
            this.storage.set("is_outlet_view", res.token.is_outlet_view);
            this.storage.set("user_id", res.token.user_id);
            this.storage.set("role", res.token.role);
            // this.onLoad = false;
            // this.show_password = false;
            let outlet_id = res.token.outlet_id;
            this.storage.set("outlet_id", res.token.outlet_id);
            if (outlet_id) {
              if (res.token.is_outlet_view) {
                const roleInfo = this.HttpService.getRequest({
                  customApiUrl: this.BASE_URL.USER_PROFILE(outlet_id),
                });
                roleInfo.subscribe((data) => {
                  this.storage.set("role", data.data["role"]);
                });
              }
            }

            if (res.token.is_business_view) {
              this.multiLocationService.getBusinessMenuAPI();
            }

            setTimeout(() => {
              if (res.token.is_business_view && !res.token.is_outlet_view) {
                let businessRedirectTo = this.setMultilocRedirection();
                this.router.navigate([`${businessRedirectTo}`]);
                this.onLoad = false;
                this.show_password = false;
                this.showLoginBtnLoader = false;
              } else {
                this.onPageLoadRouting();
              }
            }, 1000);
          } else {
            if (res.code == 206) {
              // this.showError(res.message);
              this.errorUiVisible = true;
              this.errorUiMsg = res.message;
              this.showLoginBtnLoader = false;
            }
          }
        }
      );
  }

  lockAccount() {
    let res;
    this.otpService.authLock(this.phoneNumber).subscribe(
      (data: Response) => {
        res = data;
        this.show_error_message =
          "Your account is locked. Please write to support@zceppa.com to unlock";
        this.errorUiVisible = true;
        this.errorUiMsg =
          "Your account is locked. Please write to support@zceppa.com to unlock";
      },
      (error) => {
        this.spinner.stop();
        this.errorUiVisible = true;
        this.errorUiMsg = error.error.message;
      },
      () => {}
    );
  }

  onPageLoadRouting() {
    let res: Response;
    let outlet_id = this.storage.get("outlet_id");
    const accessInfo = this.HttpService.get(
      this.BASE_URL.ACCESS_INFO(outlet_id)
    );
    accessInfo.subscribe(
      (data: any) => {
        res = data;
        if (res.code === 200) {
          this.storage.set("access", data.data);
          let is_business_view = this.storage.get("is_business_view");
          if (is_business_view) {
            this.multiLocationService.getBusinessMenuAPI();
          }
        }
      },
      (error) => {
        console.log(error);
        this.showLoginBtnLoader = false;
      },
      () => {
        this.commonService.setDashboardRedirect();
        this.pusherService.setPusherChannel();
        this.onLoad = false;
        this.show_password = false;
        this.showLoginBtnLoader = false;
      }
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  hybridAppRedirectToWebPages(authDetails) {
    let outlet_id = this.storage.get("outlet_id");
    const accessInfo = this.HttpService.get(
      this.BASE_URL.ACCESS_INFO(outlet_id)
    );
    accessInfo.subscribe(
      (data: any) => {
        console.log(data);

        let res = data;
        if (res.code === 200) {
          this.storage.set("access", data.data);
          this.storage.set("is_outlet_view", true);
          let is_business_view = this.storage.get("is_business_view");
          if (is_business_view) {
            this.multiLocationService.getBusinessMenuAPI();
          }
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.router.navigate([], {});
        this.accessApiLoading = false;
        // enable pusher service after redirection from mobile app
        this.pusherService.setPusherChannel();
        this.router.navigate([`../${authDetails?.redirectTo}`]);
      }
    );
  }

  errorBannerClose(data) {
    this.errorUiVisible = false;
    this.errorUiMsg = "";
  }

  // here we will check that user want to login with email or mobile
  isEmail(input: string): boolean {
    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  }

  // here we will check that user want to login with email or mobile
  isMobileNumber(input: string): boolean {
    // Regular expression to validate mobile number
    // const mobileRegex = /^[0-9]{10}$/; // fixed 10 digits
    // const mobileRegex = /^[0-9]{8,}$/; // 8 or more digits
    const mobileRegex = /^[0-9]+$/; // without any digit restriction
    return mobileRegex.test(input);
  }
}
