<ngx-loading-bar></ngx-loading-bar>
<mat-sidenav-container
  class="app dashboard-bg-cover"
  [ngClass]="{
    'mode-boxed': options.boxed,
    'mode-docked': options.docked,
    'theme-winter': options.theme === 'winter',
    'theme-spring': options.theme === 'spring',
    'theme-autumn': options.theme === 'autumn',
    'theme-summer': options.theme === 'summer',
    'theme-eclipse': options.theme === 'eclipse',
    'no-footer': routeOptions?.removeFooter
  }"
>
  <mat-sidenav
    class="sidebar-panel"
    #sidemenu
    id="sidebar-panel"
    [opened]="!isOver()"
    [mode]="isOver() ? 'over' : 'side'"
  >
    <app-sidebar
      (messageEvent)="receiveMessage($event)"
      (toggleFullscreen)="toggleFullscreen()"
      class="bg-cover"
    >
    </app-sidebar>
  </mat-sidenav>
  <app-header
    (toggleSidenav)="sidemenu.open()"
  ></app-header>
  <!-- apply scroll for all pages with mobile view except interact chat page  -->
  <div
    [class.main-content]="!mobile_chat_display || !isInteractChatWindow"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollUpDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onReviewScroll()"
    (scrolledUp)="onUp()"
    [scrollWindow]="false"
  >
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="mobile_chat_display">
    <app-chat></app-chat>
  </div>
</mat-sidenav-container>

<!-- [ngClass]="current_url === '/reviews' ? 'main-content-review' : 'main-content'" -->
