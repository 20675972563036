import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import Pusher from "pusher-js";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PusherService {
  // pusher: any;
  private pusher: Pusher;
  channel;
  businessChannel;
  outlet_id: String =
    this.storage.get("outlet_id") == null
      ? "my_channel"
      : this.storage.get("outlet_id");
  businessUserChannel: any;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      encrypted: true,
      flashTLS: true,
    });
    this.setPusherChannel();
  }

  // subscribeToOutlet(PreviousOutletID: string) {
  //   this.pusher.unsubscribe(PreviousOutletID);
  //   this.pusher.unbind_all();
  //   var channelOutlet = this.storage.get("outlet_id");
  //   this.pusher.subscribe(channelOutlet);
  // }

  public setPusherChannel() {
    this._setOutletId();
    this._setBusinessId();
    this._setBusinessUserId();
  }
  private _setOutletId() {
    const outlet_id = this.storage.get("outlet_id");
    this.channel = outlet_id && this.pusher.subscribe(outlet_id);
  }
  private _setBusinessId() {
    this.businessChannel =
      this.business_id && this.pusher.subscribe(this.business_id);
  }

  private _setBusinessUserId() {
    this.businessUserChannel =
      this.business_id &&
      this.user_id &&
      this.pusher.subscribe(this.businessUserId);
  }

  get businessUserId(): string {
    return `${this.business_id}_${this.user_id}` || "";
  }

  get business_id() {
    return this.storage.get("business_id");
  }

  get user_id() {
    return this.storage.get("user_id");
  }
}
