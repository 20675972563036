<ul class="navbar-nav notify_dropdown" style="cursor: pointer">
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <div class="new-message-notification-wrap">
      <div class="position-relative">
        <img
          src="assets/images/header-bell.svg"
          alt=""
          (click)="isNewNotification = false"
          ngbDropdownToggle
        />
        <span *ngIf="isNewNotification" class="notifydot"></span>
      </div>
    </div>
    <div
      ngbDropdownMenu
      class="notification-dropdown dropdown-menu-right p-0 m-0"
      style="min-width: 350px"
    >
      <perfect-scrollbar style="max-height: 100%">
        <ng-container *ngFor="let notification of notifications">
          <app-business-notification
            [notificationType]="'popup'"
            [notification]="notification"
          >
          </app-business-notification>
        </ng-container>
        <div *ngIf="notifications?.length" class="d-flex dropdown-item">
          <a
            href="javascript:;"
            [routerLink]="['/business/notifications/business-notifications']"
            class="notify_viewmore"
            >View More
          </a>
        </div>
        <div class="d-flex" *ngIf="!notifications?.length">
          <p style="text-align: center; width: 100%; padding: 80px 0px">
            No Notifications Found!
          </p>
        </div>
      </perfect-scrollbar>
    </div>
  </li>
</ul>
