import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { FeaturesByPlanService } from "../services/features-by-plan/features-by-plan.service";
import {
  MyStoreStructure,
  MyStoreSubFeatures,
  MYSTORE_SUBFEATURES,
} from "../services/features-by-plan/interface/myStore";

@Injectable({
  providedIn: "root",
})
export class MyStoreService {
  constructor(private featuresByPlan: FeaturesByPlanService) {}
  product_category = new Subject<any>();
  order_details = new Subject<any>();
  createOrder = new Subject<any>();
  createOrderOnBoard = new Subject<any>();
  mystoreFeature: MyStoreStructure = {
    zceppa_template: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.zceppa_template,
      "is_applicable",
    ],
    custom_template: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.custom_template,
      "is_applicable",
    ],
    auto_review_request: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.auto_review_request,
      "is_applicable",
    ],
    mystore_channel: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.mystore_channel,
      "values",
      ["Email", "SMS", "WhatsApp"],
    ],
    no_of_orders: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.no_of_orders,
      "is_applicable",
    ],
    no_of_products: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.no_of_products,
      "count",
    ],
    order_download_report: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.order_download_report,
      "is_applicable",
    ],
    order_flex_fields: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.order_flex_fields,
      "is_applicable",
    ],
    payment_options: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.payment_options,
      "is_applicable",
    ],
    payment_options_value: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.payment_options,
      "values",
      ["Bank_transfer", "GooglePay", "Paytm", "PhonePe"],
    ],
    product_category: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.product_category,
      "is_applicable",
    ],
    product_download_report: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.product_download_report,
      "is_applicable",
    ],
    product_flex_fields: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.product_flex_fields,
      "is_applicable",
    ],
    product_tag: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.product_tag,
      "is_applicable",
    ],
    shopnow_chat: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.shopnow_chat,
      "is_applicable",
    ],
    shopnow_link: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.shopnow_link,
      "is_applicable",
    ],
    shopnow_theme: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.shopnow_theme,
      "is_applicable",
    ],
    short_url: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.short_url,
      "is_applicable",
    ],
    tag_team_member: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.tag_team_member,
      "is_applicable",
    ],
    welcome_banner: [
      "feature_mystore",
      MYSTORE_SUBFEATURES.welcome_banner,
      "is_applicable",
    ],
  };
  private _totalOrdersCount = new BehaviorSubject<any>(null);
  public totalOrdersCount$ = this._totalOrdersCount.asObservable();
  private _totalProductsCount = new BehaviorSubject<any>(null);
  public totalProductsCount$ = this._totalProductsCount.asObservable();

  setTotalCount(count: number, tab: string) {
    if (tab == "orders") {
      this._totalOrdersCount.next(count);
    } else {
      this._totalProductsCount.next(count);
    }
  }
  async checkIsFeatureApplicable(subFeature: keyof MyStoreSubFeatures) {
    return (
      await this.featuresByPlan.getFeature(this.mystoreFeature[subFeature])
    ).isApplicable();
  }

  async checkFeatureValues(subFeature: keyof MyStoreSubFeatures) {
    let subFeatureDetails: any = [...this.mystoreFeature[subFeature]];
    let response = (
      await this.featuresByPlan.getFeature(subFeatureDetails)
    ).checkValues();
    return response;
  }

  async noOfProducts(productsCount: number) {
    let prodArr: any = [].concat(this.mystoreFeature.no_of_products);
    prodArr.push([productsCount]);
    console.log(prodArr);

    return (await this.featuresByPlan.getFeature(prodArr)).checkCount();
  }
}
