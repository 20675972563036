import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from "ngx-pagination";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CustPaginationComponent } from "./cust-pagination/cust-pagination.component";
import { GrdFilterPipe } from "./filters/grd-filter.pipe";
import { TemplateComponent } from "./templates-section/template/template.component";
import { TemplatesSectionComponent } from "./templates-section/templates-section.component";
import { MultiSelectTemplateSectionComponent } from "./multi-select-template-section/multi-select-template-section.component";

@NgModule({
  declarations: [
    GrdFilterPipe,
    TemplatesSectionComponent,
    TemplateComponent,
    CustPaginationComponent,
    MultiSelectTemplateSectionComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgbModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
  ],
  exports: [
    TemplatesSectionComponent,
    TemplateComponent,
    CustPaginationComponent,
    MultiSelectTemplateSectionComponent,
  ],
})
export class TemplatesModule {}
