import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CommonService } from "../../../app/services/common.service";
import { GlobalVariable } from "../../app.component";
import { DataService, Response } from "../../dataService/data.service";

@Component({
  selector: "app-review-layout",
  templateUrl: "./review-layout.component.html",
  styleUrls: ["./review-layout.component.scss"],
})
export class ReviewLayoutComponent implements OnInit {
  name: String;
  email: String;
  mobile: String;
  private BASE_URL = GlobalVariable;
  outlet_id: String = this.storage.get("outlet_id");
  slug_value: String;
  personelDetailForm: FormGroup;
  emailnotvalid: boolean = true;
  isValidFormSubmitted: boolean = true;
  business_name1: String;
  logo_url: String;
  business_name: String;
  isSubmitClicked: boolean;

  constructor(
    private commonservice: CommonService,
    private HttpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    let res: Response;

    this.route.params.forEach((val) => {
      this.slug_value = val["id"];
    });
    const json_data = {
      slug: this.slug_value,
    };
    const logo_service = this.HttpService.get(
      this.BASE_URL.QR_PUBLIC_LOGO,
      this.slug_value
    );
    logo_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {},
      () => {
        this.logo_url = res.data["url"];
        this.business_name = res.data["business_name"];
      }
    );

    this.route.params.forEach((val) => {
      this.slug_value = val["id"];
    });
    this.personelDetailForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required])],
      last_name: [""],
      mobile_number: ["", Validators.compose([Validators.required])],
      email_id: [""],
      country_code: ["91"],
      outlet_id: [""],
    });
    this.personelDetailForm.patchValue({
      country_code: 91,
      outlet_id: this.slug_value,
    });
  }

  /**
   * Get all the data from personel details form
   *
   * @readonly
   * @memberof ReviewLayoutComponent
   */
  get customerFormData() {
    return this.personelDetailForm.controls;
  }

  /**
   * Common email vaidation
   *
   * @memberof ReviewLayoutComponent
   */
  checkmail() {
    this.emailnotvalid = true;
    if (
      this.customerFormData.email_id.value.match(
        /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,}$/
      )
    ) {
      this.emailnotvalid = true;
    } else {
      this.emailnotvalid = false;
    }
  }

  /**
   * Send personnel details
   *
   * @param {FormGroup} form
   * @memberof ReviewLayoutComponent
   */
  sendPersonelDetails(form: FormGroup) {
    let res: Response;
    this.isValidFormSubmitted = false;
    if (this.personelDetailForm.invalid) {
      return;
    }
    this.isValidFormSubmitted = true;
    this.isSubmitClicked = true;
    const name_count = this.customerFormData.name.value.split(" ");
    let first_name: string = "";
    let last_name: string = "";
    if (name_count.length === 1) {
      first_name = name_count[0];
    } else {
      first_name = name_count[0];
      last_name = name_count[1];
    }

    const data_json = {
      // 'first_name' : this.customerFormData.name.value,
      // 'last_name' : this.customerFormData.last_name.value,
      first_name: first_name,
      last_name: last_name,
      mobile_number: this.customerFormData.mobile_number.value,
      // 'email_id' : this.customerFormData.email_id.value,
      country_code: this.customerFormData.country_code.value,
      outlet_id: this.customerFormData.outlet_id.value,
    };
    const data_service = this.HttpService.postjson(
      this.BASE_URL.QRCODE_DETAIL_URL,
      data_json
    );
    data_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        this.toastrService.error(error.error.message);
        this.isSubmitClicked = false;
      },
      () => {
        // console.log(res.data);
        this.isSubmitClicked = false;
        if (res.code === 200 || res.code === 201) {
          window.location.replace("https://" + res.data["message"]);
        } else {
          this.toastrService.error(res.data["message"]);
        }
      }
    );
  }
}
