import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "../shared/models";
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { OtpService } from "../services/otp.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-mobile-otp",
  templateUrl: "./mobile-otp.component.html",
  styleUrls: ["./mobile-otp.component.scss"],
})
export class MobileOtpComponent implements OnInit {
  @ViewChild("accessDeniedModal", { static: true })
  accessDeniedModal: ElementRef;

  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;

  loading: boolean = false;
  otp: number;
  otpTyped: boolean = false;
  disableSubmit: boolean = true;
  phoneNumber: Number;
  show_error_message: String;
  attemptsLeft: number;
  isForgetPassword: boolean = false;
  disableResend: boolean = false;
  resendClicked: boolean = false;
  errorUiVisible: boolean = false;
  errorUiMsg: string = "";

  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputClass: "otp-input",
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private otpService: OtpService,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    let qParams;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      qParams = { ...params.keys, ...params };
    });
    if (qParams?.action) {
      this.isForgetPassword = qParams.action == "forget-password";
      // this.generateOtp(qParams.phone);
    }
    if (qParams.phone) {
      this.phoneNumber = qParams.phone;
      this.generateOtp(qParams.phone);
    }
  }

  generateOtp(phoneNumber) {
    this.loading = true;
    let res: Response;
    this.otpService.getOTP(phoneNumber).subscribe(
      (data: Response) => {
        res = data;
        // this.toastrService.success(res.message);
        this.loading = false;
        if (res && res.code === 201) {
          this.attemptsLeft = res.val.attempts_left;
          if (this.attemptsLeft <= 0) {
            this.attemptsLeft = 0;
            this.disableResend = true;
          }
          this.loading = false;
          this.errorUiVisible = false;
          this.errorUiMsg = "";
        }
      },
      (error) => {
        this.loading = false;
        if (error?.error?.message) {
          // this.toastrService.error(error.error.message);
          this.errorUiVisible = true;
          this.errorUiMsg = error.error.message;
        }
      }
    );
  }

  otpChange(e) {
    this.otpTyped = true;
    this.otp = e;
    this.disableSubmit =
      this.otp && this.otp.toString().length === 6 ? false : true;
    if (this.otp && this.otp.toString().length === 6 && e.keyCode === 13) {
      this.onSubmit();
    }
  }

  resendOTP() {
    if (this.disableResend) {
      return;
    }
    let res: Response;
    this.otpService.resendMobileOTP(this.phoneNumber + "").subscribe(
      (data: Response) => {
        res = data;
        this.attemptsLeft = res.val.attempts_left;
        if (this.attemptsLeft <= 0) {
          this.attemptsLeft = 0;
          this.disableResend = true;
        }
        // this.toastrService.success(res.message);
        this.ngOtpInput.setValue("");
        this.show_error_message = "";
        this.resendClicked = true;

        this.errorUiVisible = false;
        this.errorUiMsg = "";
      },
      (error) => {
        this.errorUiVisible = true;
        this.errorUiMsg = error.error.message;
      }
    );
  }

  onSubmit() {
    //this.spinner.start();
    let res: Response;
    this.otpService
      .verifyMobileOTP(
        this.phoneNumber + "",
        this.otp.toString(),
        this.isForgetPassword
      )
      .subscribe(
        (data: Response) => {
          res = data;
          if (res && res.code === 202) {
            if (this.isForgetPassword) {
              // this.router.navigate(["/signin/create-password"], {
              //   queryParams: { mobile: res.val.phone_number },
              // });
              if (!res.val.is_email_verified) {
                this.router.navigate(["/signin/verify-email"], {
                  queryParams: {
                    user_id: res.val.user_id,
                    email: res.val.email,
                  },
                });
              } else {
                this.router.navigate(["/signin/create-password"], {
                  queryParams: { mobile: res.val.phone_number },
                });
              }
            } else {
              // this.router.navigate(["/signin/verify-email"], {
              //   queryParams: { user_id: res.val.user_id, email: res.val.email },
              // });
              if (!res.val.is_email_verified) {
                this.router.navigate(["/signin/verify-email"], {
                  queryParams: {
                    user_id: res.val.user_id,
                    email: res.val.email,
                  },
                });
              } else if (!res.val.has_password) {
                this.router.navigate(["/signin/create-password"], {
                  queryParams: { mobile: res.val.phone_number },
                });
              } else if (res.val.is_email_verified && res.val.has_password) {
                this.router.navigate(["../signin"]);
              }
            }
            // this.router.navigate(["/signin/verify-email"], {
            //   queryParams: { user_id: res.val.user_id, email: res.val.email },
            // });
            this.errorUiVisible = false;
            this.errorUiMsg = "";
          } else {
            if (res) {
              // this.toastrService.success(res.message);
              this.errorUiVisible = false;
              this.errorUiMsg = "";
            }
          }
        },
        (error) => {
          // console.log("error", error);
          this.show_error_message = error.error.message;
          // this.openBackDrop();
          this.errorUiVisible = true;
          this.errorUiMsg = error.error.message;
        }
      );
  }

  // openBackDrop() {
  //   this.modalService.open(this.accessDeniedModal, {
  //     backdropClass: "light-blue-backdrop",
  //   });
  // }

  bannerClose(data) {
    this.resendClicked = false;
  }

  errorBannerClose(data) {
    this.errorUiVisible = false;
    this.errorUiMsg = "";
  }
}
