<div class="email-verification-container">
  <div class="row m-0">
    <div class="col-sm-6 col-md-6 col-lg-6 p-0 mob-hide">
      <app-onboard-curved-ui></app-onboard-curved-ui>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 min-height-100 h-100 mob-h-auto">
      <div class="email-text-section">
        <img src="assets/images/zceppa.png" class="logo-img" />
        <label class="heading">Account Verification</label>
        <!-- <label class="description">We have sent an email to
          <span class="bold-font">{{userEmail}}</span>
          for verification. Please click the link on the email to get started.
        </label> -->

        <p *ngIf="userPhone && !isForgetPassword" class="description">
          OTP sent to your email
          <span class="bold-font">{{userEmail}}</span>
          and mobile
          <span class="bold-font">{{userPhone}}</span>
          for verification. Please enter the OTP to get started.
        </p>
        <p *ngIf="!userPhone && !isForgetPassword" class="description">
          OTP sent to your email
          <span class="bold-font">{{userEmail}}</span>
          for verification. Please enter the OTP to get started.
        </p>

        <p *ngIf="isEmailLoginType && isForgetPassword" class="description">
          OTP sent to your email
          <span class="bold-font">{{userEmail}}</span>
          for verification. Please enter the OTP to get started.
        </p>

        <p *ngIf="!isEmailLoginType && isForgetPassword" class="description">
          OTP sent to your mobile
          <span class="bold-font">{{userEmail}}</span>
          for verification. Please enter the OTP to get started.
        </p>

          <ng-otp-input #ngOtpInput class="mt-2"
            (onInputChange)="otpChange($event)"
            [config]="otpConfig">
          </ng-otp-input>
          <p class="mb-3 mt-2">
            {{ attemptsLeft }} {{ attemptsLeft == 0 || attemptsLeft == 1 ? "attempt" : "attempts" }}
            remaining
          </p>
          <div class="col-md-12 col-lg-12 p-0">
            <p class="otp-options not-received-text">
              Did not Receive?
              <span class="resend-otp-color signpcont5" (click)="generateWebsiteEmailOtp()"
                [ngClass]="{ 'resend-disabled': disableResend}">Resend OTP</span>
            </p>
          </div>

          <div class="form-group">
            <button type="button" class="submit-btn w-100" (click)="onSubmit()"
              [disabled]="disableSubmit">
              Submit
            </button>
          </div>

        <!-- <label class="resend-link" (click)="generateWebsiteEmailOtp()">Resend email</label> -->

        <div *ngIf="(otpTyped && disableSubmit && show_error_message) || attemptsLeft == 1 || disableResend" class="alert alert-danger">
          <p *ngIf="attemptsLeft == 1" class="mb-1">
            You made too many incorrect attempts. Your account will get locked
            with the next incorrect attempt. Please contact support@zceppa.com
          </p>
          <p *ngIf="disableResend" class="mb-1">
            You have exceed your limit please retry next day.
          </p>
        </div>

        <div *ngIf="resendClicked">
          <app-success-toast-ui [isVisible]="resendClicked" [imageUrl]="'assets/images/onboarding/resend-email-icon.png'"
            [headerText]="'Email Sent'" [descriptionText]="'Please check your email.'"
            (outputData)="bannerClose($event)">
          </app-success-toast-ui>
        </div>

        <div *ngIf="resendError">
          <app-error-toast-ui [imageUrl]="'assets/images/onboarding/error-toastr.png'"
            [headerText]="resendErrorMessage" (outputDataClose)="errorBannerClose($event)">
          </app-error-toast-ui>
        </div>
      </div>
    </div>
  </div>
</div>

