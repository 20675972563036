import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "../app.component";
import { DataService } from "../dataService/data.service";
import {
  TeamsStructure,
  TEAMS_SUBFEATURES,
} from "../services/features-by-plan/interface/teams";
import { AlertService } from "../services/alert.service";

@Component({
  selector: "app-permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: [
    "../appointments/appointments.component.scss",
    "../team/team.component.scss",
    "./permissions.component.scss",
  ],
})
export class PermissionsComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  loading = false;
  noDataFound = false;
  userId: string;
  userName: string = "";
  permissions: any;
  p: number;
  items_per_page: Number = 25;
  page_per_item_list: any = [
    { value: 5 },
    { value: 10 },
    { value: 25 },
    { value: 50 },
    { value: 75 },
    { value: 100 },
  ];
  managerList: any;
  teamMemberSelected: string = "";
  teamPermissions: any;
  show: any = null;
  permissions_to_send: string[];
  outlet_id: any;
  ownerDetail: any;
  outlet_name: any;
  isOwnerPermission: number = 0;
  isOwnerPreference: any;
  is_team_blocked: any;
  team_warning_text: any = "";
  save_btn_submit: boolean = false;
  assign_permission: any;
  teamsFeature: TeamsStructure = {
    owner_preference: [
      "feature_teams",
      TEAMS_SUBFEATURES.owner_preference,
      "is_applicable",
    ],
  };
  constructor(
    private route: ActivatedRoute,
    private httpService: DataService,
    private toastrService: ToastrService,
    private alertService: AlertService,
    private modal: NgbModal,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.userId = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {
    this.loading = true;
    this.outlet_id = this.storage.get("outlet_id");
    this.outlet_name = this.storage.get("outlet_name");
    this.getUserPermission();
    this.getTeamMembers();
    this.getManagers();
  }
  onCopyPerms() {
    this.alertService
      .comfirmationAlert(
        "Copy Permission",
        "Do you Confirm to copy the permissions from " +
          this.permissionsToCopyFrom() +
          " to " +
          this.userName +
          "?",
        "Copy"
      )
      .then((data) => {
        if (data.isConfirmed) {
          this.onConfirmToCopyPerms();
        } else {
          this.teamMemberSelected = "";
        }
      });
  }
  permissionsToCopyFrom() {
    return this.managerList.find(
      (v) => v.manager_id === this.teamMemberSelected
    ).manager_first_name;
  }
  onUserChange(e) {
    console.log(e);
    this.save_btn_submit = true;
    if (this.ownerDetail[0].owner_id === e) {
      this.copyPermissionFromOwner();
    } else {
      this.onCopyPerms();
    }
  }
  onConfirmToCopyPerms() {
    this.isOwnerPermission = 0;
    this.getPermissionsToCopyFrom();
  }
  async getTeamMembers() {
    let outlet_id = this.storage.get("outlet_id");
    let res: any = await this.httpService
      .get(this.BASE_URL.MANAGER_LIST_API(outlet_id))
      .toPromise();
    if (res.code === 200) {
      this.managerList = res.data.filter(
        (v) => v.hasOwnProperty("manager_id") && v.manager_id !== this.userId
      );
      this.managerList = this.managerList.map((manager) => {
        manager.manager_first_name = manager.manager_first_name.trim();
        return manager;
      });
      this.ownerDetail = res.data.filter((v) => v.hasOwnProperty("owner_id"));

      console.log(this.ownerDetail);
    }
  }

  getManagers() {
    this.httpService
      .get(this.BASE_URL.MANAGER_API_V3(this.outlet_id))
      .subscribe(
        (data: any) => {
          this.assign_permission = data.data.assign_permissions;
          this.is_team_blocked = data.data.is_product_blocked;
          this.team_warning_text = data.data.product_warning_text;
          // this.assignPermissions.is_read = false;
        },
        (error) => {},
        () => {}
      );
  }
  copyPermissionFromOwner() {
    this.httpService
      .getRequest({
        customApiUrl: this.BASE_URL.COPY_OWNERS_PERMISSION_V3(this.outlet_id),
      })
      .subscribe((res) => {
        console.log(res);
        if (res.code === 200) {
          if (res.data["full_permission_count"] < 2) {
            this.save_btn_submit = false;

            this.alertService
              .comfirmationAlert(
                "Copy Owner Permission",
                "Do you want to assign the owner permissions to this user for " +
                  this.outlet_name +
                  "?",
                "Copy"
              )
              .then((data) => {
                if (data.isConfirmed) {
                  this.onCopyPermissionFromOwner();
                } else {
                  this.teamMemberSelected = "";
                }
              });
          } else {
            this.save_btn_submit = false;
            this.alertService
              .infoMsgWithCallback(
                "Only one manager can be assigned to owner permission."
              )
              .then((data) => {
                this.teamMemberSelected = "";
              });
          }
        }
      });
  }

  onCopyPermissionFromOwner() {
    // this.save_btn_submit = true;
    this.isOwnerPermission = 1;
    this.getPermissionsToCopyFrom();
  }

  onSave() {
    let data = this.permissions.map((el) => {
      return [...el.values];
    });
    this.permissions_to_send = data.flat();
    this.httpService
      .putUserPermissions({
        customApiUrl: this.BASE_URL.USER_PERMISSIONS_V3(this.outlet_id),
        data: {
          permissions: this.permissions_to_send,
          is_owner_permission: this.isOwnerPermission,
        },
        userId: this.userId,
      })
      .subscribe(
        (data: any) => {
          if (data.code === 200) {
            this.getUserPermission();
            this.toastrService.success(data.data.message);
          }
        },
        (error) => {
          if (error.status === 406) {
            this.toastrService.error(error.error.message);
          }
          if (error.status === 403) {
            this.toastrService.error(error.error.message);
          }
        }
      );
  }

  getUserPermission() {
    let response = this.httpService.getUserPermissions({
      customApiUrl: this.BASE_URL.USER_PERMISSIONS_V3(this.outlet_id),
      userId: this.userId,
    });
    response.subscribe((data: any) => {
      console.log("permissions data", data);
      if (data.code === 200) {
        this.loading = false;
        this.userName =
          data.data.user_detail.manager_first_name +
          " " +
          data.data.user_detail.manager_last_name;
        this.permissions = data.data.permissions;
        this.teamPermissions = data.data.team_permissions;
        this.isOwnerPreference = data.data.user_detail.is_owner_preference;

        console.log(this.permissions);
        console.log(this.teamPermissions);
        // this.featureName = Object.keys(this.permissions);
        // this.teamPermissions.is_update = false;
        // this.ref.detectChanges();
        // console.log(this.featureName);
        // console.log(Object.values(this.permissions));
      }
    });
  }
  getPermissionsToCopyFrom() {
    let response = this.httpService.getUserPermissions({
      customApiUrl: this.BASE_URL.USER_PERMISSIONS_V3(this.outlet_id),
      userId: this.teamMemberSelected,
    });
    response.subscribe(
      (data: any) => {
        console.log("permissions data", data);
        if (data.code === 200) {
          this.permissions = data.data.permissions;
          this.save_btn_submit = false;
        }
      },
      (error) => {
        console.log(error);
        if (error.status === 403) {
          this.toastrService.error(error.error.response);
          this.save_btn_submit = false;
        }
      }
    );
  }

  clickToShow(index) {
    if (this.show === index) {
      this.show = null;
    } else {
      this.show = index;
    }
  }

  onRead(v) {
    console.log(v);
    if (v.is_read === false) {
      v.is_create = false;
      v.is_update = false;
      v.is_delete = false;
    }
  }

  onCreate(v) {
    console.log(v.is_read);
    if (v.is_create === true) {
      v.is_read = true;
    }
  }

  onUpdate(v) {
    if (v.is_update === true) {
      v.is_read = true;
      // v.is_create = true;
    }
  }

  onDelete(v) {
    if (v.is_delete === true) {
      v.is_read = true;
      // v.is_create = true;
      // v.is_update = true;
    }
  }

  alertForRemoveOwnerPermission() {
    this.alertService
      .comfirmationAlert(
        "Remove Owner Permission",
        "Do you want to remove the owner permissions for this user?",
        "Remove"
      )
      .then((data) => {
        if (data.isConfirmed) {
          this.removeOwnerPermission();
        }
      });
  }

  removeOwnerPermission() {
    this.httpService
      .putUserPermissions({
        customApiUrl: this.BASE_URL.REMOVE_USER_PERMISSIONS_V2(this.outlet_id),
        userId: this.userId,
      })
      .subscribe(
        (data: any) => {
          if (data.code === 200) {
            this.teamMemberSelected = "";
            this.toastrService.success(data.data.message);
            this.getUserPermission();
          }
        },
        (error) => {
          console.log(error);
          if (error.status === 403) {
            this.toastrService.error(error.error.response);
          }
        }
      );
  }
}
