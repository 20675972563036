import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "src/app/app.component";
import { DataService } from "src/app/dataService/data.service";
import { CommonService } from "src/app/services/common.service";
import { icons, InteractService } from "../../interact/interact.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, AfterViewInit {
  @ViewChildren(PerfectScrollbarComponent)
  chatScrollBars: QueryList<PerfectScrollbarComponent>;
  count = 0;
  Icons = icons;
  showEmojiMart: boolean = true;
  loaderForMedia: boolean;
  i18n = {
    categories: {
      people: "Smileys s& People",
      nature: "Animals & Nature",
      foods: "Food & Drink",
      activity: "Activity",
      places: "Travel & Places",
      objects: "Objects",
      symbols: "Symbols",
      flags: "Flags",
    },
  };
  activeChatBoxes = [];
  chatJson = [
    {
      showChat: true,
      toggleChat: true,
      chatIndex: 0,
      name: "Ankush" + this.count,
    },
    {
      showChat: true,
      toggleChat: false,
      chatIndex: 1,
      name: "Ankush" + this.count + 1,
    },
    {
      showChat: true,
      toggleChat: true,
      chatIndex: 2,
      name: "Ankush" + this.count + 2,
    },
    // {
    //   showChat: true,
    //   toggleChat: true,
    //   chatIndex: 3
    // },
  ];
  chatBoxesStorage: Array<any> = [];
  tempArray: any;
  logo_url: any;
  private BASE_URL = GlobalVariable;
  mobileactiveChatBoxes: Array<any> = [];
  count_interact_chat: Array<any> = [];
  doc_type: any;
  fileToUpload: any;
  currentUserID: any = "";
  currentUrl: any;
  previousUrl: any;
  main_menu: any;
  unreadChatCounts: number;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private interactService: InteractService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private httpService: DataService
  ) {
    this.interactService.newMessage.subscribe(this.onNewMessage.bind(this));
    this.interactService.messageStatus.subscribe(
      this.onMessageStatusWsEvent.bind(this)
    );
  }

  ngOnInit() {
    let is_outlet_view = this.storage.get("is_outlet_view");
    if (is_outlet_view) {
      this.main_menu = this.storage.get("access").main_menu;
      this.getBusinessLogo();
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
          if (
            event.url === "/interact" ||
            event.url?.split("/")?.includes("business")
          ) {
            this.activeChatBoxes = [];
            this.chatBoxesStorage = [];
            return;
          }
          if (this.previousUrl?.split("/")?.includes("business")) {
            this.showChatOnUnreadCount();
            return;
          }
        }
      });
      if (!this.router.url?.split("/")?.includes("business")) {
        this.showChatOnUnreadCount();
      }
      // let interact_id = this.storage.get("interact_id");
      // console.log(interact_id);
      // interact_id && this.showUnreadChats(interact_id);
      console.log("chat event onint");
      // this.interactService.closeChatModal.subscribe()

      // if (this.interactService.varSub==undefined) {
      // this.interactService.varSub = this.interactService.
      this.interactService.closeModal.subscribe((name: string) => {
        // this.onCloseChat(this.mobileactiveChatBoxes);
        this.onChatNameClick(this.mobileactiveChatBoxes.length);
      });
      // }
    }
  }

  showChatOnUnreadCount() {
    let chatBoxLimit = 3;
    if (this.main_menu.includes("Interact")) {
      let outlet_id = this.storage.get("outlet_id");
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.INTERACT_UNREADCOUNT(outlet_id),
        })
        .subscribe((res) => {
          if (res.code == 200) {
            let data = res.data;
            let unread_conversation_ids: any[] = data.unread_conversation_ids;
            let messageCount = data.message;
            let unreadChatCounts = unread_conversation_ids?.length || 0;
            this.unreadChatCounts = unreadChatCounts;
            if (messageCount && unreadChatCounts) {
              let showChatBoxByLimit = unread_conversation_ids.slice(
                0,
                chatBoxLimit
              );

              showChatBoxByLimit.forEach((e) => {
                this.showUnreadChats(e.interact_id, e.source);
              });
            }
          }
        });
    }
  }
  ngAfterViewInit() {
    this.scrollbarChangesSubscription();
    // this.chatScrollBars.notifyOnChanges()
  }
  getBusinessLogo() {
    let res: Response;
    let outlet_id = this.storage.get("outlet_id");
    const data_service = this.httpService.get(
      this.BASE_URL.GET_LOGO_URL(outlet_id)
    );
    data_service.subscribe(
      (data) => {
        this.logo_url = data.data["url"];
      },
      (error) => {},
      () => {}
    );
  }

  viewTicketRedirect(ticket_id) {
    this.router.navigate([`/tickets/view/${ticket_id}`]);
  }

  addEmoji(event, chat, boxFooter, messageTextarea) {
    chat.newMessage = chat.newMessage + event.emoji.native;
    messageTextarea.focus();
    this.adjustHeight(chat, boxFooter);
  }
  onChatNameClick(chat) {
    this.activeChatBoxes = [];
    this.mobileactiveChatBoxes = [];
    this.count_interact_chat = [];

    this.chatBoxesStorage = [];
    this.router.navigate(["/interact"], {
      state: { data: chat, interactId: chat.interactId },
    });
  }
  onaddchat() {
    this.onNewMessage(this.tempArray.pop());
  }
  scrollToBottom(interactId: string) {
    setTimeout(() => {
      let ele: any;
      ele = document.getElementById(interactId);
      if (ele) {
        ele = document.getElementById(interactId).children;
        ele[0].children[0].scrollTop = ele[0].children[0].scrollHeight;
      }
    }, 10);
    // let scrollComponent = this.chatScrollBars.find(_ => _.states.interactId === in)
    // console.log("scrollComponent", scrollComponent);
    // setTimeout(() => scrollComponent.directiveRef.scrollToBottom(), 0);
  }
  scrollbarChangesSubscription() {
    // this.chatScrollBars.changes.subscribe((change: QueryList<PerfectScrollbarComponent>) => {
    // console.log("ss", this.chatScrollBars.toArray());
    // change.forEach((src, i) => {
    //   let openedActiveChats = this.activeChatBoxes.filter(chat => chat.toggleChat === true);
    //   let interaction = openedActiveChats[i];
    //   // src.states.interactId = correspondingInteraction.interactId;
    //   // src.states.name = correspondingInteraction.name;
    //   interaction.src = src;
    //   if (!interaction.src) {
    //   }
    //   // src.directiveRef.scrollToBottom();
    //   console.log("interaction", interaction);
    //   // if (correspondingInteraction.scrollToBottom) {
    //   //   setTimeout(() => src.directiveRef.scrollToBottom(), 0);
    //   //   correspondingInteraction.scrollToBottom = false;
    //   // }
    //   // console.log("postion ", src.directiveRef.position(true));
    // });
    // });
  }
  scrollUpEvent(event, chat) {
    if (event.target.scrollTop < 400 && !chat.paginationApiCalled) {
      chat.page++;
      chat.paginationApiCalled = true; // disable calling pagination api multiple times.
      this.interactService
        .conversationDetailApiCall(
          chat.page.toString(),
          "20",
          "DESC",
          chat.source,
          chat.interactId
        )
        .subscribe(
          (data: any) => {
            if (data.code === 200) {
              chat.paginationApiCalled = false;
              let oldScrollHeight = event.target.scrollHeight;
              let oldScrollTop = event.target.scrollTop;
              chat.conversations.unshift(...data.data.conversations);
              this.ref.detectChanges();
              let newScrollHeight = event.target.scrollHeight;
              let newScrollTop =
                newScrollHeight - oldScrollHeight + oldScrollTop;
              event.target.scrollTop = newScrollTop;
            }
          },
          (error) => {
            chat.page--;
            chat.paginationApiCalled = false;
          }
        );
    }
  }
  onMessageStatusWsEvent(data) {
    let interaction = this.getInteraction(data.interact_id);

    if (interaction) {
      interaction.conversations.forEach((_) => {
        if (_.conversation_id == data.conversation_id) {
          _.status = data.message_status;
          // this.ref.detectChanges();
          this.ref.markForCheck();
        }
      });
    }
  }

  onCrossClick(chat, boxFooter) {
    chat.newMedia = "";
    this.adjustHeight(chat, boxFooter);
  }
  validateFile(file, chat) {
    const mimeType = file.type;
    const mimeSize = file.size;
    const fileType = file.name.split(".").pop();
    // if (['gif', 'svg'].some(_ => _ === fileType) || mimeType.match(/image\/*/) == null) {
    //   this.toastrService.error('Selected file is not image. ' + file.name);// = ;
    //   return false;
    // }
    if (["gif", "svg"].some((_) => _ === fileType)) {
      this.toastrService.error("Selected file is not image. " + file.name); // = ;
      return false;
    }
    if (
      chat.source == "GOOGLE" &&
      ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"].indexOf(fileType) == -1
    ) {
      this.toastrService.error("Selected file format is not supported."); // = ;
      return false;
    }
    let fileSize = mimeSize / 1000 / 1000;
    if (fileSize > 16) {
      this.toastrService.error(
        "The " +
          file.name +
          " could not be uploaded. The file size is exceeding the maximum file size of 16 MB."
      );
      return false;
    }
    return true;
  }

  async onFileUpload(event, chat, boxFooter: Element, messageTextarea) {
    let file = event.target.files[0];
    console.log(this.validateFile(file, chat));
    if (!this.validateFile(file, chat)) {
      return;
    }
    this.fileToUpload = file;
    this.currentUserID = chat.interactId;
    this.loaderForMedia = true;
    console.log(chat);
    const mimeType = file.type;
    this.doc_type = mimeType;

    this.adjustHeight(chat, boxFooter);
    chat.newMedia = "";
    this.adjustHeight(chat, boxFooter);
    let chatBoxFileUpload = document.getElementById(
      chat.interactId + "chatBoxFileUpload"
    );
    if (chatBoxFileUpload.onclick === null) {
      chatBoxFileUpload.onclick = (e: any) => (e.target.value = null);
    }
    if (this.validateFile(file, chat)) {
      if (!this.doc_type.match(/video\/*/)) {
        chat.newMedia = await this.interactService
          .getFileUrl(file)
          .then((res) => res)
          .catch((err) => console.log(err));
      } else if (this.doc_type.match(/video\/*/)) {
        chat.newMedia = await this.interactService
          .getVideoFileUrl(file)
          .then((res) => res)
          .catch((err) => console.log(err));
      }
      this.loaderForMedia = false;
      this.currentUserID = "";
      this.adjustHeight(chat, boxFooter);
    }
    messageTextarea.focus();
    console.log(chat.newMedia);
  }

  onFocusOfMessageInput(event: FocusEvent, chat) {
    setTimeout(() => {
      chat.isFocused = true;
      if (chat.unreadMessages > 0) {
        this.makeMarkAsReadConversation(chat?.source, chat?.interactId, chat);
      }
    }, 0);
  }
  onBlurOfMessageInput(event, chat) {
    chat.isFocused = false;
    // console.log("blur event", chat);
  }
  OnChatHeaderClick(chat) {
    if (chat.toggleChat === true && chat.unreadMessages > 0) {
      chat.unreadMessages = 0;
      return;
    }
    chat.showEmojiMart = false;
    chat.toggleChat = !chat.toggleChat;
    if (chat.toggleChat === true) {
      this.scrollToBottom(chat.interactId);
    }
  }
  adjustHeight(chat, boxFooter: Element) {
    let chatBody = document.getElementById(chat.interactId);
    setTimeout(() => {
      chatBody.style.height =
        (boxFooter.clientHeight > 30 ? 350 - boxFooter.clientHeight : 310) +
        "px";
    }, 0);
  }
  onSendNewMessage(event: KeyboardEvent, chat, boxFooter: Element) {
    // console.log(chat);

    this.adjustHeight(chat, boxFooter);
    if (event.shiftKey && event.keyCode === 13) {
    } else if (event.keyCode === 13) {
      event.preventDefault();
      console.log(chat.newMedia);
      if (chat.newMessage == "" && chat.newMedia == "") {
        return;
      }
      // let newMessage = {
      //   conversation_id: "",
      //   message: chat.newMessage !== "" ? chat.newMessage : "",
      //   status: "Queued",
      //   sender_type: "Business",
      //   media: chat.newMedia.url,
      //   media_type: chat.newMedia === "" ? "" : chat.newMedia.media_type,
      //   created_at: this.commonService.getDate().toISOString(),
      //   is_read: false,
      //   tag_team_member: "",
      // };
      // console.log(newMessage);
      // chat.conversations.push(newMessage);
      chat.showEmojiMart = false;
      this.scrollToBottom(chat.interactId);
      console.log("chat", chat);

      let response = this.interactService.sendMessageApiCall(
        chat.source,
        chat.interactId,
        chat.newMessage,
        chat.newMedia.url,
        chat.newMedia.media_id
      );
      response.subscribe(
        (data: any) => {
          if (data.code === 200) {
            // newMessage.conversation_id =
            //   data.data.conversation_detail.conversation_id;
            // newMessage.tag_team_member =
            //   data.data.conversation_detail.tag_team_member;
            this.commonService.interactUnreadCount(true);
            // newMessage.conversation_id = data.data.conversation_detail.media;
          }
        },
        (error) => {
          console.error("error", error);
          if (error.status !== 401 && error.status !== 403) {
            this.toastrService.error(error.error.message);
          }
          if (error.status == 401 || error.status == 403) {
            this.onCloseChat(chat);
          }
        }
      );
      // console.log("modal message", chat);
      chat.newMessage = "";
      chat.newMedia = "";
    } else {
    }
  }

  onNewMessage(message: any) {
    console.log(this.activeChatBoxes);
    if (
      this.router.url !== "/interact" &&
      !this.router.url?.split("/").includes("business")
    ) {
      if (
        !this.activeChatBoxes.length ||
        !this.isInteractionOngoing(message.interact_id)
      ) {
        console.log("new message", message);
        let response = this.interactService.conversationDetailApiCall(
          "1",
          "20",
          "DESC",
          message.interact_channel,
          message.interact_id
        );
        // this.storage.set("interact_id", "946c3a0c-8dc0-4156-8ac8-b5aa917a1cf2");
        this.processNewInteraction(message, response);
      } else if (this.isInteractionOngoing(message.interact_id)) {
        this.processExistingInteraction(message);
      }
    }
  }
  showUnreadChats(interact_id: string, source: string) {
    let condition =
      !this.isInteractionOngoing(interact_id) &&
      this.router.url !== "/interact" &&
      !this.router.url?.split("/").includes("business");
    if (condition) {
      let response = this.interactService.conversationDetailApiCall(
        "1",
        "20",
        "DESC",
        source?.toUpperCase(),
        interact_id
      );
      response.subscribe((data: any) => {
        // console.log("conversation on new messasge in chatbbox", data);
        if (data.code === 200 && Array.isArray(data.data.conversations)) {
          let chatUser = data.data.contact_detail;
          let newInteraction = {
            interactId: interact_id,
            conversations: data.data.conversations,
            page: 1,
            showChat: true,
            toggleChat: true,
            // toggleChat: false,
            name: chatUser.name,
            contactNumber: chatUser.contact_number,
            contactId: chatUser.contact_id,
            source: chatUser.source,
            unreadMessages: chatUser.unread_messages,
            // unreadMessages: 0,
            newMessage: "",
            newMedia: "",
            isFocused: false,
            paginationApiCalled: false,
            showEmojiMart: false,
          };

          this.mobileactiveChatBoxes.push(newInteraction);
          this.activeChatBoxes.push(newInteraction);
          console.log(this.activeChatBoxes);
          this.scrollToBottom(newInteraction.interactId);
          this.addEventListenerForEmoji(newInteraction.interactId);
          if (this.activeChatBoxes.length > 3) {
            let interaction = this.activeChatBoxes.shift();
            interaction.showEmojiMart = false;
            this.chatBoxesStorage.push(interaction);
          }
        }
      });
    }
  }
  addEventListenerForEmoji(interactId) {
    // setTimeout(() => {
    //   let chatContainer = document.getElementById(interactId + 'chatContainer');
    //   chatContainer.addEventListener('click', (event: MouseEvent) => {
    //     let emojiMart = chatContainer.querySelector(".emoji-mart");
    //     console.log("emojiMart", event.target);
    //     console.log("predicative", emojiMart.contains(event.target));
    //   })
    // }, 0);
  }
  onChatContainerClick(event, emojiMart, emojiButton, chat) {
    if (!emojiMart.contains(event.target) && event.target !== emojiButton) {
      chat.showEmojiMart = false;
    }
  }

  onSmileyClick(event: MouseEvent, chat) {
    chat.showEmojiMart = !chat.showEmojiMart;
    event.stopPropagation();
  }

  processExistingInteraction(message) {
    let interaction = this.getInteraction(message.interact_id);
    interaction.conversations.push({
      conversation_id: message.conversation_id,
      message: message.message,
      status: message.message_status,
      sender_type: message.sender_type,
      media: message.media,
      media_type: message.media_type,
      created_at: message.created_at,
      is_read: message.is_read,
      ticket_id: message.ticket_id,
      ticket_number: message.ticket_number,
      source: message.interact_channel,
      interactId: message?.interact_id,
    });
    // if (interaction.toggleChat === false) {
    interaction.unreadMessages = message.unread_messages;
    if (interaction.isFocused === true) {
      // interaction.unreadMessages = 0;
      if (interaction.unreadMessages > 0) {
        this.makeMarkAsReadConversation(
          interaction?.source,
          interaction?.interactId,
          interaction
        );
      }
    }
    this.scrollToBottom(interaction.interactId);
    // }/
  }

  processNewInteraction(message: any, response: any) {
    // console.log(message);

    response.subscribe((data: any) => {
      // console.log("conversation on new messasge in chatbbox", data);
      if (data.code === 200 && Array.isArray(data.data.conversations)) {
        let newInteraction = {
          interactId: message.interact_id,
          conversations: data.data.conversations,
          page: 1,
          showChat: true,
          toggleChat: true,
          // toggleChat: false,
          name: message.name || data?.data?.contact_detail?.name,
          contactNumber: message.contact_number,
          contactId: message.contact_id,
          source: message.interact_channel,
          unreadMessages: message.unread_messages,
          // unreadMessages: 0,
          newMessage: "",
          newMedia: "",
          isFocused: false,
          paginationApiCalled: false,
          showEmojiMart: false,
        };

        this.mobileactiveChatBoxes.push(newInteraction);
        // this.mobileactiveChatBoxes.splice(0 , 1, newInteraction);
        // if( this.mobileactiveChatBoxes.length > 1){
        //   this.mobileactiveChatBoxes.shift();
        // }

        // this.count_interact_chat.push(newInteraction)
        // this.commonService.interactionCount(this.count_interact_chat) ;
        // this.commonService.mobileChatClose(this.mobileactiveChatBoxes) ;

        // this.activeChatBoxes = [];
        // this.mobileactiveChatBoxes = [];
        // this.count_interact_chat = [];

        // this.chatBoxesStorage = [];

        // console.log(this.mobileactiveChatBoxes, this.mobileactiveChatBoxes.length);

        this.activeChatBoxes.push(newInteraction);
        // console.log('newInteraction', this.activeChatBoxes.length);

        this.scrollToBottom(newInteraction.interactId);
        this.addEventListenerForEmoji(newInteraction.interactId);
        if (this.activeChatBoxes.length > 3) {
          let interaction = this.activeChatBoxes.shift();
          interaction.showEmojiMart = false;
          this.chatBoxesStorage.push(interaction);
        }
      }
    });
  }

  isInteractionOngoing(interactId: string) {
    return (
      this.activeChatBoxes.some((_) => _.interactId === interactId) ||
      this.chatBoxesStorage.some((_) => _.interactId === interactId)
    );
  }
  getInteraction(interactId: string) {
    return (
      this.activeChatBoxes.find((_) => _.interactId === interactId) ||
      this.chatBoxesStorage.find((_) => _.interactId === interactId)
    );
  }

  // onToggleChat(chat, chatScroll: PerfectScrollbarComponent) {
  //   // console.log("inside toggle", chatScroll);
  //   chat.toggleChat = !chat.toggleChat;
  // }
  trackById(chat) {
    return chat.interactId;
  }
  onCloseChat(chat) {
    this.activeChatBoxes = this.activeChatBoxes.filter(
      (_) => _.interactId !== chat.interactId
    );
    // this.mobileactiveChatBoxes = this.mobileactiveChatBoxes.filter(_ => _.interactId !== chat.interactId);

    // commented the code to show only initially loaded chats
    // if (this.activeChatBoxes.length < 3 && this.chatBoxesStorage.length) {
    //   let newChat = this.chatBoxesStorage.pop();
    //   this.activeChatBoxes.unshift(newChat);
    //   this.scrollToBottom(newChat.interactId);
    // }
    // ---- //
    // if(this.mobileactiveChatBoxes.length){
    //   let newChat = this.chatBoxesStorage.pop();
    //   this.mobileactiveChatBoxes.unshift(newChat);
    //   this.scrollToBottom(newChat.interactId);

    // }
  }
  reverse(arr: Array<any>) {
    arr.reverse();
  }

  makeMarkAsReadConversation(source: string, interactId: string, chat: any) {
    // This is api to make conversation as read only.
    // No need to take any action on the api
    this.interactService.markAsUnreadConversation(source, interactId).subscribe(
      (res) => {
        if (res.code == 200) {
          chat.unreadMessages = 0;
          this.commonService.interactUnreadCount(true);
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  }
}
