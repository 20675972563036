import { FeatureParams, SubFeatures, GetValues, FeatureKey } from "./common";

export type MyStoreSubFeatures = {
  no_of_orders: "no_of_orders";
  no_of_products: "no_of_products";
  zceppa_template: "zceppa_template";
  custom_template: "custom_template";
  mystore_channel: "mystore_channel";
  tag_team_member: "tag_team_member";
  shopnow_link: "shopnow_link";
  short_url: "short_url";
  order_flex_fields: "order_flex_fields";
  product_flex_fields: "product_flex_fields";
  auto_review_request: "auto_review_request";
  order_download_report: "order_download_report";
  product_download_report: "product_download_report";
  product_category: "product_category";
  product_tag: "product_tag";
  payment_options: "payment_options";
  shopnow_theme: "shopnow_theme";
  welcome_banner: "welcome_banner";
  shopnow_chat: "shopnow_chat";
  payment_options_value: "payment_options";
};

export const MYSTORE_SUBFEATURES: MyStoreSubFeatures = {
  no_of_orders: "no_of_orders",
  no_of_products: "no_of_products",
  zceppa_template: "zceppa_template",
  custom_template: "custom_template",
  mystore_channel: "mystore_channel",
  tag_team_member: "tag_team_member",
  shopnow_link: "shopnow_link",
  short_url: "short_url",
  order_flex_fields: "order_flex_fields",
  product_flex_fields: "product_flex_fields",
  auto_review_request: "auto_review_request",
  order_download_report: "order_download_report",
  product_download_report: "product_download_report",
  product_category: "product_category",
  product_tag: "product_tag",
  payment_options: "payment_options",
  shopnow_theme: "shopnow_theme",
  welcome_banner: "welcome_banner",
  shopnow_chat: "shopnow_chat",
  payment_options_value: "payment_options",
};
interface MyStoreParams
  extends FeatureParams<"feature_mystore", GetValues<MyStoreSubFeatures>> {}
export interface MyStoreFeatures
  extends SubFeatures<GetValues<MyStoreSubFeatures>> {}

export interface MyStoreStructure {
  [key: string]: MyStoreParams;
}
