import { Location } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { JoyrideService } from "ngx-joyride";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Subscription } from "rxjs";
import { MultiLocationService } from "src/app/multi-location/multi-location.service";
import { GlobalVariable } from "../../app.component";
import { DataService } from "../../dataService/data.service";
import { CommonService } from "../../services/common.service";
import { ExpiryWarningService } from "../../services/expiry/expiry-warning.service";
import { MenuService } from "./menu.service";
/* tslint:disable:max-line-length */
@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  providers: [MenuService],
})
export class MenuComponent implements OnInit, OnDestroy {
  private BASE_URL = GlobalVariable;
  currentLang = "en";
  role: string = this.storage.get("role");
  menuItems: any = [];
  accessInfo: Array<string> = [];
  topActionButton: any;
  isDashboard: boolean = false;
  landingMenu: string = "";
  dashboardTab: any;
  locationMenuItems = [
    {
      title: "Dashboard",
      icon: "menu-sprite-dashboard",
      route: "/dashboard",
      showLockIcon: false,
      productAlias: "DASHBOARD",
    },
    {
      title: "MyListings",
      icon: "menu-sprite-listing",
      route: "/listing-management",
      showLockIcon: false,
      productAlias: "LISTING_MANAGEMENT",
    },
    {
      title: "Reviews",
      icon: "menu-sprite-reviews",
      route: "/reviews",
      showLockIcon: false,
      productAlias: "REVIEWS",
    },
    {
      title: "Social",
      icon: "menu-sprite-social",
      route: "/social",
      showLockIcon: false,
      productAlias: "SOCIAL",
    },
    {
      title: "Interact",
      icon: "menu-sprite-interact",
      route: "/interact",
      showLockIcon: false,
      productAlias: "INTERACT",
    },
    {
      title: "Appointments",
      icon: "menu-sprite-appointment",
      route: "/appointments",
      showLockIcon: false,
      productAlias: "APPOINTMENTS",
    },
    {
      title: "Messaging",
      icon: "menu-sprite-messaging",
      route: "/messaging",
      showLockIcon: false,
      productAlias: "MESSAGING",
    },
    {
      title: "MyStore",
      icon: "menu-sprite-mystore",
      route: "/mystore",
      showLockIcon: false,
      productAlias: "MYSTORE",
    },
    {
      title: "Tickets",
      icon: "menu-sprite-ticket",
      route: "/tickets",
      showLockIcon: false,
      productAlias: "TICKETS",
    },
    {
      title: "Payments",
      icon: "menu-sprite-payment",
      route: "/payments",
      showLockIcon: false,
      productAlias: "PAYMENTS",
    },
    {
      title: "Customers",
      icon: "menu-sprite-customers",
      route: "/customers",
      showLockIcon: false,
      productAlias: "CUSTOMERS",
    },
    {
      title: "Team",
      icon: "menu-sprite-team",
      route: "/team",
      showLockIcon: false,
      productAlias: "TEAM",
    },
    {
      title: "Calendar",
      icon: "menu-sprite-calendar",
      route: "/calendar",
      showLockIcon: false,
      productAlias: "CALENDAR",
    }, // update icon
    {
      title: "Settings",
      icon: "menu-sprite-settings",
      route: "/settings",
      showLockIcon: false,
      productAlias: "SETTINGS",
    },
  ];

  multiLocationMenuItems = [
    {
      title: "Central Dashboard",
      icon: "menu-sprite-multi-location",
      route: "/business/dashboard",
      showLockIcon: false,
      productAlias: "MULTILOCATION",
    },
    {
      title: "Unified Reviews",
      icon: "menu-sprite-reviews",
      route: "/business/reviews",
      showLockIcon: false,
      productAlias: "UNIFIED_REVIEWS",
    },
    {
      title: "Social Feed",
      icon: "menu-sprite-social-feed",
      route: "/business/social-feed",
      showLockIcon: false,
      productAlias: "SOCIAL_FEED",
    },
    {
      title: "Team",
      icon: "menu-sprite-team",
      route: "/business/teams",
      showLockIcon: false,
      productAlias: "BUSINESS_TEAM",
    },
    {
      title: "Location Groups",
      icon: "menu-sprite-location-group",
      route: "/business/outlet-groups",
      showLockIcon: false,
      productAlias: "LOCATION_GROUPS",
    },
    {
      title: "Settings",
      icon: "menu-sprite-settings",
      route: "/business/settings",
      showLockIcon: false,
      productAlias: "SETTINGS",
    },
  ];
  isMultiLocationView: boolean;
  unreadUniqueConversation: Subscription;
  totalUniqueUnreadConversation: number = 0;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    public menuService: MenuService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private commonservice: CommonService,
    private HttpService: DataService,
    private readonly joyrideService: JoyrideService,
    private location: Location,
    private multiLocationService: MultiLocationService,
    private expiryWarningService: ExpiryWarningService
  ) {
    router.events.subscribe(() => {
      //found current active url Using Angular Location Service
      this.isDashboard =
        this.location.path() == "/dashboard/rating-reviews" ||
        this.location.path() == "/dashboard/mystore" ||
        this.location.path() == "/dashboard/appointments";
    });
  }

  init() {
    if (this.storage.get("access") === null) {
      this.signout();
    } else {
      this.accessInfo = this.storage.get("access").main_menu;
    }
    this.topActionButton = this.storage.get("access").top_action_button;
    this.landingMenu = this.storage.get("access").landing_menu;
    this.dashboardTab = this.storage.get("access").dashboard_tab;
  }

  ngOnDestroy(): void {
    this.unreadUniqueConversation.unsubscribe();
  }

  showLockIcon(menu, isMultiLoc?: boolean) {
    if (menu?.productAlias) {
      let isExpired = false;
      if (isMultiLoc) {
        if (
          menu.productAlias == "BUSINESS_TEAM" ||
          menu.productAlias == "SOCIAL_FEED" ||
          menu.productAlias == "LOCATION_GROUPS"
        ) {
          let multilocPlanSubscription =
            this.expiryWarningService.multiLocExpiryPlanDetails;
          isExpired = multilocPlanSubscription?.is_expired;
        } else {
          // let multilocProductsSubscription =
          //   this.expiryWarningService.multiLocExpiryProductDetails || null;
          // isExpired =
          //   multilocProductsSubscription?.[menu.productAlias]?.is_expired;
          let multilocProductsSubscription =
            this.expiryWarningService.multiLocExpiryProductDetails || null;
          isExpired =
            multilocProductsSubscription?.[menu.productAlias]?.is_expired;
        }
      } else {
        let productsSubscription =
          this.expiryWarningService.expiryProductDetails || null;
        isExpired = productsSubscription?.[menu.productAlias]?.is_expired;
      }

      menu.showLockIcon = isExpired;
    }
    return menu;
  }

  ngOnInit(): void {
    let is_outlet_view = this.storage.get("is_outlet_view");
    if (is_outlet_view) {
      this.init();
    }
    // console.log(this.joyrideService);

    const titles = this.router.url.split("/");
    const route_param =
      titles[1].charAt(0).toLocaleUpperCase() + titles[1].slice(1);
    console.log(route_param);
    this.menuItems.forEach((val: any, key: any) => {
      if (val.title === route_param) {
        this.menuItems[key].active = true;
      }
      if (route_param === "Insights") {
        this.menuItems[3].active = true;
      }
      if (
        route_param == "Listing-management" &&
        this.menuItems[key].title == "MyListings"
      ) {
        this.menuItems[key].active = true;
      }
      console.log("is called on each time");
    });
    this.commonservice.menu_display_selection.subscribe((res) => {
      if (res !== null) {
        this.menuItems.forEach((val: any, key: any) => {
          if (val.title === res) {
            this.menuItems[key].active = true;
          } else {
            this.menuItems[key].active = false;
          }
        });
      }
    });
    this.multiLocationService.isMultiLocationView$.subscribe((response) => {
      this.isMultiLocationView = response;
    });
    this.locationMenuItems = this.locationMenuItems.map((menuItem) => {
      return this.showLockIcon(menuItem);
    });

    this.multiLocationMenuItems = this.multiLocationMenuItems.map(
      (menuItem) => {
        return this.showLockIcon(menuItem, true);
      }
    );
    this.unreadUniqueConversation =
      this.commonservice.interact_unread_unique_conversation_count.subscribe(
        (response) => {
          if (response) {
            this.totalUniqueUnreadConversation = parseInt(response);
          }
        }
      );
  }

  get multiLocationMenu() {
    return this.storage.get("business_menu")?.main_menu || [];
  }

  showMenuItem(menuItem: string) {
    if (this.isMultiLocationView) {
      return this.multiLocationMenu.some(
        (_) => _.toLowerCase() === menuItem.toLowerCase()
      );
    }
    if (menuItem == "Dashboard" && menuItem !== this.landingMenu) {
      return;
    }

    let productAllowed =
      this.accessInfo.find(
        (product) => product.toLowerCase() == menuItem.toLowerCase()
      ) || false;

    return productAllowed;
  }

  signout() {
    this.storage.remove("access_token");
    this.storage.remove("refresh_token");
    this.storage.remove("outlet_id");
    this.storage.remove("business_id");
    this.storage.remove("user_id");
    this.storage.remove("business_name");
    this.storage.remove("outlet_name");
    this.storage.remove("user_name");
    this.storage.remove("logo");
    this.storage.remove("review_count");
    this.storage.remove("default_template_id");
    this.storage.remove("role");
    this.storage.remove("access");
    this.storage.remove("has_password");
    this.router.navigate(["../signin"]);
    this.commonservice.setFirstView(false);
  }
  disableOnGatherInfo() {
    return this.router.url.split("/").includes("gather-info");
  }
  get getCurrentRoute() {
    return this.router.url.split("/");
  }
  redirectToFifthProductTour(tour) {
    if (tour == "appointments") {
      this.router.navigate(["/dashboard/appointments"]);
      setTimeout(() => {
        this.commonservice.firstViewCheck("appointments");
      }, 1000);
    } else if (tour == "mystore") {
      this.router.navigate(["/dashboard/mystore"]);
      setTimeout(() => {
        this.commonservice.firstViewCheck("mystore");
      }, 1000);
    } else {
      this.router.navigate(["/dashboard/rating-reviews"]);
      setTimeout(() => {
        this.commonservice.firstViewCheck("reviews");
      }, 1000);
    }
  }
  onDone() {
    var ele = document.getElementsByClassName("product-tour-menu");
    let navbarToggler: HTMLElement = ele[0].children[0] as HTMLElement;
    if (navbarToggler) {
      navbarToggler.click(); //hide navigation toggle when clicked on pricing option
    }
  }
  joyRideIsDesktopSidebar() {
    if (!window.matchMedia("(max-width: 767px)").matches) {
      return "fourthStep";
    }
    return;
  }
}
