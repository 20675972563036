import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { GlobalVariable } from "../app.component";
import { DataService } from "../dataService/data.service";

@Injectable({
  providedIn: "root",
})
export class MultiLocationService {
  private _isMultiLocationView = new BehaviorSubject<boolean>(false);
  public isMultiLocationView$ = this._isMultiLocationView.asObservable();
  private _openModal = new Subject<boolean>();
  public openModal$ = this._openModal.asObservable();
  private BASE_URL = GlobalVariable;
  constructor(
    private router: Router,
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.isMultiLocationView();
        console.log(event.url);
      });
  }

  openModal(res: boolean) {
    this._openModal.next();
  }

  get business_id() {
    return this.storage.get("business_id");
  }

  get getLandingMenu() {
    let landingMenu = this.storage.get("business_menu")?.landing_menu;
    return landingMenu;
  }

  isMultiLocationView() {
    let isBusinessView = this.router.url.split("/").includes("business");
    this._isMultiLocationView.next(isBusinessView || false);
  }

  getIsMultiLocationView() {
    let isBusinessView = this.router.url.split("/").includes("business");
    return isBusinessView || false;
  }
  redirectTo(redirectTo: string) {
    console.log(this.router.url);
    this.router.navigate([redirectTo], { relativeTo: this.route });
  }

  getBusinessMenuAPI() {
    if (this.business_id) {
      this.httpService
        .getRequest({
          customApiUrl: this.BASE_URL.GET_BUSINESS_MENU(this.business_id),
        })
        .subscribe((response) => {
          if (response.code == 200) {
            this.storage.set("business_menu", response.data);
          }
        });
    }
    return;
  }

  getBusinessMenuAPIFromBusinessId() {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_BUSINESS_MENU(this.business_id),
    });
  }

  toastr(message: string) {
    return {
      success: () => {
        this.toastrService.success(message);
      },
      error: () => {
        this.toastrService.error(message);
      },
      warning: () => {
        this.toastrService.warning(message);
      },
    };
  }
}
export let colorCode = {
  RED: "#EB5B3C",
  YELLOW: "#FFC107",
  GREEN: "#03C0C2",
  GREY: "#cccccc",
};
