<!-- <div class="chat_window">
  <input id="toggle" type="checkbox" style="visibility:hidden">
  <label for="toggle" class="open-button">Chat</label>

  <div class="chat-popup box">
    <form action="/action_page.php" class="form-container">
      <h1>Chat</h1>

      <label for="msg"><b>Message</b></label>
      <textarea placeholder="Type message.." name="msg" required></textarea>

      <button type="submit" class="btn">Send</button>
      <label type="button" class="btn cancel" for="toggle">Close</label>
    </form>
  </div>
</div>
 -->
<!-- <div><button (click)="onaddchat()">add</button></div> -->

<div class="row bootstrap snippets chat_box is-desktop-view">
  <ng-container class="" *ngFor="let chat of activeChatBoxes.slice().reverse()">
    <!-- <ng-container *ngIf="!applyReverse" *ngFor="let chat of activeChatBoxes"> -->
    <div class="flex_chat" *ngIf="chat?.showChat === true">
      <div class="box box-success direct-chat direct-chat-success">
        <div
          class="box-header"
          (click)="OnChatHeaderClick(chat)"
          [ngClass]="{
            'bg-zceppa-green': chat?.unreadMessages > 0 ? true : false
          }"
        >
          <h4 class="box-title" (click)="onChatNameClick(chat)">
            {{ chat.name || chat.contactNumber }}
          </h4>
          <div class="box-tools pull-right">
            <span
              data-toggle="tooltip"
              [title]="chat.unreadMessages + 'New Messages'"
              class="badge bg-light-zceppa"
              >{{ chat.unreadMessages > 0 ? chat.unreadMessages : "" }}</span
            >
            <!-- <button *ngIf="chat.toggleChat === true" type="button" class="btn btn-box-tool" data-toggle="tooltip" title="minimize" data-widget="collapse" (click)="onToggleChat(chat)"> <i
                class="fa fa-minus"></i>
            </button>
            <button *ngIf="chat.toggleChat === false" type="button" class="btn btn-box-tool" data-toggle="tooltip" title="maximize" (click)="onToggleChat(chat, chatScroll)"
              data-widget="chat-pane-toggle">
              <i class="fa fa-comments"></i></button> -->
            <button
              type="button"
              class="btn btn-box-tool"
              data-widget="remove"
              data-toggle="tooltip"
              title="close"
              (click)="onCloseChat(chat)"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
        <div
          class="chat-container"
          (click)="onChatContainerClick($event, emojiMart, emojiButton, chat)"
          *ngIf="chat.toggleChat === true"
        >
          <div class="box-body" [id]="chat.interactId">
            <perfect-scrollbar
              style="max-width: 100%; height: 296px"
              (psScrollUp)="scrollUpEvent($event, chat)"
              #chatScroll
            >
              <div>
                <div
                  class="direct-chat-messages"
                  *ngFor="let message of chat.conversations"
                >
                  <div
                    class="direct-chat-msg"
                    *ngIf="message.sender_type === 'User'"
                  >
                    <!-- <div class="direct-chat-info clearfix">
                      <span
                        class="direct-chat-timestamp pull-right">{{message.created_at | amFromUtc | amTimeAgo}}</span>
                    </div> -->
                    <img
                      class="direct-chat-img"
                      [src]="Icons[chat.source]"
                      alt="Message User Image"
                    />
                    <div class="direct-chat-text">
                      <div
                        [ngClass]="{
                          'direct-chat-media':
                            message.media !== null &&
                            (message.media_type == 'video/mp4' ||
                              message.media_type == 'image/jpeg')
                        }"
                        *ngIf="message.media"
                      >
                        <a
                          [href]="message.media"
                          target="_blank"
                          *ngIf="message.media_type === 'image/jpeg'"
                        >
                          <img
                            [src]="message.media"
                            alt=""
                            style="width: 100%"
                          />
                        </a>
                        <!-- <div> -->
                        <video
                          *ngIf="message.media_type === 'video/mp4'"
                          style="width: 100%"
                          controls
                        >
                          <source [src]="message.media" type="video/mp4" />
                          <!-- <source src="movie.ogg" type="video/ogg"> -->
                          Your browser does not support the video tag.
                        </video>
                        <a
                          *ngIf="
                            message.media_type !== 'audio' ||
                            message.media_type !== 'AUDIO'
                          "
                          [href]="message.media"
                          target="_blank"
                        >
                          <div
                            style="margin: 2px 50%"
                            *ngIf="
                              message.media_type == 'document' ||
                              message.media_type == 'application/pdf' ||
                              message.media_type ==
                                'application/vnd.ms-excel' ||
                              message.media_type == 'application/ppt' ||
                              message.media_type == 'application/docx' ||
                              message.media_type ==
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                              message.media_type ==
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            "
                          >
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'pdf'
                              "
                            >
                              <img
                                class="ImgLogo"
                                src="../../../assets/images/pdfLogo.png"
                                alt=""
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'xls' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'xlsx' ||
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'csv'
                              "
                            >
                              <img
                                class="ImgLogo"
                                src="../../../assets/images/exel.png"
                                alt=""
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'ppt' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'ppts'
                              "
                            >
                              <img
                                src="../../../assets/images/ppt.png"
                                alt=""
                                height="21px"
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'doc' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'docx'
                              "
                            >
                              <img
                                src="../../../assets/images/word.png"
                                alt=""
                                height="21px"
                              />
                            </ng-container>
                          </div>
                        </a>
                        <div *ngIf="message.media_type == 'audio'">
                          <audio style="width: 100%" height="240" controls>
                            <source [src]="message.media" type="audio/ogg" />
                            <source [src]="message.media" type="audio/mpeg" />
                          </audio>
                        </div>
                      </div>
                      <!-- </div> -->

                      <div *ngIf="message?.is_story_mention" class="insta-story-mention-reply">
                        Mentioned you in their story
                      </div>
                      <div *ngIf="message?.is_story_reply" class="insta-story-mention-reply">
                        Replied to your story
                      </div>
                      <div
                        style="word-break: break-all"
                        *ngIf="message.message !== ''"
                        [innerHTML]="message.message | linkify"
                      ></div>
                      <span style="font-size: 9px">{{
                        message.created_at | dateFormatter: "ago"
                      }}</span>
                      <ng-container *ngIf="message.ticket_id !== undefined">
                        <div
                          class="raised-ticket-left float-right"
                          style="cursor: pointer"
                          (click)="viewTicketRedirect(message.ticket_id)"
                          [title]="message.ticket_number"
                        >
                          <img src="assets/images/tiket.svg" width="18" />
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div
                    class="direct-chat-msg right"
                    *ngIf="message.sender_type === 'Business'"
                  >
                    <!-- <div class="direct-chat-info clearfix">
                      <span
                        class="direct-chat-timestamp pull-left">{{message.created_at | amFromUtc | amTimeAgo}}</span>
                    </div> -->
                    <ng-container
                      *ngIf="logo_url != undefined; else elseTemplate"
                    >
                      <img [src]="logo_url" class="direct-chat-img" />
                    </ng-container>
                    <ng-template #elseTemplate>
                      <img
                        class="direct-chat-img"
                        src="assets/images/social/zceppa1.png"
                        alt="Message User Image"
                      />
                    </ng-template>

                    <div class="direct-chat-text">
                      <!-- {{message.media}} -->
                      <div
                        class=""
                        [ngClass]="{
                          'direct-chat-media':
                            message.media !== null &&
                            (message.media_type == 'video/mp4' ||
                              message.media_type == 'image/jpeg')
                        }"
                        *ngIf="message.media"
                      >
                        <a
                          [href]="message.media"
                          target="_blank"
                          *ngIf="
                            message.media_type === 'image/jpeg' ||
                            message.media_type === 'IMAGE'
                          "
                        >
                          <img
                            [src]="message.media"
                            alt=""
                            style="width: 100%"
                          />
                        </a>
                        <!-- <div> -->
                        <video
                          *ngIf="
                            message.media_type === 'video/mp4' ||
                            message.media_type === 'VIDEO' ||
                            message.media.substr(message.media.length - 3) ==
                              'mp4'
                          "
                          style="width: 100%"
                          controls
                        >
                          <source [src]="message.media" type="video/mp4" />
                          <!-- <source src="movie.ogg" type="video/ogg"> -->
                          Your browser does not support the video tag.
                        </video>
                        <!-- {{message.media_type}} -->
                        <a [href]="message.media" target="_blank">
                          <div
                            style="margin: 2px 50%"
                            *ngIf="
                              message.media_type == 'document' ||
                              message.media_type == 'PDF' ||
                              message.media_type == 'XLS' ||
                              message.media_type == 'XLSX' ||
                              message.media_type == 'DOCX' ||
                              message.media_type == 'application/pdf' ||
                              message.media_type ==
                                'application/vnd.ms-excel' ||
                              message.media_type == 'application/ppt' ||
                              message.media_type == 'application/docx' ||
                              message.media_type ==
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                              message.media_type ==
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            "
                          >
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'pdf'
                              "
                            >
                              <img
                                class="ImgLogo"
                                src="../../../assets/images/pdfLogo.png"
                                alt=""
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'xls' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'xlsx' ||
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'csv'
                              "
                            >
                              <img
                                class="ImgLogo"
                                src="../../../assets/images/exel.png"
                                alt=""
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'ppt' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'ppts'
                              "
                            >
                              <img
                                src="../../../assets/images/ppt.png"
                                alt=""
                                height="21px"
                              />
                            </ng-container>
                            <ng-container
                              *ngIf="
                                message.media.substr(
                                  message.media.length - 3
                                ) == 'doc' ||
                                message.media.substr(
                                  message.media.length - 4
                                ) == 'docx'
                              "
                            >
                              <img
                                src="../../../assets/images/word.png"
                                alt=""
                                height="21px"
                              />
                            </ng-container>
                          </div>
                        </a>
                        <div
                          *ngIf="
                            message.media_type == 'audio' ||
                            message.media_type == 'AUDIO'
                          "
                        >
                          <audio style="width: 100%" height="240" controls>
                            <source [src]="message.media" type="audio/ogg" />
                            <source [src]="message.media" type="audio/mpeg" />
                          </audio>
                        </div>
                      </div>
                      <!-- </div> -->
                      <!-- <div class="right-direct-chat"> -->
                      <div
                        class="direct-chat-inner-text"
                        *ngIf="message.message !== ''"
                        [innerHTML]="message.message | linkify"
                      ></div>
                      <ng-container
                        *ngIf="message?.quick_reply_buttons != null"
                      >
                        <button
                          type="button"
                          class="btn-sm interct-quick-reply-btn mt-2"
                          *ngFor="
                            let actionButton of message.quick_reply_buttons.split(
                              ','
                            )
                          "
                          disabled
                        >
                          {{ actionButton }}
                        </button>
                      </ng-container>
                      <div class="message-footer" style="font-size: 9px">
                        <span *ngIf="message?.tag_team_member" class="mr-2">{{message.tag_team_member}}</span>
                        <span>{{
                          message.created_at | dateFormatter: "ago"
                        }}</span>
                        <!-- {{message.status}} -->
                        <img
                          class="ml-1"
                          *ngIf="
                            message.status === 'Delivered' ||
                            message.status === 'Read'
                          "
                          src="../../../assets/icons/icons8-double-tick-64.png"
                          height="9"
                          width="9"
                          alt=""
                        />
                        <img
                          class="ml-1"
                          *ngIf="
                            message.status === 'Sent' ||
                            message.status === 'Queued'
                          "
                          src="../../../assets/icons/singleTick.png"
                          height="10"
                          width="10"
                          alt=""
                        />
                        <span *ngIf="message.status === 'Read'">R</span>
                        <span *ngIf="message.status === 'Delivered'">D</span>
                        <span *ngIf="message.status === 'Sent'">S</span>
                      </div>
                      <ng-container *ngIf="message.ticket_id !== undefined">
                        <div
                          class="raised-ticket-right float-right"
                          style="cursor: pointer"
                          (click)="viewTicketRedirect(message.ticket_id)"
                          [title]="message.ticket_number"
                        >
                          <img src="assets/images/tiket.svg" width="18" />
                        </div>
                      </ng-container>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="box-footer" #boxFooter>
            <img
              *ngIf="loaderForMedia && currentUserID == chat.interactId"
              src="../../../assets/images/25.gif"
              alt=""
              style="margin: 0 50%"
            />
            <div
              *ngIf="chat.newMedia !== ''"
              class="upload-image-ui"
              style="height: 180px"
            >
              <span (click)="onCrossClick(chat, boxFooter)" class="cross-symbol"
                >&#10005;</span
              >
              <div>
                <img
                  *ngIf="
                    doc_type == 'image/png' ||
                    doc_type == 'image/jpg' ||
                    doc_type == 'image/jpeg' ||
                    doc_type == 'image/gif'
                  "
                  [src]="chat.newMedia?.url"
                  width="100%"
                  height="180px"
                  alt=""
                />

                <!-- <img *ngIf="doc_type == 'image/png' || doc_type == 'image/gif' " [src]="url" width="200" height="150" alt=""> -->
                <video
                  *ngIf="doc_type == 'video/mp4'"
                  [src]="chat.newMedia?.url"
                  width="200"
                  height="150"
                ></video>
                <div
                  *ngIf="
                    doc_type == 'application/pdf' ||
                    doc_type == 'application/vnd.ms-excel' ||
                    doc_type == 'application/ppt' ||
                    doc_type == 'application/docx' ||
                    doc_type ==
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    doc_type ==
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                >
                  <ng-container
                    *ngIf="
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 3
                      ) == 'pdf'
                    "
                  >
                    <img
                      class="ImgLogo"
                      src="../../../assets/images/pdfLogo.png"
                      alt=""
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 3
                      ) == 'xls' ||
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 4
                      ) == 'xlsx' ||
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 3
                      ) == 'csv'
                    "
                  >
                    <img
                      class="ImgLogo"
                      src="../../../assets/images/exel.png"
                      alt=""
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 3
                      ) == 'ppt' ||
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 4
                      ) == 'ppts'
                    "
                  >
                    <img
                      src="../../../assets/images/ppt.png"
                      alt=""
                      height="21px"
                    />
                  </ng-container>
                  <ng-container
                    *ngIf="
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 3
                      ) == 'doc' ||
                      this.fileToUpload.name.substr(
                        this.fileToUpload.name.length - 4
                      ) == 'docx'
                    "
                  >
                    <img
                      src="../../../assets/images/word.png"
                      alt=""
                      height="21px"
                    />
                  </ng-container>
                  <div class="d-block">
                    {{ this.fileToUpload.name }}
                  </div>
                </div>
                <div
                  *ngIf="
                    doc_type == 'audio/mp3' ||
                    doc_type == 'audio/ogg' ||
                    doc_type == 'audio/mpeg'
                  "
                >
                  <audio height="240" controls style="width: 100%">
                    <source [src]="chat.newMedia?.url" type="audio/ogg" />
                    <source [src]="chat.newMedia?.url" type="audio/mpeg" />
                  </audio>
                </div>
              </div>
            </div>
            <!-- <div #emojiMart class="chat-box-emoji">
              <emoji-mart
                *ngIf="showEmojiMart"
                [showPreview]="false"
                perLine="6"
                color="#03c0c2"
                (emojiClick)="addEmoji($event)"
                [skin]="1"
                set="google"
                [include]="['people', 'nature', 'foods', 'activity', 'places', 'symbols', 'flags']"
                >
              </emoji-mart>
            </div> -->
            <div class="input-group h-100 position-relative">
              <div #emojiMart class="chat-box-emoji">
                <emoji-mart
                  *ngIf="chat.showEmojiMart"
                  [showPreview]="false"
                  perLine="6"
                  color="#03c0c2"
                  (emojiClick)="
                    addEmoji($event, chat, boxFooter, messageTextarea)
                  "
                  [skin]="1"
                  set="google"
                  [include]="[
                    'people',
                    'nature',
                    'foods',
                    'activity',
                    'places',
                    'symbols',
                    'flags'
                  ]"
                >
                </emoji-mart>
              </div>
              <textarea
                #messageTextarea
                (blur)="onBlurOfMessageInput($event, chat)"
                (focus)="onFocusOfMessageInput($event, chat)"
                (keydown)="onSendNewMessage($event, chat, boxFooter)"
                [(ngModel)]="chat.newMessage"
                autosize
                [minRows]="1"
                [maxRows]="4"
                placeholder="Send a message"
                style="
                  line-height: 16px;
                  border: none !important;
                  padding: 7px 2px 7px 8px;
                  resize: none;
                  outline: none;
                  flex: auto;
                  z-index: 3;
                "
                class="chat-textarea"
              ></textarea>
              <div class="emoji_icon">
                <img
                  #emojiButton
                  (click)="onSmileyClick($event, chat)"
                  src="../../../assets/icons/smile.svg"
                  height="20px"
                  width="20px"
                  alt=""
                />
              </div>
              <div class="input-group-btn box-footer-icon attachment-icon">
                <input
                  [id]="chat.interactId + 'chatBoxFileUpload'"
                  type="file"
                  class="chatbox-input"
                  (change)="
                    onFileUpload($event, chat, boxFooter, messageTextarea)
                  "
                />
                <i class="fa fa-paperclip"></i>
                <!-- <i class="fas fa-paperclip"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- <div class="flex_chat" [class.hidechat]="hidechat2 == true">
        <div class="box box-success direct-chat direct-chat-success" [class.showchat]="showchat2 == true">
          <div class="box-header with-border">
            <h3 class="box-title">Zceppa</h3>
            <div class="box-tools pull-right">
              <span data-toggle="tooltip" title="3 New Messages" class="badge bg-light-zceppa">3</span>
              <button type="button" class="btn btn-box-tool" data-widget="collapse" (click)="clickChatEvent('val2')"> <i class="fa fa-minus"></i>
              </button>
              <button type="button" class="btn btn-box-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
                <i class="fa fa-comments"></i></button>
              <button type="button" class="btn btn-box-tool" data-widget="remove" (click)="clickCloseChatEvent('val2')"><i class="fa fa-times"></i></button>
            </div>
          </div>

          <div class="box-body">
              <perfect-scrollbar style="max-width: 100%; height: 200px;">
                <div class="direct-chat-messages">
                  <div class="direct-chat-msg">
                    <div class="direct-chat-info clearfix">
                      <span class="direct-chat-name pull-left">Murali VJ</span>
                      <span class="direct-chat-timestamp pull-right">06 Dec 2:00 pm</span>
                    </div>
                    <img class="direct-chat-img" src="assets/images/man-user.svg" alt="Message User Image">
                    <div class="direct-chat-text">
                      Hi ! How are you?
                    </div>
                  </div>
                  <div class="direct-chat-msg right">
                    <div class="direct-chat-info clearfix">
                      <span class="direct-chat-name pull-right">Jhon Doe</span>
                      <span class="direct-chat-timestamp pull-left">06 Dec 2:10 pm</span>
                    </div>
                    <img class="direct-chat-img" src="assets/images/man-user.svg" alt="Message User Image">
                    <div class="direct-chat-text">
                      Fine. What about you?
                    </div>
                  </div>
                </div>
                <div class="direct-chat-contacts">
                  <ul class="contacts-list">
                    <li>
                      <a href="#">
                        <img class="contacts-list-img" src="assets/images/man-user.svg">
                        <div class="contacts-list-info">
                              <span class="contacts-list-name">
                                Count Dracula
                                <small class="contacts-list-date pull-right">2/28/2015</small>
                              </span>
                          <span class="contacts-list-msg">How have you been? I was...</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </perfect-scrollbar>
          </div>

          <div class="box-footer">
            <form action="#" method="post">
              <div class="input-group">
                <input type="text" name="message" placeholder="Type Message ..." class="form-control">
                    <span class="input-group-btn">
                      <button type="submit" class="btn btn-primary btn-flat">Send</button>
                    </span>
              </div>
            </form>
          </div>
        </div>
    </div>
    <div class="flex_chat" [class.hidechat]="hidechat3 == true">
        <div class="box box-success direct-chat direct-chat-success" [class.showchat]="showchat3 == true">
          <div class="box-header with-border">
            <h3 class="box-title">Zceppa</h3>
            <div class="box-tools pull-right">
              <span data-toggle="tooltip" title="3 New Messages" class="badge bg-light-zceppa">3</span>
              <button type="button" class="btn btn-box-tool" data-widget="collapse" (click)="clickChatEvent('val3')"> <i class="fa fa-minus"></i>
              </button>
              <button type="button" class="btn btn-box-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
                <i class="fa fa-comments"></i></button>
              <button type="button" class="btn btn-box-tool" data-widget="remove" (click)="clickCloseChatEvent('val3')"><i class="fa fa-times"></i></button>
            </div>
          </div>

          <div class="box-body">
              <perfect-scrollbar style="max-width: 100%; height: 200px;">
                <div class="direct-chat-messages">
                  <div class="direct-chat-msg">
                    <div class="direct-chat-info clearfix">
                      <span class="direct-chat-name pull-left">Murali VJ</span>
                      <span class="direct-chat-timestamp pull-right">06 Dec 2:00 pm</span>
                    </div>
                    <img class="direct-chat-img" src="assets/images/man-user.svg" alt="Message User Image">
                    <div class="direct-chat-text">
                      Hi ! How are you?
                    </div>
                  </div>
                  <div class="direct-chat-msg right">
                    <div class="direct-chat-info clearfix">
                      <span class="direct-chat-name pull-right">Jhon Doe</span>
                      <span class="direct-chat-timestamp pull-left">06 Dec 2:10 pm</span>
                    </div>
                    <img class="direct-chat-img" src="assets/images/man-user.svg" alt="Message User Image">
                    <div class="direct-chat-text">
                      Fine. What about you?
                    </div>
                  </div>
                </div>
                <div class="direct-chat-contacts">
                  <ul class="contacts-list">
                    <li>
                      <a href="#">
                        <img class="contacts-list-img" src="assets/images/man-user.svg">
                        <div class="contacts-list-info">
                              <span class="contacts-list-name">
                                Count Dracula
                                <small class="contacts-list-date pull-right">2/28/2015</small>
                              </span>
                          <span class="contacts-list-msg">How have you been? I was...</span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </perfect-scrollbar>
          </div>

          <div class="box-footer">
            <form action="#" method="post">
              <div class="input-group">
                <input type="text" name="message" placeholder="Type Message ..." class="form-control">
                    <span class="input-group-btn">
                      <button type="submit" class="btn btn-primary btn-flat">Send</button>
                    </span>
              </div>
            </form>
          </div>
        </div>
    </div> -->
</div>
