import { Component, Inject, OnInit, Pipe, PipeTransform } from "@angular/core";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "../app.component";
import { DataService } from "../dataService/data.service";
import { DATE_FORMATS } from "./../services/utils";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
@Pipe({ name: "safe" })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    console.log(url);

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: "app-user-api-page",
  templateUrl: "./user-api-page.component.html",
  styleUrls: ["./user-api-page.component.scss"],
})
export class UserAPIPageComponent implements OnInit {
  isBlur: boolean = true;
  DATE_FORMATS = DATE_FORMATS;
  today = new Date();
  outlet_id: string = this.storage.get("outlet_id");
  private BASE_URL = GlobalVariable;
  public loading = false;
  apiDetails: any;
  // infoMessage: string = "This is testing";
  infoMessage: string =
    "All API requests are authenticated using your API Key. If you do not include your key when making an API request or use one that is incorrect or outdated, the API will return an error. For any questions and clarifications contact support@zceppa.com";
  noApiKeyInfoMessage: string =
    "All API requests are authenticated using your API Key. If you do not include your key when making an API request or use one that is incorrect or outdated, the API will return an error. Reach out to support@zceppa.com to generate API Keys.";

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private httpService: DataService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.getAPIDetails();
  }

  getAPIDetails() {
    this.httpService
      .getRequest({
        customApiUrl: this.BASE_URL.VIEW_API_DOCS(this.outlet_id),
      })
      .subscribe((res) => {
        console.log(res);
        if (res.code == 200) {
          let apiResponse = res.data;
          this.apiDetails = apiResponse;
          this.loading = false;
        }
      });
  }
}
