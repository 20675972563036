import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-banner",
  templateUrl: "./banner.component.html",
  styleUrls: ["./banner.component.scss"],
})
export class BannerComponent implements OnInit {
  @Input() bannerIconRequired: boolean;
  @Input() bannerType: string;
  @Input() bannerSubType: string;
  @Input() bannerText: string;
  @Input() bannerText2: string;
  @Input() bannerLink: any;
  @Input() bannerInfoPrefixRequired: boolean;
  @Input() bannerCloseIconRequired: boolean;
  @Output() isRemoveBanner = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    // console.log("this.bannerType", this.bannerType);
    // console.log("this.bannerSubType;", this.bannerSubType);
    // console.log("this.bannerText", this.bannerText);
    // console.log("this.bannerText2", this.bannerText2);
    // console.log("this.bannerLink", this.bannerLink);
    // console.log("this.bannerInfoPrefixRequired", this.bannerInfoPrefixRequired);
  }

  public deleteBanner() {
    this.isRemoveBanner.emit(true);
  }
}
