<div class="container">
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="gather-info-container">
        <div *ngIf="!serviceSumbitted" class="content row d-flex flex-column">
          <p class="col-12">What services do you need?</p>
          <div class="col-12 my-2 mb-5">
            <ng-container *ngIf="serviceList.length">
              <ng-container
                *ngFor="let service of serviceList; let idx = index"
              >
                <div class="d-flex check-box-area">
                  <input
                    id="service--{{ idx }}"
                    class="style-checkbox col-4"
                    style="display: flex; flex-basis: auto; margin-right: 10px"
                    (change)="setService($event, service, idx)"
                    [(ngModel)]="service.checked"
                    [ngModelOptions]="{ standalone: true }"
                    [value]="service.value"
                    name="service--{{ idx }}"
                    type="checkbox"
                    [checked]="service.checked"
                    [disabled]="service.key !== 'none' && disable()"
                  />
                  <label
                    class="mb-0 col-auto text-left cursor-pointer info-label"
                    for="service--{{ idx }}"
                  >
                    {{ service.value }}
                  </label>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="row col-12">
            <div class="col-12 text-center">
              <button
                type="button"
                (click)="submitGatherInfo()"
                [disabled]="disableButton()"
                class="btn btn-primary submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedService.length && serviceSumbitted">
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-column justify-content-center">
                <div class="d-flex justify-content-center">
                  <img
                    src="./../../../assets/images/gather-thumbsup-yellow.svg"
                    class="img-fluid thumbs-up"
                  />
                </div>
                <ng-container>
                  <div class="col-12 text-center mt-2 mb-4">
                    <p [innerHTML]="textToShow" class="thank-you-text"></p>
                  </div>
                  <p
                    [innerHTML]="buttonToShow"
                    (click)="
                      onAction(
                        buttonToShow == 'Got it' ? 'redirect' : 'connect'
                      )
                    "
                    class="colour-text cursor-pointer text-center text-uppercase"
                  ></p>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
