import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "../app.component";
import { DataService, Response } from "../dataService/data.service";
import { CommonService } from "../services/common.service";
import { AlertService } from "../services/alert.service";
@Component({
  selector: "app-business-onboarding-tweaked",
  templateUrl: "./business-onboarding-tweaked.component.html",
  styleUrls: ["./business-onboarding-tweaked.component.scss"],
})
export class BusinessOnboardingTweakedComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  @ViewChild("accessSuccessModal", { static: true })
  accessSuccessModal: ElementRef;
  @ViewChild("accessDeniedpopModal", { static: true })
  accessDeniedpopModal: ElementRef;
  createdBusinessId: any;
  isExistingOwner: boolean = false;
  business_domain: any[] = [];
  business_detail_form: FormGroup;
  images: { id: string; image_link: string }[];
  products: any[] = [];
  selectedProduct: boolean[] = [];
  product_array: any[] = [];
  emailnotvalid: boolean = true;
  business_details_submit: boolean;
  registerForm2: FormGroup;
  newOutlet_id: string;
  submitClicked: boolean;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  CountryISO312: any;
  selectedCountry = { India: "in" };
  PhoneNumberFormat = PhoneNumberFormat;

  token: any;

  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  onlyCountries: any;
  countryList: any;
  countryLoader: boolean;
  onboardJsonArray = [];
  existingUserSuccessMsg =
    "Business created successfully. Looks like you already have an account, you will be redirected to the sign-in page to continue.";

  errorUiVisible: boolean = false;
  errorUiMsg: string = "";

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private HttpService: DataService,
    public formBuilder: FormBuilder,
    private commonservice: CommonService,
    private route: ActivatedRoute
  ) {
    // this.token = this.route.snapshot.paramMap.get("authenticate_token");
  }

  ngOnInit() {
    let qParams;
    this.route.queryParams.subscribe((params: Params) => {
      qParams = { ...params.keys, ...params };
    });
    // if(window.location.href == 'http://localhost:4200/business-onboarding'){
    if (Object.keys(qParams).length == 0) {
      this.modalService.open(this.accessDeniedpopModal, {
        backdropClass: "light-blue-backdrop",
        backdrop: "static",
        keyboard: false,
      });
    } else {
      let data = window.location.href;
      this.token = data.split("=").pop();
      // console.log(this.token);
      this.storage.set("admin_access_token", this.token);
    }

    this.getProductDataForDescription();

    this.countryLoader = false;
    this.HttpService.getRequestAdmin({
      customApiUrl: `${this.BASE_URL.COUNTRY_API}`,
    }).subscribe((data) => {
      let res: Response;
      res = data;
      if (res.code === 200) {
        this.countryList = res.data["countries"];
        // console.log("this.countryList", this.countryList);
        this.CountryISO312 = this.countryList.map((e) => {
          return e.country_code.toLowerCase();
        });

        this.onlyCountries = this.CountryISO312;
        this.countryLoader = true;
      }
    });
    this.HttpService.getRequestAdminAuth({
      customApiUrl: `${this.BASE_URL.TOKEN_VALIDATE}`,
    }).subscribe(
      (data) => {
        let res: Response;
        res = data;
      },
      (error) => {
        console.log(error);

        if (error.status != 200 && error.status != 201 && error.status != 202) {
          this.modalService.open(this.accessDeniedpopModal, {
            backdropClass: "light-blue-backdrop",
            backdrop: "static",
            keyboard: false,
          });
        }
      }
    );
    let res: Response;
    this.getZceppaProducts();
    this.HttpService.get(this.BASE_URL.BUSINESS_DOMAIN).subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {},
      () => {
        this.business_domain = res.data["business_domain"];
      }
    );

    this.images = [
      { id: "01", image_link: "assets/images/BusinessGrowth-login.png" },
    ];

    /** Business details form */
    this.business_detail_form = this.formBuilder.group({
      first_name: ["", Validators.compose([Validators.required])],
      // last_name: ["", Validators.compose([Validators.required])],
      last_name: [""],
      phone: [""],
      email: ["", Validators.compose([Validators.required])],
      business_name: ["", Validators.compose([Validators.required])],
      termsAndCondition: [false, Validators.compose([Validators.requiredTrue])],
    });
  }

  get businessDetailsData() {
    return this.business_detail_form.controls;
  }
  openBackDrop() {
    this.modalService.open(this.accessSuccessModal, {
      backdropClass: "light-blue-backdrop",
    });
  }
  getZceppaProducts() {
    let res: Response;
    this.HttpService.getRequestAdmin({
      customApiUrl: this.BASE_URL.VIEW_ZCEPPA_PRODUCTS,
    }).subscribe((data) => {
      res = data;
      if (res.code === 200) {
        const products: any[] = res.data["product_list"];
        this.products = products;
        // add icon from local based on the product name
        this.products.forEach((prod: any) => {
          prod.icon = this.getProductImageFromName(prod.product_name);
          //add description
          let jsonObj = this.onboardJsonArray.find(
            (element) =>
              element.product_name.toLowerCase() ==
              prod.product_name.toLowerCase()
          );
          prod.description = jsonObj.product_description;
        });
        this.selectedProduct = [
          ...Array<boolean>(this.products.length).fill(false),
        ];
      }
    });
  }

  getProductImageFromName(prodName) {
    let prodImage;
    switch (prodName) {
      case "Interact":
        prodImage = "assets/images/onboarding/product-grey-icon/interact.svg";
        break;
      case "Social":
        prodImage = "assets/images/onboarding/product-grey-icon/social.svg";
        break;
      case "Listing Management":
        prodImage = "assets/images/onboarding/product-grey-icon/listing.svg";
        break;
      case "Reviews":
        prodImage = "assets/images/onboarding/product-grey-icon/reviews.svg";
        break;
      case "Appointments":
        prodImage =
          "assets/images/onboarding/product-grey-icon/appointment.svg";
        break;
      case "Messaging":
        // code block
        prodImage = "assets/images/onboarding/product-grey-icon/messaging.svg";
        break;
      default:
      // code block
    }
    return prodImage;
  }

  async getProductDataForDescription() {
    let jsonData = await this.storage.get("onboard_json");
    this.onboardJsonArray = jsonData.product_details;
  }

  clearFields() {
    this.business_details_submit = true;
    this.business_detail_form.reset();
    this.emailnotvalid = true;
    this.product_array = [];
    this.selectedProduct = [
      ...Array<boolean>(this.products.length).fill(false),
    ];
  }
  onBusinessDetailsSubmit(form: FormGroup) {
    this.business_details_submit = false;
    console.log(this.business_detail_form.invalid);
    if (this.business_detail_form.invalid || this.product_array.length == 0) {
      if (this.product_array.length == 0) {
        this.errorUiVisible = true;
        this.errorUiMsg = "Please select at least 1 product.";
      } else if (!this.business_detail_form.value.termsAndCondition) {
        this.errorUiVisible = true;
        this.errorUiMsg = "Please agree to terms and conditions.";
      } else {
        this.errorUiVisible = false;
        this.errorUiMsg = "";
      }
      return;
    }
    this.business_details_submit = true;
    this.submitClicked = true;
    let data = { ...this.business_detail_form.value, is_website: false };

    let formatted_number = null;
    let country_code = null;
    if (data["phone"]) {
      country_code = data["phone"]["countryCode"];
      var str1 = data["phone"]["dialCode"];
      var str2 = data["phone"]["e164Number"];

      var str3 = str1.split("");
      formatted_number = str2.slice(str3.length);
    }

    let json = {
      first_name: data["first_name"],
      // last_name: data["last_name"],
      last_name: null,
      phone: formatted_number,
      // country_code: data["phone"]["countryCode"],
      country_code: country_code,
      email: data["email"]?.trim(),
      business_name: data["business_name"],
      is_website: data["is_website"],
      terms_conditions: data["termsAndCondition"],
    };

    let payload = this.commonservice.removeEmptyFromObject(json);

    console.log(data, json);
    this.HttpService.postOnboard({
      customApiUrl: this.BASE_URL.ONBOARD_SIGNUP,
      data: payload,
    }).subscribe(
      (response: Response) => {
        if (response.code == 200 || response.code == 201) {
          this.newOutlet_id = response.data.outlet_id;
          this.isExistingOwner = response.data.is_owner_exists == 1;

          if (this.isExistingOwner) {
            this.createZceppaProduct().then((success) => {
              this.alertService
                .infoMsgWithCallback(this.existingUserSuccessMsg)
                .then((success) => {
                  if (success.isConfirmed) {
                    this.goToLogin();
                  }
                });
            });
          } else {
            this.createZceppaProduct().then((success) => {
              if (success) {
                // if (!response.data.is_otp_verified) {
                //   this.router.navigate(["/signin/mobile-otp"], {
                //     queryParams: { phone: formatted_number },
                //   });
                // } else if (!response.data.is_email_verified) {
                //   this.router.navigate(["/signin/verify-email"], {
                //     queryParams: { phone: formatted_number },
                //   });
                // } else {
                //   this.goToLogin();
                // }

                if (!response.data.is_otp_verified) {
                  this.router.navigate(["/signin/verify-email"], {
                    queryParams: {
                      type: this.BASE_URL.onboardingStatus.type.admin,
                      user_email: data["email"]?.trim(),
                      phone: formatted_number,
                    },
                  });
                } else {
                  this.goToLogin();
                }
              }
            });
          }
          this.submitClicked = false;
          this.errorUiVisible = false;
          this.errorUiMsg = "";
        }
      },
      (err) => {
        this.submitClicked = false;
        this.errorUiVisible = true;
        this.errorUiMsg = err.error.message;
      }
    );
  }

  createZceppaProduct() {
    let res: Response;
    return new Promise((resolve, reject) => {
      this.HttpService.postRequestAdmin({
        customApiUrl: this.BASE_URL.CREATE_ZCEPPA_PRODUCT(this.newOutlet_id),
        data: {
          product: this.product_array,
        },
      }).subscribe((data) => {
        res = data;
        if (res.code === 200) {
          resolve(true);
        }
      });
    });
  }

  onProductSelect(product_id: string, idx: number) {
    console.log(product_id);
    if (this.selectedProduct[idx] === true) {
      this.selectedProduct[idx] = false;
      this.product_array.splice(this.product_array.indexOf(product_id), 1);
    } else {
      this.selectedProduct[idx] = true;
      this.product_array.push(product_id);
    }
    console.log(this.product_array);
  }
  checkmail() {
    this.emailnotvalid = true;
    if (
      this.businessDetailsData.email.value.match(
        /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,}$/
      )
    ) {
      this.emailnotvalid = true;
    } else {
      this.emailnotvalid = false;
    }
  }

  closeModel() {
    this.modalService.dismissAll();
    this.router.navigate(["../signin"]);
  }

  goToLogin() {
    this.router.navigate(["../signin"]);
  }

  errorBannerClose(data) {
    this.errorUiVisible = false;
    this.errorUiMsg = "";
  }
}
