import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { MultiLocationService } from "src/app/multi-location/multi-location.service";
import { ExpiryWarningService } from "src/app/services/expiry/expiry-warning.service";
import { GlobalVariable } from "../../app.component";
import { DataService, Response } from "../../dataService/data.service";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  options = {
    lang: "en",
    theme: "winter",
    settings: false,
    docked: false,
    boxed: false,
    opened: false,
  };
  private BASE_URL = GlobalVariable;
  logo_url: String;
  outlet_id: string = this.storage.get("outlet_id");
  @Output()
  messageEvent = new EventEmitter<Object>();
  @Output()
  toggleFullscreen = new EventEmitter<void>();
  showExpiry: boolean;
  isExpired: boolean;
  expiryText: string;
  isMultiLocation: boolean;
  business_id: any;
  planName: string;
  showMultiLocExpiry: boolean = false;

  constructor(
    public translate: TranslateService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router,
    private HttpService: DataService,
    private toastrService: ToastrService,
    private commonservice: CommonService,
    private multiLocationService: MultiLocationService,
    private expiryWarningService: ExpiryWarningService
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  ngOnInit() {
    let is_outlet_view = this.storage.get("is_outlet_view");
    this.business_id = this.storage.get("business_id");
    if (is_outlet_view) {
      let res: Response;
      const data_service = this.HttpService.get(
        this.BASE_URL.GET_LOGO_URL(this.outlet_id)
      );
      data_service.subscribe(
        (data: Response) => {
          res = data;
        },
        (error) => {},
        () => {
          this.logo_url = res.data["url"];
          this.storage.set("logo", this.logo_url);
        }
      );
      this.commonservice.logo_image.subscribe((result) => {
        if (result !== null) {
          this.logo_url = result;
        }
      });
    }
    this.multiLocationService.isMultiLocationView$.subscribe((response) => {
      this.isMultiLocation = response;
      console.log(this.isMultiLocation, "this.isMultiLocation");
      // if (this.isMultiLocation) {
      //   this.getMultiLocSubscriptionDetail();
      // } else {
      //   this.getSubscriptionDetail();
      // }
    });
    // commented for new expiry method
    // this.getSubscriptionDetail();
    this.checkPlanExpiry();
    this.checkMultiLocPlanExpiry();
  }

  checkPlanExpiry() {
    let planSubscription = this.expiryWarningService.expiryPlanDetails;
    let planName = planSubscription?.plan_name;
    let isExpired = planSubscription?.is_expired;
    let expiryText = planSubscription?.product_warning_text;
    if (expiryText?.length) {
      let today_date = this.commonservice.onDateFormat(
        new Date(),
        "YYYY-MM-DD"
      );
      let end_date: any = planSubscription?.plan_end_date;
      if (end_date !== null) {
        end_date = moment(end_date, "YYYY-MM-DD");
      }
      this.showExpiry = true;
      if (isExpired) {
        this.isExpired = true;
        // this.expiryText = `Your ${planName == 'TRIAL' ? 'free trial': 'plan'} has expired!`;
        this.expiryText = `Your subscription has expired. Renew to enjoy uninterrupted services.`;

      } else {
        // let date = moment.duration(end_date.diff(today_date)).asDays();
        let planEndDate = moment(end_date, "YYYY-MM-DD").format("DD-MM-YYYY");
        this.isExpired = false;
        // this.expiryText = `Your ${planName == 'TRIAL' ? 'trial': 'plan'} expires in <strong>${date}</strong> ${
        //   date < 1 ? "day" : "days"
        // }`;
        this.expiryText = `Your subscription will expire on ${planEndDate}. Renew to enjoy uninterrupted services.`;

      }
      this.planName = planName;
    }
  }


  checkMultiLocPlanExpiry() {
    let planSubscription = this.expiryWarningService.multiLocExpiryPlanDetails;
    let planName = planSubscription?.plan_name;
    let isExpired = planSubscription?.is_expired;
    let expiryText = planSubscription?.product_warning_text;
    if (expiryText?.length) {
      let today_date = this.commonservice.onDateFormat(
        new Date(),
        "YYYY-MM-DD"
      );
      let end_date: any = planSubscription?.plan_end_date;
      if (end_date !== null) {
        end_date = moment(end_date, "YYYY-MM-DD");
      }
      this.showMultiLocExpiry = true;
      if (isExpired) {
        this.isExpired = true;
        // this.expiryText = `Your ${planName == 'TRIAL' ? 'free trial': 'plan'} has expired!`;
        this.expiryText = `Your subscription has expired. Renew to enjoy uninterrupted services.`;

      } else {
        let date = moment.duration(end_date.diff(today_date)).asDays();
        this.isExpired = false;
        // this.expiryText = `Your ${planName == 'TRIAL' ? 'trial': 'plan'} expires in <strong>${date}</strong> ${
        //   date < 1 ? "day" : "days"
        // }`;
        let planEndDate = moment(end_date, "YYYY-MM-DD").format("DD-MM-YYYY");
        this.expiryText = `Your subscription will expire on ${planEndDate}. Renew to enjoy uninterrupted services.`;
      }
      this.planName = planName;
    }
  }

  // commented for new expiry method
  getMultiLocSubscriptionDetail() {
    this.HttpService.getRequest({
      customApiUrl: this.BASE_URL.GET_SUBSCRIPTION_DETAIL(this.business_id),
    }).subscribe((response) => {
      console.log(response);
      if (response.code == 200) {
        let subscriptions = response.data.subscriptions;
        let today_date = this.commonservice.onDateFormat(
          new Date(),
          "YYYY-MM-DD"
        );
        let end_date = subscriptions.subscription_end;
        let is_trial = subscriptions.plan_name == "Trial";
        if (end_date !== null) {
          end_date = moment(end_date, "YYYY-MM-DD");
          let date = moment.duration(end_date.diff(today_date)).asDays();
          if (date <= 15) {
            this.showExpiry = true;
            if (date < 0) {
              this.isExpired = true;
              this.expiryText = `Your account has expired!`;
            } else {
              this.isExpired = false;
              this.expiryText = `Your account expires in <strong>${date}</strong> ${
                date < 1 ? "day" : "days"
              }`;
            }
          }
          console.log(date);
        }
      }
    });
  }

  // commented for new expiry method
  getSubscriptionDetail() {
    let res: Response;
    this.HttpService.get(this.BASE_URL.ACCOUNT_API(this.outlet_id)).subscribe(
      (data: Response) => {
        res = data;
        // console.log(res);
      },
      (error) => {},
      () => {
        this.showExpiry = false;
        let subscriptions = res.data["payload"].outlets[0].subscriptions;
        let outlet_status = res.data["payload"].outlets[0].outlet_status;
        let today_date = this.commonservice.onDateFormat(
          new Date(),
          "YYYY-MM-DD"
        );
        let end_date = subscriptions.subscription_end;
        let is_trial = subscriptions.plan_name == "Trial";
        if (end_date !== null && outlet_status !== "Demo") {
          end_date = moment(end_date, "YYYY-MM-DD");
          let date = moment.duration(end_date.diff(today_date)).asDays();
          if (date <= 15 && is_trial) {
            this.showExpiry = true;
            if (date < 0) {
              this.isExpired = true;
              this.expiryText = `Your free trial expired!`;
            } else {
              this.isExpired = false;
              this.expiryText = `Your trial expires in <strong>${date}</strong> ${
                date < 1 ? "day" : "days"
              }`;
            }
          }
          console.log(date);
        }
      }
    );
  }
  renewOutlet() {
    // this.HttpService.postjson({ customApiUrl: this.BASE_URL.RENEW_PRODUCT_API(this.outlet_id)}).subscribe((data: Response) => {
    const dataService = this.HttpService.postRequest({
      customApiUrl: this.BASE_URL.RENEW_PRODUCT_API(this.outlet_id),
    });
    dataService.subscribe(
      (data: Response) => {
        console.log(data);
        this.toastrService.success(data.data["message"]);
      },
      (error) => {
        this.toastrService.error(error.error.message);
      },
      () => {
    // commented for new expiry method
        // this.getSubscriptionDetail();
      }
    );
  }

  logodashboard() {
    let landingMenu = this.storage.get("access").landing_menu;
    this.router.navigate([`/${this.commonservice.setRoutingURL(landingMenu)}`]);
  }
  sendMessage() {
    this.messageEvent.emit(this.options);
  }

  setTheme(theme) {
    this.options.theme = theme;
    this.sendMessage();
  }
}
