import { FeatureParams, SubFeatures, GetValues, FeatureKey } from "./common";

export type InteractSubFeatures = {
  quick_reply: "quick_reply";
  gmb_website_widget: "gmb_website_widget";
  status: "status";
  insights: "insights";
  interact_report: "interact_report";
  conversation_category: "conversation_category";
  central_waba: "central_waba";
  conversation_chat_template: "conversation_chat_template";
};

export const INTERACT_SUBFEATURES: InteractSubFeatures = {
  quick_reply: "quick_reply",
  gmb_website_widget: "gmb_website_widget",
  status: "status",
  insights: "insights",
  interact_report: "interact_report",
  conversation_category: "conversation_category",
  central_waba: "central_waba",
  conversation_chat_template: "conversation_chat_template",
};
interface InteractParams
  extends FeatureParams<"feature_interact", GetValues<InteractSubFeatures>> {}
export interface InteractFeatures
  extends SubFeatures<GetValues<InteractSubFeatures>> {}

export interface InteractStructure {
  [key: string]: InteractParams;
}
