import {
  Directive,
  Input,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  Renderer2,
  ViewChild,
  HostBinding,
} from "@angular/core";
import { FeaturesByPlanService } from "./features-by-plan.service";
import { FeatureParams, MultipleCondition } from "./interface/common";
@Directive({
  selector: "[hasFeature]",
})
export class FeaturesByPlanDirective {
  private _disableView: MultipleCondition = null;
  constructor(
    private featuresByPlan: FeaturesByPlanService,
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2
  ) {}
  @Input() set hasFeature(features: FeatureParams) {
    this.evaluate(features);
  }

  // To check multiple condition eg: hasFeature="['val'];disableView"

  @Input() set hasFeatureDisableView(val: MultipleCondition) {
    console.log(val);
    this._disableView = val;
  }

  public async evaluate(features: FeatureParams) {
    if (Array.isArray(features)) {
      let [feature, subFeatureKey, subFeatureCondition] = features;
      let response = this.featuresByPlan.getFeatures(feature);
      const subFeatures = await response;
      switch (subFeatureCondition) {
        case "is_applicable":
          try {
            let isApplicable: boolean = (
              await this.featuresByPlan.getFeature(features, subFeatures)
            ).isApplicable();
            this.updateView(isApplicable);
            return isApplicable;
          } catch (error) {
            return false;
          }
        case "values":
          let isAvailable = (
            await this.featuresByPlan.getFeature(features, subFeatures)
          ).checkValues();
          return isAvailable;
        case "count":
          let isAllowed = (
            await this.featuresByPlan.getFeature(features, subFeatures)
          ).checkCount(this._disableView);
          this.updateView(true);
          this.disableOption(isAllowed);
          console.log(isAllowed);
          return isAllowed;
        default:
          break;
      }
    } else {
      this.updateView(false);
    }
  }

  private updateView(isApplicable: boolean) {
    if (isApplicable) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  disableOption(isDisabled: boolean) {
    if (this.element.nativeElement.previousSibling) {
      this.element.nativeElement.previousSibling.disabled = isDisabled;
      // this.renderer.setProperty(
      //   this.element.nativeElement,
      //   "disabled",
      //   isDisabled
      // );
      //   this.element.nativeElement.previousSibling.setAttribute(
      //     "disabled",
      //     isDisabled
      //   );
      // } else {
      //   this.element.nativeElement.previousSibling.removeAttribute("disabled");
    }
  }
}
