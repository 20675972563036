<div class="col-md-12 col-sm-12 col-xs-12">
  <div class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 reviewbottom">
    <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureview">
      <img
        [src]="logo_url"
        class="img-responsive"
        width="100"
        *ngIf="logo_url != undefined"
      />
      <h4>&nbsp;&nbsp;&nbsp;{{ business_name }}</h4>
    </div>
    <p>Thank you for choosing us. We would like to know you better!</p>
    <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureviewform">
      <form
        class="sec_margin_space profile_form"
        [formGroup]="personelDetailForm"
        (ngSubmit)="sendPersonelDetails(personelDetailForm)"
      >
        <div class="col-md-12 col-sm-12">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              formControlName="name"
              pattern="[a-zA-Z ]*"
              [ngClass]="{
                'red-error-class':
                  isValidFormSubmitted == false && customerFormData.name.errors
              }"
              id="name"
              placeholder="Name"
              required
            />
          </div>
          <div
            *ngIf="customerFormData.name.errors?.pattern"
            class="invalid-feedback"
          >
            <p>Customer Name should be Alphabets.</p>
          </div>
        </div>
        <!-- <div class='col-md-12 col-sm-12'>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="last_name" pattern="[a-zA-Z ]*" [ngClass]="{'red-error-class' : isValidFormSubmitted == false && customerFormData.last_name.errors}" id="last_name" placeholder="Last Name">
            </div>
            <div *ngIf="customerFormData.last_name.errors?.pattern" class="invalid-feedback">
                <p>Last Name should be Alphabets.</p>
            </div>
          </div> -->
        <!-- <div class='col-md-12 col-sm-12'>
            <div class="form-group">
              <input type="email" class="form-control" formControlName="email_id" id="email" placeholder="Email" (keyup)="checkmail()">
            </div>
            <div *ngIf="customerFormData.email_id.value!='' && emailnotvalid!=true" class="invalid-feedback">
                <p>Email is Not valid</p>
            </div>
          </div> -->
        <div class="col-md-12 col-sm-12">
          <div class="form-group">
            <input
              type="tel"
              class="form-control"
              formControlName="mobile_number"
              pattern="[0-9]*"
              minlength="10"
              maxlength="10"
              [ngClass]="{
                'red-error-class':
                  isValidFormSubmitted == false &&
                  customerFormData.mobile_number.errors
              }"
              id="mobile"
              placeholder="Mobile"
              required
            />
          </div>
          <div
            *ngIf="customerFormData.mobile_number.errors?.pattern"
            class="invalid-feedback"
          >
            <p>Mobile number should be number .</p>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 text-center">
          <button [disabled]="isSubmitClicked" class="action-button btn">
            Continue
          </button>
        </div>
      </form>
    </div>
    <!-- <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureviewfooter">
          <p>Powered by</p>
          <img src="assets/images/zceppadark.svg" class="img-responsive">
      </div> -->
  </div>
</div>

<footer>
  <div class="col-md-12 col-col-sm-12 col-xs-12">
    <div
      class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 thankyoureviewfooter"
    >
      <p>Powered by</p>
      <img src="assets/images/zceppadark.svg" class="img-responsive" />
    </div>
  </div>
</footer>
