import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-onboard-curved-ui",
  templateUrl: "./onboard-curved-ui.component.html",
  styleUrls: ["./onboard-curved-ui.component.scss"],
})
export class OnboardCurvedUiComponent implements OnInit {
  @Input() isMargin: boolean = false;
  @Input() isBigMargin: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
