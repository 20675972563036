import { FeatureParams, SubFeatures, GetValues, FeatureKey } from "./common";

export type TeamsSubFeatures = {
  //   platforms: "platforms";
  no_of_members: "no_of_members";
  leaves: "leaves";
  owner_preference: "owner_preference";
};

export const TEAMS_SUBFEATURES: TeamsSubFeatures = {
  //   platforms: "platforms",
  no_of_members: "no_of_members",
  leaves: "leaves",
  owner_preference: "owner_preference",
};
interface TeamsParams
  extends FeatureParams<"feature_teams", GetValues<TeamsSubFeatures>> {}
export interface TeamsFeatures
  extends SubFeatures<GetValues<TeamsSubFeatures>> {}

export interface TeamsStructure {
  [key: string]: TeamsParams;
}
