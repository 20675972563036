import { Routes } from "@angular/router";
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";
import { MainMenuPermissionGuard } from "./auth/permission-guard";
import { BusinessOnboardingTweakedComponent } from "./business-onboarding-tweaked/business-onboarding-tweaked.component";
import {
  AdminLayoutComponent,
  AuthLayoutComponent,
  ReviewImprovementComponent,
  ReviewLayoutComponent,
  ReviewReplyComponent,
} from "./core";
import { GatherInfoComponent } from "./core/gather-info/gather-info.component";
// import { AnalyticsComponent } from './analytics/analytics.component';
import { PermissionsComponent } from "./permissions/permissions.component";
import { FeaturesByPlanResolver } from "./services/features-by-plan/FBP-resolver/features-by-plan.resolver";
import { ProfileComponent } from "./settings/profile/profile.component";
import { UserAPIPageComponent } from "./user-api-page/user-api-page.component";
import { EmailVerificationComponent } from "./account/email-verification/email-verification.component";
import { OnboardStepOneComponent } from "./account-onboard/onboarding/onboard-step-one/onboard-step-one.component";
import { OnBoardGuard } from "./auth/on-board-guard";

export const AppRoutes: Routes = [
  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "business-onboarding",
    component: BusinessOnboardingTweakedComponent,
  },
  { path: "qr/:id", component: ReviewLayoutComponent },
  { path: "review-improvement", component: ReviewImprovementComponent },
  { path: "rr/:id", component: ReviewReplyComponent },
  {
    path: "signin",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "account",
        loadChildren: () =>
          import("./account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "error",
        loadChildren: () =>
          import("./error/error.module").then((m) => m.ErrorModule),
      },
    ],
  },
  // {
  //   path: "verify-email",
  //   component: EmailVerificationComponent,
  // },
  {
    path: "onboarding",
    // component: OnboardStepOneComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./account-onboard/onboarding/onboarding.module").then(
                (m) => m.OnboardingModule
              ),
            data: { title: "Onboarding" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    // canDeactivate: [QueryGuard],
    children: [
      {
        path: "",
        canActivate: [OnBoardGuard],
        children: [
          {
            path: "dashboard",
            loadChildren: () =>
              import("./dashboard/dashboard.module").then(
                (m) => m.DashboardModule
              ),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Dashboard" },
          },
          {
            path: "gather-info",
            component: GatherInfoComponent,
          },
          {
            path: "api-docs",
            component: UserAPIPageComponent,
          },
          {
            path: "reviews",
            loadChildren: () =>
              import("./reviews/reviews.module").then((m) => m.ReviewsModule),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Reviews" },
          },
          {
            path: "insights",
            loadChildren: () =>
              import("./insights/insights.module").then(
                (m) => m.InsightsModule
              ),
          },
          {
            path: "interact",
            loadChildren: () =>
              import("./interact/interact.module").then(
                (m) => m.InteractModule
              ),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Interact" },
          },
          // {
          //   path: 'analytics',
          //   loadChildren: './analytics/analytics.module#AnalyticsModule',
          //   canActivate: [MainMenuPermissionGuard],
          //   data: { title: 'Analytics' }
          // },
          {
            path: "customers",
            loadChildren: () =>
              import("./clients/clients.module").then((m) => m.ClientsModule),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Customers" },
          },
          {
            path: "messaging",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import("./messaging/messaging.module").then(
                    (m) => m.MessagingModule
                  ),
                canActivate: [MainMenuPermissionGuard],
                data: { title: "Messaging", featureKey: "feature_messaging" },
                resolve: { featuresByPlan: FeaturesByPlanResolver },
              },
              {
                path: "campaign",
                loadChildren: () =>
                  import(
                    "./send-campaign-invite/send-campaign-invite.module"
                  ).then((m) => m.SendCampaignInviteModule),
                canActivate: [MainMenuPermissionGuard],
                data: { title: "Campaign" },
              },
            ],
          },

          {
            path: "social",
            loadChildren: () =>
              import("./social/social.module").then((m) => m.SocialModule),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Social", featureKey: "feature_social" },
            resolve: { featuresByPlan: FeaturesByPlanResolver },
          },
          {
            path: "send-review-invite",
            loadChildren: () =>
              import("./send-review-invite/send-review-invite.module").then(
                (m) => m.SendReviewInviteModule
              ),
          },
          {
            path: "support",
            loadChildren: () =>
              import("./support/support.module").then((m) => m.SupportModule),
          },
          {
            path: "accounts",
            loadChildren: () =>
              import("./accounts/accounts.module").then(
                (m) => m.AccountsModule
              ),
          },
          {
            path: "profile",
            component: ProfileComponent,
            // canActivate: [SettingsPermissionGuard],
            data: { title: "Profile" },
          },
          {
            path: "notifications",
            loadChildren: () =>
              import("./notifications/notifications.module").then(
                (m) => m.NotificationsModule
              ),
          },
          {
            path: "team",
            loadChildren: () =>
              import("./team/team.module").then((m) => m.TeamModule),
            // canActivate: [MainMenuPermissionGuard],
            data: { title: "Team" },
          },
          {
            path: "leaderboard",
            loadChildren: () =>
              import("./team/leaderboard/leaderboard/leaderboard.module").then(
                (m) => m.LeaderboardModule
              ),
            data: { title: "Leaderboard" },
          },
          {
            path: "tickets",
            loadChildren: () =>
              import("./tickets/tickets.module").then((m) => m.TicketsModule),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Tickets" },
          },
          {
            path: "appointments",
            loadChildren: () =>
              import("./appointments/appointments.module").then(
                (m) => m.AppointmentsModule
              ),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Appointments" },
          },

          {
            path: "settings",
            loadChildren: () =>
              import("./settings/settings.module").then(
                (m) => m.SettingsModule
              ),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Settings" },
          },
          {
            path: "mystore",
            canActivate: [MainMenuPermissionGuard],
            loadChildren: () =>
              import("./my-store/my-store.module").then((m) => m.MyStoreModule),
            data: { title: "mystore", featureKey: "feature_mystore" },
            resolve: { featuresByPlan: FeaturesByPlanResolver },
          },
          {
            path: "listing-management",
            canActivate: [MainMenuPermissionGuard],
            loadChildren: () =>
              import("./gmb-listing/gmb-listing.module").then(
                (m) => m.GmbListingModule
              ),
            data: { title: "MyListings" },
          },
          {
            path: "calendar",
            loadChildren: () =>
              import("./calendar/calendar-main/calendar-main.module").then(
                (m) => m.CalendarMainModule
              ),
            canActivate: [MainMenuPermissionGuard],
            data: { title: "Calendar" },
          },
          // canActivate: [MainMenuPermissionGuard],
          // data: { title: 'Payment' }
          {
            path: "payments",
            canActivate: [MainMenuPermissionGuard],
            loadChildren: () =>
              import("./payment/payment.module").then((m) => m.PaymentModule),
            data: { title: "Payments" },
          },

          {
            path: "permissions/:id",
            component: PermissionsComponent,
          },
        ],
      },
      {
        path: "business",
        loadChildren: () =>
          import("./multi-location/multi-location.module").then(
            (m) => m.MultiLocationModule
          ),
        // children: [
        //   {
        //     path: "dashboard",
        //     loadChildren: () =>
        //       import("./multi-location/multi-location.module").then(
        //         (m) => m.MultiLocationModule
        //       ),
        //     // canActivate: [MainMenuPermissionGuard],
        //     data: { title: "Multi-Location Dashboard" },
        //   },
        // ],
      },
    ],
  },
  {
    path: "**",
    redirectTo: "error/404",
  },
];
