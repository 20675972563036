import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { CommonService } from "../services/common.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class MainMenuPermissionGuard implements CanActivate {
  accessInfo: Array<string>;

  constructor(
    public auth: AuthService,
    public router: Router,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private commonservice: CommonService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    this.accessInfo = this.getMainMenu();
    // console.log("state", state, route.data.title, this.accessInfo);

    const isMenuAvailable = this.accessInfo.some(
      (_) => _.toLowerCase() === route.data.title.toLowerCase()
    );
    let landingMenu = this.storage.get("access").landing_menu;
    if (isMenuAvailable) return isMenuAvailable;
    else
      setTimeout(() => {
        this.router.navigate([
          `/${this.commonservice.setRoutingURL(landingMenu)}`,
        ]);
      }, 0);

    // return this.accessInfo.some(_ => _.toLowerCase() === route.data.title.toLowerCase())
  }
  getMainMenu() {
    if (
      this.storage.get("access") === null ||
      this.storage.get("access") === undefined
    ) {
      this.signout();
      return;
    }
    return this.storage.get("access").main_menu;
  }
  signout() {
    this.storage.remove("access_token");
    this.storage.remove("refresh_token");
    this.storage.remove("outlet_id");
    this.storage.remove("business_id");
    this.storage.remove("user_id");
    this.storage.remove("business_name");
    this.storage.remove("outlet_name");
    this.storage.remove("user_name");
    this.storage.remove("logo");
    this.storage.remove("review_count");
    this.storage.remove("default_template_id");
    this.storage.remove("role");
    this.storage.remove("access");
    this.storage.remove("has_password");
    this.router.navigate(["../signin"]);
    this.commonservice.setFirstView(false);
  }
}
