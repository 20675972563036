import { Component, OnInit, Inject } from "@angular/core";
import { CommonService } from "../../../app/services/common.service";
import { Router, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { DataService, Response } from "../../dataService/data.service";
import { GlobalVariable } from "../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { forkJoin } from "rxjs";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-review-reply",
  templateUrl: "./review-reply.component.html",
  styleUrls: ["./review-reply.component.scss"],
})
export class ReviewReplyComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  outlet_id: String = this.storage.get("outlet_id");
  slug_value: String;
  business_logo: string;
  business_name: string;
  customer_name: string;
  feedback: string;
  replied_by: string;
  feedback_reply: string;
  public loading = false;
  showPoweredByLogo: any;

  constructor(
    private commonservice: CommonService,
    private HttpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.loading = true;
    let res: Response;

    this.route.params.forEach((val) => {
      this.slug_value = val["id"];
    });
    const json_data = {
      slug: this.slug_value,
    };
    const logo_service = this.HttpService.postjson(
      this.BASE_URL.INTERNAL_REPLAY_SLUG_URL,
      json_data
    );
    logo_service.subscribe(
      (data: Response) => {
        res = data;
      },
      (error) => {
        this.toastrService.error(error.error.message);
      },
      () => {
        let lname: string;
        if (res.data["contact_details"].last_name === null) {
          lname = "";
        } else {
          lname = res.data["contact_details"].last_name;
        }
        this.business_logo = res.data["business_logo"];
        this.business_name = res.data["business_details"].name;
        this.customer_name =
          res.data["contact_details"].first_name + " " + lname;
        this.feedback = res.data["feedback"].feedback_text;
        this.replied_by = res.data["replied_by"].user_name;
        this.feedback_reply = res.data["feedback_reply"].feedback_reply_text;
        this.showPoweredByLogo = res.data["show_powered_by_logo"];
      }
    );
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.loading = false;
    }, 2000);
  }
}
