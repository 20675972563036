import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
} from "@danielmoncada/angular-datetime-picker";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { DataTablesModule } from "angular-datatables";
import { IconSpriteModule } from "ng-svg-icon-sprite";
import { ChartsModule } from "ng2-charts";
import { FileUploadModule } from "ng2-file-upload";
import { TooltipModule } from "ng2-tooltip-directive";
import { Ng4LoadingSpinnerModule } from "ng4-loading-spinner";
import { Ng5SliderModule } from "ng5-slider";
import { AccordionModule } from "ngx-accordion";
import { TagInputModule } from "ngx-chips";
import { NgxEchartsModule } from "ngx-echarts";
import { NgxGaugeModule } from "ngx-gauge";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgxLoadingModule } from "ngx-loading";
import { MomentModule } from "ngx-moment";
import { NgxPaginationModule } from "ngx-pagination";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { NgxPrintModule } from "ngx-print";
import { QuillModule } from "ngx-quill";
import { NgxSpinnerModule } from "ngx-spinner";
import { TabsModule } from "ngx-tabset";
import { TimeagoModule } from "ngx-timeago";
import { UiSwitchModule } from "ngx-toggle-switch";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { NgSelectModule } from "@ng-select/ng-select";
import { GrdFilterPipe } from "../filters/grd-filter.pipe";
import { SanitizeHtmlPipe } from "../filters/sanitize-html.pipe";
import { DynamicVariablesComponent } from "../messaging/common/dynamic-variables/dynamic-variables.component";
import { DateFormatterCustomPipe } from "../services/date-formatter-custom.pipe";
import { FeaturesByPlanDirective } from "../services/features-by-plan/features-by-plan.directive";
import { DynamicVariableComponent } from "../social/social-create-edite-post/social-share-main/field-sections/dynamic-variable/dynamic-variable.component";
import { NumberOnlyDirective } from "./../directives/number-only.directive";
import { AnalyticsEmailConversionComponent } from "./analytics-email-conversion/analytics-email-conversion.component";
import { AnalyticsInviteClickThroughRateComponent } from "./analytics-invite-click-through-rate/analytics-invite-click-through-rate.component";
import { AnalyticsInviteRateReviewsComponent } from "./analytics-invite-rate-reviews/analytics-invite-rate-reviews.component";
import { AnalyticsInviteSentReviewsComponent } from "./analytics-invite-sent-reviews/analytics-invite-sent-reviews.component";
import { AnalyticsMonthlyGaugesComponent } from "./analytics-monthly-gauges/analytics-monthly-gauges.component";
import { AnalyticsMonthlyGoalReviewsComponent } from "./analytics-monthly-goal-reviews/analytics-monthly-goal-reviews.component";
import { AnalyticsMonthlyReviewsComponent } from "./analytics-monthly-reviews/analytics-monthly-reviews.component";
import { AnalyticsReviewStatsComponent } from "./analytics-review-stats/analytics-review-stats.component";
import { AnalyticsReviewsComponent } from "./analytics-reviews/analytics-reviews.component";
import { AnalyticsSmsConversionComponent } from "./analytics-sms-conversion/analytics-sms-conversion.component";
import { CampaignSetupComponent } from "./campaign-setup/campaign-setup.component";
import { CampaignComponent } from "./campaign/campaign.component";
import { TileAnalyticsComponent } from "./dashboard-components/tile-analytics/tile-analytics.component";
import { TileRecentWidgetComponent } from "./dashboard-components/tile-recent-widget/tile-recent-widget.component";
import { TileStatisticsComponent } from "./dashboard-components/tile-statistics/tile-statistics.component";
import { TopTileOneComponent } from "./dashboard-components/top-tile-one/top-tile-one.component";
import { TopTileTwoComponent } from "./dashboard-components/top-tile-two/top-tile-two.component";
import { DraftMessageComponent } from "./draft-message/draft-message.component";
import { DraftSocialComponent } from "./draft-social/draft-social.component";
import { GoogleAnalyticsComponent } from "./google-analytics/google-analytics.component";
import { LeaderboardReviewsComponent } from "./leaderboard-reviews/leaderboard-reviews.component";
import { MonthlyReviewsGoalComponent } from "./monthly-reviews-goal/monthly-reviews-goal.component";
import { MonthlyReviewsComponent } from "./monthly-reviews/monthly-reviews.component";
import { OverallReviewsComponent } from "./overall-reviews/overall-reviews.component";
import { RatingBySiteComponent } from "./rating-by-site/rating-by-site.component";
import { RecentReviewsComponent } from "./recent-reviews/recent-reviews.component";
import { ReferalComponent } from "./referal/referal.component";
import { ReplyTemplatesComponent } from "./reply-templates/reply-templates.component";
import { RequestTemplateComponent } from "./request-template/request-template.component";
import { ReviewResponseComponent } from "./review-response/review-response.component";
import { ReviewSetupComponent } from "./review-setup/review-setup.component";
import { ReviewStatisticsComponent } from "./review-statistics/review-statistics.component";
import { SendReceivedReviewsComponent } from "./send-received-reviews/send-received-reviews.component";
import { SentMessageComponent } from "./sent-message/sent-message.component";
import { TemplatesModule } from "./templates/templates.module";
import { SortByPipe } from "../filters/sort-pipe";
import { SearchableMultiSelectComponent } from "./searchable-multi-select/searchable-multi-select.component";
import { CustomAccordianComponent } from "./custom-accordian/custom-accordian.component";
import { BannerComponent } from "./banner/banner.component";
import { CardHeadingComponent } from "./card-heading/card-heading.component";
import { PlatformStatsComponent } from "./platform-stats/platform-stats.component";
import { ReplyDynamicVariablesComponent } from "./reply-templates/reply-dynamic-variables/reply-dynamic-variables.component";
import { SettingsUiSharedModule } from "../settings/settings-ui-components/settings-ui-shared/settings-ui-shared.module";
import { ExpiryOverlayDirective } from "../services/expiry/expiry-overlay.directive";
import { ReviewSocialShareComponent } from "./review-social-share/review-social-share.component";
import { NodatafoundComponent } from "./nodatafound/nodatafound.component";
import { PredefineVarListComponent } from "./predefine-var-list/predefine-var-list.component";
import { ShortNumberPipe } from "../services/short-number.pipe";
import { OnboardOverlayPageComponent } from './onboard-overlay-page/onboard-overlay-page.component';
import { MaskPipe } from "../services/mask.pipe";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    OverallReviewsComponent,
    SendReceivedReviewsComponent,
    MonthlyReviewsComponent,
    MonthlyReviewsGoalComponent,
    ReviewStatisticsComponent,
    RecentReviewsComponent,
    AnalyticsMonthlyReviewsComponent,
    AnalyticsInviteRateReviewsComponent,
    AnalyticsInviteSentReviewsComponent,
    AnalyticsMonthlyGoalReviewsComponent,
    GrdFilterPipe,
    AnalyticsEmailConversionComponent,
    AnalyticsSmsConversionComponent,
    AnalyticsReviewsComponent,
    AnalyticsMonthlyGaugesComponent,
    AnalyticsInviteClickThroughRateComponent,
    ReviewResponseComponent,
    RequestTemplateComponent,
    ReviewSetupComponent,
    SanitizeHtmlPipe,
    ReferalComponent,
    GoogleAnalyticsComponent,
    AnalyticsReviewStatsComponent,
    CampaignComponent,
    ReplyTemplatesComponent,
    DraftSocialComponent,
    SentMessageComponent,
    DraftMessageComponent,
    CampaignSetupComponent,
    TopTileOneComponent,
    TopTileTwoComponent,
    TileAnalyticsComponent,
    TileRecentWidgetComponent,
    TileStatisticsComponent,
    LeaderboardReviewsComponent,
    NumberOnlyDirective,
    DateFormatterCustomPipe,
    FeaturesByPlanDirective,
    DynamicVariableComponent,
    DynamicVariablesComponent,
    RatingBySiteComponent,
    SortByPipe,
    SearchableMultiSelectComponent,
    CustomAccordianComponent,
    BannerComponent,
    CardHeadingComponent,
    PlatformStatsComponent,
    ReplyDynamicVariablesComponent,
    ExpiryOverlayDirective,
    ReviewSocialShareComponent,
    NodatafoundComponent,
    PredefineVarListComponent,
    ShortNumberPipe,
    OnboardOverlayPageComponent,
    MaskPipe
  ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    NgxSpinnerModule,
    NgbModule,
    NgxLoadingModule.forRoot({}),
    // RoundProgressModule,
    NgxChartsModule,
    DataTablesModule,
    NgxPaginationModule,
    FormsModule,
    ChartsModule,
    UiSwitchModule,
    TimeagoModule.forRoot(),
    NgxGaugeModule,
    NgxQRCodeModule,
    TagInputModule,
    TooltipModule,
    ReactiveFormsModule,
    RouterModule,
    Ng4LoadingSpinnerModule.forRoot(),
    MomentModule,
    IconSpriteModule,
    GooglePlaceModule,
    NgxPrintModule,
    Ng5SliderModule,
    PerfectScrollbarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AccordionModule,
    FileUploadModule,
    NgxEchartsModule,
    // NgxEchartsModule.forRoot({ echarts }) ,
    QuillModule,
    QuillModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDf7XPr71mOfaytj0dUWdWaghEoBAb-sE4",
      libraries: ["places"],
    }),
    TemplatesModule,
    NgSelectModule,
    TabsModule,
    SettingsUiSharedModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    OverallReviewsComponent,
    SendReceivedReviewsComponent,
    MonthlyReviewsComponent,
    MonthlyReviewsGoalComponent,
    ReviewStatisticsComponent,
    RecentReviewsComponent,
    AnalyticsMonthlyReviewsComponent,
    AnalyticsInviteRateReviewsComponent,
    AnalyticsInviteSentReviewsComponent,
    AnalyticsMonthlyGoalReviewsComponent,
    GrdFilterPipe,
    AnalyticsEmailConversionComponent,
    AnalyticsSmsConversionComponent,
    AnalyticsReviewsComponent,
    TimeagoModule,
    NgxGaugeModule,
    AnalyticsMonthlyGaugesComponent,
    AnalyticsInviteClickThroughRateComponent,
    ReviewResponseComponent,
    RequestTemplateComponent,
    ReviewSetupComponent,
    NgxQRCodeModule,
    ReactiveFormsModule,
    // Ng4LoadingSpinnerModule.forRoot(),
    MomentModule,
    GooglePlaceModule,
    AgmCoreModule,
    SanitizeHtmlPipe,
    ReferalComponent,
    Ng5SliderModule,
    GoogleAnalyticsComponent,
    AnalyticsReviewStatsComponent,
    CampaignComponent,
    ReplyTemplatesComponent,
    DraftSocialComponent,
    SentMessageComponent,
    DraftMessageComponent,
    CampaignSetupComponent,
    // MessagingComponent,
    TopTileOneComponent,
    TopTileTwoComponent,
    TileAnalyticsComponent,
    TileRecentWidgetComponent,
    TileStatisticsComponent,
    LeaderboardReviewsComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NumberOnlyDirective,
    DateFormatterCustomPipe,
    FeaturesByPlanDirective,
    DynamicVariableComponent,
    DynamicVariablesComponent,
    RatingBySiteComponent,
    SearchableMultiSelectComponent,
    BannerComponent,
    SortByPipe,
    CustomAccordianComponent,
    CardHeadingComponent,
    PlatformStatsComponent,
    ExpiryOverlayDirective,
    ReviewSocialShareComponent,
    NodatafoundComponent,
    PredefineVarListComponent,
    ShortNumberPipe,
    NgxSkeletonLoaderModule,
    OnboardOverlayPageComponent,
    MaskPipe
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: false },
    },
  ],
})
export class SharedModule {}
