<ngx-loading [show]="loading"></ngx-loading>
<div class="col-md-12 col-sm-12 col-xs-12">
  <div class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 reviewbottom">
    <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureview">
      <span
        ><img
          [src]="business_logo"
          class="img-responsive"
          *ngIf="business_logo != null"
        />
        <span class="bus_name_review">{{ business_name }}</span></span
      >
    </div>
    <div class="col-md-12 col-col-sm-12 col-xs-12 text-center">
      <!-- <h5>Hi Customer Name,</h5> -->
    </div>
    <div class="panel-body">
      <ul class="chat">
        <li class="left clearfix">
          <div class="chat-body clearfix">
            <div class="header">
              <strong class="primary-font">{{ customer_name }}</strong>
              <small class="pull-right text-muted">
                <span class="glyphicon glyphicon-time"></span
              ></small>
            </div>
            <p>
              {{ feedback }}
            </p>
          </div>
        </li>
        <li class="right clearfix">
          <div class="chat-body clearfix">
            <div class="header">
              <small class="text-muted"
                ><span class="glyphicon glyphicon-time"></span
              ></small>
              <strong
                *ngIf="replied_by != null"
                class="pull-right primary-font"
                >{{ replied_by }}</strong
              >
              <strong
                *ngIf="replied_by == null"
                class="pull-right primary-font"
                >{{ business_name }}</strong
              >
            </div>
            <p>
              {{ feedback_reply }}
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<footer *ngIf="showPoweredByLogo">
  <div class="col-md-12 col-col-sm-12 col-xs-12">
    <div
      class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 thankyoureviewfooter"
    >
      <p>Powered by</p>
      <img src="assets/images/zceppadark.svg" class="img-responsive" />
    </div>
  </div>
</footer>
