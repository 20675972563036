<div class="ui-container h-100">
  <div class="white-bg h-100">
    <div class="d-flex m-0 row-container"
      [ngClass]="{'pdt-150':isMargin, 'pdt-200':isBigMargin}">
      <div class="image-bg-container">
        <img src="assets/images/onboarding/left-circle.svg" class="left-circle" alt="pattern">
        <div class="image-container">
          <img class="product-image"
          [src]="selectedData?.product_image"/>
        </div>
      </div>
      <div class="text-section">
        <img src="assets/images/onboarding/onboard_blue_dot.svg" class="pattern-img" alt="pattern">
        <p class="text-heading">
          {{selectedData?.product_name}}
        </p>
        <p class="text-description">
          {{selectedData?.product_description}}
        </p>
        <div *ngIf="selectedData?.type==1">
          <ul>
            <li *ngFor="let desc of selectedData?.product_details">
              <span class="text-details">{{desc}}</span>
            </li>
          </ul>
        </div>
        <div *ngIf="selectedData?.type==2">
          <ol>
            <li *ngFor="let desc of selectedData?.product_details">
              <span class="text-details">{{desc}}</span>
            </li>
          </ol>
        </div>
        <div *ngIf="selectedData?.type==3">
          <p class="mb-0 product-detail" *ngFor="let desc of selectedData?.product_details">
            {{desc}}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="isBottomCircleReq" class="bottom-circle-container">
      <img src="assets/images/onboarding/bottom-circle.svg" class="bottom-circle" alt="pattern">
    </div>
  </div>
</div>
