import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingHeaderComponent } from './setting-header/setting-header.component';
import { SettingTitleComponent } from './setting-title/setting-title.component';
import { SettingDescriptionComponent } from './setting-description/setting-description.component';
import { SettingUiContainerComponent } from './setting-ui-container/setting-ui-container.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    SettingHeaderComponent,
    SettingTitleComponent,
    SettingDescriptionComponent,
    SettingUiContainerComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
  ],
  exports: [
    SettingHeaderComponent,
    SettingTitleComponent,
    SettingDescriptionComponent,
    SettingUiContainerComponent
  ]
})
export class SettingsUiSharedModule { }
