import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { NgxLoadingModule } from "ngx-loading";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AccountRoutes } from "./account.routing";
import { LockscreenComponent } from "./lockscreen/lockscreen.component";
import { OtpService } from "./services/otp.service";
import { SigninComponent } from "./signin/signin.component";
import { SignupComponent } from "./signup/signup.component";
import { OnboardRandomUiComponent } from "./onboard-random-ui/onboard-random-ui.component";
import { NgOtpInputModule } from "ng-otp-input";
import { EmailVerificationComponent } from "./email-verification/email-verification.component";
import { OnboardCurvedUiComponent } from "./onboard-curved-ui/onboard-curved-ui.component";
import { MobileOtpComponent } from "./mobile-otp/mobile-otp.component";
import { CreatePasswordComponent } from "./create-password/create-password.component";
import { SuccessToastUiComponent } from "./success-toast-ui/success-toast-ui.component";
import { ErrorToastUiComponent } from "./error-toast-ui/error-toast-ui.component";
import { WebsiteSignupComponent } from "./website-signup/website-signup/website-signup.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AccountRoutes),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxUiLoaderModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    NgOtpInputModule,
    NgxLoadingModule.forRoot({
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#03c0c2",
      secondaryColour: "#03c0c2",
      tertiaryColour: "#03c0c2",
    }),
  ],
  declarations: [
    SigninComponent,
    SignupComponent,
    LockscreenComponent,
    OnboardRandomUiComponent,
    EmailVerificationComponent,
    OnboardCurvedUiComponent,
    MobileOtpComponent,
    CreatePasswordComponent,
    SuccessToastUiComponent,
    ErrorToastUiComponent,
    WebsiteSignupComponent,
  ],
  exports: [
    OnboardRandomUiComponent,
    OnboardCurvedUiComponent,
    ErrorToastUiComponent,
    SuccessToastUiComponent,
  ],
  providers: [OtpService, HttpClientModule, CookieService],
})
export class AccountModule {}
