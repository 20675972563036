<div class="row">
  <div class="col-sm-6 col-md-6 col-lg-6 p-0 mob-hide">
    <app-onboard-random-ui></app-onboard-random-ui>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 min-height-100 h-100 mob-h-auto">
    <ngx-loading [show]="loading"></ngx-loading>
    <div class="col-xs-12 login_page">
      <img src="assets/images/zceppa.png" class="signpcontimg" />
      <label class="heading">Create Password</label>

      <div class="col-lg-12 col-md-12 col-xs-12 login_sec">
        <div class="form-group mt-3 mb-0 input-wrapper">
          <input [type]="passwordType" placeholder="Password" class="form-control"
            [ngClass]="{ 'red-error-class': isSubmitted && (newPassword !== confirmPassword || !isMinLength || !isSpecialContain || !isNumberContain)}"
            [(ngModel)]="newPassword"
            (ngModelChange)="onPasswordChange()"/>

          <div *ngIf="!showPass" (click)="showPassword(1)">
            <img class="hide-image" src="../../../assets/images/onboarding/eye-close.svg"
              alt="password visibility"/>
          </div>
          <div *ngIf="showPass" (click)="showPassword(1)">
            <img class="hide-image" src="../../../assets/images/onboarding/eye.svg"
              alt="password visibility"/>
          </div>
        </div>
        <div class="form-group mt-3 mb-0 input-wrapper">
          <input [type]="passwordTypeForConfirm" placeholder="Confirm password" class="form-control"
            [ngClass]="{ 'red-error-class': isSubmitted && (newPassword !== confirmPassword || !isMinLength || !isSpecialContain || !isNumberContain)}"
            [(ngModel)]="confirmPassword"
            (ngModelChange)="onConfirmPasswordChange()"/>

          <div *ngIf="!showPassForConfirm" (click)="showPassword(2)">
            <img class="hide-image" src="../../../assets/images/onboarding/eye-close.svg"
              alt="password visibility"/>
          </div>
          <div *ngIf="showPassForConfirm" (click)="showPassword(2)">
            <img class="hide-image" src="../../../assets/images/onboarding/eye.svg"
              alt="password visibility"/>
          </div>
        </div>

        <div>
          <div>
            <img *ngIf="isMinLength" src="assets/images/onboarding/checkmark-password.svg">
            <img *ngIf="!isMinLength" src="assets/images/onboarding/cross_password.svg">
            <span class="ml-2 validation-text">At least 8 characters</span>
          </div>
          <div class="mt-1">
            <img *ngIf="isSpecialContain" src="assets/images/onboarding/checkmark-password.svg">
            <img *ngIf="!isSpecialContain" src="assets/images/onboarding/cross_password.svg">
            <span class="ml-2 validation-text">Contains a symbol</span>
          </div>
          <div class="mt-1">
            <img *ngIf="isNumberContain" src="assets/images/onboarding/checkmark-password.svg">
            <img *ngIf="!isNumberContain" src="assets/images/onboarding/cross_password.svg">
            <span class="ml-2 validation-text">Contains a number</span>
          </div>
          <div class="mt-1">
            <!-- newPassword.trim().length -->
            <img *ngIf="newPassword.length && newPassword == confirmPassword" src="assets/images/onboarding/checkmark-password.svg">
            <img *ngIf="!newPassword.length || newPassword !== confirmPassword" src="assets/images/onboarding/cross_password.svg">
            <span class="ml-2 validation-text">Password and Confirm password are same</span>
          </div>
        </div>

        <div *ngIf="errorUiVisible">
          <app-error-toast-ui [imageUrl]="'assets/images/onboarding/error-toastr.png'"
            [headerText]="errorUiMsg" (outputDataClose)="errorBannerClose($event)">
          </app-error-toast-ui>
        </div>

        <div class="form-group">
          <button type="button" class="submit-btn w-100" (click)="createPassword()"
            [disabled]="loading">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
