import { Inject, Pipe, PipeTransform } from "@angular/core";
import { Formats } from "./utils";
import { CommonService } from "./common.service";
@Pipe({
  name: "dateFormatter",
  pure: false,
})
export class DateFormatterCustomPipe implements PipeTransform {
  transform(date: string, format: Formats): string {
    return this.commonService.onDateFormat(date, format);
  }

  constructor(private commonService: CommonService) {}
}
