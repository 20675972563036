export interface ProductFeature {
  REVIEWS: "Reviews";
  INTERACT: "Interact";
  SOCIAL: "Social";
  MESSAGING: "Messaging";
  APPOINTMENTS: "Appointments";
  CUSTOMERS: "Customers";
  TEAM: "Team";
  SETTINGS: "Settings";
  MYSTORE: "MyStore";
  LISTING_MANAGEMENT: "MyListings";
  TICKETS: "Tickets";
  BUSINESS_PROFILE: "Business Profile";
  INTEGRATIONS: "Integrations";
  NOTIFICATIONS: "Notifications";
  PROFILE: "Profile";
  MULTILOC_BUSINESS_PROFILE: "Business Profile";
  MULTILOCATION: "Central Dashboard";
  UNIFIED_REVIEWS: "Unified Reviews";
  REPORT_INSIGHTS: "Report & Insights";
  BUSINESS_TEAM: "Team";
  BUSINESS_NOTIFICATIONS: "Notifications";
  BUSINESS_LOCATION_GROUPS: "Location Groups";
  BUSINESS_SETTINGS: "Settings";
  WHITE_LABEL: "White Label";
};

export const PRODUCT_NAMES: ProductFeature = {
  REVIEWS: "Reviews",
  INTERACT: "Interact",
  SOCIAL: "Social",
  MESSAGING: "Messaging",
  APPOINTMENTS: "Appointments",
  CUSTOMERS: "Customers",
  TEAM: "Team",
  SETTINGS: "Settings",
  MYSTORE: "MyStore",
  LISTING_MANAGEMENT: "MyListings",
  TICKETS: "Tickets",
  BUSINESS_PROFILE: "Business Profile",
  INTEGRATIONS: "Integrations",
  NOTIFICATIONS: "Notifications",
  PROFILE: "Profile",
  MULTILOC_BUSINESS_PROFILE: "Business Profile",
  MULTILOCATION: "Central Dashboard",
  UNIFIED_REVIEWS: "Unified Reviews",
  REPORT_INSIGHTS: "Report & Insights",
  BUSINESS_TEAM: "Team",
  BUSINESS_NOTIFICATIONS: "Notifications",
  BUSINESS_LOCATION_GROUPS: "Location Groups",
  BUSINESS_SETTINGS: "Settings",
  WHITE_LABEL: "White Label"
};

export type ExpiryProductDetails = {
  [key in keyof ProductFeature]?: {
    is_expired: boolean;
    product_warning_text: string;
    start_date: string;
    end_date: string;
  };
};

export type PlanNames =
  | "STARTER"
  | "BASIC"
  | "STANDARD"
  | "STANDARD_PLUS"
  | "ADVANCED"
  | "ADVANCED_PLUS"
  | "BASIC_PLUS"
  | "TRIAL"
  | "DEMO"
  | "Enterprise"
  | "ENTERPRISE";

export interface ExpiryPlanDetails {
  is_expired: boolean;
  plan_name: PlanNames;
  plan_start_date: string;
  plan_end_date: string;
  product_warning_text: string;
}
