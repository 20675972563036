<ngx-loading [show]="loading"></ngx-loading>
<div class="card card-border-radius reviewtabs box_shadow_color m-3 p-3">
  <div class="header">
    <h5 class="title">API Key</h5>
  </div>
  <ng-container *ngIf="apiDetails?.apikey; else empty_page">

    <app-banner [bannerType]="'info'" [bannerIconRequired]="false" [bannerInfoPrefixRequired]="true" 
      [bannerText]="infoMessage">
    </app-banner>     

    <div class="sec">
      <div class="row d-flex flex-row justify-content-between mb-2">
        <div class="col-4">
          <label for="">API Key</label>
        </div>
        <div class="col d-flex">
          <span [class.shadow-text]="isBlur">{{ apiDetails?.apikey }}</span>
          <div class="mx-2">
            <img
              *ngIf="!isBlur"
              (click)="isBlur = !isBlur"
              src="assets/images/unhide (1).svg"
              alt=""
              class="cursor-pointer hide-img"
            />
            <img
              *ngIf="isBlur"
              (click)="isBlur = !isBlur"
              src="assets/images/hide (1).svg"
              class="cursor-pointer hide-img"
            />
          </div>
        </div>
      </div>
      <div class="row d-flex flex-row justify-content-between mb-2">
        <div class="col-4">
          <label for="">Last Refresh</label>
        </div>
        <div class="col">
          {{
            apiDetails?.last_updated_date
              | dateFormatter: DATE_FORMATS.dateFirstHour
          }}
        </div>
      </div>
      <div class="row d-flex flex-row justify-content-between mb-2">
        <div class="col-4">
          <label for="">Status</label>
        </div>
        <div class="col">
          <span
            class="badge {{
              apiDetails?.is_active ? 'badge-success' : 'badge-danger'
            }} status"
          >
            {{ apiDetails?.is_active ? "Active" : "Disabled" }}
          </span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container>
    <div class="" [hidden]="!apiDetails?.apikey">
      <div class="header">
        <h5 class="title">
          API Docs
          <a
            target="_blank"
            [href]="apiDetails?.apidocs"
            class="hyperlink mx-2"
            >{{ apiDetails?.apidocs }}</a
          >
        </h5>
      </div>
      <div class="row d-flex mb-2">
        <object [data]="apiDetails?.apidocs | safe" class="website">
          <embed [src]="apiDetails?.apidocs | safe" class="website" />
        </object>
      </div>
    </div>
  </ng-container>

  <ng-template #empty_page>
    <div class="row d-flex w-100 flex-row justify-content-center">

      <app-banner [bannerType]="'warning'" [bannerIconRequired]="true" 
        [bannerText]="noApiKeyInfoMessage">
      </app-banner> 

      <div class="col-12 d-flex flex-column empty-page">
        <div class="d-flex flex-row w-100 justify-content-center">
          <img src="assets/icons/api-key.svg" width="50" />
        </div>
        <h4 class="text-center">API Key not available</h4>
      </div>
    </div>
  </ng-template>
</div>
