import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import { PusherService } from "src/app/services/pusher/pusher.service";
import { InteractService } from "../interact.service";

@Component({
  selector: "app-interact-notification-main",
  templateUrl: "./interact-notification-main.component.html",
  styleUrls: ["./interact-notification-main.component.scss"],
})
export class InteractNotificationMainComponent implements OnInit {
  pageNo: number = 1;
  size: number = 25;
  notifications: any[] = [];
  notificationOnScrollSubscription: Subscription;
  outletList: any[] = [];
  loading: boolean;
  constructor(
    private interactService: InteractService,
    private commonService: CommonService,
    private pusherService: PusherService
  ) {}

  ngOnInit(): void {
    this.notificationOnScrollSubscription =
      this.commonService.businessInteractNotificationOnScroll$.subscribe(
        (res) => {
          if (res) {
            this.onInit();
          }
        }
      );
    console.log(this.pusherService);
    this.pusherService.businessChannel.bind("interact", (data) => {
      if (this.interactService.isMultiLocationView()) {
        this.onInit(data);
        return;
      }
    });
    this.onInit();
  }

  notificationOnWS() {
    this.interactService
      .getInteractNotifications({ page: "1", size: "1" })
      .subscribe((response) => {
        if (response.code == 200) {
          const notification = response.data.conversations[0];
          if (notification) {
            const notifIndex = this.notifications.findIndex(
              (notif) => notif.outlet_id == notification.outlet_id
            );
            if (notifIndex !== -1) {
              this.notifications.splice(notifIndex, 1);
            }
            this.notifications.unshift(notification);
          }
          this.mapHasOutletAccess();
        }
      });
  }

  async onInit(data: any = null) {
    if (!this.outletList.length) {
      this.outletList = await this.getHasOutletAccess();
    }
    if (data) {
      const dataJson = JSON.parse(JSON.stringify(data));
      console.log(dataJson);
      let hasOutlet = this.outletList.find(
        (outlet) => outlet.outlet_id == dataJson.outlet_id
      );
      if (hasOutlet) {
        this.notificationOnWS();
      }
      return;
    }
    this.getNotifications();
  }

  getHasOutletAccess(): Promise<any[]> {
    return this.interactService.getHasOutletAccess();
  }

  mapHasOutletAccess() {
    this.notifications = this.notifications.map((notif) => {
      let outlet = this.outletList.find(
        (outlet) => outlet.outlet_id == notif.outlet_id
      );
      if (outlet) {
        notif.has_outlet_access = outlet.has_outlet_access;
      }
      return notif;
    });
    console.log(this.notifications);
  }

  getNotifications() {
    this.loading = true;
    this.interactService
      .getInteractNotifications({
        page: this.pageNo.toString(),
        size: this.size.toString(),
      })
      .subscribe((response) => {
        console.log(response);
        if (response.code == 200) {
          const notifications = response.data.conversations;
          notifications.length && this.notifications.push(...notifications);
          this.mapHasOutletAccess();
          if (notifications.length && this.notifications.length) {
            this.pageNo += 1;
          }
        }
        this.loading = false;
      });
  }
}
