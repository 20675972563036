import { Component, OnInit, Inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { DataService, Response } from "../../dataService/data.service";
import { GlobalVariable } from "../../app.component";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  constructor(
    private titleService: Title,
    private location: Location,
    private HttpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {}

  ngOnInit(): void {
    const tit = this.location.path().split("/");
    this.titleService.setTitle("Zceppa | " + tit[1]);
  }
}
