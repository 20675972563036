import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { OtpService } from "../services/otp.service";
import { ToastrService } from "ngx-toastr";
import { Response } from "../shared/models";
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { GlobalVariable } from "src/app/app.component";

@Component({
  selector: "app-email-verification",
  templateUrl: "./email-verification.component.html",
  styleUrls: ["./email-verification.component.scss"],
})
export class EmailVerificationComponent implements OnInit {
  private globalVar = GlobalVariable;

  @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput: NgOtpInputComponent;

  loading: boolean = false;
  resendClicked: boolean = false;
  resendError: boolean = false;
  resendErrorMessage: string = "";
  userEmail: string = "";
  userPhone: string;
  // userId;
  disableResend: boolean = false;
  disableSubmit: boolean = true;
  attemptsLeft: number;
  otp: number;
  otpTyped: boolean = false;
  otpConfig: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputClass: "otp-input",
  };
  show_error_message: String;
  isForgetPassword: boolean = false;
  isEmailLoginType: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private otpService: OtpService,
    private router: Router
  ) {}

  ngOnInit(): void {
    let qParams;
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      qParams = { ...params.keys, ...params };
    });
    if (qParams?.action) {
      this.isForgetPassword = qParams.action == "forget-password";
    }
    if (qParams?.user_email) {
      // this.verifyEmail(qParams.email);
      this.userEmail = qParams?.user_email;
      // this.userId = qParams?.userId;
      this.generateWebsiteEmailOtp(true);
    }
    if (qParams?.phone) {
      this.userPhone = qParams.phone;
    }
    if (qParams?.loginType) {
      if (qParams?.loginType == this.globalVar.loginType.email) {
        this.isEmailLoginType = true;
      } else {
        this.isEmailLoginType = false;
      }
    }
  }

  generateWebsiteEmailOtp(isFirstTime = false) {
    if (this.disableResend) {
      return;
    }
    this.loading = true;
    let res: Response;

    let url;
    if (this.isForgetPassword) {
      let payload = {
        username: this.userEmail,
      };
      url = this.otpService.forgetPassword(payload);
    } else {
      let payload = {
        username: this.userEmail,
        login_type: "EMAIL",
      };
      url = this.otpService.websiteEmailOtp(payload);
    }

    url.subscribe(
      (data: Response) => {
        res = data;
        this.loading = false;
        if (res && res.code === 201) {
          this.loading = false;
          this.resendError = false;
          this.resendErrorMessage = "";
          this.show_error_message = "";
          if (!isFirstTime) {
            this.resendClicked = true;
          } else {
            this.ngOtpInput.setValue("");
          }
          this.attemptsLeft = res.val.attempts_left;
          if (this.attemptsLeft <= 0) {
            this.attemptsLeft = 0;
            this.disableResend = true;
          }
        }
      },
      (error) => {
        this.loading = false;
        if (error?.error?.message) {
          this.show_error_message = error.error.message;
          this.resendError = true;
          this.resendErrorMessage = error.error.message;
        }
      }
    );
  }

  // resendEmail() {
  //   this.loading = true;
  //   this.otpService.resendEmailOTP(this.userId).subscribe(
  //     (data: Response) => {
  //       // this.toastrService.success(data.message);
  //       this.loading = false;
  //       this.resendClicked = true;
  //       this.resendError = false;
  //       this.resendErrorMessage = "";
  //     },
  //     (error) => {
  //       // this.toastrService.error(error.error.message);
  //       this.resendError = true;
  //       this.resendErrorMessage = error.error.message;
  //       this.loading = false;
  //     }
  //   );
  // }

  bannerClose(data) {
    this.resendClicked = false;
  }

  errorBannerClose(data) {
    this.resendError = false;
    this.resendErrorMessage = "";
  }

  otpChange(e) {
    this.otpTyped = true;
    this.otp = e;
    this.disableSubmit =
      this.otp && this.otp.toString().length === 6 ? false : true;
    if (this.otp && this.otp.toString().length === 6 && e.keyCode === 13) {
      this.onSubmit();
    }
  }

  onSubmit() {
    let res: Response;
    let payload = {
      username: this.userEmail,
      login_type: "EMAIL",
      otp: this.otp.toString(),
    };
    this.otpService.verifyWebsiteEmailOTP(payload).subscribe(
      (data: Response) => {
        res = data;
        if (res && res.code === 202) {
          if (res.val.is_phone_verify_allowed && !res.val.is_phone_verified) {
            // res.val.user_id
            //redirect to mobile otp
            this.router.navigate(["/signin/mobile-otp"], {
              queryParams: {
                type: this.globalVar.onboardingStatus.type.admin,
                phone: res.val.phone_number,
              },
            });
          } else {
            this.router.navigate(["/signin/create-password"], {
              queryParams: {
                type: this.globalVar.onboardingStatus.type.admin,
                user_email: this.userEmail,
              },
            });
          }

          // this.router.navigate(["/signin/create-password"], {
          //   queryParams: {
          //     type: this.globalVar.onboardingStatus.type.website,
          //     user_email: this.userEmail,
          //   },
          // });

          // this.router.navigate(["/signin/verify-email"], {
          //   queryParams: { user_id: res.val.user_id, email: res.val.email },
          // });
          this.resendError = false;
          this.resendErrorMessage = "";
        } else {
          if (res) {
            this.resendError = false;
            this.resendErrorMessage = "";
          }
        }
      },
      (error) => {
        // this.show_error_message = error.error.message;
        this.resendError = true;
        this.resendErrorMessage = error.error.message;
      }
    );
  }
}
