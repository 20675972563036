<a
  class="dropdown-item notif d-flex"
  [class.notif-hover]="notificationType == 'popup'"
  (click)="onSwitchOutlet()"
>
  <div class="notification-icon">
    <img
      [src]="notification.platformIcon"
      height="{{ notification.source == 'FACEBOOK' ? '22' : '24' }}"
      width="{{ notification.source == 'FACEBOOK' ? '22' : '24' }}"
    />
    <div class="unseen-noti-dot">
      <span *ngIf="notification.unread_messages !== 0" class="unread_msg_num">
        <span class="msg-count">
          {{ notification.unread_messages }}
        </span>
      </span>
    </div>
  </div>
  <div class="notification-details flex-grow-1">
    <p class="mb-1 d-flex justify-content-between">
      <span class="new-title">
        You have new message from
        {{ notification.name || notification.contact_number || "-" }}
        on
        {{ notification?.outlet_name | titlecase }}
      </span>
      <span
        class="text-small text-muted ml-auto"
        style="float: right; white-space: nowrap"
      >
        {{ notification.last_message_time | dateFormatter: "ago" }}
      </span>
    </p>
    <p class="text-small text-muted m-0 d-flex justify-content-between">
      <span>
        {{ notification.last_message || "-" }}
      </span>
      <span class="text-small text-muted ml-auto notifytime">
        <ng-container *ngIf="notificationType == 'page'">
          {{
            notification.last_message_time | dateFormatter: "D MMM YYYY h:mm A"
          }}
        </ng-container>
      </span>
    </p>
  </div>
</a>

<!-- <ng-template #switchOutletModal let-modal>
  <div class="modal-body locat_pop">
    <h4 class="fw-normal">
      You will be redirected to {{ notification?.outlet_name }}. Click continue
      to proceed
    </h4>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-primary btn-sm reviwadd"
      (click)="switchOutlet()"
    >
      Continue
    </button>
    <button
      type="submit"
      class="btn btn-sm px-3 cancel-button"
      (click)="modal.dismiss('Cross click')"
    >
      Cancel
    </button>
  </div>
</ng-template> -->

<ng-template #switchOutleNoAccesstModal let-modal>
  <div class="modal-body locat_pop">
    <h4 class="fw-normal">
      You don't have access to {{ notification?.outlet_name }}.
    </h4>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-primary btn-sm reviwadd"
      (click)="modal.dismiss('Cross click')"
    >
      ok
    </button>
  </div>
</ng-template>
