import { Routes } from "@angular/router";
import { LockscreenComponent } from "./lockscreen/lockscreen.component";
import { SigninComponent } from "./signin/signin.component";
import { SignupComponent } from "./signup/signup.component";
import { EmailVerificationComponent } from "./email-verification/email-verification.component";
import { MobileOtpComponent } from "./mobile-otp/mobile-otp.component";
import { CreatePasswordComponent } from "./create-password/create-password.component";
import { WebsiteSignupComponent } from "./website-signup/website-signup/website-signup.component";
export const AccountRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: SigninComponent,
      },
      {
        path: "signin",
        component: SigninComponent,
      },
      {
        path: "signup",
        component: SignupComponent,
      },
      {
        path: "lockscreen",
        component: LockscreenComponent,
      },
      {
        path: "mobile-otp",
        component: MobileOtpComponent,
      },
      {
        path: "verify-email",
        component: EmailVerificationComponent,
      },
      {
        path: "create-password",
        component: CreatePasswordComponent,
      },
      {
        path: "web-signup",
        component: WebsiteSignupComponent,
      },
    ],
  },
];
