export const environment = {
  production: true,
  env: "prod",
  apiHost: "https://production.zceppa.com/",
  adminApiHost: "https://adminproduction.zceppa.com/",
  widgetDomain: "https://widget.zceppa.com/",
  // apiHost: 'https://apitesting.zceppa.com/',
  // adminApiHost: 'https://adminapitesting.zceppa.com/',
  pusher: {
    key: "e25692894f683359a2ae",
    cluster: "ap2",
  },
};
