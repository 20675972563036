import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
// import { Response } from '@angular/http';
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
// import {  Events } from '@';
import { AuthService } from "../../app/auth/auth.service";
import { GlobalVariable } from "../app.component";
import { DataService } from "../dataService/data.service";
import { CommonService } from "../services/common.service";
import { FeaturesByPlanService } from "../services/features-by-plan/features-by-plan.service";

@Injectable()
export class MyHttpInterceptor {
  private BASE_URL = GlobalVariable;
  error401Count: number = 0;
  error403Count: number = 0;
  isactivestats: string;

  // error401Count: number = 0;

  private refreshTokenInProgress = false;
  private visible403Popup = false;
  private unknownErrorVisible = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private featuresByPlan: FeaturesByPlanService,
    private spinner: NgxUiLoaderService,
    private toastrService: ToastrService,
    private HttpService: DataService,
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
    refresh_token = null
  ): Observable<HttpEvent<any>> {
    // this.spinner.start();
    // const token: string = this.storage.get('access_token');

    // if (token) {
    //   request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    // }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    // return next.handle(request).pipe(
    //   map((event: HttpEvent<any>) => {
    //     if (event instanceof HttpResponse) {
    //       this.spinner.stop();
    //     }
    //     this.spinner.stop();
    //     this.error401Count = 0;
    //     this.error403Count = 0;
    //     // console.log("error401Count", this.error401Count);
    //     return event;
    //   }),
    //   catchError(err => {
    //     if (err.status === 401) {
    //       this.error401Count++;
    //       this.commonService.onError401(err.error.msg, this.error401Count);
    //     }
    //     else if (err.status === 422) {
    //       this.storage.remove('access_token');
    //       this.storage.remove('refresh_token');
    //       this.storage.remove('outlet_id');
    //       this.storage.remove('business_id');
    //       this.storage.remove('business_name');
    //       this.storage.remove('outlet_name');
    //       this.storage.remove('user_name');
    //       this.storage.remove('logo');
    //       this.storage.remove('review_count');
    //       this.storage.remove('default_template_id');
    //       this.storage.remove('role');
    //       this.storage.remove('access');
    //       this.router.navigate(['../signin']);
    //       this.commonService.setFirstView(false);
    //     } else if (err.status === 403) {
    //       this.error403Count++;
    //       this.commonService.onError403(err.error.message, this.error403Count);
    //       }
    //     return throwError(err);
    //   })
    // );

    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!window.navigator.onLine) {
          this.toastrService.error("No internet detected. Try later");
          return;
        }

        // when we deactive own account API thorows this msg .

        if (error.error.message === "Unauthorized access to the resource") {
          if (!this.unknownErrorVisible) {
            // this.toastrService.error(error.error.message, 'error');
            // this.authenticationService.logout();
            this.error401Count++;
            this.commonService.onError401(error.error.msg, this.error401Count);
            this.logout();
            this.unknownErrorVisible = true;
          }
        } else {
          if (error.status !== 401 && error.status !== 422) {
            if (error.status === 403) {
              console.error("error403", error);
              //   this.error403Count++;
              //   this.commonService.onError403(error.error.message, this.error403Count)
              if (!this.visible403Popup) {
                // this.events.publish('visible_403_popup', error.error['message']); // trigger event for shown 403 Popup
                this.visible403Popup = true; // visible403Popup is true for shown single time popup
                this.error403Count++;
                this.commonService.onError403(
                  error.error.message,
                  this.error403Count
                );
              }
            }
            console.log(error.status);
            console.log(error);
            // if (!window.navigator.onLine) {
            //     this.toastrService.error("Ooops ... Something went wrong. try again");

            // }
            if (error.status == 0 && error.statusText == "Unknown Error") {
              // removed error title from internet not connected toaster
              console.error("Something went wrong | unknown error", error);
              this.toastrService.error(
                "Ooops ... Something went wrong. try again"
              );
              // return;
            }
            if (error.status == 409 && error.statusText == "CONFLICT") {
              console.error("409 error", error);
              this.toastrService.error(error.error.message);
            }
            if (
              error.status != 404 &&
              error.status != 0 &&
              error.statusText != "Unknown Error" &&
              error.status == 406 &&
              error.statusText == "NOT ACCEPTABLE"
            ) {
              console.error("check 406, error from", error.error);
              if (error.error.hasOwnProperty("alert")) {
                this.toastrService.error(error.error.alert);
                // this.toastrService.error(error.error.hasOwnProperty("message") ? error.error.message : error.error.msg, 'error');
              } else if (error.error.hasOwnProperty("message")) {
                this.toastrService.error(error.error.message);
              }
            }
            if (error.status == 400) {
              if (error.error.hasOwnProperty("message")) {
                this.toastrService.error(error.error.message);
              }
            }
            return throwError(error);
          }

          if (this.refreshTokenInProgress) {
            console.log("refreshTokenInProgress");

            if (request.url.includes("refresh")) {
              // refresh token throw error then user logout
              this.refreshTokenInProgress = false;
              // this.presentToast(error.error.msg, 'error');
              // this.authenticationService.logout();
              this.error401Count++;
              this.commonService.onError401(
                error.error.msg,
                this.error401Count
              );
              //   this.logout();
              return throwError(error);
            }

            return this.refreshTokenSubject
              .pipe(filter((result) => result !== null))
              .pipe(take(1))
              .pipe(
                switchMap(() =>
                  next.handle(this.addAuthenticationToken(request))
                )
              );
          } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            //   const data_service = this.HttpService.post(this.BASE_URL.refreshTokenUrl, 'body' );
            return this.HttpService.postjson(
              this.BASE_URL.refreshTokenUrl,
              "body"
            ).pipe(
              switchMap((response) => {
                console.log(response);
                if (response) {
                  //Update tokens
                  var response_body = response.data;
                  this.storage.set(
                    "access_token",
                    response_body["access_token"]
                  );

                  // localStorage.setItem("token", JSON.stringify(response_body.access_token)); // stored token
                  //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  this.refreshTokenInProgress = false;
                  console.log(response.data["access_token"]);
                  this.refreshTokenSubject.next(response_body["access_token"]);
                  return next.handle(this.addAuthenticationToken(request));
                }
              })
            );
          }
        }
        console.log("error", error);
        return throwError(error); // other status present except 401
      })
    );
  }

  logout() {
    this.storage.remove("access_token");
    this.storage.remove("refresh_token");
    this.storage.remove("outlet_id");
    this.storage.remove("business_id");
    this.storage.remove("user_id");
    this.storage.remove("business_name");
    this.storage.remove("outlet_name");
    this.storage.remove("user_name");
    this.storage.remove("logo");
    this.storage.remove("review_count");
    this.storage.remove("default_template_id");
    this.storage.remove("role");
    this.storage.remove("access");
    this.storage.remove("has_password");
    this.featuresByPlan.clearFeaturesByPlan();
    this.router.navigate(["../signin"]);
    this.commonService.setFirstView(false);
  }
  addAuthenticationToken(request) {
    //   console.log('request.url.includes("refresh")', request.url.includes('refresh'));

    if (request.url.includes("refresh")) {
      // request url is refresh then send refresh token in heading and get new access token
      const refresh_token: string = JSON.parse(
        localStorage.getItem("refresh_token")
      );
      return request.clone({
        setHeaders: {
          Authorization: "Bearer " + refresh_token,
        },
      });
    } else {
      // Get access token from Local Storage
      // const accessToken = this.authService.getAccessToken();
      const token: string = this.storage.get("access_token");
      // console.log(token);

      // If access token is null this means that user is not logged in
      // And we return the original request
      if (!token) {
        return request;
      }

      // We clone the request, because the original request is immutable
      return request.clone({
        setHeaders: {
          // Authorization: 'Bearer ' + this.authService.getAccessToken()
          Authorization: "Bearer " + token,
        },
      });
    }
  }
}
