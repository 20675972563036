import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { BehaviorSubject, Subject } from "rxjs";
import { DateType, DATE_FORMATS, Formats } from "./utils";
import { Location } from "@angular/common";
@Injectable({
  providedIn: "root",
})
export class CommonService {
  public user_name = new BehaviorSubject("test");
  public review_list = new BehaviorSubject("NO_REVIEW");
  public business_name = new BehaviorSubject(null);
  public page_no = new BehaviorSubject(1);
  public show_review_page = new BehaviorSubject("NO");
  public logo_image = new BehaviorSubject("NO_IMAGE");
  public display_review = new BehaviorSubject(true);
  public send_review_invite = new BehaviorSubject(null);
  public sent_draft_campaign_tab_selection = new BehaviorSubject(null);
  public sent_draft_social_tab_selection = new BehaviorSubject(null);
  public sent_message_tab_selection = new BehaviorSubject(null);
  public profile_image_url = new BehaviorSubject(null);
  public profile_menu_selection = new BehaviorSubject(null);
  public edit_sms_selection = new BehaviorSubject(null);
  public notifiylinkselection = new BehaviorSubject(null);
  public customerslinkselection = new BehaviorSubject(null);
  public menu_display_selection = new BehaviorSubject(null);
  public infinte_scroll_flag = new BehaviorSubject(null);
  public scroll_filter_reviews = new BehaviorSubject(null);
  public filter_scroll_page = new BehaviorSubject(null);
  public otp_mobile_number = new BehaviorSubject(null);
  public switch_outlet = new BehaviorSubject(null);
  public change_outlet: BehaviorSubject<{
    outlet_id: string;
    redirectTo: string;
  }> = new BehaviorSubject(null);
  public review_setting = new BehaviorSubject(null);
  public social_setting = new BehaviorSubject(null);
  public analytics_filter = new BehaviorSubject(null);
  public analytics_filter_dateRange = new BehaviorSubject<{
    start_date: string | null;
    to_date: string | null;
    applied_from: "REVIEW_STATS" | "REVIEW_DATE_FILTER" | "BOTH";
  }>(null);
  public analyticsFilterDateRange$ =
    this.analytics_filter_dateRange.asObservable();
  public firstView = new BehaviorSubject(null);
  public analytics_filter_reset = new BehaviorSubject(null);
  public sms_alert = new BehaviorSubject(null);
  public is_active_stats = new BehaviorSubject(null);
  public show_outlet = new BehaviorSubject(null);
  public first_view_set = new BehaviorSubject(null);
  public mystore_menu_selection_top_action_button = new BehaviorSubject(false);
  public promotionSelection = new BehaviorSubject(0);
  public show_addAppointment_modal = new BehaviorSubject(false);

  public appointment_top_panel = new BehaviorSubject(null);
  public appointment_bottom_panel = new BehaviorSubject(null);
  public mystore_top_panel = new BehaviorSubject(null);
  public mystore_bottom_panel = new BehaviorSubject(null);
  public gather_info_data = new BehaviorSubject(null);
  public gather_info_service = new BehaviorSubject(null);
  public reviewsOnScroll = new Subject<boolean>();
  public reviewsOnScroll$ = this.reviewsOnScroll.asObservable();
  public businessReviewsOnScroll = new Subject<boolean>();
  public businessReviewsOnScroll$ = this.businessReviewsOnScroll.asObservable();
  public businessSocialFeedOnScroll = new Subject<boolean>();
  public businessSocialFeedOnScroll$ =
    this.businessSocialFeedOnScroll.asObservable();
  public businessInteractNotificationOnScroll = new Subject<boolean>();
  public businessInteractNotificationOnScroll$ =
    this.businessInteractNotificationOnScroll.asObservable();
  public businessNotificationOnScroll = new Subject<boolean>();
  public businessNotificationOnScroll$ =
    this.businessNotificationOnScroll.asObservable();
  error401 = new Subject<string>();
  error403 = new Subject<string>();
  onCampaignEdit = new Subject<string>();
  interaction_count = new Subject<string>();
  close_intectact_pop_up = new Subject<string>();
  disable_scroll = new Subject<string>();

  hide_show_by_mobile = new Subject<string>();
  hideChatModal = new Subject<string>();
  interact_unread_count = new Subject<string>();
  check_onboard_notification = new Subject<string>();
  is_google_reconnect = new Subject<boolean>();
  private _reviewsCount = new BehaviorSubject<any>(null);
  public reviewsCount$ = this._reviewsCount.asObservable();
  // public hide_show_by_mobile = new BehaviorSubject(false);
  hideInteractTemplateCollapsIcon = new Subject<boolean>();
  public interact_unread_unique_conversation_count = new Subject<string>();
  public settingsMeesagingTab = new BehaviorSubject(0);
  public appointmentTab = new BehaviorSubject(0);
  public settingsSocialTab = new BehaviorSubject(0);
  public isEmptyConversation = new BehaviorSubject(false);

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router,
    private location: Location
  ) {}

  /**
   * Get review from social media using infite scroll
   *
   * @param {*} review_data
   * @memberof CommonService
   */
  getSocialReview(review_data) {
    this.review_list.next(review_data);
  }
  getAppointmentTopPanel(top_panel_data) {
    this.appointment_top_panel.next(top_panel_data);
  }
  getAppointmentBottomPanel(bottom_panel_data) {
    console.log(bottom_panel_data);
    this.appointment_bottom_panel.next(bottom_panel_data);
  }
  getMystoreTopPanel(top_panel_data) {
    this.mystore_top_panel.next(top_panel_data);
  }
  getMystoreBottomPanel(bottom_panel_data) {
    console.log(bottom_panel_data);
    this.mystore_bottom_panel.next(bottom_panel_data);
  }

  /**
   * Get business profile name using observable
   *
   * @param {*} business_name
   * @memberof CommonService
   */
  getBusinessProfile(business_name) {
    this.business_name.next(business_name);
  }

  /**
   * Reset page number
   *
   * @param {*} page_no
   * @memberof CommonService
   */
  resetPageNo(page_no) {
    this.page_no.next(page_no);
  }

  /**
   * open customer review page
   *
   * @param {*} flag
   * @memberof CommonService
   */
  openCustomerReviewPage(flag) {
    this.show_review_page.next(flag);
  }

  /**
   * Logo image url
   *
   * @param {*} imageUrl
   * @memberof CommonService
   */
  logoImageUrl(imageUrl) {
    this.logo_image.next(imageUrl);
  }

  /**
   * Display reviews
   *
   * @param {*} status
   * @memberof CommonService
   */
  displayReviews(status) {
    this.display_review.next(status);
  }

  hideShowByMobile(status) {
    this.hide_show_by_mobile.next(status);
  }
  closeFullScreenChatModal(status) {
    this.hideChatModal.next(status);
  }

  interactUnreadCount(response) {
    this.interact_unread_count.next(response);
  }

  // interactionCount(status) {
  //   this.interaction_count.next(status);
  // }

  disable_scroll_event(status) {
    console.log(status);

    this.disable_scroll.next(status);
  }
  onError401(msg, count) {
    if (count === 1) this.error401.next(msg);
  }
  onError403(msg, count) {
    // if (count === 1)
    this.error403.next(msg);
  }

  /**
   *
   * @param client
   * @memberof CommonService
   */
  sendReviewInvite(client) {
    this.send_review_invite.next(client);
  }

  /**
   *
   * @param profile image upload
   * @memberof CommonService
   */
  profileimageurl(profileImageurl) {
    this.profile_image_url.next(profileImageurl);
  }

  /**
   * Profile menu selection
   *
   * @param {*} status
   * @memberof CommonService
   */
  profileMenuSelection(status) {
    this.profile_menu_selection.next(status);
  }

  editSmsSelection(status) {
    this.edit_sms_selection.next(status);
  }

  customersMenuSelection(status) {
    this.customerslinkselection.next(status);
  }

  /**
   * Menu display selection
   *
   * @param {*} status
   * @memberof CommonService
   */
  menuDisplaySelection(status) {
    this.menu_display_selection.next(status);
  }

  /**
   * Flag based infinite scroll run
   *
   * @param {*} flag
   * @memberof CommonService
   */
  holdInfiniteScroll(flag) {
    this.infinte_scroll_flag.next(flag);
  }
  /**
   * Filter based infinite scroll run
   *
   * @param {*} filter
   * @memberof CommonService
   */
  filterBasedReviews(filter) {
    this.scroll_filter_reviews.next(filter);
  }
  filterScrollPage(page) {
    this.filter_scroll_page.next(page);
  }

  /**
   *  Display otp screen on signin component
   * @param num
   */
  showOtpScreen(num) {
    this.otp_mobile_number.next(num);
  }

  /**
   * Switch outlet
   * @param flag
   */
  showOutlet(flag) {
    this.switch_outlet.next(flag);
  }
  /**
   * Switch outlet
   * @param flag
   */
  changeOutlet(outlet_id: string, redirectTo: string | null = null): void {
    this.change_outlet.next({ outlet_id, redirectTo });
  }

  showReviewSetting(review) {
    this.review_setting.next(review);
  }

  showSocialSetting(social) {
    this.social_setting.next(social);
  }

  sentdraftCampaignTabSelection(status) {
    this.sent_draft_campaign_tab_selection.next(status);
  }
  sentdraftSocialTabSelection(status) {
    this.sent_draft_social_tab_selection.next(status);
  }
  sentMessageTabSelection(status) {
    this.sent_message_tab_selection.next(status);
  }

  setFirstView(value) {
    this.firstView.next(value);
  }
  /**
   * Analytics filter changes (slice and dice)
   *
   * @param value
   */
  analyticsFilter(value) {
    this.analytics_filter.next(value);
  }
  analyticsFilterReset(value) {
    this.analytics_filter_reset.next(value);
  }
  onAnalyticsFilter(value) {
    this.analytics_filter_dateRange.next(value);
  }
  smsAlert(value) {
    this.sms_alert.next(value);
  }
  isActiveStatus(value) {
    this.is_active_stats.next(value);
  }

  isActiveOutletStatus(value) {
    this.show_outlet.next(value);
  }

  firstViewCheck(value) {
    this.first_view_set.next(value);
  }

  showAddAppointmentModal(value) {
    this.show_addAppointment_modal.next(value);
  }
  myStoreMenuSelectionTopActionButton(value) {
    this.mystore_menu_selection_top_action_button.next(value);
  }

  checkOnboardNotification(value: any) {
    this.check_onboard_notification.next(value);
  }
  isGoogleReconnect(value: boolean) {
    this.is_google_reconnect.next(value);
  }

  setReviewsTotalCount(count: number) {
    this._reviewsCount.next(count);
  }

  updateInteractCollapsIcon(value: boolean) {
    this.hideInteractTemplateCollapsIcon.next(value);
  }

  setRoutingURL(redirectTo: string) {
    switch (redirectTo) {
      case "Dashboard":
        return "dashboard";
      case "MyListings":
        return "listing-management";
      case "Social":
        return "social";
      case "Interact":
        return "interact";
      case "Integration":
        return "settings/integrations";
      default:
        return "profile";
    }
  }

  setDashboardRedirect(redirectTo?: string) {
    let topActionButton = this.storage.get("access").top_action_button;
    let landingMenu = redirectTo || this.storage.get("access").landing_menu;
    let dashboardTab = this.storage.get("access").dashboard_tab;

    if (landingMenu == "Dashboard") {
      if ([dashboardTab, topActionButton].includes("Send Review Invite")) {
        this.router.navigate(["../dashboard"]);
      } else if ([dashboardTab, topActionButton].includes("Create Order")) {
        this.router.navigate(["dashboard/mystore"]);
      } else if ([dashboardTab, topActionButton].includes("Create Post")) {
        this.router.navigate(["dashboard/social"]);
      } else {
        this.router.navigate(["dashboard/appointments"]);
      }
    } else {
      this.router.navigate([`/${this.setRoutingURL(landingMenu)}`]);
    }
  }

  getherInfoData(data: { key: string; value: string; is_service: boolean }[]) {
    this.gather_info_data.next(data);
  }

  getherInfoService(is_service: boolean) {
    this.gather_info_service.next(is_service);
  }

  private _toDate(newDate: DateType) {
    let offset: string = this.storage.get("offset") || "+05:30";
    return new Date(
      moment.utc(newDate).utcOffset(offset).format(DATE_FORMATS.yearFirstHour)
    );
  }

  getDate(newDate: DateType = new Date()) {
    return this._toDate(newDate);
  }

  onDateFilter(newDate: DateType = new Date()) {
    let actualDate = this._toDate(newDate);
    return actualDate;
  }

  onDateFormat(newDate: DateType = new Date(), format: Formats): string {
    let actualDate = moment(this._toDate(newDate));
    return this.formatDate(format, actualDate);
  }

  convertUTCDateToLocalDate(date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  formatDate(format: Formats, date: moment.Moment): string {
    let timeAgo = date.from(this.getDate());
    return (format && format !== "ago" && date.format(format)) || timeAgo;
  }

  goBack() {
    this.location.back();
  }

  openUrlInNewTab(url: string) {
    window.open(url, "_blank");
  }

  deepCloneObject(object) {
    let newData = Object.assign({}, object);
    return newData;
  }

  deepCloneArray(data) {
    let cloned = data.map((x) => Object.assign({}, x));
    return cloned;
  }

  removeEmptyFromObject(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  format12HourTime(time) {
    return moment(time, ["HH.mm A"]).format("hh:mm A");
  }

  getPriorDateToCurrentDate(days) {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - days));
    return { today, priorDate };
  }

  capitalizeEachWord(sentence) {
    // Split the sentence into an array of words
    var words = sentence.split(" ");

    // Iterate over each word in the array
    for (var i = 0; i < words.length; i++) {
      // Capitalize the first letter of the word and convert the rest of the letters to lowercase
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words back into a sentence
    var capitalizedSentence = words.join(" ");

    // Return the capitalized sentence
    return capitalizedSentence;
  }

  sortArrayOnStringKey(arrayList = [], key: string) {
    arrayList.sort((a: any, b: any) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
    return arrayList;
  }

  replaceHtmlCodeWithSpecialChars(inputData) {
    const convertedText = inputData
      .replace(/&nbsp;/g, " ")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"') // double quote
      .replace(/&apos;/g, "'"); // single quote
    return convertedText;
  }

  convertToRichTextFormat(inputData) {
    const richFormattedText = inputData
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>") // Convert to rich text bold format
      .replace(/_(.*?)_/g, "<em>$1</em>") // Convert to rich text italic format
      .replace(/~(.*?)~/g, "<s>$1</s>") // Convert to rich text strike-through format
      .replace(/```(.*?)```/g, "<code>$1</code>"); // Convert to rich text monospace format
    return richFormattedText;
  }

  // convertToWhatsAppFormat(inputData) {
  //   const whatsappFormattedText = inputData
  //     .replace(/<strong>(.*?)<\/strong>/g, "*$1*") // Convert to WhatsApp bold format
  //     .replace(/<em>(.*?)<\/em>/g, "_$1_") // Convert to WhatsApp italic format
  //     .replace(/<s>(.*?)<\/s>/g, "~$1~") // Convert to WhatsApp strike-through format
  //     .replace(/<code>(.*?)<\/code>/g, "```$1```"); // Convert to WhatsApp monospace format
  //   return whatsappFormattedText;
  // }

  convertToWhatsAppFormat(inputData) {
    const whatsappFormattedText = inputData
      .replace(/<strong>(.*?)<\/strong>/g, "*$1*") // Convert to WhatsApp bold format
      .replace(/<em>(.*?)<\/em>/g, "_$1_") // Convert to WhatsApp italic format
      .replace(/<s>(.*?)<\/s>/g, "~$1~") // Convert to WhatsApp strike-through format
      .replace(/<code>(.*?)<\/code>/g, "```$1```") // Convert to WhatsApp monospace format
      .replace(/<\/?span[^>]*>/g, ""); // remove emoji span
    return whatsappFormattedText;
  }

  convertHtmlToPlainText(html: string): string {
    // Remove <p> </p> tags
    html = html.replace(/<\/?p[^>]*>/g, "");
    // Replace <strong> and </strong> with * and *
    html = html.replace(/<strong[^>]>/g, "").replace(/<\/strong>/g, "*");
    // Replace <em> and </em> with _ and _
    html = html.replace(/<em[^>]*>/g, "").replace(/<\/em>/g, "_");
    // Replace <s> and </s> with ˜ and ˜
    html = html.replace(/<s[^>]*>/g, "").replace(/<\/s>/g, "˜");
    // Replace <code> and </code> with ``` and ```
    html = html.replace(/<code[^>]*>/g, "").replace(/<\/code>/g, "```");
    // Remove all spans, preserving the content
    html = html.replace(/<span[^>]*>/g, "").replace(/<\/span>/g, "");
    return html;
    // Convert HTML-encoded entities to characters
    // const textarea = document.createElement("textarea");
    // textarea.innerHTML = html;
    // return textarea.value;
  }

  addPercentageValueToDashboardSteps(stepArray) {
    stepArray.forEach((eachStep: any) => {
      // ignore if its first or last step
      let stepPercent = (eachStep.step * 100) / stepArray.length;
      eachStep.actualPercentage = Math.round(stepPercent);
      // eachStep.left = Math.round(stepPercent) + "%";
      // here we are saying 4% considering checkbox should be centered
      eachStep.left = Math.round(stepPercent - 4) + "%";
    });
    return stepArray;
  }

  signout() {
    this.storage.remove("access_token");
    this.storage.remove("refresh_token");
    this.storage.remove("outlet_id");
    this.storage.remove("business_id");
    this.storage.remove("user_id");
    this.storage.remove("business_name");
    this.storage.remove("outlet_name");
    this.storage.remove("user_name");
    this.storage.remove("logo");
    this.storage.remove("review_count");
    this.storage.remove("default_template_id");
    this.storage.remove("role");
    this.storage.remove("access");
    this.storage.remove("has_password");
    this.router.navigate(["../signin"]);
    this.setFirstView(false);
  }
}
// type Formats = "DD-MM-YYYY" | "YYYY-MM-DD";
// export type DateType = Date | string | moment.Moment;
