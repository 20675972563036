import { Component, OnInit, Inject } from "@angular/core";
import { DataService, Response } from "../../dataService/data.service";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  NgForm,
} from "@angular/forms";
import { GlobalVariable } from "../../app.component";
import { FileUploader, FileLikeObject } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../services/common.service";
import { ExpiryWarningService } from "../../services/expiry/expiry-warning.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  excelUploader: FileUploader = new FileUploader({
    url: "URL",
    // disableMultipart:  true
  });

  private BASE_URL = GlobalVariable;
  public loading = false;
  business_profile: FormGroup;
  outlet_details: FormGroup;
  user_profile: FormGroup;
  read_only: boolean = false;
  bdata: any;
  formdata: any;
  filesToUpload: Array<File> = [];
  fileToUpload: File = null;
  file_name: any;
  profile_image_url: string;
  phoneNumberPrefixes = ["91", "001"];
  addressLine1: string;
  addressLine2: string;
  postalCode = "600097";
  userDetails: any;
  rolelist: any;
  branchlist: any;
  emailnotvalid: boolean = true;
  url: any;
  outlet_id: string;
  weekdays: any;
  Total_leave: any[];
  manager_id: any;
  product_warning_text: string;
  is_product_blocked: any;
  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private HttpService: DataService,
    public formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private commonservice: CommonService,
    private expiryWarningService: ExpiryWarningService
  ) {}

  // types = [
  //   { heading: 'Upload Profile', src: '', hasBaseDropZoneOver: false, uploader: this.excelUploader }

  // ];

  fileObject: any;

  // fileOverBase(e: any, index: number): void {
  //   this.types[index].hasBaseDropZoneOver = e;
  // }

  onAfterAddingFile(event, index: number) {
    // if (this.types[index].uploader.queue.length > 1) {
    //   this.types[index].uploader.queue.splice(0, 1);
    // }
    // this.types[index].src = this.types[index].uploader.queue[0].file.name;
    // this.types[index].src = this.types[index].uploader.queue[0].file.name;
  }
  fileUploaded(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (e) => {
        // called once readAsDataURL is completed
        this.url = reader.result; // add source to image
      };
      this.fileToUpload = event.target.files[0];
      const mimeType = this.fileToUpload.type;
      if (mimeType.match(/image\/*/) == null) {
        this.toastrService.error("Selected file is not image."); // = ;
        return;
      }
      this.formdata = new FormData();
      this.formdata.append(
        "profile_picture",
        this.fileToUpload,
        this.fileToUpload.name
      );
      const data_service = this.HttpService.postFormData(
        this.BASE_URL.USER_PROFILE_IMAGE_UPDATE_URL(this.outlet_id),
        this.formdata
      );
      data_service.subscribe(
        (res: any) => {
          let resp: Response;
          // this.showSuccess(res.data['message']);
          if (res.status == 206 || res.code == 200 || res.code == 201) {
            this.toastrService.success(res.data.message); // = ;
            // console.log(JSON.parse(res["_body"]));
          } else {
            this.toastrService.error(res.data.message); // = ;
          }
          this.HttpService.get(
            this.BASE_URL.USER_PROFILE_IMAGE_URL(this.outlet_id)
          ).subscribe(
            (data: Response) => {
              resp = data;
            },
            (error) => {},
            () => {
              this.profile_image_url = null;
              this.profile_image_url = resp.data["profile_picture"];
              console.log(this.profile_image_url);

              this.commonservice.profileimageurl(resp.data["profile_picture"]);
            }
          );
        },
        (error) => {
          // this.showError(error);
        }
      );
    }
  }

  fileChangeEvent(e: any, i) {
    this.brandImagesUpload(e, i);
  }

  /**
   * Brand upload images
   *
   * @param {*} i
   * @param {*} e
   * @memberof BrandUploadsComponent
   */
  brandImagesUpload(event, i) {
    this.fileToUpload = event.target.files[0];
    this.formdata = new FormData();
    this.formdata.append(
      "brand_logo",
      this.fileToUpload,
      this.fileToUpload.name
    );
    const data_service = this.HttpService.postFormData(
      this.BASE_URL.BRAND_UPLOAD_URL(this.outlet_id),
      this.formdata
    );
    data_service.subscribe(
      (res) => {
        // console.log(res);
        // this.showSuccess(res.data['message']);
      },
      (error) => {}
    );
  }

  ngOnInit() {
    this.loading = true;
    let res: Response;
    // this.profile_image_url =
    //  this.commonservice.profile_image_url.subscribe(res=>{
    //   this.profile_image_url= res;
    //  });
    this.outlet_id = this.storage.get("outlet_id");
    this.getLeaveApi();
    // this.commonservice.profileMenuSelection(true);
    this.profile_image_url = null;
    this.HttpService.get(
      this.BASE_URL.USER_PROFILE_IMAGE_URL(this.outlet_id)
    ).subscribe(
      (data: Response) => {
        this.loading = false;
        res = data;
      },
      (error) => {},
      () => {
        this.profile_image_url = null;
        this.profile_image_url = res.data["profile_picture"];
        console.log(res, this.profile_image_url);

        //this.commonservice.profile_image_url(resp.data['url']);
      }
    );
    // let res: Response;
    this.outlet_details = this.formBuilder.group({
      outlet_addr_line1: ["", Validators.compose([Validators.required])],
      outlet_addr_line2: ["", Validators.compose([Validators.required])],
      city: ["", Validators.compose([Validators.required])],
      state: ["", Validators.compose([Validators.required])],
      country: ["", Validators.compose([Validators.required])],
      pincode: ["", Validators.compose([Validators.required])],
    });

    this.user_profile = this.formBuilder.group({
      first_name: ["", Validators.compose([Validators.required])],
      last_name: ["", Validators.compose([Validators.required])],
      email_id: ["", Validators.compose([Validators.required])],
      phone_number: [""],
      role: ["", Validators.compose([Validators.required])],
      branch: ["", Validators.compose([Validators.required])],
      weekly_off: [""],
      Leave_detail: [""],
    });

    this.HttpService.get(this.BASE_URL.USER_PROFILE(this.outlet_id)).subscribe(
      (data: Response) => {
        res = data;
        this.userDetails = res.data;

        let country_code = "+91";
        if (this.userDetails?.country_dial_code) {
          country_code = this.userDetails?.country_dial_code;
        }
        if (country_code[0] === "+") {
          country_code = country_code.substring(1);
        }
        if (country_code && !this.phoneNumberPrefixes.includes(country_code)) {
          this.phoneNumberPrefixes = [];
          this.phoneNumberPrefixes = [
            country_code,
            ...this.phoneNumberPrefixes,
          ];
        }

        this.user_profile.patchValue({
          first_name: this.userDetails.first_name,
          last_name: this.userDetails.last_name,
          email_id: this.userDetails.email_id,
          phone_number: this.userDetails.phone_number,
          role: this.userDetails.role,
          branch: this.userDetails.branch,
          weekly_off:
            this.userDetails.weekly_off == "NA"
              ? ""
              : this.userDetails.weekly_off.toUpperCase(),
        });
      }
    );
    this.getWeeklyOff();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.checkPlanExpiry();
    }, 500);
  }

  public deleteBanner() {
    this.product_warning_text = "";
  }
  checkPlanExpiry() {
    let divContainer: HTMLElement | null =
      document.querySelector("#expiry-modal");
    const { isExpired, expiryWarningText } =
      this.expiryWarningService.checkExpiryBasedOnPlan("PROFILE", divContainer);
    this.is_product_blocked = isExpired;
    this.product_warning_text = expiryWarningText;
  }

  getWeeklyOff() {
    this.HttpService.get(
      this.BASE_URL.MANAGER_WEEKDAYS(this.outlet_id)
    ).subscribe(
      (data: any) => {
        if (data.code === 200) {
          console.log(data.data);
          this.weekdays = data.data;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  /**
   * User profile edit and update
   * @param Form
   */
  updateUserProfile(Form: FormGroup) {
    var data = {
      email_id: Form.value.email_id,
      mobile_number: Form.value.phone_number,
      user_last_name: Form.value.last_name,
      user_first_name: Form.value.first_name,
      weekly_off: Form.value.weekly_off == "" ? null : Form.value.weekly_off,
    };
    this.HttpService.putjson(
      this.BASE_URL.USER_PROFILE_UPDATE_URL(this.outlet_id),
      data
    ).subscribe(
      (res: Response) => {
        if (res) {
          let isForceLogout = res?.data?.is_force_logout || false;
          if (isForceLogout) {
            this.commonservice.onError401(res.data["message"], 1);
          } else {
            this.showSuccess(res.data["message"]);
          }
        }
      },
      (error) => {
        this.toastrService.error(error.error.message);
      }
    );
  }

  /**
   * Get all the data from userprofile form
   *
   * @readonly
   * @memberof ProfileComponent
   */
  get userFormData() {
    return this.user_profile.controls;
  }
  /**
   * Common email vaidation
   *
   * @memberof ProfileComponent
   */
  checkmail() {
    this.emailnotvalid = true;
    if (
      this.userFormData.email_id.value.match(
        /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,}$/
      )
    ) {
      this.emailnotvalid = true;
    } else {
      this.emailnotvalid = false;
    }
  }
  /**
   *Common toastr for success function
   *
   * @param {string} msg
   * @memberof ClientsComponent
   */
  showSuccess(msg: string) {
    this.toastrService.success(msg);
  }
  getLeaveApi() {
    this.Total_leave = [];
    this.HttpService.get(
      this.BASE_URL.GET_MANAGER_LEAVE_PROFILE_API(this.outlet_id)
    ).subscribe(
      (data: any) => {
        if (data.code === 200) {
          this.Total_leave = data.data;
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}
