import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { GlobalVariable } from "../../../../app.component";
import { DataService } from "../../../../dataService/data.service";
import { DashboardService } from "../dashboard/dashboard.service";
@Injectable({
  providedIn: "root",
})
export class InteractService {
  private BASE_URL = GlobalVariable;

  constructor(
    private httpService: DataService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private dashboardService: DashboardService,
    private router: Router
  ) {}

  isMultiLocationView() {
    let isBusinessView = this.router.url.split("/").includes("business");
    return isBusinessView || false;
  }
  get business_id() {
    return this.storage.get("business_id");
  }

  getInteractNotifications(filter: { page: string; size: string }) {
    return this.httpService.getRequest({
      customApiUrl: this.BASE_URL.GET_INTERACT_NOTIFICATION(this.business_id),
      page: filter.page,
      size: filter.size,
    });
  }

  getHasOutletAccess(): Promise<any[]> {
    return new Promise((resolve) => {
      this.dashboardService.getOutletListDropdownAPI().subscribe((res) => {
        if (res.code == 200) {
          resolve(res.data.outlet_list);
        }
      });
    });
  }
}
