<div class="business-main-container">
  <ngx-loading [show]="loading"></ngx-loading>
  <ng-container *ngFor="let notification of notifications">
    <app-business-notification
      [notificationType]="'page'"
      [notification]="notification"
    ></app-business-notification>
  </ng-container>
  <div class="d-flex" *ngIf="!notifications?.length">
    <p style="text-align: center; width: 100%; padding: 80px 0px">
      No Notification Found!
    </p>
  </div>
</div>
