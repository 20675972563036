<ngx-ui-loader></ngx-ui-loader>
<div class="row">

  <div class="col-sm-6 col-md-6 col-lg-6 p-0 mob-hide">
    <app-onboard-random-ui [isMargin]="true" [isBottomCircleReq]="false"></app-onboard-random-ui>
  </div>

  <div class="col-md-6 col-lg-6 min-height-100 h-100 mob-h-auto">
    <div class="col-xs-12 login_page">
      <form class="business-onboarding" [formGroup]="business_detail_form">
        <img src="assets/images/zceppa.png" class="signpcontimg" />
        <div class="col-lg-12 col-md-12 col-xs-12 p-0 pr-20-percent">
          <label class="heading mb-0">Sign Up</label>
          <div class="row">
            <!-- <h4 class="col-12">Enter your Business Details</h4> -->
            <div class="input-group login_sec">
              <input
                class="form-control"
                formControlName="first_name"
                [ngClass]="{
                  'is-invalid':
                    business_details_submit === false &&
                    businessDetailsData.first_name.errors
                }"
                id="firstName"
                placeholder="Full Name"
                pattern="[a-zA-Z ]*"
                required
              />
              <div
                *ngIf="businessDetailsData.first_name.errors?.pattern"
                class="invalid-feedback text-left"
              >
                <p class="mb-0">Name should be in alphabets only</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="input-group login_sec col-12 p-0 w-100">
              <input
                class="form-control"
                formControlName="email"
                (keyup)="checkmail()"
                [ngClass]="{
                  'is-invalid':
                    business_details_submit === false &&
                    businessDetailsData.email.errors
                }"
                id="ownerEmail"
                placeholder="Owner Email"
              />
              <div
                *ngIf="emailnotvalid != true"
                class="invalid-feedback text-left"
              >
                <p class="mb-0">Email is not valid</p>
              </div>
            </div>
          </div>
          <div class="row col-12 p-0 login_sec" *ngIf="countryLoader">
            <ngx-intl-tel-input
              style="width: 100%; height: 46px"
              [cssClass]="'custom'"
              [selectedCountryISO]="selectedCountry.India"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.International"
              name="phone"
              formControlName="phone"
              [ngClass]="{
                'is-invalid':
                  business_details_submit === false &&
                  businessDetailsData.phone.errors
              }"
              [onlyCountries]="onlyCountries"
            >
            </ngx-intl-tel-input>
          </div>
          <div class="row">
            <div class="input-group login_sec business col-12 p-0 w-100">
              <input
                class="form-control"
                formControlName="business_name"
                [ngClass]="{
                  'is-invalid':
                    business_details_submit === false &&
                    businessDetailsData.business_name.errors
                }"
                id="business_name"
                name="business_name"
                placeholder="Business Name"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 p-0">
          <label class="heading mb-0">Which products catch your interest?</label>
          <div class="product-container" *ngFor="let product of products; let i = index">
            <label class="checkbox-container">
              <input [id]="product.product_id" name="optional" [value]="product.product_id"
                type="checkbox"
                class="style-checkbox"
                [checked]="selectedProduct[i] === true"
                (change)="onProductSelect(product.product_id, i)"/>
              <span class="checkmark"></span>
            </label>

            <div class="image-container ml-3">
              <img [src]="product.icon">
            </div>
            <div class="name-container">
              <label class="product-name mb-0"> {{product?.product_name}} </label>
            </div>
            <div class="vertical-divider"> </div>
            <label class="product-text mb-0"> {{product?.description}} </label>
          </div>

          <div class="row">
            <label class="checkbox-container terms-text">
              <span class="free-trail-text ml-2">Agreed to </span>
              <a href="https://zceppa.com/terms-and-conditions" target="_blank">terms and conditions</a>
              <input type="checkbox" class="form-control style-checkbox"
                formControlName="termsAndCondition"/>
              <span class="checkmark"></span>
            </label>
          </div>

          <div *ngIf="errorUiVisible" class="mt-3">
            <app-error-toast-ui [imageUrl]="'assets/images/onboarding/error-toastr.png'"
              [headerText]="errorUiMsg" (outputDataClose)="errorBannerClose($event)">
            </app-error-toast-ui>
          </div>

          <div class="row">
            <div class="login_sec w-100">
              <button type="button"
                class="submit-btn w-100"
                (click)="onBusinessDetailsSubmit(business_detail_form)"
                [disabled]="submitClicked">
                Sign Up
              </button>
            </div>
          </div>
          <div class="row">
            <label class="free-trail-text w-100">You are getting a free 30-day trial</label>
          </div>
          <div class="row mt-3">
            <div class="text-center w-100">
              <span class="already-registered-text">Already registered? </span>
              <span class="login-text cursor-pointer" (click)="goToLogin()">Log In</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #accessSuccessModal let-modal>
  <div class="modal-body logotp_pop">
    <img src="assets/images/success.svg" class="accessdeniedlogo" />
    <h4>Registration Successful</h4>
  </div>
</ng-template>

<ng-template #accessDeniedpopModal let-modal>
  <div class="modal-body logotp_pop text-center">
    <img src="assets/images/accessdenied.svg" class="accessdeniedlogo" />
    <h4>Access Denied</h4>
  </div>
</ng-template>
