import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InteractNotificationMainComponent } from "./interact-notification-main/interact-notification-main.component";

const routes: Routes = [
  {
    path: "interact-notifications",
    component: InteractNotificationMainComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InteractRoutingModule {}
