<div class="row">
  <div class="col-sm-6 col-md-6 col-lg-6 p-0 mob-hide">
    <app-onboard-random-ui></app-onboard-random-ui>
  </div>
  <div class="col-sm-6 col-md-6 col-lg-6 min-height-100 h-100 mob-h-auto">

    <ngx-loading [show]="loading"></ngx-loading>
    <div class="col-xs-12 login_page">
      <img src="assets/images/zceppa.png" class="signpcontimg" />

      <div class="col-lg-12 col-md-12 col-xs-12 login_sec">
        <div class="form-group mt-3 mb-0">
          <p class="mb-0 heading">OTP</p>
          <p class="description mb-3 mb-0">
            OTP sent to {{phoneNumber}} for verification. Please enter the OTP to get started.
          </p>
          <ng-otp-input #ngOtpInput class="mt-2"
            (onInputChange)="otpChange($event)"
            [config]="otpConfig">
          </ng-otp-input>

          <p class="mb-3 mt-2">
            {{ attemptsLeft }} {{ attemptsLeft == 0 || attemptsLeft == 1 ? "attempt" : "attempts" }}
            remaining
          </p>
        </div>
        <div class="form-group">
          <button type="button" class="submit-btn w-100" (click)="onSubmit()"
            [disabled]="disableSubmit">
            Submit
          </button>
        </div>

        <div class="col-md-12 col-lg-12 p-0">
          <p class="otp-options not-received-text">
            Did not Receive?
            <span class="resend-otp-color signpcont5" (click)="resendOTP()"
              [ngClass]="{ 'resend-disabled': disableResend}">Resend OTP</span>
          </p>
        </div>
        <div *ngIf="(otpTyped && disableSubmit && show_error_message) || attemptsLeft == 1 || disableResend" class="alert alert-danger">
          <p *ngIf="attemptsLeft == 1" class="mb-1">
            You made too many incorrect attempts. Your account will get locked
            with the next incorrect attempt. Please contact support@zceppa.com
          </p>
          <p *ngIf="disableResend" class="mb-1">
            You have exceed your limit please retry next day.
          </p>
        </div>

        <div *ngIf="resendClicked">
          <app-success-toast-ui [isVisible]="resendClicked" [imageUrl]="'assets/images/onboarding/sucess-toast.png'"
            [headerText]="'OTP Sent'" (outputData)="bannerClose($event)">
          </app-success-toast-ui>
        </div>

        <div *ngIf="errorUiVisible">
          <app-error-toast-ui [imageUrl]="'assets/images/onboarding/error-toastr.png'"
            [headerText]="errorUiMsg" (outputDataClose)="errorBannerClose($event)">
          </app-error-toast-ui>
        </div>

      </div>
    </div>

  </div>
</div>


<!-- <ng-template #accessDeniedModal let-modal>
  <div class="modal-body logotp_pop">
    <img src="assets/images/accessdenied.svg" class="accessdeniedlogo" />
    <h4>
      <span> Access Denied </span>
    </h4>
    <p>{{ show_error_message }}</p>
    <button type="button" class="btn btn-light" (click)="modal.close()">
      Ok
    </button>
  </div>
</ng-template> -->
