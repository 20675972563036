<ngx-loading [show]="loading"></ngx-loading>

<div class="col-md-12 col-sm-12 col-xs-12">
  <div class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 reviewbottom">
    <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureview">
      <span
        ><img
          [src]="logo_url"
          class="img-responsive"
          *ngIf="logo_url != undefined"
        />
        <span class="bus_name_review">{{ business_name }}</span></span
      >
      <!-- <p>Your experience is what matters to us. <br/>Review your experience with us!</p> -->
    </div>
    <div class="col-md-12 col-col-sm-12 col-xs-12 text-center">
      <h5>{{ reviewp }} {{ customer_name }}</h5>
      <p>{{ reviewp1 }}</p>
    </div>

    <div class="col-md-12 col-col-sm-12 col-xs-12 text-center">
      <button
        class="btn"
        (click)="openSocialReview()"
        [ngStyle]="reviewlikeStyle"
        style="background: #fff"
      >
        <img src="{{ reviewlike }}" width="30px" />
      </button>
    </div>
    <div class="col-md-12 col-col-sm-12 col-xs-12 text-center">
      <button
        class="btn"
        (click)="needImproveModal()"
        [ngStyle]="reviewdislikeStyle"
        style="background: #fff"
      >
        <img src="{{ reviewdislike }}" width="30px" />
      </button>
    </div>
    <!-- <div class="col-md-12 col-col-sm-12 col-xs-12 text-center reviewneedsimpbtn">
            <ngb-rating class='star-rating' [rate]="3" (hover)="hovered=$event" (leave)="hovered=0" [starTemplate]="ratingStar"  max="5"></ngb-rating>
        </div> -->
    <!-- <div class="col-md-12 col-col-sm-12 col-xs-12 thankyoureviewfooter">
            <p>Powered by</p>
            <img src="assets/images/zceppadark.svg" class="img-responsive">
        </div> -->
  </div>
</div>
<footer>
  <div class="col-md-12 col-col-sm-12 col-xs-12">
    <div
      class="col-md-4 offset-md-4 col-sm-4 offset-sm-4 col-xs-12 thankyoureviewfooter"
    >
      <p>{{ poweredby }}</p>
      <img src="{{ poweredby1 }}" class="img-responsive" />
    </div>
  </div>
</footer>

<ng-template #needimproveModal>
  <div class="col-lg-12 col-md-12 col-sm-12 feedbackimprove">
    <h4>Please share your feedback to help us improve!</h4>
    <div class="col-md-12 col-sm-12">
      <div class="form-group">
        <textarea placeholder="Feedback" [(ngModel)]="feedback"></textarea>
      </div>
      <div class="form-group text-center">
        <ngb-rating
          class="star-rating"
          [starTemplate]="ratingStar"
          [(rate)]="selected_rating"
          (hover)="ratings = $event"
          [readonly]="readonly"
        ></ngb-rating>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 text-center">
      <button class="action-button btn" (click)="thankyouModal()">Ok</button>
    </div>
  </div>
</ng-template>
<ng-template #thankyoumodal>
  <div class="col-lg-12 col-md-12 col-sm-12 feedbackimprove">
    <h3>
      Thank you for your feedback. We will look into it and get back to you
      shortly
    </h3>
    <div class="col-md-12 col-sm-12 text-center">
      <button class="action-button btn" (click)="closeModal()">Ok</button>
    </div>
  </div>
</ng-template>

<ng-template #ratingStar let-fill="fill">
  <span class="star" [class.full-star]="fill === 100">
    <span class="half-star" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>
