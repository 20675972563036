import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
// import { RequestOptions, Headers, Http, HttpModule } from '@angular/http';
import { LOCAL_STORAGE, WebStorageService } from "ngx-webstorage-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GlobalVariable } from "../app.component";

@Injectable({
  providedIn: "root",
})
export class DataService {
  BASE_API: string = GlobalVariable.BASE_API_URL;
  BASE_API_URL_ADMIN: string = GlobalVariable.BASE_API_URL_ADMIN;
  private API_LIST = GlobalVariable;
  response_data: any;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private http: HttpClient,
    private httpservice: HttpClient
  ) {}

  /**
   * Common Http get method
   * @param customApiUrl
   * @param id
   * @param slug
   * @param cust_id
   */
  get(
    customApiUrl,
    id = null,
    slug = null,
    cust_id = null
  ): Observable<Response> {
    if (id !== null) {
      const myParams = { outlet_id: id };
      return this.http
        .get(this.BASE_API + customApiUrl, {
          observe: "response",
          params: myParams,
        })
        .pipe(map((res) => this.returnValue(res)));
    } else if (slug !== null) {
      return this.http
        .get(this.BASE_API + customApiUrl + "?slug=" + slug, {
          observe: "response",
        })
        .pipe(map((res) => this.returnValue(res)));
    } else if (cust_id !== null) {
      return this.http
        .get(this.BASE_API + customApiUrl, {
          observe: "response",
          params: cust_id,
        })
        .pipe(map((res) => this.returnValue(res)));
    } else {
      return this.http
        .get(this.BASE_API + customApiUrl, { observe: "response" })
        .pipe(map((res) => this.returnValue(res)));
    }
  }

  public getWithoutBaseUrl(customApiUrl) {
    // Create an instance of HttpHeaders with the desired headers
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
      }),
    };
    return this.httpservice.get(customApiUrl, httpOptions);
  }

  /**
   * Common http post method with json data
   * @param customApiUrl
   * @param data
   */
  postjson(customApiUrl, data = null): Observable<Response> {
    return this.http
      .post(this.BASE_API + customApiUrl, data, { observe: "response" })
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   *Post method with query params
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @returns
   * @memberof DataService
   */
  post(customApiUrl, data = null, id = null) {
    const myParams = { outlet_id: id };
    return this.http
      .post(this.BASE_API + customApiUrl + "?outlet_id=" + id, data, {
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }
  /**
   * @memberof DataService
   */
  postRequest({
    customApiUrl = "",
    data = {},
    outlet_id = null,
    start = null,
    end = null,
    assigned_to = null,
    upload_location = null,
    source = null,
  }: postRequestInterface = {}) {
    let queryParams = {
      ...(outlet_id !== null && { outlet_id }),
      ...(start !== null && { start }),
      ...(end !== null && { end }),
      ...(assigned_to !== null && { assigned_to }),
      ...(upload_location !== null && { upload_location }),
      ...(source !== null && { source }),
    };

    return this.http
      .post(`${this.BASE_API}${customApiUrl}`, data, {
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }
  postOnboard({
    customApiUrl = "",
    data = {},
    outlet_id = null,
    start = null,
    end = null,
    assigned_to = null,
    upload_location = null,
    source = null,
  }: postRequestInterface = {}) {
    let queryParams = {
      ...(outlet_id !== null && { outlet_id }),
      ...(start !== null && { start }),
      ...(end !== null && { end }),
      ...(assigned_to !== null && { assigned_to }),
      ...(upload_location !== null && { upload_location }),
      ...(source !== null && { source }),
    };
    const head = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${this.storage.get("admin_access_token")}`,
      // 'processData': 'false',
      // 'mimeType': 'multipart/form-data',
    });
    const options = { headers: head };

    return this.http
      .post(`${this.BASE_API}${customApiUrl}`, data, {
        headers: head,
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  //  postOnboard(customApiUrl, data = null) {
  //   const head = new HttpHeaders({
  //     'Access-Control-Allow-Origin': '*',
  //     'Authorization': `Bearer ${this.storage.get('access_token')}`,
  //     // 'processData': 'false',
  //     // 'mimeType': 'multipart/form-data',
  //   });
  //   const options = { headers: head };
  //   return this.httpservice.post(this.BASE_API + customApiUrl, data, { headers: head,observe: 'response' }).pipe(map(res => this.returnValue(res)));
  // }

  /**
   *
   * @param param0 ADMIN TESTING API GET
   */
  getRequestAdmin({ customApiUrl = "" }: getRequestInterface = {}) {
    let queryParams = {
      // ...(page !== null && { page }),
    };

    return this.http
      .get(`${this.BASE_API_URL_ADMIN}${customApiUrl}`, {
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }
  getRequestAdminAuth({ customApiUrl = "" }: getRequestInterface = {}) {
    let queryParams = {
      // ...(page !== null && { page }),
    };
    const head = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${this.storage.get("admin_access_token")}`,
      // 'processData': 'false',
      // 'mimeType': 'multipart/form-data',
    });
    const options = { headers: head };

    return this.http
      .get(`${this.BASE_API_URL_ADMIN}${customApiUrl}`, {
        params: queryParams,
        headers: head,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   *
   * @param param0 ADMINTESTING API POST
   */

  postRequestAdmin({
    customApiUrl = "",
    data = {},
    outlet_id = null,
  }: postRequestInterface = {}) {
    let queryParams = {
      ...(outlet_id !== null && { outlet_id }),
    };

    return this.http
      .post(`${this.BASE_API_URL_ADMIN}${customApiUrl}`, data, {
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  getRequest({
    customApiUrl = "",
    customer_list = null,
    contact_tag = null,
    conversation_tag = null,
    page = null,
    size = null,
    order = null,
    source = null,
    review_id = null,
    interact_id = null,
    outlet_id = null,
    start = null,
    end = null,
    booked_start_date = null,
    booked_end_date = null,
    min_rating = null,
    max_rating = null,
    start_date = null,
    rating = null,
    end_date = null,
    date = null,
    status = null,
    order_status = null,
    delivery_type = null,
    payment_status = null,
    keyword = null,
    slot = null,
    slot_time = null,
    assigned_to = null,
    product = null,
    post_type = null,
    filter_by = null,
    data = null,
    team_member = null,
    team_members = null,
    categories = null,
    booked_type = null,
    video_conference = null,
    enquiry_converted = null,
    ticket = null,
    download = null,
    default_qa_sort = null,
    sort_order = null,
    sort_by = null,
    list_format = null,
    account_classification = null,
    is_followed = null,
    is_unread = null,
    channel = null,
    tag_member = null,
    view = null,
    location_groups = null,
    cities = null,
    gbp_status = null,
    id = null,
    location_group_id = null,
    city_id = null,
    outlet_status = null,
    reviews_with_comments = null,
    is_review_share_template = null,
    source_list = null,
    template_category = null,
    template_type = null,
    is_setup_completed = null,
    activity = null,
    feed_unread = null,
    is_default = null,
  }: getRequestInterface = {}) {
    let queryParams = {
      ...(customer_list !== null && { customer_list }),
      ...(contact_tag !== null && { contact_tag }),
      ...(conversation_tag !== null && { conversation_tag }),
      ...(page !== null && { page }),
      ...(size !== null && { size }),
      ...(order !== null && { order }),
      ...(source !== null && { source }),
      ...(review_id !== null && { review_id }),
      ...(interact_id !== null && { interact_id }),
      ...(product !== null && { product }),
      ...(start !== null && { start }),
      ...(end !== null && { end }),
      ...(start_date !== null && { start_date }),
      ...(end_date !== null && { end_date }),
      ...(booked_start_date !== null && { booked_start_date }),
      ...(booked_end_date !== null && { booked_end_date }),
      ...(rating !== null && { rating }),
      ...(min_rating !== null && { min_rating }),
      ...(max_rating !== null && { max_rating }),
      ...(date !== null && { date }),
      ...(outlet_id !== null && { outlet_id }),
      ...(status !== null && { status }),
      ...(order_status !== null && { order_status }),
      ...(delivery_type !== null && { delivery_type }),
      ...(payment_status !== null && { payment_status }),
      ...(keyword !== null && { keyword }),
      ...(slot !== null && { slot }),
      ...(slot_time !== null && { slot_time }),
      ...(assigned_to !== null && { assigned_to }),
      ...(filter_by !== null && { filter_by }),
      ...(data !== null && { data }),
      ...(team_member !== null && { team_member }),
      ...(team_members !== null && { team_members }),
      ...(categories !== null && { categories }),
      ...(booked_type !== null && { booked_type }),
      ...(video_conference !== null && { video_conference }),
      ...(enquiry_converted !== null && { enquiry_converted }),
      ...(ticket !== null && { ticket }),
      ...(download !== null && { download }),
      ...(default_qa_sort !== null && { default_qa_sort }),
      ...(sort_order !== null && { sort_order }),
      ...(sort_by !== null && { sort_by }),
      ...(list_format !== null && { list_format }),
      ...(account_classification !== null && { account_classification }),
      ...(post_type !== null && { post_type }),
      ...(is_followed !== null && { is_followed }),
      ...(is_unread !== null && { is_unread }),
      ...(channel !== null && { channel }),
      ...(tag_member !== null && { tag_member }),
      ...(view !== null && { view }),
      ...(location_groups !== null && { location_groups }),
      ...(cities !== null && { cities }),
      ...(gbp_status !== null && { gbp_status }),
      ...(id !== null && { id }),
      ...(location_group_id !== null && { location_group_id }),
      ...(city_id !== null && { city_id }),
      ...(outlet_status !== null && { outlet_status }),
      ...(reviews_with_comments !== null && { reviews_with_comments }),
      ...(is_review_share_template !== null && { is_review_share_template }),
      ...(source_list !== null && { source_list }),
      ...(template_category !== null && { template_category }),
      ...(template_type !== null && { template_type }),
      ...(is_setup_completed !== null && { is_setup_completed }),
      ...(activity !== null && { activity }),
      ...(feed_unread !== null && { feed_unread }),
      ...(is_default !== null && { is_default }),
    };

    return this.http
      .get(`${this.BASE_API}${customApiUrl}`, {
        params: queryParams,
        observe: "response",
      })
      .pipe(
        map((res) => {
          return this.returnValue(res);
        })
        // retryWhen((errors) => {
        //   console.log("er", errors);
        //   return errors.pipe(
        //     scan((acc, error) => {
        //       let customErr = { ...error, show_popup: true };
        //       if (acc > 2) throw customErr;
        //       console.log("attempt " + acc);
        //       return acc + 1;
        //     }, 1),
        //     delay(3000)
        //     // tap((res) => {
        //     //   console.log(res);
        //     //   return false;
        //     // })
        //   );
        //   // errors.pipe(map((err) => console.log(err)));

        //   // return errors.pipe(retry(3), delay(2000));
        // })
      );
  }

  deleteRequest({
    customApiUrl = "",
    appointment_id = null,
    media_id = null,
    product_id = null,
    source_id = null,
    source = null,
    is_ignore = null,
    comment_id = null,
  }: deleteRequestInterface = {}) {
    let queryParams = {
      ...(appointment_id !== null && { appointment_id }),
      ...(media_id !== null && { media_id }),
      ...(product_id !== null && { product_id }),
      ...(source_id !== null && { source_id }),
      ...(source !== null && { source }),
      ...(is_ignore !== null && { is_ignore }),
      ...(comment_id !== null && { comment_id }),
    };

    return this.http
      .delete(this.BASE_API + customApiUrl, {
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  deleteRequestWithPayload({
    customApiUrl = "",
    data = {},
    appointment_id = null,
    media_id = null,
    product_id = null,
    source_id = null,
    source = null,
  }: deleteRequestInterface = {}) {
    let queryParams = {
      ...(appointment_id !== null && { appointment_id }),
      ...(media_id !== null && { media_id }),
      ...(product_id !== null && { product_id }),
      ...(source_id !== null && { source_id }),
      ...(source !== null && { source }),
    };

    return this.http
      .delete(this.BASE_API + customApiUrl, {
        params: queryParams,
        body: data,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  putRequest({
    customApiUrl = "",
    data = {},
    outlet_id = null,
    start = null,
    end = null,
    upload_location = null,
    source = null,
    interact_id = null,
  }: putRequestInterface) {
    let queryParams = {
      ...(start !== null && { start }),
      ...(end !== null && { end }),
      ...(outlet_id !== null && { outlet_id }),
      ...(upload_location !== null && { upload_location }),
      ...(source !== null && { source }),
      ...(interact_id !== null && { interact_id }),
    };

    return this.http
      .put(this.BASE_API + customApiUrl, data, {
        params: queryParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  getUserPermissions({
    customApiUrl = "",
    userId = "",
  }: getUserPermissionsInterface) {
    return this.http
      .get(`${this.BASE_API}${customApiUrl}/${userId}`, { observe: "response" })
      .pipe(map((res) => this.returnValue(res)));
  }
  putUserPermissions({
    customApiUrl = "",
    data = {},
    userId = "",
  }: putUserPermissionsInterface) {
    return this.http
      .put(`${this.BASE_API}${customApiUrl}/${userId}`, data, {
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   * Send form data to server
   *
   * @param {*} customApiUrl
   * @param {*} [data=null]
   * @returns
   * @memberof DataService
   */
  postFormData(customApiUrl, data = null) {
    const head = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${this.storage.get("access_token")}`,
      // 'processData': 'false',
      mimeType: "multipart/form-data",
    });
    const options = { headers: head };
    return this.httpservice
      .post(this.BASE_API + customApiUrl, data, {
        headers: head,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   *Common update function put method
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @memberof DataService
   */
  put(customApiUrl, data = null, id = null, flag = null) {
    if (id !== null && flag === null) {
      return this.http
        .put(this.BASE_API + customApiUrl + "?outlet_id=" + id, data, {
          observe: "response",
        })
        .pipe(map((res) => this.returnValue(res)));
    } else if (id !== null && flag !== null) {
      return this.http
        .put(
          this.BASE_API +
            customApiUrl +
            "?outlet_id=" +
            id +
            "&consumer_choice=" +
            data,
          { observe: "response", params: data }
        )
        .pipe(map((res) => this.returnValue(res)));
    } else {
      return this.http
        .put(this.BASE_API + customApiUrl, data, { observe: "response" })
        .pipe(map((res) => this.returnValue(res)));
    }
  }

  /**
   * Common create function with put method json data
   *
   * @param {*} customApiUrl
   * @param {*} data
   * @returns
   * @memberof DataService
   */
  putjson(customApiUrl, data, qury_params = null) {
    //console.log(qury_params);
    if (qury_params === null) {
      return this.http
        .put(this.BASE_API + customApiUrl, data, { observe: "response" })
        .pipe(map((res) => this.returnValue(res)));
    } else {
      return this.http
        .put(this.BASE_API + customApiUrl, data, {
          observe: "response",
          params: qury_params,
        })
        .pipe(map((res) => this.returnValue(res)));
    }
  }

  /**
   * Common delete method
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @memberof DataService
   */
  delete(customApiUrl, id = null, out_let = null) {
    const myParams = { template_id: id };
    return this.http
      .delete(this.BASE_API + customApiUrl, {
        params: myParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
    // return this.http.delete(this.BASE_API + customApiUrl, { observe: 'response' })
    //   .pipe(map(res => this.returnValue(res)));
  }

  /**
   * Common delete method
   * @param {*} customApiUrl
   * @param {*} data
   * @memberof DataService
   */
  deleteRecords(customApiUrl, data = null) {
    const myParams = data;
    return this.http
      .delete(this.BASE_API + customApiUrl, {
        params: myParams,
        observe: "response",
      })
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   * Common service function for api call
   * @param customApiUrl ;
   * @param data ;
   */
  commonServiceFunction(
    customApiUrl,
    page = null,
    size = null,
    sort_order = null,
    type = null,
    source = null,
    rating = null,
    start = null,
    end = null,
    min = null,
    max = null,
    keyword = null
  ): Observable<Response> {
    if (type === null) {
      if (page != null && sort_order === null) {
        // const myParams = { 'outlet_id': data, 'page': page, 'size': size, 'order': order };
        const myParams = { page: page, size: size, sort_order: sort_order };
        return this.http
          .get(this.BASE_API + customApiUrl, {
            params: myParams,
            observe: "response",
          })
          .pipe(map((res) => this.returnValue(res)));
      } else if (page == null && sort_order === null) {
        console.log("indside if in common servcice function");
        const myParams = {};
        // const myParams = { 'outlet_id': data };
        return this.http
          .get(this.BASE_API + customApiUrl, {
            params: myParams,
            observe: "response",
          })
          .pipe(map((res) => this.returnValue(res)));
      } else if (page !== null && sort_order !== null) {
        const myParams = { page: page, size: size, sort_order: sort_order };
        // const myParams = { 'outlet_id': data, 'page': page, 'size': size, 'order': order };
        return this.http
          .get(this.BASE_API + customApiUrl, {
            params: myParams,
            observe: "response",
          })
          .pipe(map((res) => this.returnValue(res)));
      } else {
        return this.http
          .get(this.BASE_API + customApiUrl, { observe: "response" })
          .pipe(map((res) => this.returnValue(res)));
      }
    } else {
      let temp_source: any;
      let temp_rating: any;
      let temp_start: any;
      let temp_end: any;
      let temp_min: any;
      let temp_max: any;
      let temp_keyword: string;
      if (source === null || source === "") {
        temp_source = "";
      } else {
        temp_source = "&source=" + source;
      }
      if (rating === null || rating === "undefined") {
        temp_rating = "";
      } else {
        temp_rating = "&rating=" + rating;
      }
      if (start === null) {
        temp_start = "";
      } else {
        temp_start = "&start=" + start;
      }
      if (end === null) {
        temp_end = "";
      } else {
        temp_end = "&end=" + end;
      }
      if (min === null) {
        temp_min = "";
        temp_max = "";
        temp_rating = "";
      } else {
        temp_min = "&min_rating=" + min + "&max_rating=" + max;
      }
      if (keyword === null || keyword === "") {
        temp_keyword = "";
      } else {
        temp_keyword = "&keyword=" + keyword;
      }
      return this.http
        .get(
          this.BASE_API +
            customApiUrl +
            "?" +
            "page=" +
            page +
            "&sort_order=" +
            sort_order +
            "&size=" +
            size +
            temp_source +
            temp_rating +
            temp_start +
            temp_end +
            temp_min +
            temp_keyword,
          { observe: "response" }
        )
        .pipe(map((res) => this.returnValue(res)));
    }
  }
  // commonServiceFunction(customApiUrl, data = null, page = null, size = null, order = null,
  //   type = null, source = null, rating = null, start = null, end = null, min = null, max = null): Observable<Response> {
  //     console.log(order)
  //     if (type === null) {
  //     if (data != null && page != null && order === null) {
  //       // const myParams = { 'outlet_id': data, 'page': page, 'size': size, 'order': order };
  //       const myParams = {  'page': page, 'size': size, 'order': order };
  //       return this.http.get(this.BASE_API + customApiUrl,
  //         { params: myParams, observe: 'response' }).pipe(map(res => this.returnValue(res)));
  //     } else if (data != null && page == null && order === null) {
  //       console.log("indside if in common servcice function");
  //       const myParams = { };
  //       // const myParams = { 'outlet_id': data };
  //       return this.http.get(this.BASE_API + customApiUrl,
  //         { params: myParams, observe: 'response' }).pipe(map(res => this.returnValue(res)));
  //     } else if (data !== null && page !== null && order !== null) {
  //       const myParams = {  'page': page, 'size': size, 'order': order };
  //       // const myParams = { 'outlet_id': data, 'page': page, 'size': size, 'order': order };
  //       return this.http.get(this.BASE_API + customApiUrl,
  //         { params: myParams, observe: 'response' }).pipe(map(res => this.returnValue(res)));
  //     } else {
  //       return this.http.get(this.BASE_API + customApiUrl, { observe: 'response' }).pipe(map(res => this.returnValue(res)));
  //     }
  //   } else {
  //     let temp_source: any;
  //     let temp_rating: any;
  //     let temp_start: any;
  //     let temp_end: any;
  //     let temp_min: any;
  //     let temp_max: any;
  //     if (source === null || source === '') {
  //       temp_source = '';
  //     } else {
  //       temp_source = '&source=' + source;
  //     }
  //     if (rating === null || rating === 'undefined') {
  //       temp_rating = '';
  //     } else {
  //       temp_rating = '&rating=' + rating;
  //     }
  //     if (start === null) {
  //       temp_start = '';
  //     } else {
  //       temp_start = '&start=' + start;
  //     }
  //     if (end === null) {
  //       temp_end = '';
  //     } else {
  //       temp_end = '&end=' + end;
  //     }
  //     if (min === null) {
  //       temp_min = '';
  //       temp_max = '';
  //       temp_rating = '';
  //     } else {
  //       temp_min = '&min_rating=' + min + '&max_rating=' + max;
  //     }
  //     return this.http.get(this.BASE_API + customApiUrl + '?outlet_id=' + data + '&page=' + 1 + '&order=' + order
  //       + '&size=' + size + temp_source + temp_rating + temp_start + temp_end + temp_min,
  //       { observe: 'response' }).pipe(map(res => this.returnValue(res)));
  //   }
  // }

  /**
   * Get otp api list
   */
  getOTP(mobile_number: number): Observable<Response> {
    const body = { mobile_number: mobile_number.toString() };
    return this.http
      .put(this.BASE_API + this.API_LIST.OTP_URL, body, { observe: "response" })
      .pipe(map((res) => this.returnValue(res)));
  }

  analyticsSliceAndDice(
    customApiUrl,
    team = null,
    channel = null,
    followed = null,
    tagteam = null,
    start = null,
    end = null,
    page,
    size,
    keyword = null
  ) {
    let temp_team: any;
    let temp_channel: any;
    let temp_followed: any;
    let temp_tagteam: any;
    let temp_start: any;
    let temp_end: any;
    let temp_page: any;
    let temp_size: any;
    let key_word: any;
    console.log(size, page);

    if (page === null) {
      temp_page = "";
    } else {
      temp_page = "&page=" + page;
    }
    if (size === null) {
      temp_size = "";
    } else {
      temp_size = "&size=" + size;
    }
    if (team === null) {
      temp_team = "";
    } else {
      temp_team = "&team_member=" + team;
    }
    if (channel === null) {
      temp_channel = "";
    } else {
      temp_channel = "&channel=" + channel;
    }
    if (keyword === null) {
      key_word = "";
    } else {
      key_word = "&keyword=" + keyword;
    }
    if (followed === null) {
      temp_followed = "";
    } else {
      if (followed === "true") {
        temp_followed = "&is_followed=" + true;
      } else if (followed === "false") {
        temp_followed = "&is_followed=" + false;
      }
    }

    if (tagteam === null) {
      temp_tagteam = "";
    } else {
      temp_tagteam = "&tag_member=" + tagteam;
    }

    if (start === null) {
      temp_start = "";
    } else {
      temp_start = "&start=" + start;
    }
    if (end === null) {
      temp_end = "";
    } else {
      temp_end = "&end=" + end;
    }

    return this.http
      .get(
        this.BASE_API +
          customApiUrl +
          "?" +
          temp_team +
          temp_channel +
          temp_followed +
          temp_tagteam +
          temp_start +
          temp_end +
          temp_page +
          temp_size +
          key_word,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  downloadReportExcel(
    customApiUrl,
    team = null,
    channel = null,
    source = null,
    followed = null,
    tagteam = null,
    start = null,
    end = null,
    page,
    size = null,
    ticket = null,
    keyword = null
  ) {
    let temp_team: any;
    let temp_channel: any;
    let temp_followed: any;
    let temp_tagteam: any;
    let temp_start: any;
    let temp_end: any;
    let temp_size: any;
    let temp_page: any;
    let temp_ticket: any;
    let temp_source: any;
    let temp_keyword: any;

    if (team === null) {
      temp_team = "";
    } else {
      temp_team = "&team_member=" + team;
    }
    if (channel === null) {
      temp_channel = "";
    } else {
      temp_channel = "&channel=" + channel;
    }
    if (source === null) {
      temp_source = "";
    } else {
      temp_source = "&source=" + source;
    }
    if (followed === null) {
      temp_followed = "";
    } else {
      if (followed === "true") {
        temp_followed = "&is_followed=" + true;
      } else if (followed === "false") {
        temp_followed = "&is_followed=" + false;
      }
    }

    if (tagteam === null) {
      temp_tagteam = "";
    } else {
      temp_tagteam = "&tag_member=" + tagteam;
    }

    if (start === null) {
      temp_start = "";
    } else {
      temp_start = "&start=" + start;
    }
    if (end === null) {
      temp_end = "";
    } else {
      temp_end = "&end=" + end;
    }
    if (page === null) {
      temp_page = "";
    } else {
      temp_page = "&page=" + page;
    }
    if (size === null) {
      temp_size = "";
    } else {
      temp_size = "&size=" + size;
    }
    if (ticket === null || ticket === undefined) {
      temp_ticket = "";
    } else {
      temp_ticket = "&ticket=" + ticket;
    }
    if (keyword === null || keyword == "") {
      temp_keyword = "";
    } else {
      temp_keyword = "&keyword=" + keyword;
    }

    // return this.http.get(this.BASE_API + customApiUrl + '?outlet_id=' + id + temp_team + temp_channel + temp_followed + temp_tagteam + temp_start + temp_end,
    return this.http
      .get(
        this.BASE_API +
          customApiUrl +
          "?" +
          temp_team +
          temp_channel +
          temp_source +
          temp_followed +
          temp_tagteam +
          temp_start +
          temp_end +
          temp_page +
          temp_size +
          temp_ticket +
          temp_keyword,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  sentdraftSliceAndDice(
    customApiUrl,
    sent_type = null,
    start = null,
    end = null,
    page = null,
    size: any = null
  ) {
    let temp_team: any;
    let temp_start: any;
    let temp_end: any;
    let temp_page: any;
    let temp_size: any;
    if (sent_type === null || sent_type === "all") {
      temp_team = "";
    } else {
      temp_team = "&source=" + sent_type;
    }
    if (start === null) {
      temp_start = "";
    } else {
      temp_start = "&start=" + start;
    }
    if (end === null) {
      temp_end = "";
    } else {
      temp_end = "&end=" + end;
    }
    if (page === null) {
      temp_page = "";
    } else {
      temp_page = "&page=" + page;
    }
    if (size === null) {
      temp_size = "";
    } else {
      temp_size = "&size=" + size;
    }
    // return this.http.get(this.BASE_API + customApiUrl + '?outlet_id=' + id + temp_team + temp_start + temp_end,
    // return this.http.get(this.BASE_API + customApiUrl + '?' + temp_team + temp_start + temp_end,
    return this.http
      .get(
        this.BASE_API +
          customApiUrl +
          temp_team +
          temp_start +
          temp_end +
          temp_page +
          temp_size,
        { observe: "response" }
      )
      .pipe(map((res) => this.returnValue(res)));
  }

  /**
   * Return respospone funtion
   * @param res ;
   */
  returnValue(res: any): Response {
    let response = <Response>{};
    response = { data: res.body, code: res.status };
    return response;
  }
}

export interface Response {
  [x: string]: any;
  data: any;
  code: number;
}

/**
 * adding header and Authorization methods
 * const head = new Headers({ 'Content-type': 'application/json; charset=utf-8',
 * 'Authorization': 'Bearer ${this.storage.get('access_token')}' });
 * const options = new RequestOptions({ headers: head , params: myParams});
 */

interface postRequestInterface {
  customApiUrl?: string;
  data?: any;
  outlet_id?: string;
  start?: string;
  end?: string;
  assigned_to?: string;
  upload_location?: string;
  source?: string;
}

interface getRequestInterface {
  customApiUrl?: string;
  contact_tag?: string | null;
  conversation_tag?: string | null;
  customer_list?: string | null;
  page?: string;
  size?: string;
  order?: string;
  source?: string | null;
  review_id?: string;
  interact_id?: string;
  outlet_id?: string;
  start?: string | null;
  end?: string | null;
  booked_start_date?: string | null;
  booked_end_date?: string | null;
  min_rating?: string;
  max_rating?: string;
  start_date?: string;
  rating?: any;
  end_date?: string;
  post_type?: string;
  date?: string;
  status?: any;
  order_status?: any;
  delivery_type?: any;
  keyword?: string | null;
  payment_status?: string;
  slot?: string;
  slot_time?: string;
  assigned_to?: string;
  product?: string;
  filter_by?: string;
  data?: string;
  team_member?: string;
  team_members?: string | null;
  categories?: string | null;
  booked_type?: string | null;
  video_conference?: string | null;
  enquiry_converted?: string | null;
  ticket?: string;
  download?: string | boolean;
  default_qa_sort?: string;
  sort_order?: string;
  sort_by?: string;
  list_format?: string;
  account_classification?: string;
  is_followed?: string;
  is_unread?: boolean;
  channel?: string;
  tag_member?: string;
  view?: string;
  location_groups?: string;
  cities?: string;
  gbp_status?: string;
  id?: string;
  location_group_id?: string;
  city_id?: string;
  outlet_status?: string;
  reviews_with_comments?: boolean | number;
  is_review_share_template?: number;
  source_list?: string | null;
  template_category?: string;
  template_type?: string;
  is_setup_completed?: boolean | number;
  activity?: string;
  feed_unread?: string;
  is_default?: number;
}

interface putRequestInterface {
  customApiUrl?: string;
  data?: any;
  outlet_id?: string;
  start?: string;
  end?: string;
  upload_location?: string;
  source?: string;
  interact_id?: string;
}
interface deleteRequestInterface {
  customApiUrl?: string;
  appointment_id?: string;
  media_id?: string;
  product_id?: string;
  source_id?: string;
  source?: string;
  data?: any;
  is_ignore?: boolean;
  comment_id?: string;
}

interface getUserPermissionsInterface {
  customApiUrl: string;
  userId: string;
}
interface putUserPermissionsInterface {
  customApiUrl: string;
  data?: any;
  userId: string;
}
