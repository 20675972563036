<div>
  <div *ngIf="bannerType=='error'" class="banner error-banner">
    <img *ngIf="bannerIconRequired" class="banner-icon mr-1" src="assets/images/multi-location/error.svg" alt="Alert"/>
    <span>{{bannerText}} &nbsp;</span>
    <span *ngIf="bannerLink" class="highlight-link" routerLink="{{bannerLink.value}}">{{bannerLink.text}}</span>
    <img *ngIf="bannerCloseIconRequired" src="assets/images/close-grey-icon.svg" class="banner-close-icon" (click)="deleteBanner()"/>
  </div>

  <div *ngIf="bannerType=='warning'" class="banner warning-banner">
    <!-- <img *ngIf="bannerIconRequired" class="banner-icon mr-1" src="assets/images/noun_Warning_32256.svg" alt="Warning"/> -->
    <img *ngIf="bannerIconRequired" class="banner-icon mr-1" src="assets/images/banner/warning.svg" alt="Warning"/>

    <div *ngIf="!bannerSubType">
      <span>{{bannerText}} &nbsp;</span>
      <span *ngIf="bannerLink" class="highlight-link" routerLink="{{bannerLink.value}}">{{bannerLink.text}}</span>
      <img *ngIf="bannerCloseIconRequired" src="assets/images/close-grey-icon.svg" class="banner-close-icon" (click)="deleteBanner()"/>
    </div>

    <div *ngIf="bannerSubType && bannerSubType=='link-between'">
      <span>{{bannerText}}</span>
      <span *ngIf="bannerLink.value" class="highlight-link-between" routerLink="{{bannerLink.value}}">{{bannerLink.text}}</span>
      <span *ngIf="!bannerLink.value" class="highlight-link-between">{{bannerLink.text}}</span>
      <span>{{bannerText2}}</span>
      <img *ngIf="bannerCloseIconRequired" src="assets/images/close-grey-icon.svg" class="banner-close-icon" (click)="deleteBanner()"/>
    </div>

    <div *ngIf="bannerSubType && bannerSubType=='info-prefix'">
      <p>
        <strong *ngIf="bannerInfoPrefixRequired">Info!-</strong>
        {{bannerText}}
      </p>
    </div>
  </div>

  <div *ngIf="bannerType=='info'" class="banner info-banner">
    <p>
      <strong *ngIf="bannerInfoPrefixRequired">Info!-</strong>
      {{bannerText}}
    </p>
  </div>
</div>
