import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BusinessNotificationService } from "../business-notification.service";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-business-notification",
  templateUrl: "./business-notification.component.html",
  styleUrls: ["./business-notification.component.scss"],
})
export class BusinessNotificationComponent implements OnInit {
  @ViewChild("failedUpdateModal", { static: true })
  failedUpdateModal: ElementRef;
  @Input("notification") notification;
  @Input() notificationType: "popup" | "page" = "page";
  errors: any[] = [];
  failedDetails: any;

  constructor(
    private businessNotificationService: BusinessNotificationService,
    private modalService: NgbModal,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  getFailedUpdateDetails() {
    this.openModal(this.failedUpdateModal);
    this.businessNotificationService
      .getFailedUpdateDetails(this.notification?.data?.reference_id)
      .subscribe((response) => {
        console.log(response);
        if (response.code == 200) {
          let failed_outlets = response.data.failed_list;
          let failed_list = [];
          if (Object.keys(failed_outlets).length) {
            failed_list = Object.keys(failed_outlets).map((key) => {
              let errors = failed_outlets[key];
              return errors;
            });
          }
          this.errors = failed_list;
          this.failedDetails = response.data;
          console.log(this.errors);
        }
      });
  }

  openModal(modalTemplate): void {
    this._openModal(modalTemplate);
  }
  private _openModal(modal) {
    this.modalService.open(modal, {
      size: "md",
    });
  }

  clickToRedirect(notification) {
  if (!notification) {
      return;
    }

    if (notification?.category == 'location_bulk_upload') {
      this.router.navigate(["/business/outlet-groups/outlet"]);
    }
  }
}
