<ng-template #customContent>
  <p>
    The left menu has links to all <b>Zceppa features</b> and product your
    purchase.
  </p>
  <p>
    <b>Settings</b> takes you to your Zceppa configurations along with your
    business profile, account details, your plan information and usage metrics.
  </p>
</ng-template>
<ng-template #doneButton>
  <my-button class="joyride-button cursor-pointer">Next</my-button>
  <!-- <my-button class="joyride-button" (click)="(nextView)">Next</my-button> -->
</ng-template>

<ul
  [joyrideStep]="joyRideIsDesktopSidebar()"
  [stepContent]="customContent"
  [doneTemplate]="doneButton"
  (next)="onDone()"
  class="navigation d-flex menu-container mt-3"
  appAccordion
>
  <ng-container
    *ngFor="
      let menuItem of isMultiLocationView
        ? multiLocationMenuItems
        : locationMenuItems;
      let i = index
    "
  >
    <li
      class="navigation-item"
      [routerLink]="[menuItem.route]"
      routerLinkActive="menu-item-active"
      appAccordionLink
      [routerLinkActiveOptions]="{ exact: true }"
      *ngIf="menuItem.title != undefined && showMenuItem(menuItem.title)"
      [class.pointer-disable]="disableOnGatherInfo()"
    >
      <a class="navigation-link" routerLinkActive="menu-item-active">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#' + menuItem.icon"
          [width]="'22px'"
        ></svg-icon-sprite>
        <span class="title mr-auto left_menu"
          >{{ menuItem.title }}

          <!-- <span class="campicon"
            *ngIf="menuItem.title == 'Interact' || menuItem.title == 'Payments' || menuItem.title == 'MyStore'"> new!
            <img width="22px" src="assets/images/new1.png" style="position: relative;top: -18px;left: -22px;">
          </span> -->
        </span>
        <ng-container *ngIf="menuItem.title == 'Interact'" >
          <div class="new-message-notification" *ngIf="totalUniqueUnreadConversation">
            <span class="msg-count"> {{totalUniqueUnreadConversation}} </span>
          </div>
        </ng-container>
        <span class="pr-1" *ngIf="menuItem?.showLockIcon">
          <img src="assets/images/lock-icon.svg" alt="">
        </span>

      </a>
    </li>
  </ng-container>
</ul>

<!-- <div *ngFor="let menuitem of menuService.getAll()">
        <li class="navigation-item" appAccordionLink group="{{menuitem.state}}" *ngIf="menuitem.type !== 'divider' || menuitem.type !== 'title'">
          <a class="navigation-link" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <i class="icon {{ menuitem.icon }} mr-3"></i>
            <span class="title mr-auto">{{ menuitem.name | translate }}</span>
            <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
          <a class="navigation-link" appAccordionToggle href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <i class="icon {{ menuitem.icon }} mr-3"></i>
            <span class="title mr-auto">{{ menuitem.name | translate }}</span>
            <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
           </a>
          <a class="navigation-link" appAccordionToggle href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <i class="icon {{ menuitem.icon }} mr-3"></i>
            <span class="title mr-auto">{{ menuitem.name | translate }}</span>
            <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
          <a class="navigation-link" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <i class="icon {{ menuitem.icon }} mr-3"></i>
            <span class="title mr-auto">{{ menuitem.name | translate }}</span>
            <span class="badge badge-{{ badge.type }} mr-3" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <i class="menu-caret icon ion-ios-arrow-down"></i>
          </a>
          <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub'">
            <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
              <a [routerLink]="['/', menuitem.state, childitem.state ]" class="navigation-link relative">{{ childitem.name | translate }}</a>
            </li>
          </ul>
        </li>
        <li class="navigation-item" *ngIf="menuitem.type === 'divider'">
          <hr/>
        </li>
        <li class="navigation-item" *ngIf="menuitem.type === 'title'">
          <span class="navigation-title">{{ menuitem.name | translate }}</span>
        </li>
      </div>
    </ul> -->
