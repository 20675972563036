import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appExpiryOverlay]",
})
export class ExpiryOverlayDirective {
  @Input() set appExpiryOverlay(isExpired: boolean) {
    this.handleOverlay(isExpired);
  }
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  handleOverlay(isExpired: boolean) {
    const nativeElement = this.element.nativeElement;
    const currentStyles = getComputedStyle(nativeElement);

    if (isExpired) {
      this.renderer.setStyle(nativeElement, 'opacity', '0.2');
      this.renderer.setStyle(nativeElement, 'pointer-events', 'none');
      this.renderer.setStyle(nativeElement, 'filter', 'blur(2px)');
    } else {
      if (currentStyles.opacity === '0.2' &&
          currentStyles.pointerEvents === 'none' &&
          currentStyles.filter === 'blur(2px)') {
        this.renderer.removeStyle(nativeElement, 'opacity');
        this.renderer.removeStyle(nativeElement, 'pointer-events');
        this.renderer.removeStyle(nativeElement, 'filter');
      }
    }
  }
}
